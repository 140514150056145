import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import DOMPurify from 'dompurify';
import Swiper from 'react-id-swiper';
import { commonCodeConverter, ScrollNone, numberFormat } from 'common/utilFn';
import 'css/purchase/PurchaseShowcaseB2C.scss';
import { CommonCode, useMultipleCommonCodes } from 'queries/common/useCommonCode';
import purchaseShowcaseB2CStore, { filteringProductList, getProductList, toggleAnotherProductPrice } from 'store/purchase/purchaseShowcaseB2CStore';
import { ProductPrice } from 'types/purchase/purchase.interface';
import LayerPopup from 'component/LayerPopup';
import { useAllCommonMessage } from 'queries/common/useCommonMessage';

type Props = {
  goToPayment: (priceSeq: number, payType: string, pledge?: boolean) => void;
  openPopup: (popupName: string) => void;
};

const PurchaseShowcaseB2CProduct: FC<Props> = ({ goToPayment: goToPaymentFn, openPopup }) => {
  const { data: commonMessage } = useAllCommonMessage();
  const { isFetched: isFetchedCommonCodes, data: commonCodes = {} } = useMultipleCommonCodes(['PURTABW', 'PURTABM', 'PURPROD', 'PURPRODDT', 'PURPRODW4', 'PURPRODW12', 'PURPRODW24', 'PURPROD2', 'PURPROD3']);

  const productList = purchaseShowcaseB2CStore((state) => state.productList);
  const filteredProductList = purchaseShowcaseB2CStore((state) => state.filteredProductList);
  const selectedProductWeek = purchaseShowcaseB2CStore((state) => state.selectedProductWeek);
  const setSelectedProductWeek = purchaseShowcaseB2CStore((state) => state.setSelectedProductWeek);
  const selectedLessonMode = purchaseShowcaseB2CStore((state) => state.selectedLessonMode);
  const setSelectedLessonMode = purchaseShowcaseB2CStore((state) => state.setSelectedLessonMode);
  const [showSoldoutPopup, setShowSoldoutPopup] = useState(false);
  const [showBillFlagChangePopup, setShowBillFlagChangePopup] = useState(false);
  const [currentBillFlagChangeProduct, setCurrentBillFlagChangeProduct] = useState<ProductPrice | null>(null);

  useEffect(() => {
    getProductList();
  }, []);

  // 주회수 선택
  const handleSelectProductWeek = useCallback((week: number) => {
    setSelectedProductWeek(week);
    filteringProductList();
  }, [setSelectedProductWeek]);

  // 수업 모드 선택
  const handleSelectLessonMode = useCallback((lessonMode: string) => {
    setSelectedLessonMode(lessonMode);
    filteringProductList();
  }, [setSelectedLessonMode]);

  // 수업 모드 필터값: 선택된 주에서 수업모드별로 가장 저렴한 가격 찾기
  const getLessonModeTabValue = useCallback((commonCode: CommonCode) => {
    let value = commonCode.note1;
    if (commonCode.note2) {
      const cheapestPrice = productList
        .filter((product) => product.productWeek === selectedProductWeek && product.lessonMode === commonCode.detailCode)
        .reduce((minPrice, product) => Math.min(minPrice, product.realPrice), Infinity);
      if (cheapestPrice!==Infinity) {
        const fourWeekPrice = 4 * cheapestPrice / selectedProductWeek;
        value += ' '+commonCode.note2.replace('#', numberFormat(fourWeekPrice));
      }
    }
    return value;
  }, [productList, selectedProductWeek]);

  // 첫번째 상세 설명 리스트(주회수에 따라 나뉘어야 함)
  const firstDetailList = useMemo(() => {
    return commonCodes[`PURPRODW${selectedProductWeek}`] || [];
  }, [isFetchedCommonCodes, selectedProductWeek]);

  // 4주당 가격 계산
  const pricePerMonth = (product: ProductPrice) => {
    if (product.showAnotherProductPrice && product.anotherProductPrice)
      return numberFormat(4 * product.anotherProductPrice?.realPrice / product.anotherProductPrice?.productWeek);
    return numberFormat(4 * product.realPrice / product.productWeek);
  };

  // 결제 페이지로 이동
  const goToPayment = useCallback((product: ProductPrice) => {
    if (product.showAnotherProductPrice && product.anotherProductPrice) goToPaymentFn(product.anotherProductPrice.priceSeq, '');
    else goToPaymentFn(product.priceSeq, '');
  }, []);

  // 정기 구독 해제 팝업
  const onClickBillFlag = useCallback((product: ProductPrice) => {
    if (!product.showAnotherProductPrice) {
      ScrollNone(true);
      setCurrentBillFlagChangeProduct(product);
      setShowBillFlagChangePopup(true);
    } else {
      setCurrentBillFlagChangeProduct(product);
      toggleAnotherProductPrice(product.priceSeq);
    }
  }, []);

  // sold out 알림창
  const alertSoldOut = useCallback(() => {
    ScrollNone(true);
    setShowSoldoutPopup(true);
  }, []);

  const swiperParam = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    allowTouchMove: true,
    centeredSlides: true,
    shouldSwiperUpdate: true,
    longSwipes: true,
    breakpoints: {
      1025: {
        allowTouchMove: true,
        centeredSlides: false,
      },
      1377: {
        centeredSlides: false,
        allowTouchMove: false,
      }
    }
  };

  const productDetails = [
    {
      type: 'first-features',
      data: firstDetailList,
    },
    {
      type: 'second-features',
      data: commonCodes.PURPROD2,
    },
    {
      type: 'third-features',
      data: commonCodes.PURPROD3,
    },
  ];

  const setMaxHeight = (element: HTMLElement | null, selector: string) => {
    const updateHeights = () => {
      if (element) {
        const allLists = document.querySelectorAll(selector);
        let maxHeight = 0;
        allLists.forEach(list => {
          (list as HTMLElement).style.height = 'auto';
          const height = list.getBoundingClientRect().height;
          maxHeight = Math.max(maxHeight, height);
        });
        if (maxHeight > 0) {
          allLists.forEach(list => {
            (list as HTMLElement).style.height = `${maxHeight}px`;
          });
        }
      }
    };

    // Initial update
    updateHeights();

    let resizeTimeout: NodeJS.Timeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(updateHeights, 100);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleResize);
    };
  };

  const [pcBtnStartActiveIndex, setPcBtnStartActiveIndex] = useState(3);

  return (
    <section className='purchase-showcase-intro'>
      <header className='purchase-showcase-intro__header'>
        <strong className='pre-title-satisfaction'>
          <em className='top-row'>
            <span>100</span>
            <span className='percent'>%</span>
          </em>
          <em className='bottom-row'>만족보장</em>
        </strong>
        <h2 className='section-title'>오직 유폰에서만 <br />4주 이내 전액 환불 약속</h2>
        <p className='welcome'>역대 최고 수강생 만족도와 재수강률 <br />민병철유폰을 지금 경험해보세요.</p>
      </header>
      <ul className='week-options'>
        {commonCodes.PURTABW?.map((item: CommonCode) => (
          <li key={`weekOption${Number(item.detailCode)}`} className={`week-options__item ${selectedProductWeek === Number(item.detailCode) ? 'is-active' : ''}`}>
            <button className='tab-btn' onClick={() => handleSelectProductWeek(Number(item.detailCode))}>
              <span className='tab-btn__title'>{item.note1}</span>
              {item.note2 && <span className='tab-btn__sub-title'>{item.note2}</span>}
            </button>
          </li>
        ))}
      </ul>
      <ul className='type-options'>
        {productList.length>0 && commonCodes.PURTABM?.map((item: CommonCode) => (
          <li className='type-options__item' key={`lessonMode${item.detailCode}`}>
            <input
              className='hide-input'
              type='radio'
              id={`lessonMode${item.detailCode}`}
              name='lessonMode'
              checked={selectedLessonMode === item.detailCode}
              onChange={() => handleSelectLessonMode(item.detailCode)}
            />
            <label htmlFor={`lessonMode${item.detailCode}`}>{getLessonModeTabValue(item)}</label>
          </li>
        ))}
      </ul>
      <div className='product-options'>
        <Swiper {...swiperParam}>
          {filteredProductList.map((product, index) => {
            const isPremium = product.productCategory.includes('PREMIUM');
            const isBest = Boolean(commonCodeConverter(commonCodes.PURPROD, 'note8', product.productCategory));

            return (
              <div className={`product-item ${isPremium ? 'is-premium' : ''} ${isBest ? 'is-best' : ''}`} key={product.priceSeq} onMouseEnter={() => setPcBtnStartActiveIndex(index)} onMouseLeave={() => setPcBtnStartActiveIndex(3)}>
                <div className='product-item__container'>
                  {isBest &&
                    <em className='best-tag'>Best</em>
                  }

                  {/* 상품(Light/Light Pro/Premium/Premium Pro) */}
                  <div className='product-item__head'>
                    <h3 className='prd-name'>
                      <span>{commonCodeConverter(commonCodes.PURPROD, 'detailName', product.productCategory)}</span>
                    </h3>
                    <ul className='prd-schedule'>
                      <li className='prd-schedule__duration'><span>{commonCodeConverter(commonCodes.PURPROD, 'note1', product.productCategory)}분</span></li>
                      <li>{commonCodeConverter(commonCodes.PURPROD, 'note4', product.productCategory)}</li>
                    </ul>
                    <ul className='prd-timezone'>
                      <li className='prd-timezone__notice'>{commonCodeConverter(commonCodes.PURPROD, 'note5', product.productCategory)}</li>
                      <li className='prd-timezone__graph'>
                        <div className='available-bar'>
                          <span>{commonCodeConverter(commonCodes.PURPROD, 'note6', product.productCategory)}시</span>
                          <span>{commonCodeConverter(commonCodes.PURPROD, 'note7', product.productCategory)}시</span>
                        </div>
                      </li>
                    </ul>
                    <strong className='prd-price'>{pricePerMonth(product)}<span className='won'>원</span><span className='per-week'>/4주</span></strong>
                  </div>
                  {!product.soldoutFlag
                    ? <button type='button' className={`btn-start ${pcBtnStartActiveIndex === index ? 'is-pc-active' : ''}`} onClick={() => goToPayment(product)}>시작하기</button>
                    : <button type='button' className='btn-soldout' onClick={alertSoldOut}>Sold out</button>
                  }
                  <div className='product-item__body'>
                    <div className='product-item__feature' ref={el => { setMaxHeight(el, '.product-item__feature'); }}>
                      {productDetails.map(({ type, data }) => {
                        return (
                          <ul key={`${type}-${product.priceSeq}`} className={`prd-features prd-features--${type}`}>
                            {data?.filter((code: CommonCode) => code.note1 === product.productCategory)
                              .map((code: CommonCode) => (
                                <li
                                  key={code.detailCode}
                                  className={`${code.note2}`}
                                  dangerouslySetInnerHTML={{ __html: code.note2 === 'event' ? DOMPurify.sanitize(`<em>Event</em>${code.note3 || ''}`) : DOMPurify.sanitize(code.note3 || '') }}
                                />
                              ))}
                          </ul>
                        );
                      })}
                    </div>
                    {product.anotherProductPrice &&
                      <div className='prd-subscribe'>
                        <div className='prd-subscribe__checkbox'>
                          <input
                            type='checkbox'
                            id={`subscription-${product.priceSeq}`}
                            checked={!product.showAnotherProductPrice}
                            onChange={() => {}}
                          />
                          <label
                            onClick={() => onClickBillFlag(product)}
                            htmlFor={`subscription-${product.priceSeq}`}>정기결제</label>
                        </div>
                        {
                          product.showAnotherProductPrice && (
                            <div className='prd-subscribe__card-info' onClick={() => openPopup('cardInfoPopup')}>
                              무이자 12개월
                            </div>
                          )
                        }
                      </div>
                    }
                  </div>
                </div>
              </div>
            );
          })}
        </Swiper>
      </div>

      {/* Sold out 버튼 클릭 시 알림 */}
      {showSoldoutPopup && (
        <LayerPopup
          tit={commonMessage?.PURALERT01} // 상품 준비중
          desc1={commonMessage?.PURALERT02} // 해당 수강권은 솔드아웃으로 현재 상품 준비 중입니다. 지금 바로 시작 가능한 다른 상품을 둘러보세요.
          btnCnt={1}
          btnOkCmt={commonMessage?.['0000000094']} // 확인
          btnOkFunction={() => {
            ScrollNone();
            setShowSoldoutPopup(false);
          }}
        />
      )}
      {/* 정기결제 체크 해제 시 알림 */}
      {showBillFlagChangePopup && (
        <LayerPopup
          tit={commonMessage?.PURALERT03} // 정기 결제를 해제할까요?
          desc1={selectedProductWeek === 12 ? commonMessage?.PURALERT04 : commonMessage?.PURALERT06} // 정기 구독 해제 시,<br/><b>N% 할인</b>만 받을 수 있어요.<br/>(정기결제는 M% 할인 혜택)
          btnCnt={2}
          btnNoCmt={commonMessage?.PURALERT05} // 네, 해제할게요
          btnNoFunction={() => {
            if (currentBillFlagChangeProduct) toggleAnotherProductPrice(currentBillFlagChangeProduct?.priceSeq);
            ScrollNone();
            setShowBillFlagChangePopup(false);
          }}
          btnYesCmt={commonMessage?.['0000000095']} // 아니요
          btnYesFunction={() => {
            ScrollNone();
            setShowBillFlagChangePopup(false);
          }}
        />
      )}
    </section>
  );
};

export default PurchaseShowcaseB2CProduct;
