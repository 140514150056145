import React, {Component} from 'react';
import * as utilFn from '../common/utilFn';
import axios from 'axios';
export default class Ad extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bindHtml : null,
      cssloaded : false,
      jsloaded : false
    };
  }

  componentWillMount() {
    this.getLandingPageFromCommonCode('LANDINGPG',this.props.match.params.pagecode,(returnData) => {
      // detailCode
      const getSubData = returnData.filter( (obj) => {
        return obj.detailCode === this.props.match.params.subpagecode;
      });
      if (getSubData.length === 0) {
        alert('경로 오류입니다.');
        return null;
      }
      const [importUrl, importCSS, importJS] = [getSubData[0].note1, getSubData[0].note2, getSubData[0].note3];

      fetch(importUrl)
        .then(result => {
          return result.text();
        })
        .then(page => {
          this.setState(
            {
              bindHtml: { __html: page }
            },
            () => {
              const arrCSS =[
                {url : 'https://img.uphone3.com/web/css/font.css', media : 'all'},
                {url : 'https://img.uphone3.com/web/landing/b2chtml/common/ui_mo.css', media : 'screen and (max-width: 1024px)'},
                {url : 'https://img.uphone3.com/web/landing/b2chtml/common/ui_pc.css', media : 'screen and (min-width: 1025px)'},
                {url : importCSS, media : 'all'},
              ];
              const arrJS = [
                'https://img.uphone3.com/web/js/jquery-1.10.2.min.js',
                'https://img.uphone3.com/web/js/swiper-bundle.min.js',
                'https://img.uphone3.com/web/landing/landingcommon.js',
                importJS,
                'https://cdnjs.cloudflare.com/ajax/libs/gsap/2.1.3/TweenMax.min.js',
                'https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.7/ScrollMagic.min.js',
                'https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.7/plugins/animation.gsap.min.js',
                'https://cdnjs.cloudflare.com/ajax/libs/ScrollMagic/2.0.7/plugins/debug.addIndicators.min.js'
              ];
              this.getCss(arrCSS);
              this.getScript(arrJS);
            }
          );
        });
    });
  }

  getLandingPageFromCommonCode = (c,detailCode,callback) => {
    axios({
      method:'GET',
      url:process.env.REACT_APP_API_URL + '/commonCode/' + c + '/' + detailCode,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8'
      }
    })
      .then( (response) => {
        return callback(response.data);
      })
      .catch( (error) => {
        console.error('error: ', error);
      });
  };

  getCss = (urlArr) => {
    let i = 0;
    (this.cssLoad = (arr) => {
      var _this = this;
      var head = document.getElementsByTagName('head')[0], done = false;
      var link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = arr.url;
      link.media = arr.media;
      link.onload = link.onreadystatechange = function(){
        if ( !done && (!this.readyState ||
                    this.readyState === 'loaded' || this.readyState === 'complete') ) {
          done = true;
          i++;
          if (i < urlArr.length) _this.cssLoad(urlArr[i]);
          else _this.setState({ cssloaded : true });
        }
      };
      head.appendChild(link);
    })(urlArr[0]);
  };

  getScript = (urlArr) => {
    let i = 0;
    (this.scriptLoad = (url) => {
      var _this = this;
      var head = document.getElementsByTagName('head')[0], done = false;
      var script = document.createElement('script');
      script.src = url;
      script.onload = script.onreadystatechange = function(){
        if ( !done && (!this.readyState ||
                    this.readyState === 'loaded' || this.readyState === 'complete') ) {
          done = true;
          i++;
          if (i < urlArr.length)_this.scriptLoad(urlArr[i]);
          else _this.setState({ jsloaded : true });
        }
      };
      head.appendChild(script);
    })(urlArr[0]);
  };

  render() {
    const { bindHtml, cssloaded, jsloaded } = this.state;
    return (
      <>
        {
          cssloaded && jsloaded &&
                    <div id={'ad'}
                      dangerouslySetInnerHTML={  bindHtml && bindHtml}
                    />
        }
      </>
    );
  }
}
