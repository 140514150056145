import React, { FC, useCallback, useState, useEffect } from 'react';
import 'css/purchase/PurchaseShowcaseB2C.scss';

import ChannelService from 'component/ChannelService';
import ChannelTalkButton from 'component/channelTalk/ChannelTalkButton';

type Props = {
  ww: string;
};
const PurchaseShowcaseB2CBottomButtons: FC<Props> = ({ ww }) => {
  const [showButton, setShowButton] = useState(false);
  const [channelTalkBadge, setChannelTalkBadge] = useState(0);

  const goToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const target = document.querySelector('.purchase-showcase-sales__header');
      if (target) {
        const targetTop = (target as HTMLElement).offsetTop;
        const windowHeight = window.innerHeight;
        setShowButton(window.scrollY !== 0 && (window.scrollY >= targetTop - windowHeight));
      }
    };

    window.addEventListener('scroll', handleScroll);
    // 초기 로드 시에도 체크
    handleScroll();

    // 채널톡 설정
    ChannelService.boot({
      'pluginKey': ChannelService.key(),
      'customLauncherSelector': '.channel-talk',
      'hideChannelButtonOnBoot': true,
    }, (count: number) => setChannelTalkBadge(count));
    ChannelService.setPage(window.location.href);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      ChannelService.shutdown();
    };
  }, []);

  return (
    <>
      {/* 상단 이동 버튼 */}
      {showButton && (
        <button className='purchase-showcase-move-top' onClick={goToTop} />
      )}
      {/* 채널톡 버튼 */}
      <ChannelTalkButton badgeCount={channelTalkBadge} visible={showButton || ww === 'pc'} />
    </>
  );
};

export default PurchaseShowcaseB2CBottomButtons;
