import React, {useState, useEffect, useRef, useCallback} from 'react';
import Lottie from 'react-lottie-segments';
import { animateScroll as scroll } from 'react-scroll';
import moment from 'moment';

import pCircle from '../json/progressbar_circle_blue.json';
import sFace from '../json/sound_face_blue.json';
import * as utilFn from 'common/utilFn';
import {httpDefaultClient} from 'configs/api';

const LevelTestBook = props => {
  const [loading, setLoading] = useState(true);
  const [book, setBook] = useState([]);
  const [st, setST] = useState();
  const [dp, setDP] = useState();
  const [fb, setFB] = useState();
  const [rs, setRS] = useState();
  const [menuClass, setMenuClass] = useState();
  const [selectedMenu, setselectedMenu] = useState();
  const trans = [false, false];
  const [bookList, setBookList] = useState(false);
  const [level, setLevel] = useState();
  const [lmin, setLmin] = useState(0);

  const [ld, setLd] = useState();
  const [lt, setLt] = useState();
  const [timeLottie, setTimeLottie] = useState();
  const [fdBubble, setFdBubble] = useState(false);
  const comST = useRef(null);
  const comRS = useRef(null);
  const comFB = useRef(null);
  const comDP = useRef(null);
  const con = useRef(null);
  const greeting = useRef(null);
  const goodbye = useRef(null);

  const td = moment().format('YYYY-MM-DD');
  const tt = moment().format('HH:mm');

  const [step, setStep] = useState(0);

  const [teacher, setTeacher]=useState(props.teacher||' ');


  useEffect(() => {
    if (loading){
      httpDefaultClient.get(`/content/leveltest/${props.seq}/book`)
        .then(res => {
          const json = res.data;
          const a = json.lessonDate;
          const b = json.lessonTime;

          setLoading(false);
          setBook(json);
          json.ST && setST(json.ST);
          json.DP && setDP(json.DP);
          json.FB && setFB(json.FB);
          json.RS && setRS(json.RS);
          setLd(a);
          setLt(b);

          setLevel(json.levelCode);
          setTeacher(json.teacherName);

          const inLE = moment(a+' '+b).add(10, 'minutes').format('HH:mm');
          const lt = moment(a+' '+b).format('HH:mm');

          setTimeLottie(td === a && tt >= lt && tt <= inLE);
        });

      const m = Number(moment().format('m'));
      const s = Number(moment().format('s'));
      const mm = m > 9 ? Number(String(m).split('')[1]) : m;
      if ((mm >= 8 && s > 30) || mm >= 9){
        setStep(2);
      }

      const ms = (mm * 60 + s) / 570 * 30;
      setLmin(ms >= 30 ? 29 : ms);
    }
    window.addEventListener('scroll', scrollPos, true);
    return function cleanup(){
      window.removeEventListener('scroll',scrollPos,true);
    };
  });

  const moveCom = (e, tp, ps) => {
    e.preventDefault();
    const t = e.currentTarget;
    const scrollpos = window.scrollY || document.body.scrollTop;
    const a = tp===0?greeting.current:tp===1?comST.current:tp===2?comRS.current:tp===3?comFB.current:tp===4?comDP.current:goodbye.current;
    let b;

    t.classList.add('on');

    ps === 'main' ? b = 0 : b = 500;
    setselectedMenu(a);

    setTimeout(() => {
      t.classList.remove('on');
      clsList();
      scroll.scrollTo(a.offsetTop, {duration: 1000, smooth: 'easeOutQuint'});
    }, b);
  };

  const viewList = () => {
    setBookList(true);
  };
  const clsList = () => {
    setBookList(false);
  };
  const clsPop = () => {
    props.history.goBack();
    window.close();
  };
  const scrollPos = () => {
    const pos = window.scrollY || document.body.scrollTop;
    setMenuClass(pos > 0?'sticky':'');
    menuSelect();
  };
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: pCircle,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const sFaceA = {
    loop: step !== 1,
    autoplay: true,
    animationData: sFace,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  const handleEvent = (n, obj) => {
    const m = moment().format('m');
    const mm = m > 9 ? Number(String(m).split('')[1]) : m;
    const s = Number(moment().format('s'));

    const inLE = moment(ld+' '+lt).add(10, 'minutes').format('HH:mm');
    const a = moment(ld+' '+lt).format('HH:mm');

    const nd = moment().format('YYYY-MM-DD');
    const nt = moment().format('HH:mm');

    const vs = nd === ld && nt >= a && nt <= inLE;

    setTimeLottie(vs);

    let pt = (mm * 60 + s) / 570 * 30;
    if (pt >= 30) pt = 30;
    setLmin(pt);

    if (n === 1 && mm === 8 && s > 30 && vs){
      setStep(1);
      setFdBubble(true);
      setTimeout(fdBubbleHide, 5000);
      goFeedBack();
    } else if (((n === 1 && mm >= 9) || (n === 2 && obj.type === 'complete')) && vs){
      setStep(2);
    } else if (n === 3 && obj.type === 'complete'){
      setLmin(30);
    }
  };

  const goFeedBack = e => {
    const scrollpos = window.scrollY || document.body.scrollTop;
    const wp = window.innerHeight * 3 / 10;
    if (scrollpos < wp){
      scroll.scrollTo(goodbye.current.offsetTop, {duration: 1000, smooth: 'easeOutQuint'});
    }
  };

  const fdBubbleHide = () => {
    setFdBubble(false);
  };

  // 스크롤시 팝업 메뉴 on
  const menuSelect = useCallback(() => {
    const scrollTop =document.body.scrollTop + (window.innerHeight / 2); // 다음 섹션이 화면의 반이상 올라오면 다음 섹션 선택
    const menu = [greeting,comST,comRS,comFB,comDP,goodbye];
    menu.map((ele) => {
      const menuName = ele.current.classList[1].toUpperCase();
      const eleTop = ele.current.getBoundingClientRect().top;
      if (scrollTop>=eleTop){
        setselectedMenu(menuName);
      }
    });
  },[]);

  return loading ? null : (
    <div className={'previewBookLT'} ref={con}>
      <div className={'leveltestBook'}>
        <div className={`tmWrap ${menuClass}`}>
          <>
            <div className={'menu'} onClick={viewList}><img className={'circleBtn'} src={`${process.env.REACT_APP_IMG_URL}/icon/btn_menu_circle.svg`} alt={'menu'}/></div>
            <div className={'clsBtn'} onClick={clsPop}><img className={'circleBtn'} src={`${process.env.REACT_APP_IMG_URL}/icon/btn_close_circle.svg`} alt={'Close Button'} /></div>
          </>
        </div>
        <div className={`menuList ${!bookList && 'hidden'}`}>
          <div className={'clsBtn'} onClick={clsList}><img className={'circleBtn'} src={`${process.env.REACT_APP_IMG_URL}/icon/btn_close_circleblack.svg`} alt={'Close Button'}/></div>
          <ul>
            <li className={selectedMenu==='GREETING'&&'on'} onClick={(e) => moveCom(e,0)}>Greeting<br/><span>강사님과 첫인사 하기</span></li>
            <li className={selectedMenu==='ST'&&'on'}  onClick={(e) => moveCom(e,1)}>Part 1. 의사소통<br/><span>대화 나누기</span></li>
            <li className={selectedMenu==='RS'&&'on'}  onClick={(e) => moveCom(e,2)}>Part 2. 발음<br/><span>문장 읽기</span></li>
            <li className={selectedMenu==='FB'&&'on'}  onClick={(e) => moveCom(e,3)}>Part 3. 문법<br/><span>문장 만들기</span></li>
            <li className={selectedMenu==='DP'&&'on'}  onClick={(e) => moveCom(e,4)}>Part 4. 유창성&이해력<br/><span>사진 묘사하기</span></li>
            <li className={selectedMenu==='GOODBYE'&&'on'}  onClick={(e) => moveCom(e,5)}>Goodbye<br/><span>강사님과 끝인사 하기</span></li>
          </ul>
        </div>
        <div className={'conWrap greeting'} ref={greeting}>
          <div className={'txt1'}>{teacher} 강사님과<br/>레벨테스트를<br/>진행해보세요.</div>
          <div className={'txt2'}>오늘의 반가운 인사말</div>
          <div className={'txt3'}>"{book.greeting}"</div>
          <br/>
          <img className={'arrow'} onClick={(e) => moveCom(e,1, 'main')} src={`${process.env.REACT_APP_IMG_URL}/icon/arrow_down_w.svg`} alt={'icon'} />
        </div>
        {st &&
					<div className={'w st'} ref={comST}>
					  <div className={'title'}>Part 1. 의사소통</div>
					  <div className={'subTitle'}>강사님과 가볍게 오늘의 대화를 나눠보세요.</div>
					  <div className={'contentBox'}>
					    <div className={'txt1'}>오늘의 주제</div>
					    <div className={'subject'}>{st.subject}</div>
					    <div className={'tip'}><span>TIP</span> 아래 단어를 활용해도 좋아요.</div>
					    <ul>
					      {st.examples.map((v, i) => {
					        return (
					          <li key={i} className={'smalltalk'}>{v.sentence}{level==='low'&&<span>{v.description}</span>}</li>
					        );
					      })}
					    </ul>
					  </div>
					</div>}
        {rs &&
					<div className={'w rs pd'} ref={comRS}>
					  <div className={'title'}>Part 2. 발음</div>
					  <div className={'subTitle'}>아래의 문장을 읽어보세요.</div>

					  {rs.map((v, i) => {
					    return (
					      <div className={'contentBox'} key={i}>
					        <div className={'no'}>{i+1<10?'0'+(i+1):i+1}</div>
					        <div className={'boxSh'} />
					        <div className={'sntn'}>{v.sentence}</div>
					        <div className={'desc'}>{v.description}</div>
					      </div>
					    );
					  })}

					</div>}
        {fb &&
					<div className={'w fb pd'} ref={comFB}>
					  <div className={'title'}>Part 3. 문법</div>
					  <div className={'subTitle'}>빈칸을 채워 문장을 말해보세요.</div>
					  {fb.map((v, i) => {
					    const a = v.sub[0].sentence;
					    const b = a.split('**');
					    const c = b[0]+'<span>'+b[1]+'</span>';

					    let d = '';
					    b.length > 0 ?
					      b.map((v, i) => {
					        d+= i%2 === 1? '<span>' + v:'</span>' + v;
					      })
					      : d = a;
					    return (
					      <div className={'contentBox'} key={i}>
					        <div className={'no'}>{i+1<10?'0'+(i+1):i+1}</div>
					        <div className={'boxSh'} />
					        <div className={'sntn'}>{v.sentence}</div>
					        <div className={'desc'}>{v.description}</div>
					        {level==='low'&& <><div className={'tern'}>Example</div>
					          <div className={'ssntn'} dangerouslySetInnerHTML={{__html: d}}/></>}
					      </div>
					    );
					  })}
					</div>}
        {dp &&
					<div className={'w dp st'} ref={comDP}>
					  <div className={'title'}>Part 4. 유창성&이해력</div>
					  <div className={'subTitle'}>사진을 보고 질문에 답해보세요.</div>
					  <div className={'img'}><img src={dp.imageUrl} alt={'image'}/></div>
					  {level==='low'&& <div className={'contentBox'}>
					    <div className={'tip'}><span>TIP</span> 아래 단어를 활용해도 좋아요.</div>
					    <ul>
					      {dp.examples.map((v, i) => {
					        return (
					          <li key={i} className={'smalltalk'}>{v.sentence}<span>{v.description}</span></li>
					        );
					      })}
					    </ul>
					  </div>}
					</div>}

        <div className={'conWrap goodbye bottom'} ref={goodbye}>
          <div className={'txt1'}>{teacher} 강사님에게<br/>고마운 마음을 전하며<br/>수업을 마무리해요.</div>
          <div className={'txt2'}>오늘의 끝인사</div>
          <div className={'txt3'}>"{book.closing}"</div>
        </div>
      </div>
      <div className={`msk ${!bookList && 'hidden'}`} onClick={clsList}/>
      {ld && lt &&
			<div className={`timePrg ${timeLottie} ${menuClass}`}>
			  <div className={`fdInfo ${fdBubble}`}>테스트를 마무리하고 강사님의<br/>피드백을 받아보세요.</div>
			  <div className={'lottiePrg pCircle'}>
			    <Lottie options={defaultOptions} speed={1/570} playSegments={{segments:[lmin, 30], forceFlag:true}} eventListeners={[{eventName: 'complete', callback: obj => handleEvent(3, obj),}]} />
			  </div>
			  <div className={'lottiePrg sFace'} >
			    {step === 0 && <Lottie options={sFaceA} speed={1} playSegments={{segments:[0, 85], forceFlag:true}} eventListeners={[{eventName: 'loopComplete', callback: obj => handleEvent(1, obj),}]} />}
			    {step === 1 && <Lottie options={sFaceA} speed={1} playSegments={{segments:[85, 124], forceFlag:true}} eventListeners={[{eventName: 'complete', callback: obj => handleEvent(2, obj),}]} />}
			    {step === 2 && <Lottie options={sFaceA} speed={1} playSegments={{segments:[124, 210], forceFlag:true}} />}
			  </div>
			</div>}
    </div>
  );
};

export default LevelTestBook;
