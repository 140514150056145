import {create} from 'zustand';
import {createJSONStorage, devtools, persist} from 'zustand/middleware';

import {referrerSlice, ReferrerT} from './referrerSlice';

export const referrerStore = create<ReferrerT>()(persist(
  devtools((...all) => ({
    ...referrerSlice(...all),
  }), { store: 'referrer' }),
  {
    name: 'referrerStore',
    storage: createJSONStorage(() => sessionStorage)
  }
)
);

export default referrerStore;
