import React, {useState, useMemo, useEffect} from 'react';
import {httpDefaultClient} from 'configs/api';

type Props = {
	ww: string;
};

const Footer: React.FC<Props> = (props) => {

  const [popup, setPopup] = useState(false);
  const [popupTitle, setPopupTitle] = useState('');
  const [popupCon, setPopupCon] = useState('');
  const [infoOn, setInfoOn] = useState(false);
  const [address, setAddress] = useState('');

  const isLandingPage = useMemo(() => window.location.pathname.toLowerCase().startsWith('/landing/') || window.location.pathname.toLowerCase().indexOf('/teacher')>=0, []);

  useEffect(() => {
    httpDefaultClient.get('/commonMessage/getCommonMessage?languageCode=ko&messageCode=address')
      .then(res => {
        setAddress(res.data.message);
      }).catch(error => {
        console.error('error: ', error);
      });
  }, []);

  const viewPop = (v: any) => {
    let u: string;
    let t: any;

    if (v === 'p'){
      u = '/sign/getSupport?supportId=terms';
      t = '이용약관';
    } else {
      u = '/sign/getSupport?supportId=privacy';
      t = '개인정보처리방침';
    }

    httpDefaultClient.get(u)
      .then(res => {
        const json = res.data;
        if (json.result) {
          setPopup(true);
          setPopupTitle(t);
          setPopupCon(json.data);
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  const clsPop = () => {
    setPopup(false);
  };

  const onToggleInfo = () => {
    setInfoOn(!infoOn);
  };

  return (
    <>
      <footer className={`footer web-footer ${isLandingPage ?'web-footer--ver-landing':'web-footer--ver-landing'}`} id='footer'>
        <div className='web-footer__inner'>
          <div className='pc-column pc-column--right'>
            <div className='web-footer-award'>
              <div className='web-footer-award__inner'>
                <ul>
                  <li className='award-item award-item--kepba'>
                    <strong className='award-item__title'>10년 연속 한국 소비자선호도 1위</strong>
                    <em className='award-item__agency'>주관 : 한국미디어리서치, 주최 : 스포츠 서울</em>
                  </li>
                  <li className='award-item award-item--kceb'>
                    <strong className='award-item__title'>2024 소비자선정 우수기업 브랜드대상</strong>
                    <em className='award-item__agency'>후원 : 동아일보, 주최 : 오픈엑스</em>
                  </li>
                </ul>
              </div>
            </div>
            <div className='web-footer-contact'>
              <dl className='time-item'>
                <dt>학습컨설팅센터</dt>
                <dd>채팅상담 9~6시(점심시간 12~1시)주말, 공휴일 휴무</dd>
              </dl>
            </div>
          </div>
          <div className='pc-column pc-column--left'>
            <ul className='web-footer-sns'>
              <li className='web-footer-sns__item'>
                <a href='https://www.facebook.com/Uphone.English'
								 className='web-footer-sns__lnk web-footer-sns__lnk--facebook' target='_blank' rel='noopener noreferrer'><span
                    className='blind'>민병철유폰 facebook 바로가기</span></a>
              </li>
              <li className='web-footer-sns__item'>
                <a href='https://www.instagram.com/uphone.english'
								 className='web-footer-sns__lnk web-footer-sns__lnk--insta' target='_blank' rel='noopener noreferrer'><span className='blind'>민병철유폰 instagram 바로가기</span></a>
              </li>
              <li className='web-footer-sns__item'>
                <a href='https://www.youtube.com/channel/UCx0WE73FTCq7G07snOVnl4Q/featured'
								 className='web-footer-sns__lnk web-footer-sns__lnk--youtube' target='_blank' rel='noopener noreferrer'><span className='blind'>민병철유폰 youtube 바로가기</span></a>
              </li>
              <li className='web-footer-sns__item'>
                <a href='https://blog.naver.com/bcm_uphone' className='web-footer-sns__lnk web-footer-sns__lnk--blog'
								 target='_blank' rel='noopener noreferrer'><span className='blind'>민병철유폰 네이버 blog 바로가기</span></a>
              </li>
            </ul>
            <ul className='web-footer-nav'>
              <li className='web-footer-nav__item'>
                <a href='https://bcmrecruit.notion.site/bcmrecruit/BCM-Winning-Team-6ad4c18854834ab0999a963b3265f37b'
								 target='_blank' rel='noreferrer'>채용</a>
              </li>
              <li className='web-footer-nav__item'>
                <a href='/guide' className='link'>FAQ</a>
              </li>
              <li className='web-footer-nav__item'>
                <button type='button' onClick={() => viewPop('p')}>이용약관</button>
              </li>
              {props.ww !== 'pc' &&
							<li className='web-footer-nav__item web-footer-nav__item--mo'>
							  <a href={process.env.REACT_APP_MAIN_URL + '/bugreport'} rel='noreferrer noopener' target='_blank'>오류신고</a>
							</li>
              }
              <li className='web-footer-nav__item'>
                <button type='button' onClick={() => viewPop('i')}>개인정보처리방침</button>
              </li>
              {props.ww === 'pc' &&
							<>
							  <li className='web-footer-nav__item web-footer-nav__item--pc'>
							    <a href='https://www.ftc.go.kr/bizCommPop.do?wrkr_no=2208112206&apv_perm_no='
										 target='_blank' rel='noopener noreferrer'>사업자정보확인</a>
							  </li>
							  <li className='web-footer-nav__item web-footer-nav__item--pc'>
							    <a href={process.env.REACT_APP_MAIN_URL + '/bugreport'} rel='noreferrer noopener' target='_blank'>오류신고</a>
							  </li>
							</>
              }
            </ul>
            <div className={`web-footer-company footCompany ${infoOn ?'on':''}`}>
              {
                props.ww !== 'pc' &&
							<button type='button' className='btnFootFold' onClick={() => onToggleInfo()}>(주) 민병철교육그룹 사업자 정보</button>
              }
              <ul className='info-list'>
                <li className='info-item'>대표이사 : 민병철 외 1인</li>
                <li className='info-item'>학습컨설팅센터 : 1599-6846</li>
                <li className='info-item'>사업자등록번호 220-81-12206</li>
                <li className='info-item'>통신판매업신고번호 제 06837호</li>
                <li className='info-item'>원격평생교육시설 121호</li>
                <li className='info-item'>주소 : {address}</li>
              </ul>

              <small className='copyright'>COPYRIGHT © (주) 민병철교육그룹. 모든 권리 보유</small>
            </div>
          </div>
        </div>
      </footer>
      {popup &&
        <>
          <div id='pLayer' className=''>
            <div className='tTitle'>{popupTitle}</div>
            <div className='clsBtn'>
              <button onClick={() => clsPop()}><img src='https://img.uphone3.com/web/btn_gnb_close.png' alt='close'/></button></div>
            <div className='contents' dangerouslySetInnerHTML={{__html: popupCon}}/>
          </div>
          <div className='dimmed'/>
        </>
      }
    </>
  );
};

export default Footer;
