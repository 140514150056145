import React, {Component} from 'react';
import FreelessonBook from '../component/FreelessonBook';
import '../css/FreelessonBook.scss';

export default class FreelessonBookView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={'freelessonBook__wrapper'}>
        <div className={'freelessonBook__wrapper__inner'}>
          <FreelessonBook
            code={this.props.match.params.code}
          />
        </div>
      </div>

    );
  }
}
