import React, {useState} from 'react';
import 'css/orderPledge.scss';
import moment from 'moment';
import LayerPopup from '../LayerPopup';
import Top from '../top';


const ClassPledge = ({ props }) => {
  const [disagree, setDisagree] = useState(false);

  // state
  const {
    memberName,
    companyPurchaseInfo,
    employeeNo,
    purchaseagreeCode,
    purchaseagreeTitle,
    purchaseagreeContent,
    selectProduct,
    personalOrder,
  } = props;

  const {
    companyName,
    termStartdate,
    termEnddate,
  } = companyPurchaseInfo;

  // props
  const {
    ww, history, processStep2, closePopup, go,
  } = props;

  return (
    <>
      {ww === 'pc' ?
        <div className='dimmed dimmed--low'></div>
        :
        <Top title={'수강신청'} sub={''} history={history}
          back={personalOrder? () => go('/') : () => go('/Order')}
        />
      }
      <article className={'order-pledge'}>
        <h2 className='order-pledge__title'>{purchaseagreeCode === 'CONSENT' ? '동의서' : '서약서'}</h2>
        <div className='order-pledge__inner'>
          <ul className='order-pledge__info'>
            <li>· 소속 : {companyName}</li>
            <li>· 성명 : {memberName}</li>
            {employeeNo && <li>· 사번 : {employeeNo}</li>}
            <li>· 교육명 : {purchaseagreeTitle}</li>
            <li>· 교육기관 : 민병철교육그룹</li>
            <li>· 교육기간 : {termStartdate.replace(/-/g, '.')}~{termEnddate.replace(/-/g, '.')}</li>
          </ul>
          <p className='order-pledge__notice'>본인은 위와 같이 교육을 받음에 있어 다음 사항을 성실히 이행할 것을 서약합니다.</p>
          <ul className='order-pledge__cont' dangerouslySetInnerHTML={{__html: purchaseagreeContent}} />
        </div>
        <div className='order-pledge__foot'>
          <strong className='order-pledge__date'>{moment().format('YYYY년 MM월 DD일')}</strong>
          <strong className='order-pledge__writer'>서약자 {memberName.split('').join(' ')} (인) {memberName}</strong>
        </div>
        <ul className='order-pledge__confirm'>
          <li>
            <button type='button' className='btn btn--cancel' onClick={() => setDisagree(true)}>다음에 할게요</button>
          </li>
          <li>
            <button type='button' className='btn' onClick={() => processStep2(selectProduct.priceSeq, personalOrder?'personal':'', ww === 'mo')}>네, 동의합니다</button>
          </li>
        </ul>
        <button type='button'
          className='btn-close'
          onClick={() => {
            closePopup('purchaseAgreePage');
            if (personalOrder) go('/');
          }}>
          <span className='blind'>닫기</span>
        </button>
      </article>

      {disagree &&
                <LayerPopup
                  tit='동의하지 않으면 수강신청을 할 수 없습니다. 다음에 하시겠습니까?'
                  btnCnt={2}
                  btnNoCmt='다음에 하기'
                  btnNoFunction={
                    () => {
                      setDisagree(false);
                      closePopup('purchaseAgreePage');
                      if (ww === 'mo' && !personalOrder) {
                        history.goBack();
                      } else if (personalOrder) {
                        go('/');
                      }
                    }
                  }
                  btnYesCmt='동의 후 계속하기'
                  btnYesFunction={() => setDisagree(false)}
                />
      }
    </>
  );
};

export default ClassPledge;
