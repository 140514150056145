import React from 'react';
import Lottie from 'react-lottie';

import quiz from '../../json/quiz.json';
import '../../css/freeReport/LessonIntroduction.scss';

interface PropsType {
  memberName:string
}

export default function LessonIntroduction({ memberName }: PropsType) {
  return (
    <section className='lesson-introduction'>
      <h3 className='lesson-introduction-title'>이제 막 영어회화를<br /> 결심한 {memberName}님께<br />필요한 것은?</h3>
      
      <section className='introduction-card ai'>
        <p className='introduction-card__title title'>{memberName}님의 발음과 문법은<br /> 조금만 더 연습하면 자연스러워질 거예요.</p>
        <img src={process.env.REACT_APP_IMG_URL + '/report/freereport_blue_01.png'} alt='' />
        <p className='introduction-card__description description'>언제든 실시간 교정해주는<br />발음부스터·유폰 GPT로 연습해 보세요.</p>
        <span className='introduction-card__link link' onClick={() => window.open(process.env.REACT_APP_MAIN_URL + '/sub/speech-booster', '_self')}>유폰 AI 학습 자세히 보기 &gt;</span>
      </section>
      
      <section className='introduction-card quiz'>
        <p className='introduction-card__title title'>{memberName}님의 학습이 즐거울 수 있도록<br/>다양한 교재를 준비했어요.</p>
        <Lottie options={{ loop: true, autoplay: true, animationData: quiz, }} />
        <p className='introduction-card__description description'>퀴즈, VOD부터 롤플레잉까지.<br/>매 수업 변신하는 수업교재를 만나보세요.</p>
      </section>
      
      <section className='introduction-card habit'>
        <p className='introduction-card__title title'>영어는 꾸준히 해야 느니까,<br/>{memberName}님의 꾸준한 습관을 만들어드릴게요!</p>
        <img src={process.env.REACT_APP_IMG_URL + '/report/freereport_blue_03.png'} alt='' />
      </section>
      
      <section className='introduction-card lesson-review'>
        <p className='introduction-card__title title'>{memberName}님과 비슷한<br/>레벨의 수강생은<br/>이렇게 학습하고 있어요.</p>
        <img src={process.env.REACT_APP_IMG_URL + '/report/freereport_blue_04.png'} alt='' />
        <a className='introduction-card__link link' onClick={() => window.open(process.env.REACT_APP_MAIN_URL + '/lessonreview', '_self')}>수강생 후기 더보기 &gt;</a>
      </section>
      
      <button className='introduction-button' onClick={() => window.open(process.env.REACT_APP_ENV_URL + '/Order', '_self')}>하루 10분 영어회화 시작</button>
    </section>
  );
}
