import React from 'react';
import 'css/channelTalk.scss';

interface Props {
  badgeCount: number,
  visible?: boolean
}

export default function ChannelTalkButton({ badgeCount, visible = true }: Props) {
  // 기업문의 버튼 없는 페이지는 채널톡 버튼이 하단에 붙어야함
  const B2BblackList = ['course', 'order'];
  const pathname = window.location.pathname.toLowerCase();
  const isB2BHidden = B2BblackList.some(page => pathname.includes(page));

  return (
    <button className={`channel-talk ${isB2BHidden ? 'channel-talk--only' : ''} ${visible ? '' : 'channel-talk--hidden'}`}>
      {badgeCount > 0 && <div className='badge'>{badgeCount}</div>}
    </button>
  );
}
