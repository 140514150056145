import React, {useState} from 'react';
import {ElementType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: ElementType;
}

export default function Discussion({componentCode, content}: PropsType) {
  const [showMemo, setShowMemo] = useState(false);

  return (
    <div className={`page ${componentCode}`}>
      <div className={'component-name'}>Open Discussion</div>
      <div className='component-desc'>글에서 얻은 나만의 인사이트를 바탕으로 토론을 진행해 보세요.</div>
      {content.imgUrl && (
        <div className='pictureBox'>
          <img className='picture' src={content.imgUrl} alt='' />
        </div>
      )}
      <div className={'discussion-wrapper'}>
        <div className={'situation'}>{content.sentence}</div>
        {content.sub && content.sub.length > 0 && (
          <ul>
            {content.sub.map((item, index) => (
              <li key={`k${index}`} className={'opinion'}>
                {item.sentence}
              </li>
            ))}
          </ul>
        )}
      </div>
      {content?.memo && (
        <div className={'memo'}>
          <div
            className={showMemo ? 'memo-button open' : 'memo-button close'}
            onClick={() => setShowMemo((v) => !v)}
          >
            메모
          </div>
          {showMemo && <div className={'memo-content'}>{content.memo}</div>}
        </div>
      )}
    </div>
  );
}
