import React, {Component} from 'react';
import {httpDefaultClient} from 'configs/api';

export default class ConsultingTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      txt: ''
    };
  }
  componentDidMount(){
    httpDefaultClient.get('/sign/getSupport?supportId=consulting')
      .then(res => {
        const json = res.data;
        if (json.result) {
          this.setState({
            txt: json.data
          });
        }
      })
      .catch(error => console.error('error: ', error));
  }
  render() {
    return (
      <div dangerouslySetInnerHTML={{__html : this.state.txt}} />
    );
  };

}
