import React, {useEffect, useRef, useState, useCallback} from 'react';
import {animateScroll as scroll} from 'react-scroll';
import {isMobile} from 'react-device-detect';
import '../css/FreelessonBook.scss';
import {httpDefaultClient} from 'configs/api';

const FreelessonBook = props => {
  const [loading, setLoading] = useState(false);
  const [book, setBook] = useState([]);
  const [components, setComponents] = useState();
  const [st, setST] = useState();
  const [ti, setTI] = useState();
  const [md, setMD] = useState();
  const [rt, setRT] = useState();
  const [de, setDE] = useState();
  const [pd, setPD] = useState();
  const [qu, setQU] = useState();
  const [bookList, setBookList] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState();
  const bookRef = useRef(null);
  const comST = useRef(null);
  const comTI = useRef(null);
  const comMD = useRef(null);
  const comRT = useRef(null);
  const comDE = useRef(null);
  const comPD = useRef(null);
  const comQU = useRef(null);
  const [transPD, setTransPD] = useState(false);
  const [transQU, setTransQU] = useState(false);
  const [teacher, setTeacher] = useState('');
  const [member, setMember]=useState('');
  const [levelCode, setLevelCode] = useState('');
  const [juniorFlag, setJuniorFlag]=useState(false);

  useEffect(() => {
    const url = isNaN(props.code) ? `/freelesson/bookByCode/${props.code}?languageCode=ko` : `/freelesson/book/${props.code}?languageCode=ko`;
    httpDefaultClient.get(url)
      .then(res => {
        const json = res.data;
        setBook(json);
        setComponents(json.components);
        if (json.ST) setST(json.ST);
        if (json.TI) setTI(json.TI);
        if (json.MD) setMD(json.MD);
        if (json.RT) setRT(json.RT);
        if (json.DE) setDE(json.DE);
        if (json.PD) setPD(json.PD);
        if (json.QU) setQU(json.QU);
        const transState = json.levelCode==='low'||json.levelCode==='zero'?true:false;
        setTransPD(transState);
        setTransQU(transState);
        setTeacher(json.teacherName);
        setMember(json.memberName);
        setLevelCode(json.levelCode);
        setJuniorFlag(json.juniorFlag);
        setLoading(true);
      })
      .catch((error) => console.error('error: ', error));
    bookRef.current.addEventListener('scroll', scrollPos);
    return function cleanup(){
      bookRef.current.removeEventListener('scroll',scrollPos);
    };
  }, []);

  const toggleTranslation = (componentCode) => {
    if (componentCode==='PD'){
      setTransPD(!transPD);
    }
    else if (componentCode==='QU'){
      setTransQU(!transQU);
    }
  };

  const close = () => {
    if (props.closeFunction) {
      props.closeFunction();
    } else {
      if (isMobile) {
        window.close();
        window.location.href = process.env.REACT_APP_MAIN_URL + '/';
      } else {
        window.location.href = process.env.REACT_APP_MAIN_URL + '/';
      }
    }
  };

  const moveToComponent = (e, componentCode) => {
    e.preventDefault();
    const ref = eval('com'+componentCode);
    const t = e.currentTarget;
    if (ref && ref.current) {
      t.classList.add('on');
      setSelectedMenu(componentCode);
      setTimeout(() => {
        t.classList.remove('on');
        closeShortCut();
        ref.current.scrollIntoView({ behavior: 'smooth' });
      }, 500);
    }
  };

  const openShortCut = () => {
    setBookList(true);
  };

  const closeShortCut = () => {
    setBookList(false);
  };

  const componentDescription = useCallback((componentCode) => {
    if (componentCode==='ST') {
      return juniorFlag?'강사님과 반갑게 인사해요.':'강사님과 가볍게 대화를 나눠보세요.';
    } else if (componentCode==='TI') {
      return juniorFlag?'아래 그림을 보고 질문에 대답해 보세요.':'아래 이미지를 보고 질문에 답해보세요.';
    } else if (componentCode==='MD') {
      return '강사님과 역할을 나누어 대화문을 읽어보세요.';
    } else if (componentCode==='RT') {
      return '본문을 정확하게 읽어보세요.';
    } else if (componentCode==='DE') {
      return '사진을 최대한 자세하게 묘사해보세요.';
    } else if (componentCode==='PD') {
      return juniorFlag
        ?'주어진 문장을 사용하여 나만의 문장을 말해보세요.'
        : levelCode==='zero'
          ?'강사님의 발음을 듣고 따라 말해보세요.'
          :'패턴을 사용하여 자신만의 문장을 말해보세요.';
    } else if (componentCode==='QU') {
      return levelCode==='zero'&&!juniorFlag?'앞에 배운 표현을 활용해서 답해보세요.':'오늘의 주제와 관련된 질문에 답해보세요.';
    }
    return '';
  }, [levelCode, juniorFlag]);

  const scrollPos = () => {
    menuSelect();
  };

  const menuSelect = useCallback(() => {
    const menu = [comST,comTI,comMD,comRT,comDE,comPD,comQU];
    menu.map(ele => {
      if (ele && ele.current) {
        const menuName = ele.current.classList[1].toUpperCase();
        const eleTop = ele.current.getBoundingClientRect().top;
        if (eleTop<window.innerHeight / 2) {
          setSelectedMenu(menuName);
        }
      }
    });
  });

  return (
    <div className={'freelessonBook'} ref={bookRef} id='book'>
      <div className={'freelessonBook__top'}>
        <div className={'freelessonBook__top--menu'} onClick={openShortCut}><img className={'circleButton'} src={`${process.env.REACT_APP_IMG_URL}/icon/btn_menu_circle.svg`} alt={'menu'}/></div>
        <div className={'freelessonBook__top--close'} onClick={close}><img className={'circleButton'} src={`${process.env.REACT_APP_IMG_URL}/icon/btn_close_circle.svg`} alt={'Close Button'} /></div>
      </div>
      <div className={`freelessonBook__menu ${!bookList && 'hidden'}`}>
        <div className={'close--button'} onClick={closeShortCut}><img className={'circleButton'} src={`${process.env.REACT_APP_IMG_URL}/icon/btn_close_circleblack.svg`} alt={'Close Button'}/></div>
        <ul>
          {st && <li className={selectedMenu==='ST'?'on':''} onClick={(e) => moveToComponent(e, 'ST')}>Greeting & Small Talk<br/><span>{componentDescription('ST')}</span></li>}
          {ti && <li className={selectedMenu==='TI'?'on':''} onClick={(e) => moveToComponent(e, 'TI')}>Today's Image<br/><span>{componentDescription('TI')}</span></li> }
          {md && <li className={selectedMenu==='MD'?'on':''} onClick={(e) => moveToComponent(e, 'MD')}>Main Dialogue<br/><span>{componentDescription('MD')}</span></li>}
          {rt && <li className={selectedMenu==='RT'?'on':''} onClick={(e) => moveToComponent(e, 'RT')}>Read a Text Aloud<br/><span>{componentDescription('RT')}</span></li> }
          {de && <li className={selectedMenu==='DE'?'on':''} onClick={(e) => moveToComponent(e, 'DE')}>Describe a Picture<br/><span>{componentDescription('DE')}</span></li> }
          {pd && <li className={selectedMenu==='PD'?'on':''} onClick={(e) => moveToComponent(e, 'PD')}>Pattern Drills<br/><span>{componentDescription('PD')}</span></li>}
          {qu && <li className={selectedMenu==='QU'?'on':''} onClick={(e) => moveToComponent(e, 'QU')}>Questions<br/><span>{componentDescription('QU')}</span></li>}
        </ul>
      </div>
      <div className={'freelessonBook__greeting'}>
        <div className={'txt1'}>
          {teacher} 강사님과
          <br/>
          {juniorFlag?'레벨테스트를':'무료수업을'}
          <br/>
          {juniorFlag?'시작해보세요.':'진행해보세요.'}
        </div>
        <div className={'txt2'}>오늘의 반가운 인사말</div>
        <div className={'txt3'}>"{book.greeting}"</div>
        <img className={'balloon'} src={`${process.env.REACT_APP_IMG_URL}/icon/balloon.svg`} alt={'icon'} />
        <br/>
        <img className={'arrow'} src={`${process.env.REACT_APP_IMG_URL}/icon/arrow_down_w.svg`} alt={'icon'} onClick={(e) => moveToComponent(e, components[0])} />
      </div>
      {st &&
				<div className={'freelessonBook__unit st'} ref={comST}>
				  <div className={'title'}>Greeting & Small Talk</div>
				  <div className={'subTitle'}>{componentDescription('ST')}</div>
				  <div className={'contentBox'}>
				    <div className={'smallTitle'}>오늘의 주제</div>
				    <div className={'subject'}>{st.subject}</div>
				    <div className={'tip'}><span>TIP</span>
				      {juniorFlag
				        ? '아래 단어를 사용해 보세요.'
				        : levelCode==='zero'
				          ?'강사님과 연결되면 바로 수업이 시작됩니다. 스크립트를 읽으며 대화를 이어나가 주세요.'
				          :'아래 단어를 활용해 보세요.'}
				    </div>
				    { (levelCode!=='zero'||juniorFlag) &&
							<ul>
							  {st.examples && st.examples.map((v, i) => {
							    return (
							      <li key={i} className={'smalltalk'}>{v.sentence}<span>{v.description}</span></li>
							    );
							  })}
							</ul>
				    }
				  </div>
				  { levelCode==='zero' && !juniorFlag &&
						<div className={'freelessonBook__unit__dialog'}>
						  {st.examples && st.examples.map((v, i) => {
						    return (
						      <div className={`speaker ${i%2==1?'A':'B'}`} key={i}>
						        {i%2==1 ? (<div className={'part a'}>Student<span>S</span></div>):(<div className={'part b'}><span>T</span>Teacher</div>)}
						        <div key={i} className={'bubble'}>
						          <div className='sentence'>{v.sentence}</div>
						          {v.description && <div key={i}  className='description'>{v.description}</div>}
						        </div>
						      </div>
						    );
						  })}
						</div>
				  }
				</div>
      }
      {ti &&
        <div className={'freelessonBook__unit ti'} ref={comTI}>
          <div className={'title'}>Today’s Image</div>
          <div className={'subTitle'}>{componentDescription('TI')}</div>
          {ti.map((item, index) => {
            return (
              <div className={'questionBox'} key={index}>
                <div className={'no'}>Question {item.sort}</div>
                <div className={'boxSh'} />
                <div className={'sentence'}>{item.sentence}</div>
                <div className={'image'}><img src={item.imageUrl} /></div>

              </div>
            );
          })}
        </div>}
      {md &&
				<div className={'freelessonBook__unit md'} ref={comMD}>
				  <div className={'title'}>Main Dialogue</div>
				  <div className={'subTitle'}>{componentDescription('MD')}</div>
				  <div className={'freelessonBook__unit__dialog'}>
				    {md.map((v, i) => {
				      return (
				        <div className={`speaker ${v.speaker}`} key={i}>
				          {v.speaker === 'A' ? (<div className={'part a'}>Student<span>S</span></div>):(<div className={'part b'}><span>T</span>Teacher</div>)}
				          <div key={i} className={'bubble'}>
				            <div className='sentence'>{v.sentence}</div>
				            {v.description && <div key={i}  className='description'>{v.description}</div>}
				          </div>
				        </div>
				      );
				    })}
				  </div>
				</div>}
      {rt &&
        <div className={'freelessonBook__unit rt'} ref={comRT}>
          <div className={'title'}>Read a Text Aloud</div>
          <div className={'subTitle'}>{componentDescription('RT')}</div>
          {rt.map((item, index) => {
            return (
              <div className={'testBox'} key={index}>
                <div className={'question'}>Question {item.sort}</div>
                <div className={'sentence'}>{item.sentence}</div>
              </div>
            );
          })}
        </div>}
      {de &&
        <div className={'freelessonBook__unit de'} ref={comDE}>
          <div className={'title'}>Describe a Picture</div>
          <div className={'subTitle'}>{componentDescription('DE')}</div>
          {de.map((item, index) => {
            return (
              <div className={'testBox'} key={index}>
                <div className={'question'}>Question {item.sort}</div>
                <div className={'image'}><img src={item.imageUrl} /></div>
              </div>
            );
          })}
        </div>}
      {pd &&
				<div className={'freelessonBook__unit pd'} ref={comPD}>
				  <div className={'title'}>Pattern Drills</div>
				  <div className={'subTitle'}>{componentDescription('PD')}</div>
				  {pd && pd.length>0 && pd[0].description && <div className={`trans ${transPD&&'tt'}`} onClick={(e) => toggleTranslation('PD')}><span className={'e'}>A/가</span><span className={'t'}>ABC</span></div>}
				  {pd.map((item, index) => {
				    return (
				      <div className={'contentBox withNumber'} key={index}>
				        <div className={'no'}>{index+1<10?'0'+(index+1):index+1}</div>
				        <div className={'boxSh'} />
				        <div className={'sentence'}>{item.sentence}</div>
				        <div className={`translation ${!transPD?'off':''}`}>{item.description}</div>
				        {
				          item.sub && item.sub.length>0 &&
									<>
									  <div className={'subBadge'}>Teacher's Turn</div>
									  { item.sub && item.sub.map((subItem, index) => {
									      return <div key={`s${index}`} className={'subSentence'} dangerouslySetInnerHTML={{__html: subItem.sentence}}/>;
									    })
									  }
									</>
				        }
				        {item.imageUrl &&
                  <div className={'image'}><img src={item.imageUrl} /></div>
				        }
				      </div>
				    );
				  })}
				</div>}
      {qu &&
				<div className={'freelessonBook__unit qu'} ref={comQU}>
				  <div className={'title'}>Questions</div>
				  <div className={'subTitle'}>{componentDescription('QU')}</div>
				  {qu && qu.length>0 && qu[0].description && <div className={`trans ${transQU&&'tt'}`} onClick={(e) => toggleTranslation('QU')}><span className={'e'}>A/가</span><span className={'t'}>ABC</span></div>}
				  {qu && qu.map((item, i) => {
				    return (
				      <div className={'contentBox withNumber'} key={i}>
				        <div className={'no'}>{i+1<10?'0'+(i+1):i+1}</div>
				        <div className={'boxSh'} />
				        <div className={'sentence'}>{item.sentence}</div>
				        <div className={`translation ${!transQU?'off':''}`}>{item.description}</div>
				        {item.sub && item.sub.length>0 &&
									<>
									  <div className={'subBadge'}>Sample Answer</div>
									  { levelCode==='zero' && item.sub && item.sub.map((subItem, index) => {
									    return <div key={`s${index}`} className={'subSentence'} dangerouslySetInnerHTML={{__html: subItem.sentence}}/>;
									  })
									  }
									  { levelCode!=='zero' &&
											<div className={'subSentence'} dangerouslySetInnerHTML={{__html: item.sub[0].sentence}}/>
									  }
									</>
				        }
				        {item.imageUrl &&
                  <div className={'image'}><img src={item.imageUrl} /></div>
				        }
				      </div>
				    );
				  })}
				</div>}

      <div className={'freelessonBook__greeting'}>
        <div className={'txt1'}>{member}님<br/> Great👏, 오늘 <br/>무료수업 멋졌어요.</div>
        <div className={'hoLine'} />
        <section className={'afterbenefit'}>
          <div className={'afterbenefit-title'}>남은 혜택도 확인하세요!</div>
          <section className={'afterbenefit-card'}>
            <img className={'afterbenefit-card__icon'} src={process.env.REACT_APP_IMG_URL + '/freelesson/book/icon_bye_report@2x.png'} alt='report'/>
            <p className={'afterbenefit-card__sub'}>5가지 영역별 피드백</p>
            <p className={'afterbenefit-card__title'}>무료수업 결과리포트</p>
          </section>
          <section className={'afterbenefit-card'}>
            <img className={'afterbenefit-card__icon'} src={process.env.REACT_APP_IMG_URL + '/freelesson/book/icon_bye_consult@2x.png'} alt='consult'/>
            <p className={'afterbenefit-card__sub'}>전문 컨설턴트가 드리는</p>
            <p className={'afterbenefit-card__title'}>학습 상담 · 특별 혜택</p>
          </section>
        </section>
      </div>
      <div className={`msk ${!bookList && 'hidden'}`} />
    </div>
  );
};

export default FreelessonBook;
