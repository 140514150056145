import React, {useState} from 'react';
import {ScriptType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: ScriptType;
}

const componentDesc = {
  script: '강사님과 오늘의 본문 내용에 대해 얘기해보세요.',
  summary: '요약본을 참고하여 학습한 내용에 대해 얘기해보세요.',
};

export default function Script({componentCode, content}: PropsType) {
  const [showMemo, setShowMemo] = useState(false);
  const [showSummary, setShowSummary] = useState(content.summaryFlag);

  return (
    <div className={`page ${componentCode}`}>
      <div className='component-name'>Script Reading</div>
      <div className='component-desc'>{componentDesc[content.summaryFlag?'summary':'script']}</div>
      {
        content.summaryFlag &&
        <button className={'switch'}
          onClick={() => setShowSummary((v) => !v)}
        >
          <span>{showSummary?'본문 보기':'요약본 보기'}</span>
        </button>
      }
      {showSummary
        ? <div className='content'>{content.summary}</div>
        : content.script.map((item, index) => (
          <div className='content' key={`s${index}`}>
            {item.speaker ? `${item.speaker}: ` : ''}
            {item.sentence}
          </div>
        ))
      }
      {content?.memo && (
        <div className={'memo'}>
          <div
            className={showMemo ? 'memo-button open' : 'memo-button close'}
            onClick={() => setShowMemo((v) => !v)}
          >
            메모
          </div>
          {showMemo && <div className={'memo-content'}>{content.memo}</div>}
        </div>
      )}
    </div>
  );
}
