import React, { useEffect, useState, useMemo } from 'react';
import Swiper from 'react-id-swiper';

import ChannelService from 'component/ChannelService';
import ChannelTalkButton from 'component/channelTalk/ChannelTalkButton';
import { Loading } from 'component/Loading';
import ClassQuotationSection from './ClassQuotationSection';
import ClassQuotationB2BSection from './ClassQuotationB2BSection';
import TicketItem from './TicketItem';
import TicketVendingMachineB2B from './TicketVendingMachineB2B';

import { httpDefaultClient } from 'configs/api';
import { useMultipleCommonCodes } from 'queries/common/useCommonCode';
import Top from '../../component/top';
import { saveAccessDataByAccessFunction } from '../../services/accessData/accessAnalytics';
import SupportBoard from '../common/SupportBoard';
import LayerPopup from '../LayerPopup';
import PurchaseBanner from './PurchaseBanner';

const ClassEnrollment = ({ props }) => {
  const { data: commonCodes = {} } = useMultipleCommonCodes(['BENEFIT']);
  const [channelTalkBadge, setChannelTalkBadge] = useState(0);

  //state
  const {
    pageComplete,
    commonMsg, 
    selectProduct,
    selectProductBillFlag,
    lessonStatus, companyPurchaseInfo, productInfo,
    viewProduct, selectProductList, bestProduct, selectBestProductList,
    purchaseBanner,
    contentOnly,
    isAliceOrder,
    memberName,
    companyName,
    languageTypeList, 
    languageTypeState,
    unablePurchaseB2B,
    productMinuteList, productWeekList, templateCodeList, lessonModeList, timezoneCodeList, productTypeList,
    timezoneCodeIndex,
    productMinuteState, productWeekState, templateCodeState, lessonModeState, timezoneCodeState, productTypeState,
    productMinuteValue, productWeekValue, lessonModeValue, timezoneCodeValue, productTypeValue,
    fixedCardInfo,
    waitingFlag, termlimitCount, waitingPriceSeq,
    juniorFlag,
    inClassFlag,
    isShowWaitingClassPopup, isShowFinishWaitingClassPopup, isShowAlreadyDoneWaitingClassPopup, presetWaitingClassPriceSeq,
    languageTypeValue,
    languageNameValue,
    courseSeqValue,
    courseNameValue,
    currentCourseSeq,
    strategyList,
    levelCode,
    memberClass,
    discountType,
    couponDiscount,
    couponValue,
    mileageInput,
    mMileage,
    satisfyFlag,
  } = props;

  //props
  const {
    history,
    go,
    ww,
    params3,
    params4,
    processStep2,
    toggleStateValue,
    openPopup,
    bestProductClick,
    purchaseBannerClick,
    productClick,
    selectFilterValue,
    selectStrategyTab,
    changeStrategyLanguage,
    showStrategy,
    closePopup,
    movingToB2BClassPayment,
    getPaymentInfo,
  } = props;

  const isB2B = memberClass==='B2B';

  useEffect(() => {
    if (!isB2B && productInfo) {
      getPaymentInfo(selectProduct.billFlag === selectProductBillFlag ? selectProduct.priceSeq : selectProduct.anotherProductPrice.priceSeq, false);
    }
  }, [selectProduct, selectProductBillFlag]);

  // 배너 영역 노출 여부
  const showPurchaseBanner = useMemo(() => {
    if (!purchaseBanner) return false;
    return purchaseBanner.filter(product => ww === 'pc' ? product.deviceCode !== 'MOBILE' : ww === 'mo' ? product.deviceCode !== 'PC' : true
    ).length > 0;
  }, [ww, purchaseBanner]);

  // 배너 클릭
  const handleClickPurchaseBanner = (PopupData) => {
    const { popupType } = PopupData;
    if (popupType === 'NONE') {
      return;
    }

    purchaseBannerClick(PopupData);
  };


  // 대기 신청 관련
  const isShowWaitingClassButton = waitingFlag && selectProduct.soldoutFlag; // 상품 대기 신청하기 버튼 노출 여부
  const showWaitingClassPopupToggle = () => { isShowWaitingClassPopup ? closePopup('isShowWaitingClassPopup') : openPopup('isShowWaitingClassPopup'); };
  const showFinishWaitingClassPopupToggle = () => { isShowFinishWaitingClassPopup ? closePopup('isShowFinishWaitingClassPopup') : openPopup('isShowFinishWaitingClassPopup'); };
  const alreadyDoneWaitingClassPopupToggle = () => { isShowAlreadyDoneWaitingClassPopup ? closePopup('isShowAlreadyDoneWaitingClassPopup') : openPopup('isShowAlreadyDoneWaitingClassPopup'); };

  // 대기 신청 처리
  const handleSendWaitingClass = async (priceSeq) => {
    showWaitingClassPopupToggle();
    try {
      const { data } = await httpDefaultClient.post('/company/companyTermWaiting/save', { priceSeq });
      if (!data.result) {
        alreadyDoneWaitingClassPopupToggle();
        return;
      }
      showFinishWaitingClassPopupToggle();
    } catch (error) {
      console.error('대기 신청 실패:', error);
      alreadyDoneWaitingClassPopupToggle();
    }
  };

  const forTicketVendingMachineProps = {
    ww,
    commonMsg,
    productMinuteList, productWeekList, templateCodeList, lessonModeList, timezoneCodeList, productTypeList,
    timezoneCodeIndex,
    productMinuteState, productWeekState, templateCodeState, lessonModeState, timezoneCodeState, productTypeState,
    productMinuteValue, productWeekValue, lessonModeValue, timezoneCodeValue, productTypeValue,
    languageTypeList, languageTypeState,
    viewProduct, productClick, selectProductList,
    selectFilterValue,
    selectStrategyTab,
    changeStrategyLanguage,
    showStrategy,
    waitingFlag, termlimitCount,
    showWaitingClassPopupToggle,
    presetWaitingClassPriceSeq,
    languageTypeValue,
    languageNameValue,
    courseSeqValue,
    courseNameValue,
    currentCourseSeq,
    strategyList,
    levelCode,
    isAliceOrder,
    isB2B,
    juniorFlag,
    inClassFlag,
  };

  const forClassQuotationSectionProps = {
    commonMsg,
    memberClass,
    selectProduct,
    selectProductBillFlag,
    toggleStateValue,
    movingToB2BClassPayment,
    processStep2,
    closePopup,
    discountType,
    couponDiscount,
    couponValue,
    mileageInput,
    mMileage,
    memberName,
    satisfyFlag,
  };

  const forClassQuotationB2BSectionProps = {
    selectProduct,
    movingToB2BClassPayment,
  };

  useEffect(() => {
    saveAccessDataByAccessFunction('reg_referrer');

    // 채널톡 설정
    ChannelService.boot({
      'pluginKey': ChannelService.key(),
      'customLauncherSelector': '.channel-talk',
      'hideChannelButtonOnBoot': true,
    }, (count) => setChannelTalkBadge(count));
    ChannelService.setPage(window.location.href);
    
    return () => {
      ChannelService.shutdown();
    };
  }, []);

  return (
    <div className={'step1'}>
      <Top
        title={'수강신청'} sub={''} history={history}
        movePageName={!(companyPurchaseInfo && companyPurchaseInfo.personalPayment === 0) ? '최대 무이자 12' : null}
        ani={!(companyPurchaseInfo && companyPurchaseInfo.personalPayment === 0) ? 'wave' : null}
        openPopup={() => openPopup('cardInfoPopup')}
        noBg={ww === 'pc'}
        notFixed={ww === 'pc'}
        useTit={true}
        onn={'on'}
        setTopBannerMargin={0}
      />
      {/* 채널톡 버튼 */}
      <ChannelTalkButton badgeCount={channelTalkBadge}/>
      
      {!pageComplete ?
        <Loading />
        :
        <>
          {isAliceOrder && ww === 'pc' &&
          <div className='order-ad'>
            <div className={'topBnrPC'} id={'topBnrPC'}>
              <div className={'slide3 swiper-slide b2b' + (contentOnly ? ' contentOnly' : '')}>
                <div className={'subTxt'}>
                  <span>가장 개인화된</span> AI 영어 스피킹
                </div>
                <div className={'tit'}>
                  <span>{companyName}만을 위한</span> 차원이 다른 하이앨리스
                </div>
              </div>
            </div>
            {/* 무이자 혜택 pc 배너 */}
            {!(companyPurchaseInfo && companyPurchaseInfo.personalPayment === 0) &&
              <div className={'bnrCardInfo ' + fixedCardInfo}>
                <div className={'clickArea'} onClick={() => openPopup('cardInfoPopup')}>
                  <p className={'txt'} dangerouslySetInnerHTML={{__html: commonMsg['PUR000063']}} />
                  <span className={'ico'}></span>
                </div>
              </div>
            }
          </div>
          }

          {!isAliceOrder &&
          <div className='order-ad'>
            {ww === 'pc' &&
              <div className={'topBnrPC'} id={'topBnrPC'}>
                <div className={'slide3 swiper-slide b2b' + (contentOnly ? ' contentOnly' : '')}>
                  <div className={'subTxt'}>
                    <span>영어교육과정 입과</span>를 환영합니다
                  </div>
                  <div className={'tit'}>
                    <span>{companyName}만을 위한</span> 차원이 다른 {contentOnly ? '영어학습' : '1:1수업관리'}
                  </div>
                </div>
              </div>
            }
            {/* 무이자 혜택 pc 배너 */}
            {ww === 'pc' && !(companyPurchaseInfo && companyPurchaseInfo.personalPayment === 0) &&
              <div className={'bnrCardInfo ' + fixedCardInfo}>
                <div className={'clickArea'} onClick={() => openPopup('cardInfoPopup')}>
                  <p className={'txt'} dangerouslySetInnerHTML={{__html: commonMsg['PUR000063']}} />
                  <span className={'ico'}></span>
                </div>
              </div>
            }
            
            {/* 수강혜택 배너 */}
            { showPurchaseBanner &&
              <article className={'card-tickets'}>
                { ww === 'pc' &&
                <div className={'card-tickets__head'}>
                  <h2 className='card-tickets__head-title' dangerouslySetInnerHTML={{__html: commonMsg['PUR000176']}} />
                </div>
                }
                <div className='card-tickets__body'>
                  <Swiper {...params4}>
                    {purchaseBanner
                      .filter((product) => {
                        if (ww === 'pc') { return product.deviceCode !== 'MOBILE'; }
                        if (ww === 'mo') { return product.deviceCode !== 'PC';}
                      }).map((item, i) => {
                        return (
                          <div className='card-tickets__slide card-tickets__slide--purchase-banner' key={i}>
                            <PurchaseBanner
                              onClickBanner={handleClickPurchaseBanner}
                              item={item}
                            />
                          </div>
                        );
                      })}
                  </Swiper>
                </div>
              </article>
            }
            
            { bestProduct?.filter((product) => product.soldoutFlag === false).length > 0 &&
              <article className={'card-tickets card-tickets--best card-tickets--b2b'}>
                <div className={'card-tickets__head'}>
                  <h2 className='card-tickets__head-title'>추천상품</h2>
                </div>
                <div className='card-tickets__body'>
                  <Swiper {...params3}>
                    {bestProduct.filter((product) => product.soldoutFlag === false).map((item, i) => {
                      return (
                        <div className='card-tickets__slide' key={i}>
                          <TicketItem
                            i={i}
                            item={item}
                            waitingFlag={waitingFlag}
                            isSummary={true}
                            productClick={bestProductClick}
                            selectProductList={selectBestProductList}
                            hideBestTag={true}
							              isB2B={isB2B}
							              juniorFlag={juniorFlag}
							              inClassFlag={inClassFlag}
                          />
                        </div>
                      );
                    })}
                  </Swiper>
                </div>
              </article>
            }
          </div>
          }
          {!contentOnly && <div className={'divider-line'}/>}

          <TicketVendingMachineB2B
            props={forTicketVendingMachineProps}
          />

          { !isAliceOrder &&
            <>
              <div className='divider-line'/>
              <section className='order-package'>
                <h2 className='order-package__title' dangerouslySetInnerHTML={{__html: commonMsg['PUR000045']}}/>
                <ul className='order-package__list'>
                  {commonCodes?.BENEFIT && commonCodes?.BENEFIT.map((list, i) => {
                    return (
                      list.note2 !== 'X' && !(contentOnly && list.note3 === 'X') &&
                      <li key={i} dangerouslySetInnerHTML={{__html: list.note1}}/>
                    );
                  })}
                </ul>
              </section>
            </>
          }


          {/* 보이스콜(CHIME) 도입하지 않은 고객사는 미노출 */}
          {(lessonStatus?.serviceType === 'CHIME') && !isAliceOrder &&
            <>
              <div className='divider-line'/>
              <SupportBoard supportId={'supportDeviceB2B'}/>
            </>
          }

          {/* 하이앨리스 안내 */}
          {isAliceOrder &&
            <>
              <div className='divider-line'/>
              <SupportBoard supportId={'hialiceNotice'}/>
            </>
          }

          {/* (B2B) 결제하기 버튼 && 하단 픽스 버튼 */}
          {isB2B && productInfo && !isShowWaitingClassButton && (
            <ClassQuotationB2BSection props={forClassQuotationB2BSectionProps} />
          )}

          {/* (B2C && B2B2C) 결제하기 버튼 && 하단 픽스 버튼 */}
          {!isB2B && productInfo && (
            <>
              <div className='buffer-b2c'></div>
              <ClassQuotationSection props={forClassQuotationSectionProps}/>
            </>
          )}
        

          {/* B2B 선착순 수강신청완료 팝업 */}
          {unablePurchaseB2B &&
            <LayerPopup
              tit={'이미 수강신청을 완료하였습니다.'}
              desc1={'기타 문의사항은 채팅상담으로 남겨주세요.'}
              btnCnt={1}
              btnOkCmt={commonMsg['0000000094']}
              btnOkFunction={() => closePopup('unablePurchaseB2B')}
            />
          }
          {/* 선착순 대기신청내역 팝업 */}
          {
            isShowWaitingClassPopup &&
            <LayerPopup
              tit={'대기신청 하시겠습니까?'}
              desc1={'선택한 수강권'}
              bottomRed={selectProduct.productName}
              btnCnt={2}
              btnYesCmt={'대기신청하기'}
              btnYesFunction={() => handleSendWaitingClass(waitingPriceSeq)}
              btnNoCmt={'취소'}
              btnNoFunction={showWaitingClassPopupToggle}
            />
          }
          {/* 선착순 대기신청완료 팝업 */}
          {
            isShowFinishWaitingClassPopup &&
						<LayerPopup
						  tit={'대기신청이 완료되었습니다.'}
						  desc1={'인원 내 취소 발생시 순차적으로 연락드리겠습니다.'}
						  btnCnt={1}
						  btnOkCmt={'확인'}
						  btnOkFunction={() => go('/')}
						/>
          }
          {/* 중복대기신청 팝업 */}
          {
            isShowAlreadyDoneWaitingClassPopup &&
            <LayerPopup
              tit={'이미 신청하신 대기가 있습니다.'}
              desc1={'1인당 1상품 대기가능'}
              desc2={'문의사항은 채팅상담으로 남겨주세요.'}
              btnCnt={1}
              btnOkCmt={'확인'}
              btnOkFunction={alreadyDoneWaitingClassPopupToggle}
            />
          }
        </>
      }
    </div>
  );
};

export default ClassEnrollment;
