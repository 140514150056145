import React, {useState} from 'react';
import {BookType, DialogType, ExerciseType, PictureLTType} from 'component/book/book-type';


interface PropsType {
  book: BookType;
  lessonType?: string;
}

export default function Closing({book, lessonType}: PropsType) {
  const teacherName = book.teacherName!=='Teacher'?book.teacherName:'';

  if (lessonType==='TICKET') {
    return (
      <div className={'closing leveltest'}>
        <div className='closing1'>
          테스트를 마무리하고
          <br />강사님의 피드백을
          <br />받아보세요.
        </div>
        <div className='closing2'>오늘의 끝인사</div>
        <div className='closing3'>{book.closing}</div>
      </div>
    );
  }
  else if (lessonType==='TRIAL') {
    return (
      <div className={'closing'}>
        <div className='closing-trial'>
          무료 수업을
          <br />
          완료하고, 출석 혜택을
          <br />
          확인하세요.
        </div>
        <section className={'trialbenefit'}>
          <div className='trialbenefit-title'>출석 혜택</div>
          <section className={'trialbenefit-card'}>
            <img className={'trialbenefit-card__icon'} src={'https://img.uphone3.com/freelesson/book/icon_bye_report@2x.png'} alt='report'/>
            <p className={'trialbenefit-card__sub'}>약점 진단 · 학습 가이드 제공</p>
            <p className={'trialbenefit-card__title'}>무료 수업 리포트</p>
          </section>
          <section className={'trialbenefit-card'}>
            <img className={'trialbenefit-card__icon'} src={'https://img.uphone3.com/freelesson/book/icon_bye_consult@2x.png'} alt='consult'/>
            <p className={'trialbenefit-card__sub'}>전문 컨설턴트가 드리는</p>
            <p className={'trialbenefit-card__title'}>학습 상담 · 특별 혜택</p>
          </section>
        </section>
      </div>
    );
  }
  return (
    <div className={'closing'}>
      <div className='closing1'>
        {teacherName} 강사님에게
        <br />
        고마운 마음을 전하며
        <br />
        수업을 마무리해요.
      </div>
      <div className='closing2'>오늘의 끝인사</div>
      <div className='closing3'>{book.closing}</div>
    </div>
  );
}
