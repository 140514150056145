import React, {useState} from 'react';
import {ElementType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: ElementType[];
}

const componentName = {
  PD: 'Pattern Drills',
  KE: 'Key Expressions',
  EX: 'Exercise',
  VO: 'Vocabulary',
  QU: 'Questions',
  RS: 'Part 2. 발음',
  FB: 'Part 3. 문법',
  DP: 'Part 4. 유창성&이해력',
};

const componentDesc = {
  PD: '패턴을 사용하여 자신만의 문장을 말해보세요.',
  KE: '오늘의 표현을 자신만의 다른 표현으로 말해보세요.',
  EX: '강사님과 A/B를 읽고 빈칸에 알맞은 답을 넣어보세요.',
  VO: '단어를 사용해 나만의 문장을 만들어 보세요.',
  QU: '앞에 배운 표현을 활용해서 답해보세요.',
  RS: '아래의 문장을 읽어보세요.',
  FB: '빈칸을 채워 문장을 말해보세요.',
  DP: '사진을 보고 질문에 답해보세요.',
};

const componentBadge = {
  PD: 'Teacher\'s Turn',
  KE: 'Similar Expression',
  FB: 'Example',
  QU: 'Sample Answer',
};

const mark = (markdown: string) => {
  let text = markdown;
  let isOpened = false;
  while (true) {
    const findIndex = text.indexOf('**');
    if (findIndex < 0) {
      break;
    } else {
      if (isOpened) {
        text = `${text.slice(0, findIndex)}</b>${text.slice(findIndex + 2)}`;
      } else {
        text = `${text.slice(0, findIndex)}<b>${text.slice(findIndex + 2)}`;
      }
      isOpened = !isOpened;
    }
  }
  return text;
};


export default function Element({componentCode, content}: PropsType) {
  const [showDescription, setShowDescription] = useState(false);
  const [showMemo, setShowMemo] = useState(
    new Array(content?.length || 0).fill(false)
  );
  const toggleShowMemo = (index: number) => {
    const arr = [...showMemo];
    arr[index] = !arr[index];
    setShowMemo(arr);
  };

  return (
    <div className={`page ${componentCode}`}>
      <div className={componentCode=='RS'||componentCode=='FB'?'leveltest-component-name':'component-name'}>{componentName[componentCode]}</div>
      <div className='component-desc'>{componentDesc[componentCode]}</div>
      {content && content.length > 0 && content[0].description && (
        <button className={'switch'} onClick={() => setShowDescription(!showDescription)}>
          {showDescription ? 'ABC' : 'A/가'}
        </button>
      )}
      {content &&
        content.map((item, index) => (
          <div key={`s${index}`} className='content-box'>
            <div className='label'>0{item.sort}</div>
            <div className={`content ${componentCode}`}>{item.sentence}</div>
            {showDescription && (
              <div className='description'>{item.description}</div>
            )}
            {componentBadge[componentCode] && item.sub && item.sub.length > 0 && (
              <>
                <span className='badge'>{componentBadge[componentCode]}</span>
                <ul>
                  {item.sub.map((item2, index2) => (
                    <li
                      key={`i${index2}`}
                      className='sub-sentence'
                      dangerouslySetInnerHTML={{
                        __html: mark(item2.sentence),
                      }}
                    />
                  ))}
                </ul>
              </>
            )}
            {item.imageUrl && (
              <img className='picture' src={item.imageUrl} alt='' />
            )}
            {item?.memo && (
              <div className={'memo'}>
                <div
                  className={showMemo[index] ? 'memo-button open' : 'memo-button close'}
                  onClick={() => toggleShowMemo(index)}
                >
                  메모
                </div>
                {showMemo[index] && <div className={'memo-content'}>{item.memo}</div>}
              </div>
            )}
          </div>
        ))}
    </div>
  );
}
