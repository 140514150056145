import { StateCreator } from 'zustand';

type LessonModeType = 'AUDIO' | 'VIDEO';

interface LessonModeDetail {
  groupCode: string;
  detailCode: LessonModeType;
  languageCode: string;
  detailName: string;
  sort: number; 
  note1: string; 
  note2: string;
  note3: string; 
  note4: string;
  note5: string;
  useFlag: boolean;
}

interface AlertInfoType {
  alertText: string;
  alertDesc?: string;
  alertFunction: () => void;
}

interface ServerDayType {
  today: string,
  tomorrow:string,
}

type CourseTopic = {
  courseSeq: number;
  courseName: string;
  levelCode: string;
  sort: number;
  unitSeq: number;
  subject: string;
};

type RecommendInfoType = {
  memberSeq: number;       
  recommendCode: string;   
  lessonPhone: string;   
}

interface OriginalLessonInfoType {
  trialSeq: number;
  timechangeUrl: string;
  juniorFlag: boolean;
  lessonMode: string;
  levelCode: string;
  courseSeq: number;
  lessonDate: string;
  lessonTime: string;
  lessonTimestamp: number;
}


export interface ReserveTrialT {
  lessonMode: LessonModeType;
  levelCode: string;
  showLevel: boolean;
  LessonModeLMSData: LessonModeDetail[];
  levelCodeLMSData: LessonModeDetail[];
  isLoading: boolean;
  alertInfo: AlertInfoType;
  bodyTop: string;
  bodyHiddenSpace: string;
  fixedTitle: string;
  juniorFlag: boolean;
  memberFlag: boolean;
  lessonMinute: number;
  serverDay: ServerDayType;
  redirectPath: string;
  topicList: CourseTopic[];
  selectedTopic: CourseTopic;
  isReserveButtonActive: boolean;
  recommendInfo: RecommendInfoType;
  replacePath: string;
  fixedMode: string;
  lessonTimestamp: number;
  isShowTrialBook: boolean;
  trialSeq: number;
  originalLessonInfo: OriginalLessonInfoType;
  lessonReviews: any[];
  setOriginalLessonInfo: (info: OriginalLessonInfoType) => void;
  setTrialSeq: (seq: number) => void;
  setIsShowTrialBook: (bool: boolean) => void;
  setLessonTimestamp: (timestamp: number) => void;
  setReplacePath: (path: string) => void;
  setRedirectPath: (path: string) => void;
  setServerDay: (day: ServerDayType) => void;
  setLessonMinute: (min: number) => void;
  setLessonMode: (lessonMode: LessonModeType) => void; 
  setLevelCode: (code:string) => void;
  setShowLevel: (code:boolean) => void;
  setLessonModeLMSData: (lmsData: LessonModeDetail[]) => void;
  setLevelCodeLMSData: (lmsData: LessonModeDetail[]) => void;
  setIsLoading: (bool: boolean) => void;
  setAlertInfo: (alertInfo: AlertInfoType) => void;
  setFixedTitle: (title: string) => void;
  setBodyHiddenSpace: (space: string) => void;
  setBodyTop: (top: string) => void;
  setJuniorFlag: (flag: boolean) => void;
  setMemberFlag: (flag: boolean) => void;
  setIsReserveButtonActive: (bool: boolean) => void;
  setTopicList: (topicList: CourseTopic[]) => void;
  selectTopic: (topic: CourseTopic) => void;
  setRecommendInfo: (info: RecommendInfoType) => void;
  setFixedMode: (mode: string) => void;
  setLessonReviews: (lessonReviews: any[]) => void;
  resetTrialState: () => void; 
}

const initialState = {
  lessonMode: 'AUDIO' as 'AUDIO' | 'VIDEO',
  levelCode: 'mid',
  showLevel: true,
  LessonModeLMSData: [],
  levelCodeLMSData: [],
  isLoading: true,

  topicList: [],
  selectedTopic: {
    courseSeq: 0,
    courseName: '',
    levelCode: 'low',
    sort: 0,
    unitSeq: 0,
    subject: ''
  },
  alertInfo: {
    alertText: '',
    alertFunction: () => { },
  },
  bodyTop: '19rem',
  bodyHiddenSpace: '0',
  fixedTitle: '',
  juniorFlag: false,
  memberFlag: false,
  checkApplyResult: false,
  lessonMinute: 10,
  serverDay: {
    today: '',
    tomorrow:''
  },
  replacePath:'',
  redirectPath: '',
  isReserveButtonActive: false,
  recommendInfo: {
    memberSeq: 0,
    recommendCode: '',
    lessonPhone: ''
  },
  fixedMode: '',
  lessonTimestamp: 0,
  isShowTrialBook: false,
  trialSeq: 0,
  originalLessonInfo: {
    trialSeq: 0,
    timechangeUrl: '',
    juniorFlag: false,
    lessonMode: '',
    levelCode: '',
    courseSeq: 0,
    lessonDate: '',
    lessonTime: '',
    lessonTimestamp: 0
  },
  lessonReviews: [],
};

export const reserveTrialSlice: StateCreator<
  ReserveTrialT, 
  any[],  
  any[], 
  ReserveTrialT
> = (set) => ({
  ...initialState,
  setLessonMode: (mode) => {
    set((state) => ({ ...state, lessonMode: mode }));
  },
  setLevelCode: (code) => {
    set((state) => ({ ...state, levelCode: code }));
  },
  setShowLevel: (data) => {
    set((state) => ({ ...state, showLevel: data }));
  },
  setLessonModeLMSData(lmsData) {
    set((state) => ({ ...state, LessonModeLMSData: lmsData }));
  },
  setLevelCodeLMSData(lmsData) {
    set((state) => ({ ...state,levelCodeLMSData : lmsData }));
  },
  setIsLoading: (bool:boolean) => {
    set((state) => ({ ...state, isLoading: bool }));
  },
  setAlertInfo: (alertInfo) => {
    set((state) => ({ ...state, alertInfo }));
  },
  setFixedTitle: (fixedTitle) => {
    set((state) => ({ ...state, fixedTitle }));
  },
  setBodyHiddenSpace: (bodyHiddenSpace) => {
    set((state) => ({ ...state, bodyHiddenSpace }));
  },
  setBodyTop: (bodyTop) => {
    set((state) => ({ ...state, bodyTop }));
  },
  setJuniorFlag: (juniorFlag) => {
    set((state) => ({ ...state, juniorFlag }));
  },
  setMemberFlag: (memberFlag) => {
    set((state) => ({ ...state, memberFlag }));
  },
  setLessonMinute: (lessonMinute) => {
    set((state) => ({ ...state, lessonMinute }));
  },
  setServerDay: (serverDay) => {
    set((state) => ({ ...state, serverDay }));
  },
  setRedirectPath: (redirectPath) => {
    set((state) => ({ ...state, redirectPath }));
  },
  setReplacePath: (replacePath) => {
    set((state) => ({ ...state, replacePath }));
  },
  setIsReserveButtonActive: (bool) => {
    set((state) => ({ ...state, isReserveButtonActive: bool }));
  },
  setTopicList: (topicList) => {
    set((state) => ({ ...state, topicList }));
  },
  selectTopic: (topic) => {
    set((state) => ({ ...state, selectedTopic:topic }));
  },
  setRecommendInfo: (recommendInfo) => {
    set((state) => ({ ...state, recommendInfo }));
  },
  setFixedMode: (fixedMode) => {
    set((state) => ({ ...state, fixedMode }));
  },
  setLessonTimestamp:(lessonTimestamp) => {
    set((state) => ({ ...state, lessonTimestamp }));
  },
  setIsShowTrialBook:(bool) => {
    set((state) => ({ ...state, isShowTrialBook:bool }));
  },
  setTrialSeq:(trialSeq) => {
    set((state) => ({ ...state, trialSeq }));
  },
  setOriginalLessonInfo:(originalLessonInfo) => {
    set((state) => ({ ...state, originalLessonInfo }));
  },
  setLessonReviews: (lessonReviews) => {
    set((state) => ({ ...state, lessonReviews: [...lessonReviews] }));
  },
  resetTrialState: () => {
    set(initialState);
  },
});
export default reserveTrialSlice;
