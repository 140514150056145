import React from 'react';
import reserveTrialStore from 'store/trial/reserve/reserveTrialStore';
import * as utilFn from 'common/utilFn';

export default function TrialDisabledUserInfo() {
  const inputState = reserveTrialStore((state) => state.inputState);
  return (
    <>
      <div className={`memberInput ${inputState.name && 'v'}`}>
        <input name={'name'} className='input' id={'name'} value={inputState.name} readOnly={true} disabled={true} />
        <label htmlFor={'name'}>이름</label>
      </div>
      <div className={`memberInput t2 ${inputState.cellPhone && 'v'}`}>
        <input name={'cellPhone'} id={'cellPhone'} className={`input--tel input ${inputState.cellPhoneError && 'error'}`}
          type={'tel'} maxLength={13} value={utilFn.cellPhoneInput('ko', inputState.cellPhone)} readOnly={true} disabled={true} />
        <label htmlFor={'cellPhone'}>휴대전화번호</label>
      </div>
      <p className={'telChangeMap'}>번호 변경 : 민병철유폰 앱 &gt; 내 수강정보 &gt; 설정</p>
    </>
  );
}
