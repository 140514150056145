import React from 'react';
import TrialGuide from './TrialGuide';
import reserveTrialStore, { validateInput }  from 'store/trial/reserve/reserveTrialStore';

export default function TrialMode() {
  const lessonMode = reserveTrialStore((state) => state.lessonMode);
  const setLessonMode = reserveTrialStore((state) => state.setLessonMode);
  const LessonModeLMSData = reserveTrialStore((state) => state.LessonModeLMSData);
  const selectDate = reserveTrialStore((state) => state.selectDate);
  const selectTime = reserveTrialStore((state) => state.selectTime);
  const setDisplayRequestDate = reserveTrialStore((state) => state.setDisplayRequestDate);
  const fixedMode = reserveTrialStore((state) => state.fixedMode);
  const juniorFlag = reserveTrialStore((state) => state.juniorFlag);
  
  const selectLessonMode = (lessonMode: 'AUDIO' | 'VIDEO') => {
    selectDate('');
    selectTime('');
    setDisplayRequestDate('');
    setLessonMode(lessonMode);
    validateInput();
  };
  
  return (
    <div>
      {!fixedMode && !juniorFlag && (
        <>
          <div className={'titleBox'}>
            <div className={'titleBox__title'}>수업 방식</div>
          </div>
          <div className={'lessonMode'}>
            <ul className={'lessonMode__list'}>
              {LessonModeLMSData && LessonModeLMSData.map((v, i) => (
                <li key={i} className={`lessonMode__item ${lessonMode === v.detailCode ? 'lessonMode__item--active' : ''}`}
                  onClick={() => selectLessonMode(v.detailCode)}>
                  <p>{v.detailName}</p>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
      <TrialGuide/>
    </div>
  );
}
