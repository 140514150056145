import React, { useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

import * as utilFn from 'common/utilFn';
import completeTrialStore, { getTrialDate } from 'store/trial/complete/completeTrialStore';

export default function TrialCompleteAppDownloadPopup() {
  const trialInfo = completeTrialStore((state) => state.trialInfo);
  const setAppDownloadPopupFlag = completeTrialStore((state) => state.setAppDownloadPopupFlag);
  const trialDate = getTrialDate();
  const lessonMode = trialInfo.lessonMode==='AUDIO'?'전화수업(보이스콜)':'화상수업(페이스콜)';
  
  const closePopup = () => {
    setAppDownloadPopupFlag(false);
  };

  useEffect(() => {
    utilFn.ScrollNone(true);
    return () => { utilFn.ScrollNone(false); };
  }, []);
  
  return (
    <>
      <div className='trialComplete__app-popup'>
        <button className='close-button' onClick={closePopup} />
        <h3 className='popup-notice'>꼭 알아두세요!</h3>
        {(trialInfo.lessonMode==='VIDEO' && trialInfo.memberClass==='N')?
          <p className='popup-text'>
            반드시 <strong>앱 회원가입</strong>하고,<br/>
            <b>{trialDate}</b><br/>
            <b>앱 {lessonMode}에</b><br/>
            출석해 주세요.
          </p>
          :
          <p className='popup-text'>
            앱 로그인을 할 경우,<br/>
            <b>{trialDate}</b>에<br/>
            <b>앱으로 {lessonMode}</b>을 진행해요.
          </p>
        }
        <a className='popup-mobile-button' href={trialInfo.appDownloadLink}>유폰 앱 다운로드</a>
        <div className='popup-pc-qr'>
          <div className='qr-code'>
            <QRCodeCanvas value={trialInfo.appDownloadLink} />
          </div>
          <p className='qr-description'>카메라로 QR코드를 스캔하여<br/>앱을 다운로드해주세요.</p>
        </div>
      </div>
      <div className='trialComplete__dimmed'></div>
    </>
  );
}
