import React, { FC, useCallback } from 'react';

import 'css/purchase/PurchaseShowcaseB2C.scss';
import { useReviewList } from 'queries/purchase/usePurchaseService';

const PurchaseShowcaseB2CReview: FC = () => {
  const { data: reviewList = [] } = useReviewList();

  const firstRowReviews = [...reviewList.slice(0, 15), ...reviewList.slice(0, 15)];
  const secondRowReviews = [...reviewList.slice(15, 30), ...reviewList.slice(15, 30)];

  const formatMemberId = useCallback((memberId: string) => {
    if (memberId.length <= 3) {
      return memberId + '***';
    } else {
      return memberId.slice(0, 3) + '*'.repeat(memberId.length - 3);
    }
  }, []);

  return (
    <section className='purchase-showcase-review'>
      <header className='purchase-showcase-review__header'>
        <strong className='pre-title-satisfaction'>
          <em className='top-row'>
            <span>100</span>
            <span className='percent'>%</span>
          </em>
          <em  className='bottom-row'>만족보장</em>
        </strong>
        <h2 className='section-title'>유폰 수강생 만족 후기</h2>
      </header>
      <ul className='review-list first-row'>
        {firstRowReviews.map((review, index) => (
          <li key={index} className='review-item'>
            <em className='points'>
              <img src={`${process.env.REACT_APP_IMG_URL}/purchase/ico_five_stars.png`} alt='리뷰 5점' />
            </em>
            <ul className='user-info'>
              <li className='course-period'>{review.coursePeriod}개월 수강 회원</li>
              <li className='writer'>{formatMemberId(review.memberId)} {review.inputDatetime}</li>
            </ul>
            <p className='content'>{review.content}</p>
          </li>
        ))}
      </ul>
      <ul className='review-list second-row'>
        {secondRowReviews.map((review, index) => (
          <li key={index} className='review-item'>
            <em className='points'>
              <img src={`${process.env.REACT_APP_IMG_URL}/purchase/ico_five_stars.png`} alt='리뷰 5점' />
            </em>
            <ul className='user-info'>
              <li className='course-period'>{review.coursePeriod}개월 수강 회원</li>
              <li className='writer'>{formatMemberId(review.memberId)} {review.inputDatetime}</li>
            </ul>
            <p className='content'>{review.content}</p>
          </li>
        ))}
      </ul>
    </section>
  );
};

export default PurchaseShowcaseB2CReview;
