import React, {Component} from 'react';
import moment from 'moment';

export default class ClassCard extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    window.addEventListener('scroll', this.titleChk);
  }

  titleChk = () => {
    this.titleArr.map(x => {
      x.getBoundingClientRect().top<0?x.classList.add('stiky'):x.classList.remove('stiky');
    });

    this.classViewArr.map(x => x.classList.remove('true'));
  };

  titleArr = [];
  titleInput = (ref) => {
    if (!ref) {return false;}
    this.titleArr.push(ref);
  };
  classViewArr = [];

  openTextBook = () => {
    if (this.props.ww === 'pc') {
      window.open('/TextBook/'+this.props.t.memberunitSeq+'/'+this.props.t.lessonSeq, '_blank', 'width=375, height=812, top=100, left=100, scrollbars=yes, resizable=no');
    } else {
      window.location.href = '/TextBook/'+this.props.t.memberunitSeq+'/'+this.props.t.lessonSeq;
    }
  };

  onClickLT = (s) => {
    if (this.props.ww === 'pc') {
      window.open('/LevelTest/p/'+s, '_blank', 'width=375, height=812, top=100, left=100, scrollbars=yes, resizable=no');
    } else {
      window.location.href = '/LevelTest/p/'+s;
    }
  };

  render() {
    let t = this.props.t;
    const n = this.props.n;
    const c = this.props.lectType;
    const fb = this.props.fb;
    const ci = this.props.courseIntro;
    const chg = this.props.changeable;
    const lock = this.props.lockCard;
    const cmt = this.props.lockCardContent;
    const servey = this.props.servey;
    const inClass = this.props.inClass;
    const preview = this.props.preview;
    const txtBook = this.props.txtBook;
    let a;

    if (preview){
      t.cardType = 'Disable';
    } else if (txtBook) {
      if (t.lessonMode === 'LEVELTEST' && this.props.ww !== 'pc') {
        t.cardType = 'LT';
      } else {
        t.cardType = 'General';
      }
    }

    if (t.lastPageStatus === 'Last') {
      return (<></>);
    }
    (t.lastPageStatus === 'Last' && n === 0) ? a = 1 : a = 0;
    if ((c === 'pLectList' && n === a && t.lastPageStatus !== 'Last' && t.memberunitSeq && !preview && !txtBook) || ci) {

      return (
        <>
          {!ci &&
						<div className={'infoWrap pt'} ref={this.titleInput}>
						  <div className={'float'}>
						    <div className={'title'}>{t.courseName}</div>
						  </div>
						  <div className={'dum'}>

						  </div>
						</div>
          }

          {((ci && chg && lock) || (ci && !inClass && lock)) &&
						<div className={'lock'}>
						  <div className={'ico'}/>
						  <div className={'txt'} dangerouslySetInnerHTML={{__html: cmt}}/>
						</div>
          }
        </>
      );
    }

    if (t.cardType === 'General') {
      if (c === 'nLectList' && n === 3) {
        return (
          <div className={'lockWrap'}>
            <img src={process.env.REACT_APP_IMG_URL + '/classroom/lock.svg'} alt={'lock'}/>
            <div className={'text01'}>현재 과정을 완료하면 다음 과정의<br/>전체 유닛이 확인 가능해요.</div>
          </div>
        );
      } else if (c === 'nLectList' && n >= 3) {
        return null;
      }
      let a = t.memberunitSeq;

      return (
        <div className={'listWrap ' + c + ' ' + t.completeFlag}>
          <div className={'left'}>
            <div className={'lImg'}
							 style={{backgroundImage: 'url(' + (t.lessonMode === 'LEVELTEST' ? process.env.REACT_APP_IMG_URL + '/classroom/levelTest.png' : process.env.REACT_APP_LMS_IMG_URL + t.savefileLocation) + ')'}}/>
            <div className={'badg ' + (t.lessonMode === 'LEVELTEST' ? ' adj' : ' ')}>
              <div
                className={'txt'}>{t.lessonMode === 'LEVELTEST' ? 'Level Test' : 'UNIT. ' + (t.unitNumber ? t.unitNumber < 10 ? '0'+(t.unitNumber) : (t.unitNumber) : n+1 < 10 ? '0'+(n+1) : (n+1))}</div>
            </div>
          </div>
          <div className={'right'}>
            <div className={'t'}>
              {t.nextLessonFlag && <div className={'date m01'}><span className={'purple'}>다음수업 </span>{t.lessonMode === 'LEVELTEST' && <span className={'fwr'}>{(t.lessonDate) ? t.lessonDate.replace(/-/g, '.') + '. ' + t.lessonTime.substr(0, 5) : '\u00A0'}</span>}</div>}
              {!t.nextLessonFlag && <div
                className={'date'}>{(t.lessonDate) ? t.lessonDate.replace(/-/g, '.') + '. ' + t.lessonTime.substr(0, 5) : '\u00A0'}</div>}
              <div
                className={'title fe ' + (txtBook ? 'three' : 'two')}>{t.lessonMode === 'LEVELTEST' ? `레벨테스트 ${t.testNumber ? t.testNumber : '1'}회차` : t.subject}</div>
            </div>
            {txtBook ?
              <div className={'bt'}>
                <div className={'move'} onClick={t.lessonMode === 'LEVELTEST' ? () => this.onClickLT(t.lessonSeq) : this.openTextBook}>수업모드</div>
              </div>
              :
              <div className={'bt'}>
                <div className={'l'}>
                  <div className={'lh'}>스스로학습</div>
                  <div
                    className={(t.progressRate !== 0) ? 'li f28 purple' : 'li f28'}>{(t.progressRate !== undefined) ? t.progressRate : '0'}%
                  </div>
                </div>
                <div className={'l'}>
                  <div className={'lh'}>피드백</div>
                  <div
                    className={t.feedbackstatusCode === 'BEFORE' ? 'li BEFORE' : 'li purple'}>{fb}</div>
                </div>
                <div className={'l'}>
                  {t.employeeName ? <div className={'lh'}>{t.employeeName}</div> : '\u00A0'}
                  {(t.employeeName && t.surveyScore > 0)
                    ? <div className={'li purple'}><img
                      src={process.env.REACT_APP_IMG_URL + '/classroom/tch_star.svg'}
                      alt={'icon'}/>{t.surveyScore}</div>
                    : '\u00A0'}
                  {(t.employeeName && t.surveyScore === 0)
                    ? <div className={'li survey'}
											   onClick={servey}>평가</div>
                    : '\u00A0'}
                </div>
              </div>
            }
          </div>
        </div>
      );
    } else if (t.cardType === 'LT') {
      return (
        <div className={`listWrap ${c === 'pLectList' ? 'p' : ''}`}>
          <div className={'lt complete'} onClick={() => this.onClickLT(t.lessonSeq)}>
            <div className={'txt02 date'}>{t.lessonDate ? moment(t.lessonDate).format('YYYY.MM.DD')+' / '+t.lessonTime.substr(0, 5) : ''}</div>
            <div className={'title'}>레벨테스트 {t.testNumber}회차 보기</div>
          </div>
        </div>
      );
    } else if (t.cardType === 'Disable') {
      return (
        <div className={'listWrap'}>
          <div className={'left disable'}>
            <div className={'lImg'}
							 style={{backgroundImage: 'url(' + (t.savefileLocation?process.env.REACT_APP_LMS_IMG_URL+t.savefileLocation:'https://img.uphone3.com/classroom/freetalking.png') + ')'}}/>
            <div className={'badg'}>
              <div className={'txt'}>UNIT. {(t.unitNumber ? t.unitNumber < 10 ? '0'+(t.unitNumber) : (t.unitNumber) : n+1 < 10 ? '0'+(n+1) : (n+1))}</div>
            </div>
          </div>
          <div className={'right disable'}>
            <div className={'t'}>
              <div className={'date'}>{'\u00A0'}</div>
              <div className={'title fe three'}>{t.subject}</div>
            </div>
            <div className={'bt'}>
              {preview ? t.transSubject
                :'다음 Unit 학습을 기다리고 있어요.'}
            </div>
          </div>
        </div>
      );
    }
  }
}
