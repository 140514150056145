import React from 'react';

import { ScrollNone, whatDay } from 'common/utilFn';
import Lottie from 'react-lottie';
import loading from 'json/home_loading_gray.json';

import reserveTrialStore, { onClickRequestDateTime, parserShowRecommendTime } from 'store/trial/reserve/reserveTrialStore';

export default function TrialAllTimeSlot() {
  let numberOfShowMoreTime = 0;
  const levelCode = reserveTrialStore((state) => state.levelCode);
  const slotAll = reserveTrialStore((state) => state.slotAll);
  const recommendTimeOne = reserveTrialStore((state) => state.recommendTimeOne);
  const recommendTimeTwo = reserveTrialStore((state) => state.recommendTimeTwo);
  const recommendTimeThree = reserveTrialStore((state) => state.recommendTimeThree);
  const recommendTimeFour = reserveTrialStore((state) => state.recommendTimeFour);
  const showSlotPopup = reserveTrialStore((state) => state.showSlotPopup);
  const setShowSlotPopup = reserveTrialStore((state) => state.setShowSlotPopup);
  const selectedDate = reserveTrialStore((state) => state.selectedDate);
  const selectedTime = reserveTrialStore((state) => state.selectedTime);
  const isShowMoreTime = reserveTrialStore((state) => state.isShowMoreTime);
  const serverDay = reserveTrialStore((state) => state.serverDay);
  const { today, tomorrow } = serverDay;

  const closeSlotPopup = () => {
    ScrollNone(false);
    setShowSlotPopup(false);
  };

  if (!showSlotPopup) {
    return null;
  }

  return (
    <>
      <div className={'bottomPop freeSlotPopup'}>
        <div className={'topWrap'}>
          <img className={'clsBtn'} onClick={closeSlotPopup} src={`${process.env.REACT_APP_IMG_URL}/bottomPopClose.svg`} alt={'close'} />
        </div>
        {slotAll ?
          slotAll.length > 0 ?
            <div className={'contents'}>
              <div className={'scroll'}>
                {recommendTimeOne &&
                <div className={'recmWrap'}>
                  <div className={'recmTitle'}>추천시간</div>
                  {levelCode === 'low' || levelCode === 'zero'
                    ? <div className={'recmTxt01'}>초보 전문 교재로 쉽고 자신있게 시작하세요.</div>
                    : <div className={'recmTxt01'}>빠른 시간대를 고를수록 영어 목표 달성 확률이 높아요.</div>}
                  <div className='recommend-time-box'>
                    <div
                      className={`recmDate-time recmDate ${(recommendTimeOne.scheduleDate === selectedDate && recommendTimeOne.scheduleTime === selectedTime) && 'on'} ${recommendTimeOne.scheduleDate !== today && 'not'} ${recommendTimeOne.availCount === 0 ? 'disabled' : ''}`}
                      onClick={(e) => { onClickRequestDateTime(recommendTimeOne.scheduleDate, recommendTimeOne.scheduleTime); }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: parserShowRecommendTime(recommendTimeOne) }} />
                    </div>
                    <div
                      className={`recmDate-time recmDate ${(recommendTimeTwo.scheduleDate === selectedDate && recommendTimeTwo.scheduleTime === selectedTime) && 'on'} ${recommendTimeTwo.scheduleDate !== today && 'not'} ${recommendTimeTwo.availCount === 0 ? 'disabled' : ''}`}
                      onClick={(e) => { onClickRequestDateTime(recommendTimeTwo.scheduleDate, recommendTimeTwo.scheduleTime); }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: parserShowRecommendTime(recommendTimeTwo) }} />
                    </div>
                    <div
                      className={`recmDate-time recmDate ${(recommendTimeThree.scheduleDate === selectedDate && recommendTimeThree.scheduleTime === selectedTime) && 'on'} ${recommendTimeThree.scheduleDate !== today && 'not'} ${recommendTimeThree.availCount === 0 ? 'disabled' : ''}`}
                      onClick={(e) => { onClickRequestDateTime(recommendTimeThree.scheduleDate, recommendTimeThree.scheduleTime); }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: parserShowRecommendTime(recommendTimeThree) }} />
                    </div>
                    <div
                      className={`recmDate-time recmDate ${(recommendTimeFour.scheduleDate === selectedDate && recommendTimeFour.scheduleTime === selectedTime) && 'on'} ${recommendTimeFour.scheduleDate !== today && 'not'} ${recommendTimeFour.availCount === 0 ? 'disabled' : ''}`}
                      onClick={(e) => { onClickRequestDateTime(recommendTimeFour.scheduleDate, recommendTimeFour.scheduleTime); }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: parserShowRecommendTime(recommendTimeFour) }} />
                    </div>
                  </div>
                </div>
                }
                <div className={'allWrap'}>
                  <div className={'recmTitle'}>모든시간</div>
                  <div className={'recmTxt01'}>아침 6시부터 새벽 2시까지 자유롭게 선택하세요!</div>
                  {slotAll &&
                  <>
                    {slotAll.map((v, i) => {
                      return (
                        <div className={`dateWrap ${(numberOfShowMoreTime > 30 && !isShowMoreTime) ? 'dateWrap__hidden' : ''}`} key={i}>
                          <div className={'date'}>{v[0].scheduleDate.split('-')[1] + '/' + v[0].scheduleDate.split('-')[2]} ({v[0].scheduleDate === today ? '오늘' : v[0].scheduleDate === tomorrow ? '내일' : whatDay(v[0].scheduleDate)})</div>
                          <ul>
                            {
                              v.map((vi: any, i: number) => {
                                numberOfShowMoreTime += 1;
                                return (
                                  <li className={`${(vi.availCount === 0) && 'disable'} ${(vi.scheduleDate === selectedDate && vi.scheduleTime === selectedTime) && 'on'} elected${(numberOfShowMoreTime > 30 && !isShowMoreTime) ? 'time__hidden' : ''}`} key={i}
                                    onClick={(vi.availCount > 0 ? () => { onClickRequestDateTime(vi.scheduleDate, vi.scheduleTime); } : () => { })}>
                                    <span><span>{vi.scheduleTime}</span></span>
                                  </li>
                                );
                              })
                            }
                          </ul>
                        </div>
                      );
                    })}
                  </>
                  }
                </div>
              </div>
            </div>
            :
            <div className={'contents c recmDate'}>
              <div className={'recmWrap'}>
                <div className={'recmTitle'}>
                  조회 가능한 수업 시간이 없습니다.
                </div>
              </div>
            </div>
          : <div className={'contents c recmDate'}>
            <Lottie options={{ loop: true, autoplay: true, animationData: loading }} />
          </div>
        }
      </div>
      <div className={`mask ${showSlotPopup ? 'on' : ''}`} />
    </>
  );
}
