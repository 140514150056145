import React from 'react';
import {useParams} from 'react-router-dom';
import '../css/Book.scss';
import Book from 'component/book/Book';
import ReactMeta from 'component/ReactMeta';

interface RouteParams {
  timechangeUrl: string;
}

const TrialBookPage: React.FC = () => {
  const { timechangeUrl } = useParams<RouteParams>();

  const closeBook = () => {
    window.close();
    window.location.href = process.env.REACT_APP_MAIN_URL + '/';
  };

  return (
    <>
      <ReactMeta title='무료수업으로 실력진단 | 민병철유폰 1:1영어회화' description='1:1수업부터 유폰 콘텐츠, AI학습까지 모두 무료체험으로 경험해보세요.'/>
      <div className='book-page'>
        <Book
          lessonType={'TRIAL'}
          timechangeUrl={timechangeUrl}
          closeBook={closeBook}
        />
      </div>
    </>
  );
};

export default TrialBookPage;
