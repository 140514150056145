import { StateCreator } from 'zustand';

interface InputStateType {
  [key: string]: string | boolean | number | object;
  name: string;
  nameError: string;
  cellPhone: string;
  cellPhoneError: string;
  auth: string;
  authError: string;
  authInfoView: boolean;
  authDisabled: boolean;
  authPass: boolean;
  contentViewCode: string;
  showContentView: boolean;
  isAuthCodeButton: boolean; //인증코드 버튼 활성화
  activeApplyButton: boolean;
  time: number;
  authTimeError: boolean;
  authInput: string;
  authState: boolean;
  alertText: string;
  alertFunction: () => void;
}

interface ContentStateType {
  contentViewCode: string;
  showContentView: boolean;
}

export interface TrialInputSliceT {
  inputState: InputStateType;
  checkAll: boolean;
  checkState: boolean[];
  inputType: string;
  isInputComplete: boolean;
  contentState: ContentStateType;
  setInputState: (state: InputStateType) => void;
  setCheckAll: (bool: boolean) => void;
  setCheckState: (state: boolean[]) => void;
  setInputType: (type: string) => void;
  setIsInputComplete: (bool: boolean) => void;
  setContentState: (contentState: ContentStateType) => void;
  resetTrialInputState:() => void; 
}

const initialState = {
  inputState: {
    name: '',
    nameError: '',
    cellPhone: '',
    cellPhoneError: '',
    auth: '',
    authError: '',
    authInfoView: false,
    authDisabled: true,
    authPass: false,
    contentViewCode: '',
    showContentView: false,
    isAuthCodeButton: false,
    activeApplyButton: false,
    time: 0,
    authInput: '',
    authTimeError: false,
    authState: false,
    alertText: '',
    alertFunction: () => { },
  },
  contentState: {
    contentViewCode: '',
    showContentView:false,
  },
  checkAll: false,
  checkState: [false, false],
  inputType:'',
  isInputComplete: false,
};

const trialInputSlice: StateCreator<
TrialInputSliceT,any[], 
any[], TrialInputSliceT
  > = (set) => ({
    ...initialState,
    setInputState:(inputState:InputStateType) => {
      set((state) => ({ ...state, inputState,  }));
    },
    setCheckAll: (bool) => {
      set((state) => ({ ...state, checkAll: bool }));
    },
    setCheckState: (checkState) => {
      set((state) => ({ ...state, checkState }));
    },
    setContentState: (contentState) => {
      set((state) => ({ ...state, contentState }));
    },
    setInputType: (inputType:string) => {
      set((state) => ({ ...state, inputType }));
    },
    setIsInputComplete: (bool:boolean) => {
      set((state) => ({ ...state, isInputComplete:bool }));
    },
    resetTrialInputState: () => {
      set(initialState);
    },
  });
 
export default trialInputSlice;
