import React from 'react';
import '../../css/PurchaseSquareTab.scss';

const PurchaseSquareTab = (props) => {

  const {
    tabList,
    stateList,
    dataTitle,
    size,
    onChange,
    alignTop,
    detailName,
    note,
    bestTag, // 무지개색
    recommendTag, // 보라색
    isB2B,
    align,
  } = props;

  return (
    <ul className={`order-square-tab ${(isB2B) ? 'order-square-tab--b2b' : ''}`}>
      <>
        {stateList && tabList?.map((item, index) => (
          <li key={index} className={`order-square-tab__item order-square-tab__item--${size} ${alignTop ? 'order-square-tab__item--align-top' : '' } ${dataTitle==='productType' ? 'order-square-tab__item--asymmetry' : !isB2B && dataTitle==='productMinute' ? 'order-square-tab__item--asymmetry' : ''}`}>
            <input type='radio' name={dataTitle} id={`${dataTitle}${index}`} checked={stateList[index]||false} onChange={onChange ? () => onChange(dataTitle, index, tabList.length, item.detailCode) : null} />
            <label htmlFor={`${dataTitle}${index}`}>
              {bestTag && item[bestTag] &&
                <em className={'tag tag--best'}  dangerouslySetInnerHTML={{__html: item[bestTag]}}></em>
              }
              {recommendTag && item[recommendTag] &&
                <em className={'tag tag--recommend'}  dangerouslySetInnerHTML={{__html: item[recommendTag]}}></em>
              }
              <strong className='main-txt'>{item[detailName]}</strong>
              {note && <span className='sub-txt' style={{textAlign:align?item[align]:'center'}} dangerouslySetInnerHTML={{__html: item[note]}} />}
            </label>
          </li>
        ))}
      </>
    </ul>
  );
};

export default PurchaseSquareTab;
