import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

import UnitedTopBannerBasic from './UnitedTopBannerBasic';
import UnitedTopBannerLink from './UnitedTopBannerLink';
import UnitedTopBannerPopup from './UnitedTopBannerPopup';

import unitedTopBannerStore, { readUnitedTopBanner } from '../../store/unitedTopBanner/unitedTopBannerStore';
import ignoreResizeObserverErrors from '../../utils/resizeObserverErrors';

export default function UnitedTopBanner() {
  const { pathname: currentPathName, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const currentParamName = searchParams.get('bn') || '';
  const cookieParamName = Cookies.get('topBannerParam') || '';

  if (currentParamName) { Cookies.set('topBannerParam', currentParamName); }

  useEffect(() => {
    readUnitedTopBanner({ currentPathName, currentParamName: currentParamName || cookieParamName });
  }, [currentPathName, currentParamName]);

  useEffect(() => {
    ignoreResizeObserverErrors();
  }, []);

  const unitedTopBanner = unitedTopBannerStore();

  const { actionType = '' } = unitedTopBanner;
  const isOpenTopBannerBasic = actionType === 'none';
  const isOpenTopBannerLink = actionType === 'link';
  const isOpenTopBannerPopup = actionType === 'popup';
  
  // * '/textbook' 위치에서는 탑배너를 노출시키지 않습니다. - 웹수업 영역
  if (currentPathName.toLowerCase().includes('/textbook')) {
    return (<></>);
  }

  return (
    <React.Fragment>
      {isOpenTopBannerBasic && <UnitedTopBannerBasic unitedTopBanner={unitedTopBanner} />}
      {isOpenTopBannerLink && <UnitedTopBannerLink unitedTopBanner={unitedTopBanner} />}
      {isOpenTopBannerPopup && <UnitedTopBannerPopup unitedTopBanner={unitedTopBanner} />}
    </React.Fragment>
  );
}
