import {useQuery, UseQueryOptions} from 'react-query';
import {httpRefreshClient} from 'configs/api';
import { onLogout } from 'common/utilFn';

export interface LoginResult {
  result: boolean;
  data?: LoginData;
  message: string;
  messageCode: string;
}
export interface LoginData {
  accessToken?: string;
  scheduleCode?: string;
  memberSeq?: number;
  memberClass?: string;
  appDownloadLink?: string;
}

const checkLogin = async (): Promise<LoginResult> => {
  const { data } = await httpRefreshClient.post('/sign/checkLogin');
  
  return data as LoginResult;
};

// 사용 예시
// const { data, isLoading, isError, isFetched, isSuccess } = useCheckLogin();

const useCheckLogin = (
  options?: UseQueryOptions<any, Error>
) => {
  const query = useQuery({
    queryKey: ['checkLogin'],
    queryFn: checkLogin,
    staleTime: 50 * 60 * 1000, 
    cacheTime: 50 * 60 * 1000,
    ...options, 
  });

  if (query.isFetched && query.isSuccess && query.data) {
    const data = query.data;
    if (!data.result) {
      if (data.messageCode === 'TOKEN_EXPIRED') {
        onLogout();
      }
    }
  }
  return query;
};

export default useCheckLogin;
