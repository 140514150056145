import { httpDefaultClient } from 'configs/api';
import { isAndroid, isIOS } from 'react-device-detect';
import { PurchaseEmergencyMessage, PurchaseFaqItem, PurchaseReviewItem } from 'types/purchase/purchase.interface';

class PurchaseService {
  async getReviewList() {
    const response = await httpDefaultClient.get('/myInfo/review/list?page=0&categoryCode=REGULAR&orderBy=desc&size=30');
    return response.data.data as PurchaseReviewItem[];
  }
  async getFaqList() {
    const device = isIOS ? 'ios' : isAndroid ? 'aos' : 'all';
    try {
      const response = await httpDefaultClient.get('/myInfo/faq/list', { params: { device } });
      return response.data as PurchaseFaqItem[];
    } catch (error) {
      console.error('Error fetching /myInfo/faq/list:', error);
    }
  }
  async getEmergencyMessage() {
    try {
      const response = await httpDefaultClient.get('/web/support/orderissue');
      return response.data as PurchaseEmergencyMessage;
    } catch (error) {
      return {};
    }
  }
}

export default new PurchaseService();
