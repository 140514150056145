import React, { Component } from 'react';
import { Loading } from 'component/Loading';

export default class OrderResultWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    // purchaseDevice WEB이고 payType 네이버페이아닐때 아이프레임 레이어팝업 내부에 리턴되는 페이지
    // 토스 PC 결제일 때 return url /OrderResultWeb/purchaseSeq로 진입된 후 /OrderResult/purchaseSeq로 보냄
    window.parent.location.href='/OrderResult/'+parseInt(this.props.match.params.seq);
  };

  render() {
    return <Loading />;
  }
}
