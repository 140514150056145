import React from 'react';

import ConsultingTime from '../../component/ConsultingTime';
import Top from '../../component/top';

const ClassEnrollmentClosed = ({ props }) => {
  //state
  const {
    isB2BOrderPeriod, orderFull, totalorderFull, personalOrder
  } = props;

  //props
  const {
    history,
    replaceHistory,
    ww,
  } = props;



  return (
    <div className='step4'>
      <Top title={'수강신청'}
        sub={''}
        history={history}
        back={!personalOrder && !totalorderFull && isB2BOrderPeriod ? () => replaceHistory('/Order') : null}
        useTit={!isB2BOrderPeriod}
        onn={'on'}
        notFixed ={ ww !== 'pc' }
      />
      <div className={'visualWrap b2b'}>
        <div className={'title'}>
          {orderFull ? '선착순 수강신청이 마감되었습니다.' : '수강신청 기간이 만료되었습니다.'}
        </div>
      </div>
      <div className={'grayBox'}>
        <p className={'tit'}>
                    기타 문의사항은 채팅상담으로 남겨주세요.
        </p>
        <ConsultingTime />
      </div>
    </div>
  );
};

export default ClassEnrollmentClosed;
