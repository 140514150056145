import React, {useState} from 'react';
import {ExerciseType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: ExerciseType[];
}

function mark(speaker: string, sentence: string) {
  const underline =
    '<b style=\'border-bottom: #999 1px solid;\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>';
  return `<span>${speaker}</span>${sentence.replace(/_/g, underline)}`;
}


export default function Exercise({componentCode, content}: PropsType) {
  const [showDescription, setShowDescription] = useState(false);
  const [showMemo, setShowMemo] = useState(
    new Array(content?.length || 0).fill(false)
  );
  const toggleShowMemo = (index: number) => {
    const arr = [...showMemo];
    arr[index] = !arr[index];
    setShowMemo(arr);
  };

  return (
    <div className={`page ${componentCode}`}>
      <div className='component-name'>Exercise</div>
      <div className='component-desc'>
        강사님과 A/B를 읽고 빈칸에 알맞은 답을 넣어보세요.
      </div>
      {content &&
        content.length > 0 &&
        content[0].sub &&
        content[0].sub.length > 0 &&
        content[0].sub[0].description && (
        <div className={'switch'} onClick={() => setShowDescription(!showDescription)}>
          {showDescription ? 'ABC' : 'A/가'}
        </div>
      )}
      {content &&
        content.map((item, index) => (
          <div key={`s${index}`} className='content-box'>
            <div className='label'>0{item.sort}</div>
            {item.sub &&
              item.sub.length > 0 &&
              item.sub.map((row, i) => (
                <div className='exercise-sentence-wrapper' key={`e${index}${i}`}>
                  <div
                    className={`sentence-${row.speaker}`}
                    dangerouslySetInnerHTML={{
                      __html: mark(row.speaker||'', row.sentence||''),
                    }}
                  />
                  {showDescription && row.description && (
                    <div className='description'>{row.description}</div>
                  )}
                </div>
              ))}
            {item?.memo && (
              <div className={'memo'}>
                <div
                  className={showMemo[index] ? 'memo-button open' : 'memo-button close'}
                  onClick={() => toggleShowMemo(index)}
                >
                  메모
                </div>
                {showMemo[index] && <div className={'memo-content'}>{item.memo}</div>}
              </div>
            )}
          </div>
        ))}
    </div>
  );
}
