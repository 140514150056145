import React, { useEffect, useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import unitedTopBannerStore from '../../store/unitedTopBanner/unitedTopBannerStore';
import { useOptimizedResize } from '../../hooks/useOptimizedResize';
import * as utilFn from 'common/utilFn';

type IProps = {
  unitedTopBanner: {
    type: string,
    bannerColor: string,
    mobileImageHeight: number,
    mobileImageFile: string,
    pcImageHeight: number,
    pcImageFile: string,
    actionType: string,
    popupcloseColor: string,
    popupImageFile: string,
  }
}

export default function UnitedTopBannerPopup({ unitedTopBanner }: IProps) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
  
  const [isShowTopBannerPopup, onChangeShowTopBannerPopup] = useState(false);
  const onOpenTopBannerPopup = () => onChangeShowTopBannerPopup(true);
  const onCloseTopBannerPopup = () => onChangeShowTopBannerPopup(false);
  
  const setMobileBannerHeight = unitedTopBannerStore((state) => state.setMobileBannerHeight);
  const setPCBannerHeight = unitedTopBannerStore((state) => state.setPCBannerHeight);

  const handleMobileResize = useCallback((height: number) => {
    setMobileBannerHeight(height);
  }, [setMobileBannerHeight]);

  const handlePCResize = useCallback((height: number) => {
    setPCBannerHeight(height);
  }, [setPCBannerHeight]);

  const { ref: mobileRef } = useOptimizedResize(handleMobileResize);
  const { ref: pcRef } = useOptimizedResize(handlePCResize);

  const { 
    bannerColor,
    pcImageHeight,
    pcImageFile,
    mobileImageHeight,
    mobileImageFile,
    popupcloseColor,
    popupImageFile,
  } = unitedTopBanner;

  const openTopBannerDepthPopup = () => {
    onOpenTopBannerPopup();
    utilFn.ScrollNone(true);
  };

  const closeTopBannerDepthPopup = () => {
    onCloseTopBannerPopup();
    utilFn.ScrollNone(false);
  };


  if (isDesktop) {
    return (
      <>
        <div 
          ref={pcRef}
          id='TopBannerLMS'
          className={`top-banner-lms top-banner-lms--pc ${(window.location.pathname).toLowerCase().indexOf('/course/p3') >= 0 ? 'is-magazine' : ''} ${(window.location.pathname).toLowerCase().indexOf('/course') >= 0 ? 'course' : (window.location.pathname).toLowerCase().indexOf('/freereport/r') >= 0 ? 'freereport' :  (window.location.pathname).toLowerCase().indexOf('/guide') >= 0 ? 'guide': (window.location.pathname).toLowerCase().indexOf('/order') >= 0 ? 'order':''}`}
          onClick={() => openTopBannerDepthPopup()}
        >
          <div style={{ width: '100%', background: `#${bannerColor}`, height: `${pcImageHeight}px` }} className='pc'>
            <img src={pcImageFile} id={'TopBannerImg'} alt='' />
          </div>
        </div>

        {/* 팝업 영역 */}
        {isShowTopBannerPopup &&
          <div className='pc-banner-popup'>
            <div id={'TopBannerPopup'}>
              <div className={'popupimg'}>
                <div className={`btnClose ${popupcloseColor}`} onClick={() => closeTopBannerDepthPopup()}></div>
                <div className={'contentArea'}>
                  <img src={popupImageFile} alt=''/>
                </div>
              </div>
            </div>
            <div id={'msk'}></div>
          </div>
        }
      </>
    );
  }

  if (!isDesktop) {
    return (
      <>
        <div 
          ref={mobileRef}
          id={'TopBannerLMS'}
          className={`top-banner-lms top-banner-lms--mo ${(window.location.pathname).toLowerCase().indexOf('/course') >= 0 ? 'course' : (window.location.pathname).toLowerCase().indexOf('/freereport/r') >= 0 ? 'freereport' :  (window.location.pathname).toLowerCase().indexOf('/guide') >= 0 ? 'guide': (window.location.pathname).toLowerCase().indexOf('/order') >= 0 ? 'order':''}`}
          onClick={() => openTopBannerDepthPopup()}
        >
          <div style={{ width: '100%', background: `#${bannerColor}`, height: `${mobileImageHeight}px` }} className='mo'>
            <img src={mobileImageFile} id={'TopBannerImg'} alt='' />
          </div>
        </div>

        {/* 팝업 영역 */}
        {isShowTopBannerPopup &&
          <>
            <div id={'TopBannerPopup'}>
              <div className={'popupimg'}>
                <div className={`btnClose ${popupcloseColor}`} onClick={() => closeTopBannerDepthPopup()}></div>
                <div className={'contentArea'}>
                  <img src={popupImageFile} alt=''/>
                </div>
              </div>
            </div>
            <div id={'msk'}></div>
          </>
        }
      </>
    );
  }

  return (<></>);
}
