import React from 'react';
import '../css/PersonalPopup.scss';
import DOMPurify from 'dompurify';
import { numberFormat } from 'common/utilFn';
const PersonalPopup = ({ props }) => {
  const {
    memberName,
    productDesc,
    personalOrderInfo,
    closePopup,
    goPersonalOrder,
  } = props;

  return (
    <>
      <div className={'personal-popup'}>
        <div className={'personal-popup__close'}>
          <img src={process.env.REACT_APP_IMG_URL + '/common/btn_close.svg'} alt={'close'}  onClick={() => closePopup('personalPopup')} />
        </div>
        <div className={'personal-popup__title'}>
          {memberName}님을 위한<br/>맞춤 수강권이 도착했어요.
        </div>
        <div className={'personal-popup__card'}>
          <div className={'personal-popup__card--top'}>
            <div className={'personal-popup__card--top--week'}>{productDesc.productWeek}<span>주</span></div>
            <ul>
              <li>{productDesc.lessonModeName}<br/>{productDesc.productMinute}분</li>
              <li>{productDesc.templateName}</li>
              <li dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(productDesc.timezoneName) }}></li>
            </ul>
          </div>
          <div className={'personal-popup__card--desc'}>
            유폰 수강권 (<b>{numberFormat(personalOrderInfo.paymentAmount)}</b>원)으로<br/>지금 바로 시작해요.
          </div>
        </div>
        <div className={'personal-popup__button'} onClick={goPersonalOrder}>
          수강신청 하기
        </div>
      </div>
      <div className='personal-popup-dimmed'></div>
    </>
  );
};

export default PersonalPopup;
