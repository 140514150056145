import React, { useEffect } from 'react';
import classNames from 'classnames';
import Lottie from 'react-lottie';

import reserveTrialStore, { compareTimestamp, getTrialSlots, isChangeMode, parserShowRecommendTime, validateInput } from 'store/trial/reserve/reserveTrialStore';
import { ScrollNone } from 'common/utilFn';

import loading from 'json/home_loading_gray.json';

export default function TrialRecommendTimeSlot() {
  const isSlotLoading = reserveTrialStore((state) => state.isSlotLoading);
  const recommendTimes = reserveTrialStore((state) => state.recommendTimes);
  const displayRequestDate = reserveTrialStore((state) => state.displayRequestDate);
  const lessonMode = reserveTrialStore((state) => state.lessonMode);
  const levelCode = reserveTrialStore((state) => state.levelCode);
  const lessonMinute = reserveTrialStore((state) => state.lessonMinute);
  const setShowSlotPopup = reserveTrialStore((state) => state.setShowSlotPopup);
  const selectDate = reserveTrialStore((state) => state.selectDate);
  const selectTime = reserveTrialStore((state) => state.selectTime);
  const selectedDate = reserveTrialStore((state) => state.selectedDate);
  const selectedTime = reserveTrialStore((state) => state.selectedTime);
  
  const handleClickFreeLessonTime = async(item: any) => {
    const { scheduleDate, scheduleTime } = item;
    if (isChangeMode()) {
      const isClassStartingInTenMinutes = await compareTimestamp();
      if (isClassStartingInTenMinutes) {
        return;
      }
    }
    
    selectDate(scheduleDate);
    selectTime(scheduleTime);
    validateInput();
  };

  const openSlotPopup = () => {
    ScrollNone(true);
    setShowSlotPopup(true);
    getTrialSlots();
  };

  useEffect(() => {
    getTrialSlots();
  }, [lessonMode, levelCode, lessonMinute]);


  // 팝업에서 선택했을 때
  if (displayRequestDate) {
    return (
      <>
        <div className='titleBox' >
          <p className='titleBox__title'>추천 시간</p>
          <div className='titleBox__link'>
            <span onClick={openSlotPopup}>시간 변경 <img src={process.env.REACT_APP_IMG_URL + '/icon/arrow_right_5s.svg'} /></span>
          </div>
        </div>
        <div
          onClick={openSlotPopup}
          className='recommend-time-card popup-selected'
          dangerouslySetInnerHTML={{ __html: displayRequestDate }}
        ></div>
      </>
    );
  }

  // 슬롯이 없을 때
  if (!isSlotLoading && recommendTimes.length < 1) {
    return (
      <>
        <div className='titleBox'>
          <p className='titleBox__title'>수업 일시</p>
        </div>
        <div className='recommend-time-card no-slot'>
          <div className='recommend-time-card__txt'> <img src={process.env.REACT_APP_IMG_URL + '/freelesson/freelesson_time_exclamation.svg'} alt='' />
            현재 모든 수업이 마감되었습니다.<br />채팅상담으로 문의주시면 도와드리겠습니다.
          </div>
        </div>
      </>
    );
  }

  // 로딩중
  if (isSlotLoading) {
    return (
      <>
        <div className='titleBox' >
          <p className='titleBox__title'>수업 일시</p>
        </div>
        <div className='recommend-time-card loading'>
          <div className='recommend-time-card__lottie'>
            <Lottie options={{ loop: true, autoplay: true, animationData: loading }} />
          </div>
          추천 수업 시간을 선정하고 있어요
        </div>
      </>
    );
  }

  return (
    <>
      <div className='titleBox'>
        <p className='titleBox__title'>수업 일시</p>
      </div>
      <ul className='recommend-time-list'>
        {recommendTimes.map((item: any, idx: number) => {
          const isSelected = item.scheduleDate === selectedDate && item.scheduleTime === selectedTime;
          const timeClasses = classNames('recommend-time-card', {
            'one-times': recommendTimes.length === 1,
            'three-times': recommendTimes.length === 3,
            'selected': isSelected,
          });

          return (
            <li key={idx} onClick={() => handleClickFreeLessonTime(item)} className={timeClasses}>
              <div dangerouslySetInnerHTML={{ __html: parserShowRecommendTime(item) }} />
            </li>
          );
        })}
      </ul>
      {recommendTimes.length >= 4 &&
        <>
          <div className='recommend-time-more' onClick={openSlotPopup}>
          다른 시간 보기 <img src={process.env.REACT_APP_IMG_URL + '/icon/arrow_right_5s.svg'} />
          </div>
          <div className='recommend-time-description'>· 가장 빠른 인기 시간. 결심했을 때 바로 시작하세요! </div>
        </>
      }
    </>
  );
}
