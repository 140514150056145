import React, { Component } from 'react';
import axios from 'axios';

import '../css/notice.scss';
import '../css/common.scss';

import Top from '../component/top';
import Footer from '../component/Footer';
import {httpDefaultClient, httpRefreshClient} from 'configs/api';

export default class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'p1',
      pageT : '',
      upRcmd : [],
      category :[],
      categoryList : [],  // 카테고리 클릭 시 해당 카테고리 질문들
      categoryAnotherList : [],
      categoryCode : '', // 문의하기로 넘길 값

      TKTCLF : [],
      viewT: '',
      viewCnt : '',
      viewSeq : '',

      asFlag : false,
      loading : true,

      ctgrFtlength : '',
      backSeq : '',

      historyFaqSeq : [],
      //prevFaqSeq : '',
      windowHistoryLength : 0,
      selectCategory : '', // pc 선택된 카테고리
      ww :'', //widow resize될때 마다 변하는 width
      pcContent : [],// pc 클릭된 질문에 대한 답변
    };
  }

  reRender = (seq, ccode) => {
    // 공지사항 상세보기
    httpDefaultClient.get('/boards/'+seq)
      .then(res => {
        let jd = res.data.data;
        let titCtgr;

        // 상세보기 시 타이틀 변경
        this.state.category.map((v) => {
          if (seq === v.boardSeq) {
            this.state.TKTCLF.filter(c => {
              if ( v.categoryCode === c.detailCode){
                titCtgr = c.detailName;
                return titCtgr;
              }
            });
            this.setState({pageT : titCtgr,});
          }
        });
        if (ccode === undefined){
          // 카테고리 구별
          let ff= this.state.category.filter((v) => {
            return jd.categoryCode  === v.categoryCode;
          });
          // 현재 질문 제외한 가튼 카테고리
          let ctgrAnotherFt = ff.filter((v) => {
            return jd.boardSeq  !== v.boardSeq;
          });
          this.setState({
            categoryList : ff,
            categoryAnotherList: ctgrAnotherFt,
          });
        } else {
          let ctgrAnotherFt = this.state.category.filter((v) => {
            if (ccode  === v.categoryCode){
              return seq  !== v.boardSeq;
            }
          });
          this.setState({categoryAnotherList: ctgrAnotherFt});
        }

        this.state.TKTCLF.map((v) => {
          if (jd.categoryCode === v.detailCode){
            this.setState({pageT :v.detailName});
          }
        });
        this.setState({
          viewT : jd.title,
          viewCnt : jd.content,
          viewSeq : jd.boardSeq,
          loading : false,
          asFlag : false,

          categoryCode : jd.categoryCode,
        });

      })
      .catch((error) => console.error('error: ', error));
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      history: { action },
      location : { pathname }
    } = this.props;

    if (action === 'POP' && (prevProps.location.pathname !== pathname) && (prevState.viewT === this.state.viewT) ) {
      if (window.location.pathname.indexOf('p2') !== -1){
        let codeurl = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1, window.location.pathname.length);
      }

      if (prevProps.match.params.page === this.props.match.params.page){
        this.reRender(this.props.match.params.seq);
      }
    }
    window.addEventListener('resize', this.onResize);
  }

  componentWillMount() {
    window.categoryTotalCount = window.categoryTotalCount ? window.categoryTotalCount : [];

    this.ww = window.innerWidth >= 1025?'pc':'mo';
    window.addEventListener('resize', () => {
      this.ww = window.innerWidth>=1025?'pc':'mo';
    });
  }

  componentDidMount() {
    // 공통 코드
    this.commonCode('TKTCLF');
    this.commonCode('COMNOTI');

    this.getFaqList();
    httpRefreshClient.post('/sign/checkLogin').then(res => {
      if (res.data.result) {
        this.tokenOK();
      }
    });

    if (this.ww==='pc' && this.props.match.params.seq===undefined) this.getctry('UPHONE','유폰추천'); //pc일 경우 제일 처음 유폰추천 리스트
  }

  onResize = () => {
    this.setState({ ww: window.innerWidth>=1025?'pc':'mo' });
    if (this.ww==='mo'){
      (window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1)==='UPHONE')&& this.props.history.push('/guide');
    }
    if (this.ww==='pc'){
      (window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1)==='guide')&& this.props.history.push('/guide/p2/UPHONE');
    }
  };

  // 뒤로 가기 시 타이틀 유지
  chgTitle = () => {
    this.setState({
      pageT: this.state.pageT,
      // ctgrFtlength : this.state.ctgrFtlength
    });
  };

  getFaqList = () => {
    this.setState({
      loading : true
    });
    // 자주묻는 질문
    httpDefaultClient.get('/myInfo/faq/list').then(response => {
      const json = response.data;
      // noticeFlag true => 유폰추천으로 빠지는 애들
      let upRcmd = json.filter((v) => {
        return v.noticeFlag === true;
      });

      this.setState({
        upRcmd : upRcmd,
        category : json,
        loading : false,
        asFlag : false,
      });
      // 새로고침

      if (window.location.pathname.indexOf('p1') !== -1){
        // 주소 체계 p1 케이스
      }
      if (window.location.pathname.indexOf('p2') !== -1){
        // 주소 체계 p2 케이스
        let codeurl = window.location.pathname.substring(window.location.pathname.lastIndexOf('/')+1, window.location.pathname.length);

        let name;
        this.state.TKTCLF.map((v) => {
          if ( v.detailCode === codeurl){
            name = v.detailName;
          }
        });
        this.getctry(codeurl,name);
      }

      if (window.location.pathname.indexOf('view') !== -1) {
        let bseq = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1, window.location.pathname.length);
        this.reRender(bseq);
      }
    })
      .catch((error) => {
        console.error('myInfo/faq/list: ', error);
      });
  };

  tokenOK = () => {


    httpDefaultClient.get('/lectureRoom/lessonStatus')
      .then(response => {
        const json = response.data;
        this.setState({
          memStatus: json.data.schedulestatusCode,
          languageType: json.data.languageType
        });
      })
      .catch((error) => {
        console.error('lessonStatus: ', error);
      });
  };

  //공통코드 조회
  commonCode = (c) => {
    axios({
      method:'GET',
      url:process.env.REACT_APP_API_URL + '/commonCode/getList/'+c,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then( (response) => {
        this.setState({[c]:response.data});
      })
      .catch( (error) => {
        console.error('error: ', error);
      });
  };

  go = (t) => {
    this.props.history.push('/'+t);
  };

  // 카테고리 클릭 시
  getctry = (code,name) => {
    this.setState({selectCategory : code});

    // 카테고리애들에서 클릭한 애 목록
    let ctgrFt = (code==='UPHONE')?this.state.upRcmd: this.state.category.filter((v) => { return code  === v.categoryCode;});

    this.setState({
      categoryList : ctgrFt,
      pageT: name || '유폰추천',
      ctgrFtlength : ctgrFt.length
    });
    window.categoryTotalCount[code] = ctgrFt.length;
    if (this.props.match.params.seq===undefined) this.props.history.push('/guide/p2/'+ code);
  };

  // 상세
  getFaqView =(seq, ccode ,back) => {
    if (this.ww ==='mo'){
      this.props.history.push('/guide/p3view/'+seq);
      this.setState({
        loading : true,
      });
    } else {
      if (this.state.viewSeq === seq) {
        this.setState({
          viewSeq: false
        });
        return false;
      }
    }

    let a = [];
    let b;

    a = [...this.state.historyFaqSeq];
    if (back===true) a.pop();

    b = a[a.length-1];

    let go;
    back === true ? go = b  : go= seq;

    // 공지사항 상세보기
    httpDefaultClient.get('/boards/'+go)
      .then(res => {
        const json = res.data;
        this.ww==='mo' && window.scrollTo(0, 0, 'smooth');

        let jd = res.data.data;
        let titCtgr;

        // 상세보기 시 타이틀 변경
        this.state.category.map((v) => {
          if (seq === v.boardSeq) {
            this.state.TKTCLF.filter(c => {
              if ( v.categoryCode === c.detailCode){
                titCtgr = c.detailName;
                return titCtgr;
              }
            });
            this.setState({pageT : titCtgr,});
          }
        });
        if (ccode === undefined){
          // 카테고리 구별
          if (this.state.selectCategory!=='UPHONE'){ //pc의 유폰추천일때는 구별하지 않음
            let ff=	this.state.category.filter((v) => {
              return jd.categoryCode  === v.categoryCode;
            });
            // 현재 질문 제외한 가튼 카테고리
            let ctgrAnotherFt = ff.filter((v) => {
              return jd.boardSeq  !== v.boardSeq;
            });
            this.setState({
              categoryList : ff,
              categoryAnotherList: ctgrAnotherFt,
            });
          }
        } else {
          let ctgrAnotherFt = this.state.category.filter((v) => {
            if (ccode  === v.categoryCode){
              return seq  !== v.boardSeq;
            }
          });
          this.setState({categoryAnotherList: ctgrAnotherFt});
        }

        this.state.TKTCLF.map((v) => {
          if (jd.categoryCode === v.detailCode){
            this.setState({pageT :v.detailName});
          }
        });
        this.setState({
          viewT : jd.title,
          viewCnt : jd.content,
          viewSeq : jd.boardSeq,
          loading : false,
          asFlag : false,

          categoryCode : jd.categoryCode,
          historyFaqSeq: back ? a : [...this.state.historyFaqSeq, jd.boardSeq],
          prevFaqSeq :  b,
        });

        if (this.ww==='pc'){
          let obj={seq : seq, content :json.data.content};
          if (!this.state.pcContent.some(content => content.seq === seq)){
            this.setState({
              pcContent:this.state.pcContent.concat(obj)
            });
          }
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  render() {
    return (
      <div  className={'faqPage '+(this.props.match.params.page ? this.props.match.params.page : 'p1')}>
        <Top
          title={this.props.match.params.page === 'p1' ?'자주 묻는 질문'
            : this.props.match.params.page === 'p2' ? this.state.pageT+ (this.state.ctgrFtlength ? ' ('+this.state.ctgrFtlength+')' : (this.props.match.params.seq && window.categoryTotalCount[this.props.match.params.seq]) ? ' (' + window.categoryTotalCount[this.props.match.params.seq] +')' : '')
              : this.props.match.params.page === 'p3view' ? this.state.pageT
                :'자주 묻는 질문'
          }
          onn={'on'}
          notFixed ={true}
          setTopBannerMargin = {this.ww === 'pc' && this.props.setTopBannerMargin}
				    sub={''} history={this.props.history}  chgTitle={() => this.chgTitle()} />
        <div className={'stepWrap'}>
          {this.ww==='pc'&& <h1 className={'pcTitle'}> 자주 묻는 질문</h1>}
          <div className={'step1'}>
            {this.ww==='mo'&&
						<dl>
						  <dt className={'tit'}>유폰 추천</dt>
						  {this.state.upRcmd.length !==0 &&
							this.state.upRcmd.map((v,i) => {
							  return (
							    <dd key={v + i} className={'list'}>
							      <p className={'question'} onClick={() => this.getFaqView(v.boardSeq,v.categoryCode,)}><span className={'bulQ'}>Q.</span>{v.title}</p>
							    </dd>
							  );
							})}
						</dl>}
            <dl className={this.ww==='pc' ? 'pcCategories' : ''}>
              {this.ww==='mo'&&<dt className={'tit'}>모든 카테고리</dt>}
              {this.ww==='pc'&& <dd className={'list' + (this.state.selectCategory==='UPHONE'?' active':'')} onClick={() => this.getctry('UPHONE','유폰추천')}><p className={'question'}>유폰추천</p></dd>}
              {this.state.TKTCLF.map((v,i) => {
                if (v.note1 === '1') return (
                  <dd key={v + i} className={'list' + (this.state.selectCategory===v.detailCode?' active':'')} onClick={() => this.getctry(v.detailCode,v.detailName)}>
                    <p className={'question'}>{v.detailName}</p>
                  </dd>
                );
              })}
            </dl>
          </div>
          <div className={'step2'}>
            {this.state.categoryList.map((v,i) => {
              return (
                <div key={v + i} className={`list ${(this.state.viewSeq=== v.boardSeq)?'on':''}`} >
                  <p className={'question'} onClick={() => this.getFaqView(v.boardSeq)}><span className={'bulQ'}>Q.</span>{v.title}</p>
                  {(this.ww==='pc')&&
									this.state.pcContent.map((content) => (content.seq === v.boardSeq)&& <div className={'answer'} key={v.boardSeq} dangerouslySetInnerHTML={{ __html: content.content }} />)}
                </div>
              );
            })}
          </div>
          <div className={'step3'}>
            <div className={'viewW'}>
              <p className={'tit'}>{this.state.viewT}</p>
              <div className={'cnt'}>
                <div dangerouslySetInnerHTML={{__html: this.state.viewCnt && this.state.viewCnt.includes('*cta*') ? this.state.viewCnt.split('*cta*')[0] : this.state.viewCnt}} />
              </div>
            </div>

            <dl className={'otherWrap'}>
              <dt>위와 관련해서 다른 부분이 궁금하신가요?</dt>
              <dd>
                {this.state.categoryAnotherList.map((v,i) => {
                  return (
                    <div  key={v + i}className='list'>
                      <p className={'question'} onClick={() => this.getFaqView(v.boardSeq,v.categoryCode)}><span className={'bulQ'}>Q.</span> {v.title} </p>
                    </div>
                  );
                })}
              </dd>
              <dt className={'addHelp'}>추가적인 도움이 필요하신가요?</dt>
              <dd className={'box linkTab'}>
                <div className={'lsCenter'}>
                  <p className={'tit'}>오류신고</p>
                  <p>오류로 인해 어려움을 겪고 있으면 알려주세요.</p>
                  <a href={process.env.REACT_APP_MAIN_URL + '/bugreport'} title='오류신고' className={'lnkTel'}></a>
                </div>
              </dd>
              <dd className={'box'}>
                <div className={'lsCenter'}>
                  <p className={'tit'}>학습컨설팅센터 1599-1791</p>
                  <p>
										상담시간 : 9시~6시 (점심시간 12시~1시)<br/>
										주말, 공휴일 휴무
                  </p>
                </div>
              </dd>
            </dl>
          </div>
          {this.ww === 'pc' && this.state.memStatus === 'INCLASS' && (this.state.languageType === 'en' || this.state.languageType === 'jp' || this.state.languageType === 'cn') &&
					<div className={'noticeWrap'}>
					  <img className={'info'} src={process.env.REACT_APP_IMG_URL + '/common/ico_excm.svg'} alt='info'/>
						곧 수업인데 앱 사용이 어려운가요?
					  <a href='/ClassRoom' title='웹 강의실 이용하기' className={'move'}>웹 강의실 이용하기</a>
					</div>
          }
        </div>
        {this.state.loading &&
				<div className={'loading'}>
				  <img src={process.env.REACT_APP_IMG_URL + '/schedule/loader.gif'} alt='loader'/>
				</div>
        }
        <Footer ww={this.ww}/>
      </div>
    );
  }
}
