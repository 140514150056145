import {useQuery, UseQueryOptions} from 'react-query';
import { httpDefaultClient } from 'configs/api';

/* 전체 호출 */
const getAllCommonMessage = async (): Promise<Record<string, string>> => {
  const { data } = await httpDefaultClient.get('/commonMessage/getList');
  return data as Record<string, string>;
};

export const useAllCommonMessage = (
  options?: UseQueryOptions<any, Error>
) => {
  return useQuery({
    queryKey: ['commonMessage'],
    queryFn: getAllCommonMessage,
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    ...options,
  });
};

/* 1건 호출 */
const getCommonMessage = async (messageCode: string): Promise<string> => {
  const { data } = await httpDefaultClient.get('/commonMessage/getCommonMessage',
    {params: {
      messageCode: messageCode
    }});
  return data.message||'' as string;
};

export const useCommonMessage = (
  messageCode: string,
  options?: UseQueryOptions<any, Error>
) => {
  const queryKey = ['commonMessage', messageCode];
  return useQuery({
    queryKey: queryKey,
    queryFn: () => getCommonMessage(messageCode),
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    ...options,
  });
};
