import { useQuery } from 'react-query';
import { PurchaseFaqItem } from 'types/purchase/purchase.interface';
import queryOptions from './purchaseQueries';

export function useReviewList() {
  return useQuery(queryOptions.reviewList());
}

export function useFaqList() {
  return useQuery({
    ...queryOptions.faqList(),
    select: (list) => {
      if (!list) return [];
      return list
        .filter(item => item.categoryCode === 'PURCHASE')
        .sort((a, b) => a.orderNumber - b.orderNumber) as PurchaseFaqItem[];
    },
  });
}

export function useEmergencyMessage() {
  return useQuery(queryOptions.emergencyMessage());
}
