import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { httpDefaultClient } from 'configs/api';
import { purchaseShowcaseB2CSlice, PurchaseShowcaseB2CT } from './purchaseShowcaseB2CSlice';

export const purchaseShowcaseB2CStore = create<
  PurchaseShowcaseB2CT
>()(devtools((...all) => ({
  ...purchaseShowcaseB2CSlice(...all),
}), { store: 'purchaseShowcaseB2C' }));

export default purchaseShowcaseB2CStore;

export const getProductList = async () => {
  const setProductList = purchaseShowcaseB2CStore.getState().setProductList;

  try {
    const { data } = await httpDefaultClient.get('/product/renewalPriceList');
    setProductList(data);
    filteringProductList();
  } catch (error) {
    console.error('/product/renewalProductList' + error);
  }
};

export const filteringProductList = () => {
  const productList = purchaseShowcaseB2CStore.getState().productList;
  const selectedProductWeek = purchaseShowcaseB2CStore.getState().selectedProductWeek;
  const selectedLessonMode = purchaseShowcaseB2CStore.getState().selectedLessonMode;
  const setFilteredProductList = purchaseShowcaseB2CStore.getState().setFilteredProductList;
  const filteredProductList = productList.filter((product) => product.productWeek === selectedProductWeek && product.lessonMode === selectedLessonMode);
  setFilteredProductList(filteredProductList);
};

export const toggleAnotherProductPrice = (priceSeq: number) => {
  const setFilteredProductList = purchaseShowcaseB2CStore.getState().setFilteredProductList;
  const filteredProductList = purchaseShowcaseB2CStore.getState().filteredProductList;
  const updatedProductList = filteredProductList.map((product) => product.priceSeq === priceSeq ? { ...product, showAnotherProductPrice: !product.showAnotherProductPrice } : product);
  setFilteredProductList(updatedProductList);
};
