import React, { useRef } from 'react';
import Swiper from 'react-id-swiper';
import reserveTrialStore, { isChangeMode } from 'store/trial/reserve/reserveTrialStore';
import { formatMemberId } from 'common/utilFn';

export default function TrailHeader() {
  const fixedMode = reserveTrialStore((state) => state.fixedMode);
  const juniorFlag = reserveTrialStore((state) => state.juniorFlag);
  const lessonReviews = reserveTrialStore((state) => state.lessonReviews)?? [];
  const swiperRef = useRef<any>(null);
  
  const goHome = () => {
    if (juniorFlag) {
      window.location.href = process.env.REACT_APP_MAIN_URL + '/junior';
    } else {
      window.location.href = process.env.REACT_APP_MAIN_URL + '/';
    }
  };
  
  const titleName = isChangeMode() ? '무료 수업 예약 변경' :
    (fixedMode === 'AUDIO') ? '전화 무료수업 예약' :
      (fixedMode === 'JUNIOR') ? '주니어 무료수업 예약' :
        (fixedMode === 'VIDEO') ? '화상 무료수업 예약' :
          (juniorFlag) ? '주니어 무료수업 예약' :
            '무료수업 예약';
  
  const swiperParam = {
    slidesPerView: 1,
    spaceBetween: 0,
    centeredSlides: true,
    loop: true,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    getSwiper: (swiper: any) => {
      swiperRef.current = swiper;
    }
  };
  
  return (
    <header className={`apply-form-head ${juniorFlag || fixedMode ==='JUNIOR' ? 'junior' : ''}`}>
      <div className={'apply-form-head__inner'}>
        <img className='lnk-home' src={process.env.REACT_APP_IMG_URL + (juniorFlag || fixedMode ==='JUNIOR' ? '/freelesson/bi_uphonejunior70.svg' : '/web/logo_default.svg')} alt={'민병철유폰'} onClick={goHome} />
        <div className={'apply-form-head__title'}><span id='applyFormTitleText'>{titleName}</span></div>
        <div className={`apply-form-head__desc ${juniorFlag || fixedMode ==='JUNIOR' ? 'junior' : ''}`}>
          <Swiper {...swiperParam}>
            {lessonReviews?.map((item:any, index:number) => {
              const memberId = formatMemberId(item?.memberId);
              return (
                <div className='apply-form-head__review' key={index}>
                  <p className='review-content'>“{item.content}”</p>
                  <div className='review-info'>
                    <div className={`member-icon ${juniorFlag || fixedMode ==='JUNIOR' ? 'junior' : ''}`} />
                    {memberId}
                    <span className={(juniorFlag || fixedMode === 'JUNIOR') ? 'review-tab hidden' : 'review-tab'}/>
                    <span className={(juniorFlag || fixedMode === 'JUNIOR') ? 'hidden' : ''}>{item.inputDatetime.replace(/-/g, '.')}</span>
                  </div>
                </div>);
            })}
          </Swiper>
        </div>
      </div>
    </header>
  );
}
