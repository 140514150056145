import { StateCreator } from 'zustand';

export interface TrialInfoType {
  trialSeq: number;
  memberSeq: number;
  memberName: string;
  memberenglishName: string;
  memberClass: string;
  juniorFlag: boolean;
  lessonSeq: number;
  lessonMode: string;
  lessonDate: string;
  lessonTime: string;
  lessonMinute: number;
  courseSeq: number;
  courseName: string;
  memberunitSeq: number;
  sort: number;
  unitSeq: number;
  subject: string;
  appDownloadLink: string;
  originalLessonDate?: string;
  originalLessonTime?: string;
}

interface ServerDayType {
  today: string,
  tomorrow:string,
}

export interface CompleteTrialT {
  trialInfo: TrialInfoType
  setTrialInfo: (info: TrialInfoType) => void;
  serverDay: ServerDayType;
  setServerDay: (day: ServerDayType) => void;
  appDownloadPopupFlag: boolean;
  setAppDownloadPopupFlag: (bool: boolean) => void;
  isLoading: boolean;
  setIsLoading: (bool: boolean) => void;
  isShowTrialBook: boolean;
  setIsShowTrialBook:(bool: boolean) => void;
  isShowChangeAlert: boolean;
  setIsShowChangeAlert:(bool: boolean) => void;
  ABType: string;
  setABType: (type: string) => void;
}

const initialState = {
  trialInfo: {
    trialSeq: 0,
    memberSeq: 0,
    memberName: '',
    memberenglishName: '',
    memberClass: '',
    juniorFlag: false,
    lessonSeq: 0,
    lessonMode: '',
    lessonDate: '',
    lessonTime: '',
    lessonMinute: 0,
    courseSeq: 0,
    courseName: '',
    memberunitSeq: 0,
    sort: 0,
    unitSeq: 0,
    subject: '',
    appDownloadLink: ''
  },
  serverDay: {
    today: '',
    tomorrow:''
  },
  appDownloadPopupFlag: false,
  isLoading: false,
  isShowTrialBook:false,
  isShowChangeAlert:false,
  ABType: 'A',
};

export const completeTrialSlice: StateCreator<
CompleteTrialT, 
  any[],  
  any[], 
  CompleteTrialT
> = (set) => ({
  ...initialState,
  setTrialInfo: (trialInfo) => {
    set((state) => ({ ...state, trialInfo }));
  },
  setServerDay: (serverDay) => {
    set((state) => ({ ...state, serverDay }));
  },
  setAppDownloadPopupFlag: (bool) => {
    set((state) => ({ ...state, appDownloadPopupFlag:bool }));
  },
  setIsLoading:(bool) => {
    set((state) => ({ ...state, isLoading: bool }));
  },
  setIsShowTrialBook:(bool) => {
    set((state) => ({ ...state, isShowTrialBook: bool }));
  },
  setIsShowChangeAlert:(bool) => {
    set((state) => ({ ...state, isShowChangeAlert: bool }));
  },
  setABType:(value) => {
    set((state) => ({ ...state, ABType: value }));
  }
});
export default completeTrialSlice;
