import React, {Component} from 'react';
import * as utilFn from '../common/utilFn';

export default class ClearParam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  };

  componentDidMount() { // 처음 랜더링 될때
    setTimeout(this.reload, 500);
  }

  reload = () => {
    this.setState({
      ref:utilFn.getMarketingCodeValue('ref'),
      fmc:utilFn.getMarketingCodeValue('fmc'),
      lmc:utilFn.getMarketingCodeValue('lmc'),
      loading: true,
    });
  };

  clear = () => {
    utilFn.removeMarketingCodeValue('ref');
    utilFn.removeMarketingCodeValue('fmc');
    utilFn.removeMarketingCodeValue('lmc');
    this.reload();
  };

  render() {
    return (
      <>{ this.state.loading &&
        <div style={{fontSize: '3rem'}}>
          <p>ref: {this.state.ref}</p>
          <p>fmc: {this.state.fmc}</p>
          <p>lmc: {this.state.lmc}</p>
          <br/>
          <button onClick={this.reload} style={{marginRight: '1rem'}}>reload</button>
          <button onClick={this.clear}>clear</button>
        </div>
      }
      </>
    );
  }
}
