import React, {useState} from 'react';
import {DialogType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: DialogType;
}

const componentName = {
  MD: 'Main Dialog',
  MD1: 'Main Dialog1',
  MD2: 'Main Dialog2',
};
const componentDesc = {
  dialog: '강사님과 역할을 나누어 대화문을 읽어보세요.',
  summary: '요약본을 참고하여 학습한 내용에 대해 얘기해보세요.',
};


export default function Dialog({componentCode, content}: PropsType) {
  const [showMemo, setShowMemo] = useState(false);
  const [firstSpeaker, setFirstSpeaker] = useState('A');
  const [showSummary, setShowSummary] = useState(content.summaryFlag);

  return (
    <div className={`page ${componentCode}`}>
      <div id={componentCode}>
        <div className='component-name'>{componentName[componentCode]}</div>
        <div className='component-desc'>
          {content.summaryFlag ? componentDesc.summary : componentDesc.dialog}
        </div>
        {
          content.summaryFlag &&
          <button className={'switch'}
            onClick={() => setShowSummary((v) => !v)}
          >
            <span>{showSummary?'본문 보기':'요약본 보기'}</span>
          </button>
        }
        {/* 요약본 */}
        {showSummary && <div className='content'>{content.summary}</div>}
        {/* 본문 */}
        {!showSummary && (
          <>
            {
              !content.summaryFlag &&
              <button className={'switch'}
                onClick={() =>
                  firstSpeaker === 'A' ? setFirstSpeaker('B') : setFirstSpeaker('A')
                }
              >
                <img src={`${process.env.REACT_APP_IMG_URL}/icon/switch_role.svg`} alt='switch' />
                <span>Switch</span>
              </button>
            }

            <div className='dialogue-wrapper'>
              {content.dialog &&
                content.dialog.map((item, index) => (
                  <div
                    key={`s${index}`} className={`speaker ${
                      firstSpeaker === 'A'
                        ? item.speaker === 'A'
                          ? 'A'
                          : 'B'
                        : item.speaker === 'A'
                          ? 'B'
                          : 'A'
                    }`}
                  >
                    {item.speaker === firstSpeaker && (
                      <div
                        className={`part ${firstSpeaker === 'A' ? 'a' : 'as'}`}
                      >
                        Student<span>S</span>
                      </div>
                    )}
                    {item.speaker !== firstSpeaker && (
                      <div
                        className={`part ${firstSpeaker === 'A' ? 'b' : 'bs'}`}
                      >
                        <span>T</span>Teacher
                      </div>
                    )}
                    <div className='bubble'>
                      <div className='sentence'>{item.sentence}</div>
                    </div>
                  </div>
                ))}
              {Array.isArray(content) &&
                content.map((item, index) => (
                  <div
                    key={`s${index}`}
                    className={`speaker ${
                      firstSpeaker === 'A'
                        ? item.speaker === 'A'
                          ? 'A'
                          : 'B'
                        : item.speaker === 'A'
                          ? 'B'
                          : 'A'
                    }`}
                  >
                    {item.speaker === firstSpeaker && (
                      <div
                        className={`part ${firstSpeaker === 'A' ? 'a' : 'as'}`}
                      >
                        Student<span>S</span>
                      </div>
                    )}
                    {item.speaker !== firstSpeaker && (
                      <div
                        className={`part ${firstSpeaker === 'A' ? 'b' : 'bs'}`}
                      >
                        <span>T</span>Teacher
                      </div>
                    )}
                    <div className='bubble'>
                      <div className='sentence'>{item.sentence}</div>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
        {content?.memo && (
          <div className={'memo'}>
            <div
              className={showMemo ? 'memo-button open' : 'memo-button close'}
              onClick={() => setShowMemo((v) => !v)}
            >
              메모
            </div>
            {showMemo && <div className={'memo-content'}>{content.memo}</div>}
          </div>
        )}
      </div>
    </div>
  );
}
