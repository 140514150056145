import React, { useEffect } from 'react';
import TrialMode from './TrialMode';
import TrialLevel from './TrialLevel';
import TrialRecommendTimeSlot from './TrialRecommendTimeSlot';
import TrialAllTimeSlotPopup from './TrialAllTimeSlotPopup';
import ReserveTrialButton from './ReserveTrialButton';
import TrialBook from './TrialBook';
import TrialUserInfo from './TrialUserInfo';
import { ScrollNone } from 'common/utilFn';

const TrialBody = () => {
  
  useEffect(() => {
    if (window.innerWidth >= 1024) {
      setTimeout(() => {
        ScrollNone(false);
      },100); 
    } 
  },[]);
  
  return (
    <div className={'apply-form-body__inner'}>
      <TrialMode />
      <TrialLevel />
      <TrialBook />
      <TrialRecommendTimeSlot />
      <TrialAllTimeSlotPopup />
      <TrialUserInfo />
      <ReserveTrialButton />
    </div>
  );
};
export default TrialBody;
