import React, {useState} from 'react';
import {TodayImageType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: TodayImageType[];
}

export default function TodayImage({componentCode, content}: PropsType) {
  const [showMemo, setShowMemo] = useState(
    new Array(content?.length || 0).fill(false)
  );
  const toggleShowMemo = (index: number) => {
    const arr = [...showMemo];
    arr[index] = !arr[index];
    setShowMemo(arr);
  };

  return (
    <div className={`page ${componentCode}`}>
      <div className={'component-name'}>Today's Image</div>
      <div className='component-desc'>아래 이미지를 보고 질문에 답해보세요.</div>
      {content.map((item, index) => (
        <div key={`s${index}`}>
          <div className='content-box'>
            <div className='label long'>Question {index + 1}</div>
            <div className='content TI'>{item.sentence}</div>
            <div className='pictureBox'>
              <img
                className='picture'
                src={item.imgUrl}
                alt=''
              />
            </div>
            {item?.memo && (
              <div className={'memo'}>
                <div
                  className={showMemo[index] ? 'memo-button open' : 'memo-button close'}
                  onClick={() => toggleShowMemo(index)}
                >
                  메모
                </div>
                {showMemo[index] && <div className={'memo-content'}>{item.memo}</div>}
              </div>
            )}
          </div>
          {item.sub?.length > 0 && (
            <ul className='step-progress'>
              {item.sub.map((row, i) => (
                <li className='StepProgress-item' key={`f${index}${i}`}>
                  <div className='follow-title'>Follow up Question {i + 1}</div>
                  <pre className='follow-content'>{row.sentence}</pre>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
}
