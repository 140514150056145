import React, { Component, createRef } from 'react';
import Swiper from 'react-id-swiper';
import moment from 'moment';
import axios from 'axios';

import '../css/LtReport.scss';

import * as utilFn from '../common/utilFn';
import PlayerState from '../context/PlayerState';
import Top from '../component/top';
import Polly from '../component/AwsPolly';
import AudioPlayer from '../component/AudioPlayer';
import LessonIntroduction from '../component/freeReport/LessonIntroduction';
import {httpDefaultClient} from 'configs/api';

export default class FreeReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lvCommentList: [],
      membererAll: [0,0],
      reportArea: false, // 무료수업 리포트 전체 (팝업제외) 영역
      reportPopArea: false, // 무료수업 팝업 전부 들어있는 영역
      pushPopWrap: true, //  첫진입시 sms,push 동의 안한 사람에게 뜨는 팝업
      pushPopEventPop: false, // 할인.이벤트 안내 동의 팝업
      eventPushPopWrap: false, // 스크롤시 나타나는 토스트팝업
      adAgree: false, // 토스트팝업 (이벤트소식) 받아보기 클릭여부
      eventPushOkOn: false, // 토스트팝업 받아보기 클릭 > 상단 검정배너
      circleGraphOn: true, // 원형그래프 애니메이션효과

      commentCommunication: {},
      commentGrammar: {},
      commentPronunciation: {},
      commentFluency: {},
      commentComprehension: {},
      strengths: [],
      weaknesses: [],
      evaluationitemList: [],
      juniorFlag: false,
    };
  }

  async componentWillMount(){
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    if (this.props.match.params.seq) {
      let feedbackSeq = this.props.match.params.seq;
      if (isNaN(this.props.match.params.seq)) {
        feedbackSeq = await this.findFeedbackSeq(this.props.match.params.seq);
      }
      if (feedbackSeq) {
        this.commonCodes('UNITLEVEL');
        this.scoreCommentData(); // 영역별 점수 코멘트 조회
        this.getEvaluationitemList();// 강점 및 약점의 코멘트 조회
        this.FreeMyScoreData(feedbackSeq); // 무료수업 리포트 첫진입시
        this.freeRecoCourseData(feedbackSeq); // 무료수업 추천 과정리스트
      } else {
        alert('무료수업 데이터가 없습니다.');
        this.props.history.push('/');
      }
    }
  }

  componentDidMount() {
    // 스크롤 이벤트 적용
    window.addEventListener('scroll', this.scrollPushPop); // 스크롤시 팝업 노출
    window.addEventListener('resize',this.changeWw,true);
  }

  componentWillUnmount() { // 컴포넌트 제거 후
    window.removeEventListener('scroll', this.scrollPushPop);
    window.removeEventListener('resize',this.changeWw);
  }

  changeWw = () => {
    this.ww = window.innerWidth>=1025?'pc':'mo';
    this.setState({
      ww : this.ww
    });
  };

  commonCodes = (codes) => {
    axios({
      method:'GET',
      url:process.env.REACT_APP_API_URL + '/commonCode/multi/'+codes,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then( (response) => {
        if (response.data) {
          this.setState({...response.data});
        }
      })
      .catch( (error) => {
        console.error('error: ' + error);
      });
  };

  commonCodeValue = (t, k, v) => {
    if (t && k && v && this.state[t]){
      const a = this.state[t].map(x => x.detailCode);
      const b = a.indexOf(v);
      return this.state[t][b][k];
    }
  };

  // 무료수업 피드백 일련번호 찾기
  findFeedbackSeq = async (reportUrl) => {
    let feedbackSeq;
    await axios({
      method:'GET',
      url:process.env.REACT_APP_API_URL + '/web/freeReportCode?code='+reportUrl,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      }
    })
      .then( (response) => {
        if (response.data.result) {
          feedbackSeq = response.data.data;
        }
      })
      .catch( (error) => {
        console.error('error: ', error);
      });
    return feedbackSeq;
  };

  // 레벨테스트 리포트 레벨 및 점수별 코멘트, 백분위 조회.
  levelCommentData = (score) => {
    httpDefaultClient.get('/freelesson/levelComment')
      .then(response => {
        const data = response.data; // 레벨테스트 리포트 레벨 및 점수별 코멘트, 백분위 조회
        const memberSum = data.reduce((accumulator, current) => accumulator + current.memberCount, 0);
        const juniormemberSum = data.reduce((accumulator, current) => accumulator + current.juniormemberCount, 0);
        const comment = data.find(element => element.score===score);

        this.setState({
          lvComment: data, // 레벨테스트 회차별 점수 리스트
          memberSum,
          juniormemberSum,
          levelComment: comment.levelComment,
          comprehensiveComment: comment.comprehensiveComment
        });
      })
      .catch((error) => console.error('error: ', error));
  };

  // 영역별 점수 코멘트 조회
  scoreCommentData = () => {
    httpDefaultClient.get('/freelesson/scoreComment')
      .then(response => {
        const data = response.data; // 영역별 점수 코멘트 조회
        const commentCommunication = data
          .filter(element => element.category==='communication')
          .reduce((accumulate, current) => {
            return {
              ...accumulate,
              [current.score]: current.comment
            };
          }, {});

        const commentGrammar = data
          .filter(element => element.category==='grammar')
          .reduce((accumulate, current) => {
            return {
              ...accumulate,
              [current.score]: current.comment
            };
          }, {});

        const commentPronunciation = data
          .filter(element => element.category==='pronunciation')
          .reduce((accumulate, current) => {
            return {
              ...accumulate,
              [current.score]: current.comment
            };
          }, {});

        const commentFluency = data
          .filter(element => element.category==='fluency')
          .reduce((accumulate, current) => {
            return {
              ...accumulate,
              [current.score]: current.comment
            };
          }, {});

        const commentComprehension = data
          .filter(element => element.category==='comprehension')
          .reduce((accumulate, current) => {
            return {
              ...accumulate,
              [current.score]: current.comment
            };
          }, {});

        this.setState({
          commentCommunication,
          commentGrammar,
          commentPronunciation,
          commentFluency,
          commentComprehension
        });
      })
      .catch((error) => console.error('error: ', error));
  };

  // 강점 및 약점 코멘트 조회
  getEvaluationitemList = () => {
    httpDefaultClient.get('/freelesson/evaluationitem')
      .then(response => {
        const data = response.data;
        this.setState({
          evaluationitemList: data, // 강점약점 코멘트 전체리스트
        });
      })
      .catch((error) => console.error('error: ', error));
  };

  // 장단점 코멘트 코드 해석
  getEvaluationContent = (category, item, code) => {
    const list = this.state.evaluationitemList.filter(t => t.category === category && t.item===item && t.code===code);
    return list.length>0 ? list[0].content : '';
  };

  // 장단점 코멘트 출력
  evaluationComment = (feedbackItem) => {
    const state = this.state;
    return (
      <ul className={'diaComment'}>
        {
          state.strengths && state.strengths
            .filter((t) => {
              return t.feedbackItem==feedbackItem;
            })
            .map((t, i) => {
              return (
                <li className={'swComment'} key={i}>
                  <span className={'dcCheckIco'}></span><em>{this.getEvaluationContent(t.categoryCode, t.feedbackItem, t.feedbackCode)}</em>
                </li>
              );
            })
        }
        {
          state.weaknesses && state.weaknesses
            .filter((t) => {
              return t.feedbackItem==feedbackItem;
            })
            .map((t, i) => {
              return (
                <li className={'swComment'} key={i}>
                  <span className={'dcCheckIco csCheckRed'}></span><em>{this.getEvaluationContent(t.categoryCode, t.feedbackItem, t.feedbackCode)}</em>
                </li>
              );
            })
        }
      </ul>
    );
  };
  
  // 무료수업리포트 data
  FreeMyScoreData = (seq) => {
    httpDefaultClient.get('/freelesson/report/' + seq)
      .then(response => {
        const data = response.data;
        this.setState({
          // 선택 회차,날짜
          moveDate: data.data.levelTestLists.testDate,
          moveTscore: data.data.levelTestLists.totalScore,
          selecDate: data.data.levelTestLists.testDate,
          selecLv: data.data.level,
          selecTscore: data.data.levelTestLists.totalScore,
          selecSeq: seq,

          notiStatus: data.data.notiStatus, // 팝업노출여부 true일때 노출
          memberName: data.data.memberName, // 회원이름
          memberSeq : (data.data.levelTestLists && data.data.levelTestLists.memberSeq) ? data.data.levelTestLists.memberSeq :  '',
          juniorFlag: data.data.juniorFlag,

          message: (data.data.levelTestLists && data.data.levelTestLists.message) ? data.data.levelTestLists.message.replace(/\n/g, '<br/>') : '', // 강사코멘트
          curTotalScore: data.data.levelTestLists.totalScore, // 총점
          curFeedbackSeq: data.data.levelTestLists.feedbackSeq, // seq
          // 최신 레벨테스트 전체 데이터
          currentLevelReport: data.data.levelTestLists,

          // 영역별 점수
          communication: data.data.levelTestLists.communication, // 의사소통 점수
          grammar: data.data.levelTestLists.grammar, // 문법 점수
          pronunciation: data.data.levelTestLists.pronunciation, // 주의발음 점수
          fluency: data.data.levelTestLists.fluency, // 유창성 점수
          comprehension: data.data.levelTestLists.comprehension, // 이해력 전점수

          // 강점/약점
          strengths: data.data.levelTestLists.strengths,
          weaknesses: data.data.levelTestLists.weaknesses,

          // 내용 피드백
          grammars: data.data.levelTestLists.grammars,
          pronunciations: data.data.levelTestLists.pronunciations,

          audioUrl : data.data.audioFileName,
          feedbackIputDate : data.data.levelTestLists.inputDatetime

        });

        this.levelCommentData(data.data.levelTestLists.totalScore); // 리포트레벨,점수별 코멘트,백분위 조회

        // sms/
        if (data.data.notiStatus === true && this.ww==='mo'){ // true => 팝업노출
          this.setState({
            reportPopArea: true,
          });
        } else { // 팝업 미노출
          this.setState({
            reportArea: true,
            eventPushPopWrap: false, // 스크롤시 나타나는 토스트 팝업
          });
        }
      })
      .then(() => {
        // 레벨테스트 점수 초중고 분류
        let lvArr = [];
        const lvIfo = new utilFn.LevelInfo;
        const t = lvIfo.infoFromScore(this.state.curTotalScore);

        if (this.state.curTotalScore >= 50 && this.state.curTotalScore <= 155){
          const lowLv = [];
          const t = {'communication' : 21, 'pronunciation' :23, 'grammar' : 24, 'fluency' : 21, 'comprehension':23};
          const b = Object.assign(lowLv, t);
          lvArr.push(lowLv);
        } else if (this.state.curTotalScore >= 160 && this.state.curTotalScore <= 350){
          const midLv = [];
          const t = {'communication' : 57, 'pronunciation' :61, 'grammar' : 63, 'fluency' : 55, 'comprehension':59};
          const b = Object.assign(midLv, t);
          lvArr.push(midLv);
        } else if (this.state.curTotalScore >= 355 && this.state.curTotalScore <= 500){
          const highLv = [];
          const t = {'communication' : 87, 'pronunciation' :89, 'grammar' : 92, 'fluency' : 85, 'comprehension':88};
          const b = Object.assign(highLv, t);
          lvArr.push(highLv);
        }

        this.setState({
          avLvArr: lvArr,
          avCommunication: lvArr[0].communication,
          avPronunciation: lvArr[0].pronunciation,
          avGrammar: lvArr[0].grammar,
          avFluency: lvArr[0].fluency,
          avComprehension: lvArr[0].comprehension,
        });
      })
      .catch((error) => console.error('error: ', error));
  };

  // 무료수업리포트 과정추천 조회
  freeRecoCourseData = (seq) => {
    httpDefaultClient.get('/freelesson/report/' + seq + '/recommend')
      .then(response => {
        const data = response.data;
        this.setState({
          //추천과정 전체리스트
          magazineList: data.magazineList,//추천과정 전체리스트
        });
      })
      .catch((error) => console.error('error: ', error));
  };

  // 회원/비회원 알림여부 변경
  notificationInfo = () => {
    httpDefaultClient.post('/freelesson/report/notificationInfo?feedbackSeq=' + this.state.selecSeq)
      .catch((error) => console.error('error: ', error));
  };

  // 뒤로가기 클릭시 스크롤 이벤트 종료
  backEventStop = () => {
    window.removeEventListener('scroll', this.scrollPushPop); // 스크롤 이벤트 종료
  };

  // 과정상세페이지로 이동
  onClickCourse = (courseSeq) => {
    window.removeEventListener('scroll', this.scrollPushPop); // 스크롤 이벤트 종료
    this.props.history.push(`/Course/p2/${courseSeq}`);
  };

  // 과정 메인페이지로 이동
  onClickCourseMain = () => {
    window.removeEventListener('scroll', this.scrollPushPop); // 스크롤 이벤트 종료
    this.props.history.push('/order');
  };

  // 팝업닫기
  onClickPushPopCls = () => {
    this.setState({
      pushPopWrap: false, // push팝업닫기
      reportPopArea: false,
      reportArea: true, // 리포트 페이지 보이기
      eventPushPopWrap: false, //
      circleGraphOn: true, // 원형그래프 on
    });
  };

  // 푸시,문자로 할인,이벤트팝업
  onClickEventPop = () => {
    this.setState({
      reportPopArea: true, // 팝업 전체 영역
      pushPopEventPop: true, // event팝업 보이기
      //pushPopWrap: true, // push팝업닫기
      reportArea: false, // 리포트 페이지 보이기
    });
  };

  // 푸시,문자로 할인,이벤트팝업 닫기
  onClickEventPopCls = () => {
    if (this.state.pushPopWrap === true){ // 첫진입 팝업> 푸시,문자로 할인,이벤틍
      this.setState({
        pushPopEventPop: false, // event팝업 닫기
        reportArea: false, // 리포트 페이지 보이기
      });
    } else {
      this.setState({
        pushPopEventPop: false, // event팝업 닫기
        reportPopArea: false, // 팝업 전체영역 닫기
        reportArea: true, // 리포트 페이지 보이기
      });
    }
  };

  // 알림을 받아볼래요 (팝업닫기)
  onClickPpAlarmWrapCls = () => {
    this.notificationInfo();
    this.setState({
      reportPopArea: false, // 팝업 전체영역 닫기
      pushPopWrap: false, // push팝업닫기
      reportArea: true, // 리포트 페이지 보이기
      notiStatus: false, // 수신동의 처리 (스크롤시 나타나는 토스트팝업 안뜨게)
      //회원: 회원정보, 무료수업 정보를 문자/푸쉬 중 N 되어있는 항목을 Y 처리
      //비회원:  ﻿무료수업 정보를 문자/푸쉬 중 N 되어있는 항목을 Y 처리
    });
  };

  eMessage = (t, v) => {
    axios({
      method:'get',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (t === 'emailError'){
          this.setState({emailError: response.data.message});
        } else {
          alert(response.data.message);
          return null;
        }
      })
      .catch( (error) => {
        console.error('error: ', error);
      });
  };

  setRefPushPop = createRef();

  setRefPushPopEnd = createRef();

  // 스크롤시 토스트 팝업 노출
  scrollPushPop = () => {
    const PushPop = window.scrollY || window.pageYOffset; // 현재 스크롤값
    const RefPushPopY = this.setRefPushPop.offsetTop; //나의레벨영역 top 위치에서 팝업 보이기
    const RefPushPopEndY = this.setRefPushPopEnd.offsetTop; // 내용피드백 top 위치에서 팝업 숨기기

    if (this.state.notiStatus === true){ // 팝업 노출(true) / 미노출(false)
      if (PushPop >= RefPushPopY && PushPop <= RefPushPopEndY) { // 현재 스크롤값이 나의레벨영역 top 보다 크고, 내용피드백 top 보다 작으면 true
        this.setState({
          eventPushPopWrap: true,
        });
      } else {
        this.setState({
          eventPushPopWrap: false,
        });
      }
    }
  };

  // 스크롤시 노출되는 토스트 팝업 > 받아보기 클릭
  onClickAdAgree = () => {
    // 1. 클릭 > 버튼칼라 변경
    // 2. 클릭 > 상단에 배너 노출
    // 3. sms, email 수신동의처리
    this.notificationInfo(); // 수신동의 처리
    this.setState({
      adAgree: true, // 받아보기 버튼 칼라변경
      eventPushOkOn: true, // 상단 배너노출
      eventPushPopWrap: false,
      notiStatus : false
    });

    // 상단 배너 숨기기
    setTimeout(() => {
      this.setState({
        eventPushOkOn: false,
      });
    },2000);
  };

  audioEvent = (type, status, timeValue, fileName) => {
    this.id = fileName;
    if (!this.uniqueName) this.uniqueName = {};
    if (!this.uniqueName[this.id]) this.uniqueName[this.id] = { startTime : 0, endTime : 0 };

    if (status === 'START'){
      this.uniqueName[this.id].startTime = timeValue;
    } else if (status === 'END'){
      this.uniqueName[this.id].endTime = timeValue;
    }
  };

  render() {
    const state = this.state;
    const ts = this.state;
    const props = this.props;
    // 레벫별 나를위한 추천과정 슬라이드
    const params2 = {
      slidesPerView: this.ww === 'pc' ? 'auto': 1.45,
      spaceBetween: 15,
      loop: false,
      shouldSwiperUpdate: true, //스와이프 초기화
      rebuildOnUpdate : true,
      centeredSlides: this.ww !== 'pc',
    };

    return (
      <div className={'ltReportPage p1'}>
        <div id={'step1'} className={'step1'}>
          {/*back & title*/}
          <div className={'reportArea ' + (state.reportArea && 'on')}>
            <div className={'backBtn'} onClick={() => this.backEventStop()}>
              <Top title={''} sub={''} history={this.props.history} noBg={true} notFixed = {true} />
            </div>
            <div className={'freeTopWrap'}>
              <div className={'ftTitleWrap'}>
                <div className={'ftGrTxt'}><img src='https://img.uphone3.com/report/lt_gr_txt_01.svg' alt='결과리포트 도착' /></div>
                <div className={'ftTitle'}>
                  {state.memberName}님, 결과 <span className={'nwWord'}>리포트</span> <span className={'nwWord'}>함께 보시겠어요?</span>
                </div>
                <div className={'ftSubTitle'}>
                  이제 학습 컨설턴트의 전화[02-1599-6846]를 받으면<br/>
                  결과리포트 분석 후 학습 방향을 알려드려요.
                </div>
              </div>
              <div className={'ftInfo'}>
                <div className={'ftInfoTitle'}>
                  <img src='https://img.uphone3.com/report/call_ico.svg' alt='전화 아이콘' />Consultation call
                </div>
                <ul>
                  <li><em>1</em>결과리포트 맞춤 분석</li>
                  <li><em>2</em>실력별 과정 추천 / 학습 설계</li>
                  <li><em>3</em>스페셜 할인 쿠폰 제공<span>NEW</span></li>
                </ul>
              </div>
              {/*무료혜택,이벤트소식 푸시팝업,  'on' 붙이면 나타남*/}
              <div className={'dropMenuWrap eventPushPopWrap ' + (state.eventPushPopWrap && 'on')}>
                <div className={'top'}><p></p></div>
                <ul>
                  <li>
                    <div className={'eventPushWrap maxWrap'}>
                      <div className={'epL'}>푸시/문자로 <em>다양한 영어 컨텐츠와<br/>무료 혜택, <span onClick={() => this.onClickEventPop()}>이벤트 소식</span></em>을 전해드려요.</div>
                      <div className={'epR ' + (state.adAgree && 'on')}
                        onClick={state.adAgree === false ? () => this.onClickAdAgree() : null}
                      >받아보기</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            {/*무료혜택,이벤트소식 푸시팝업 클릭 > 상단에 설정완료 문구 나타남 */}
            <div className={'eventPushOkWrap ' + (state.eventPushOkOn && 'on')}>
              <p>이벤트 소식 받아보기가 설정되었습니다.</p>
            </div>

            {/*타이틀,그래프*/}
            <div className={'feedback'}>
              <div className={'weekData'}><p>{String(state.selecDate).replace(/-/g,'.')}</p></div>
              <div className={'myLevelWrap'} ref={ (ref) => {this.setRefPushPop=ref;} }>
                <div className={'categoryTitle'}>
                  나의 레벨
                </div>
                <div className={'circleGraph'}>
                  <div className={'graphInner'}>
                    <div className={'single-chart'}>
                      <div className={'circleBaseLine'}></div>
                      <svg viewBox='0 0 36 36' className={'circular-chart'}>
                        <defs>
                          <linearGradient id={'gradient'} x1='0%' y1='0%' x2='0%' y2='100%'>
                            <stop offset='0%' stopColor='#00e599'/>
                            <stop offset='100%' stopColor='#00bfe5'/>
                          </linearGradient>
                        </defs>
                        <path className={'circle ' + (state.circleGraphOn && 'on')}
                          strokeDasharray= {(state.selecTscore)/5 + ', 100'}
                          d='M18 2.0845
																		 a 15.9155 15.9155 0 0 1 0 31.831
																		 a 15.9155 15.9155 0 0 1 0 -31.831'
                          stroke='url(#gradient)'
                        />
                      </svg>
                      <div className={'circleTxtWrap'}>
                        <div className={'cirLevel'}>
                          Level.{state.selecLv}
                        </div>
                        <div className={'cirScore'}>
                          <p>{state.selecTscore}</p>
                          /500
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={'myPercentileWrap'}>
                <div className={'categoryTitle'}>
                  나의 백분율
                </div>
                <div className={'percentileGraph'}>
                  {state.lvComment &&
                    <>
                      <ul>
                        {state.lvComment.map((t, i) => {
                          return (
                            <li className={'perGraphWrap'} key={i}>
                              <div className={'perGraph'}
                                style={{
                                  height: state.juniorFlag?((100/state.juniormemberSum) * t.juniormemberCount)*5 +'%':((100/state.memberSum) * t.memberCount)*5 +'%',
                                  backgroundColor: state.curTotalScore === t.score ? 'transparent' : '#F2F2F2',
                                  backgroundImage: state.curTotalScore === t.score ? 'linear-gradient(to top, #00E599 , #00BFE5)' : 'none'
                                }}
                              >
                                <p
                                  style={{
                                    display: state.curTotalScore === t.score ? 'block' : 'none'
                                  }}
                                >상위 <span>{state.juniorFlag?t.juniorPercentile:t.percentile}</span>%</p>
                              </div>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  }
                </div>
              </div>
              <div className={'rfMyLevelWrap maxWrap'}>
                <div className={'myLtTxt'} dangerouslySetInnerHTML={{__html : state.levelComment}}>
                </div>
                <p className={'myLtInfo'}>
                  <span className='linDot'>·</span><span className='linTxt'>공인 시험 점수는 유폰 회원의 점수를 바탕으로 만들어진 통계자료로,  개인에 따라 등급에 차이가 있을 수 있습니다.</span>
                </p>
              </div>
              {/*셀프 평가 & 다시 듣기*/}
              <div className={'rfReListenWrap'}>
                <div className={'categoryTitle'}>
                  수업한 내용 확인하기
                </div>
                {
                  (() => {
                    if (state.audioUrl && state.audioUrl.length > 0){
                      if (moment(state.feedbackIputDate).add('21', 'days').format('YYYY-MM-DD') <= moment().format('YYYY-MM-DD')){
                        //기간 만료
                        return (
                          <div className={'fbSubTitle'}>녹음 파일의 재생 및 다운로드는 3주간 가능하며, 이후에는 불가합니다.</div>
                        );
                      } else {
                        //기간 내
                        return (
                          <div className={'fbSubTitle'}>녹음 파일의 재생 및 다운로드는 3주간 가능합니다.</div>
                        );
                      }
                    } else {
                      return (
                        <div className={'fbSubTitle'}>등록된 녹음 파일이 없습니다. 조금만 기다려 주세요!</div>
                      );
                    }
                  })()
                }
                <div className={'player maxWrap'}>
                  {moment(state.feedbackIputDate).add('21', 'days').format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') && (state.audioUrl && state.audioUrl.length > 0) ?
                    <PlayerState src={state.audioUrl}>
                      <AudioPlayer subject={'Level Test_'+moment().format('HHmm')}
                        lDate={moment(state.feedbackIputDate).format('YYMMDD')}
                        lTime={state.feedbackIputDate.slice(0, 5).replace(':', '')}
                        onChangeStatus = {this.audioEvent}/>
                    </PlayerState>
                    : <div className='controls'>
                      <div className='audioControls'><span className='icon play'><img
                        src={`${process.env.REACT_APP_IMG_URL}/icon/btn_player_black_d.png`}
                        alt={'play'}/></span></div>
                      <div className='progressBox'>
                        <input defaultValue={0} type='range' name='progressBar' id='prgBar' max={0}
                          min={0}/>
                        <div className={'prg'} style={{width: 0}}/>
                      </div>
                      <div className={'audioDownload'}><span className={'icon dn'}><img
                        src={`${process.env.REACT_APP_IMG_URL}/icon/btn_down_black_d.png`}
                        alt={'download'}/></span></div>
                    </div>}
                </div>
              </div>
              {/*셀프 평가 & 다시 듣기*/}
              <div className={'rfAdviceWrap'}>
                <div className={'categoryTitle'}>
                  종합 진단 및 학습 조언
                </div>
                <div className={'rfaTxt maxWrap'} dangerouslySetInnerHTML={{__html: state.comprehensiveComment}}>
                </div>
              </div>
              {/*영역별 진단*/}
              <div className={'rfDiagnosisWrap'}>
                {/*영역별 진단 그래프*/}
                <div className={'categoryTitle'}>
                  영역별 진단
                </div>
                {/*영역별 진단 그래프*/}
                <div className={'graphListWrap'}>
                  <ul className={'graphInfo'}>
                    <li className={'gi02'}><span></span>내 점수</li>
                    <li className={'gi03'}><span></span>유폰 {state.curTotalScore >= 50 && state.curTotalScore <= 155 ? '초급' : state.curTotalScore >= 160 && state.curTotalScore <= 350 ? '중급' : state.curTotalScore >= 355 && state.curTotalScore <= 500 ? '고급' : ''} 평균</li>
                  </ul>
                  <ul className={'graphList'}>
                    <li className={'liTest'}>
                      <ul>
                        <li className={'li01'}><span>100</span></li>
                        <li className={'li02'}><span>75</span></li>
                        <li className={'li03'}><span>50</span></li>
                        <li className={'li04'}><span>25</span></li>
                        <li className={'li05'}><span>0</span></li>
                      </ul>
                    </li>
                    <li className={'glList'}>
                      <div className={'ltGraphWrap'}>
                        <div className={'ltGraphSet'}>
                          <div className={'ltGraph lig02'}
                            style={{
                              height: (100/100)*state.communication +'%'
                            }}
                          ></div>
                          <div className={'ltGraph lig03'}
                            style={{
                              height: (100/100)*state.avCommunication +'%'
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className={'ltgName'}>의사소통</div>
                    </li>
                    <li className={'glList'}>
                      <div className={'ltGraphWrap'}>
                        <div className={'ltGraphSet'}>
                          <div className={'ltGraph lig02'}
                            style={{
                              height: (100/100)*state.pronunciation +'%'
                            }}
                          ></div>
                          <div className={'ltGraph lig03'}
                            style={{
                              height: (100/100)*state.avPronunciation +'%'
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className={'ltgName'}>발음</div>
                    </li>
                    <li className={'glList'}>
                      <div className={'ltGraphWrap'}>
                        <div className={'ltGraphSet'}>
                          <div className={'ltGraph lig02'}
                            style={{
                              height: (100/100)*state.grammar +'%'
                            }}
                          ></div>
                          <div className={'ltGraph lig03'}
                            style={{
                              height: (100/100)*state.avGrammar +'%'
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className={'ltgName'}>문법</div>
                    </li>
                    <li className={'glList'}>
                      <div className={'ltGraphWrap'}>
                        <div className={'ltGraphSet'}>
                          <div className={'ltGraph lig02'}
                            style={{
                              height: (100/100)*state.fluency +'%'
                            }}
                          ></div>
                          <div className={'ltGraph lig03'}
                            style={{
                              height: (100/100)*state.avFluency +'%'
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className={'ltgName'}>유창성</div>
                    </li>
                    <li className={'glList'}>
                      <div className={'ltGraphWrap'}>
                        <div className={'ltGraphSet'}>
                          <div className={'ltGraph lig02'}
                            style={{
                              height: (100/100)*state.comprehension +'%'
                            }}
                          ></div>
                          <div className={'ltGraph lig03'}
                            style={{
                              height: (100/100)*state.avComprehension +'%'
                            }}
                          ></div>
                        </div>
                      </div>
                      <div className={'ltgName'}>이해력</div>
                    </li>
                  </ul>
                </div>
                {/*영역별 코멘트 및 강점 & 약점*/}
                <ul className={'diagnosisList maxWrap'}>
                  <li className={'diaLi'}>
                    <div className={'diaTitle'}>의사소통</div>
                    <div className={'diaSubTitle'}>
                      {state.commentCommunication[state.communication]}
                    </div>
                    {this.evaluationComment('COMMUNICATION')}
                  </li>

                  {/*발음*/}
                  <li className={'diaLi'}>
                    <div className={'diaTitle'}>발음</div>
                    <div className={'diaSubTitle'}>
                      {state.commentPronunciation[state.pronunciation]}
                    </div>
                    {this.evaluationComment('PRONUNCIATION')}
                  </li>

                  {/*문법*/}
                  <li className={'diaLi'}>
                    <div className={'diaTitle'}>문법</div>
                    <div className={'diaSubTitle'}>
                      {state.commentGrammar[state.grammar]}
                    </div>
                    {this.evaluationComment('GRAMMAR')}
                  </li>

                  {/*유창성*/}
                  <li className={'diaLi'}>
                    <div className={'diaTitle'}>유창성</div>
                    <div className={'diaSubTitle'}>
                      {state.commentFluency[state.fluency]}
                    </div>
                    {this.evaluationComment('FLUENCY')}
                  </li>

                  {/*이해력*/}
                  <li className={'diaLi'}>
                    <div className={'diaTitle'}>이해력</div>
                    <div className={'diaSubTitle'}>
                      {state.commentComprehension[state.comprehension]}
                    </div>
                    {this.evaluationComment('LISTENING')}
                  </li>
                </ul>
              </div>
              {/*강사코멘트*/}
              <div className={'lectureCommentWrap'}>
                <div className={'categoryTitle'}>
                  강사님의 코멘트
                </div>
                <div className='lectureComment maxWrap' dangerouslySetInnerHTML={{__html: state.message}} ></div>
              </div>
              {/*내용 피드백*/}
              <div className={'rfFeedBackWrap'} ref={ (ref) => {this.setRefPushPopEnd=ref;} }>
                <div className={'categoryTitle'}>
                  내용 피드백
                </div>
                <div className={'fbTitle'}>이럴 땐 이렇게 말하기</div>
                <div className={'fbSubTitle'}>
                  수업 때 말했던 문장을 문법과 표현에 집중하여 다시 말해보세요.
                </div>

                {state.grammars &&
                  <ul className={'fbCommentWrap maxWrap'}>
                    {state.grammars.map((t, i) => {
                      return (
                        <li className={'fbCommentList'} key={i}>
                          {/*수강생*/}
                          <div className={'fbMem'} dangerouslySetInnerHTML={{__html: t.studentSentence}}>
                          </div>
                          {/*강사수정*/}
                          <div className={'teacher fbLec'}>
                            {t.teacherSentence &&
                              <Polly t={t.teacherSentence} type={'teacher'} n={i} badge={t.teachersentenceType.toLowerCase()}/>}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                }
                <div className={'fbTitle'}>주의할 발음</div>
                <div className={'fbSubTitle'}>
                  수업 때 말했던 문장을 문법과 표현에 집중하여 다시 말해보세요.
                </div>

                {state.pronunciations &&
                  <ul className={'fbCarefulWrap maxWrap'}>
                    {state.pronunciations.map((t, i) => {
                      return (
                        <li className={'fbCarefulLi sent'} key={i}>
                          <Polly t={t.pronunciation} type={'sentence'} n={i}/>
                        </li>
                      );
                    })}
                  </ul>
                }
              </div>
            </div>

            {/* 수강 유도 영역  */}
            <LessonIntroduction memberName={state.memberName} />
            
            {/*레벨별 과정추천리스트*/}
            <div className={'CourseRecommend'}>
              <div className={'categoryTitle'}>
                {state.memberName}님을 위한 추천 과정
              </div>
              <div className={'mlrWrap maxWrap'}>
                {state.magazineList &&
                  <>
                    {state.magazineList.map((t, i) => {
                      return (
                        <ul className={'mlrSlideWrap'} key={i}>
                          <div className={'mlrTitle'}>
                            {/*내 레벨(Level{state.lastLevel})에 맞는 추천 과정*/}
                            {t.title === '중급 레벨 추천 과정' || t.title === '초급 레벨 추천 과정' || t.title === '고급 레벨 추천 과정' ? '내 레벨(Level' + state.selecLv + ')에 맞는 추천 과정' : t.title}
                          </div>
                          {state.magazineList &&
                            <>
                              <Swiper {...params2}>
                                {t.courseList.map((tg, idx) => {
                                  return (
                                    <li className={'ttt mlrLi'} key={idx}
                                      onClick={() => this.onClickCourse(tg.courseSeq)}
                                    >
                                      <div className={'imgCardWrap'} style={tg.thumbnailsavefileLocation  && { backgroundImage: `url(${process.env.REACT_APP_LMS_IMG_URL}${tg.thumbnailsavefileLocation })`}}>
                                        <div className={'lv'}>
                                          {this.commonCodeValue('UNITLEVEL', 'note3', tg.levelCode)}
                                        </div>
                                        <div className={'tit'}>{tg.courseName}</div>
                                      </div>
                                    </li>
                                  );
                                })
                                }
                              </Swiper>
                            </>
                          }
                        </ul>
                      );
                    })
                    }
                  </>
                }
              </div>
              <div className={'mlrBtnWrap'}>
                <div className={'mlrBtn'} onClick={() => this.onClickCourseMain()}>지금 바로 시작하기</div>
              </div>
            </div>
          </div>
          {/*리포트 push popup*/}
          <div className={'reportPopArea ' + (state.reportPopArea && 'on')} style={{height : '100vh'}}>
            <div className={'pushPopWrap ' + (state.pushPopWrap && 'on')}>
              <div className={'topWrap'}>
                <div className={'clsBtn'} onClick={() => this.onClickPushPopCls()}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='19.092' height='19.092' viewBox='0 0 19.092 19.092'>
                    <g id='btn_close' transform='translate(0.707 0.707)'>
                      <line y2='25' transform='translate(17.678 0) rotate(45)' fill='none' stroke='#111' />
                      <line y1='25' transform='translate(17.678 17.678) rotate(135)' fill='none' stroke='#111' />
                    </g>
                  </svg>
                </div>
              </div>
              <div className={'reportCom pushPop'}>
                <div className={'ppTitle'}>
                  나에게 유용한 정보<br/>
                  알림으로 전부
                </div>
                <div className={'ppPopBtn'} onClick={() => this.onClickEventPop()}>푸시, 문자로 할인·이벤트 안내</div>
                <div className={'ppContent'}>
                  <ul>
                    <li className={'ppl01'}>
                      <div className={'pL'}></div>
                      <div className={'pR'}>
                        <p>특별 할인 & 프로모션 알림</p>영어회화 할인받고 시작하기
                      </div>
                    </li>
                    <li className={'ppl02'}>
                      <div className={'pL'}></div>
                      <div className={'pR'}>
                        <p>시즌 이벤트 소식</p>댓글, 후기 이벤트로 1등 상품 받기
                      </div>
                    </li>
                    <li className={'ppl03'}>
                      <div className={'pL'}></div>
                      <div className={'pR'}>
                        <p>영어 학습 콘텐츠</p>영어 공부에 유용한 표현 확인하기
                      </div>
                    </li>
                  </ul>
                </div>
                <div className={'ppAlarmWrap'} onClick={() => this.onClickPpAlarmWrapCls()}>
                  <div className={'ppaOkBtn btn'}>알림을 받아볼래요</div>
                  {/*<div className={'ppaNoBtn'}>다음에 할래요</div>*/}
                </div>
              </div>
            </div>

            {/*푸쉬,문자로 할인,이벤트 안내 Click =>  팝업*/}
            <div className={'pushPopEventPop ' + (state.pushPopEventPop && 'on')}>
              <div className={'topWrap'}>
                <div className={'topTitle'}>할인/이벤트 안내에 대한 동의</div>
                <div className={'clsBtn'} onClick={() => this.onClickEventPopCls()}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='19.092' height='19.092' viewBox='0 0 19.092 19.092'>
                    <g id='btn_close' transform='translate(0.707 0.707)'>
                      <line y2='25' transform='translate(17.678 0) rotate(45)' fill='none' stroke='#111' />
                      <line y1='25' transform='translate(17.678 17.678) rotate(135)' fill='none' stroke='#111' />
                    </g>
                  </svg>
                </div>
              </div>
              <div className={'reportCom infoPopWrap'}>
                <ul className={'infoListWrap'}>
                  <li className={'infoLi'}>
                    <div className={'infoTitle'}>
                      1. 개인정보의 수집 및 이용 목적
                    </div>
                    <div className={'infoTxt'}>
                      ① 이벤트 등 광고성 정보 전달
                    </div>
                  </li>
                  <li className={'infoLi'}>
                    <div className={'infoTitle'}>
                      2. 수집하는 개인정보 항목
                    </div>
                    <div className={'infoTxt'}>
                      ① 이름, 아이디, e-mail, 휴대폰번호
                    </div>
                  </li>
                  <li className={'infoLi'}>
                    <div className={'infoTitle'}>
                      3. 수집한 개인정보의 보유 및 이용기간
                    </div>
                    <div className={'infoTxt'}>
                      ① 보존기간 : 개인정보 보존 기간 만료 또는 동의 철회시까지
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
