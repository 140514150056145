import React, {useEffect} from 'react';
import {ScrollNone} from '../common/utilFn';

const BottomPopup = ({children, closePopup, bottomBtn, btnGoTo, history, type}) => {

  useEffect(() => {
    const timer = setTimeout(() => {

      ScrollNone(true);
      clearTimeout(timer);
    }, 100);
    return () => {
      ScrollNone(false);
    };
  },[]);

  return (
    <>
      <div className={`bottomPop commonPop ${type? type : ''}`}>
        { closePopup &&
                <div className={'topWrap'}>
                  <div className={'clsBtn'} onClick={closePopup}/>
                </div>
        }
        <div className={'contents'}>
          <div className={'wrap'}>
            {children}
          </div>
        </div>
        {bottomBtn &&
                <button className='bttmStBtn' onClick={() => history.push(btnGoTo)}>{bottomBtn}</button>
        }
      </div>
      <div className={'msk adj'} />
    </>
  );
};


export default BottomPopup;
