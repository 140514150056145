'use client';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';

import popupStore from 'store/popup/popupStore';

import 'css/common/AppDownloadPopup.scss';

const AppDownloadPopup = () => {
  const history = useHistory();
  const isOpenAppDownloadPopup = popupStore((state) => state.isOpenAppDownloadPopup);
  const closeAppDownloadPopup = popupStore((state) => state.closeAppDownloadPopup);
  const appDownloadLink = popupStore((state) => state.appDownloadLink);

  const onClickDownloadApp = () => {
    history.push(appDownloadLink ? appDownloadLink : 'http://m.site.naver.com/16FkN');
  };

  return (
    <>
      {isOpenAppDownloadPopup &&
        <>
          <div className={'app-download-popup is-pc'}>
            <button type='button' className={'close-button'} onClick={closeAppDownloadPopup} />
            <div className={'txt-row'}>
              <strong>앱을 다운로드하세요.</strong>
              <p>기업회원 레벨테스트는 <em>유폰 앱으로 신청 및 <br/>진행</em>할 수 있어요. <em>기업회원으로 로그인</em>하고<br/>레벨테스트를 진행해보세요!</p>
            </div>
            <div className={'img-row'}>
              {appDownloadLink ? (
                <QRCodeCanvas value={appDownloadLink} />
              ) : (
                <img src={process.env.REACT_APP_IMG_URL + '/web/landing/mcp/pc/link_app@2x.jpg'} width={174} height={174} alt='민병철 유폰 앱 다운로드 QR코드' />
              )}
              <p>카메라로 QR코드를 스캔하여<br/>앱을 다운로드해 주세요.</p>
            </div>
          </div>
          <div className={`${'app-download-popup'} ${'is-mobile'}`}>
            <button type='button' className={'close-button'} onClick={closeAppDownloadPopup} />
            <div className={'txt-row'}>
              <strong>앱을 다운로드하세요.</strong>
              <p>기업회원 레벨테스트는 <em>유폰 앱으로 신청 및 <br/>진행</em>할 수 있어요. <em>기업회원으로 로그인</em>하고<br/>레벨테스트를 진행해보세요!</p>
              <button type='button' className={'button-mobile'} onClick={onClickDownloadApp}>유폰 앱 다운로드</button>
            </div>
          </div>
          <div className={'app-download-dimmed'}></div>
        </>
      }
    </>
  );
};

export default AppDownloadPopup;
