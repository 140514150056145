import React, {Component ,createRef} from 'react';
import {httpDefaultClient} from 'configs/api';

export default class PolicyInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      txt: ''
    };
  }

  componentDidMount(){
    httpDefaultClient.get('/sign/getSupport?supportId=privacy')
      .then(res => {
        const json = res.data;
        if (json.result) {
          this.setState({
            txt: json.data
          });
        }
      })
      .catch((error) => console.error('error: ', error));
  }

  sendMessage = (msg) => {
    //window.android.sendMessage(msg);
    //this.inputRef.current.android.sendMessage(msg);
    //alert(msg);
    this.setState({
      value: 'App으로 전송'
    });
  };

  inputRef = createRef();

  render() {
    return (
      <div dangerouslySetInnerHTML={{__html : this.state.txt}} />
    );
  };


}
