import { useEffect } from 'react';

import { httpRefreshClient } from 'configs/api';
import { onLogout, storage } from 'common/utilFn';

type OnSuccessFunction = () => void | Promise<void>;
type OnFailFunction = () => void | Promise<void>;

const useCheckLoginAndExecute = (onSuccess: OnSuccessFunction, onFail: OnFailFunction) => {
  useEffect(() => {
    async function checkLoginAndExecute() {
      try {
        const { data } = await httpRefreshClient.post('/sign/checkLogin');
        if (data.result) {
          onSuccess();
        } else {
          onLogout();
          onFail();
        }
      } catch (error) {
        console.error('Failed to /sign/checkLogin:', error);
      }
    }

    checkLoginAndExecute();
  }, []);
};

export default useCheckLoginAndExecute;
