import PurchaseService from './purchaseService';


const queryKeys = {
  faqList: ['purchaseFaqList'] as const,
  reviewList: ['purchaseReviewList'] as const,
  emergencyMessage: ['purchaseEmergencyMessage'] as const,
};

const queryOptions = {
  reviewList: () => ({
    queryKey: queryKeys.reviewList,
    queryFn: () => PurchaseService.getReviewList(),
  }),
  faqList: () => ({
    queryKey: queryKeys.faqList,
    queryFn: () => PurchaseService.getFaqList(),
  }),
  emergencyMessage: () => ({
    queryKey: queryKeys.emergencyMessage,
    queryFn: () => PurchaseService.getEmergencyMessage(),
  }),
};

export default queryOptions;
