import React from 'react';

type MovingToClassPaymentButtonProps = {
  isSelectedProductRecurringBilling: boolean,
  MovingToClassPaymentButtonText: string,
  moveToClassPayment: () => void,
};

const MovingToClassPaymentButton = ({ 
  isSelectedProductRecurringBilling,
  MovingToClassPaymentButtonText,
  moveToClassPayment,
}: MovingToClassPaymentButtonProps) => {
  return (
    <button
      type='button'
      className={`quotation-section__pay-button quotation-section__pay-button${isSelectedProductRecurringBilling ? '--recurring' : '--none-recurring'}`}
      onClick={moveToClassPayment}
    >
      {MovingToClassPaymentButtonText}
    </button>
  );
};

export default MovingToClassPaymentButton;
