import React, { Component, createRef } from 'react';
import axios from 'axios';

import 'css/orderCode.scss';

import { httpDefaultClient, httpRefreshClient } from 'configs/api';
import referrerStore from 'store/referrer/referrerStore';
import * as utilFn from 'common/utilFn';
import { ScrollNone } from 'common/utilFn';

import { Loading } from 'component/Loading';
import Footer from 'component/Footer';
import LayerPopup from 'component/LayerPopup';
import SupportBoard from 'component/common/SupportBoard';

export default class OrderCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'p1',
      toastOff : true,
    };
  }

  codeInput = createRef();

  componentWillMount(){
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.freeOrder = false;

    this.getCommonMsgList();

    const cookieChk = localStorage.getItem('orderCode');
    if (cookieChk && cookieChk !== 'undefined') {
      this.setState({
        orderCode: cookieChk,
      });
    }
    localStorage.removeItem('orderCode');
  }

  componentDidMount(){
    window.addEventListener('resize', this.changeWw, true);
    ScrollNone();

    httpRefreshClient.post('/sign/checkLogin').then(res => {
      if (res.data.result) {
        this.tokenOK();
      } else {
        this.setState({
          notLogin : true,
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeWw);
  }

  changeWw = () => {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.setState({
      ww : this.ww
    });
  };

  tokenOK = () => {
    // 회원 정보 조회
    httpDefaultClient.get('/myInfo/list').then(res => {
      const json = res.data;
      if (json.result) {
        this.setState({
          myInfo : json.data,
        });
        if (json.data.memberClass === 'B2B') {
          this.alertMessage('PUR000041', true, 'info');
          this.setState({
            unableEnter: true,
          });
        } else {
          this.getHasWaitingBill();
        }
      } else {
        this.props.history.push('/');
      }
    }).catch((error) => {
      console.error('error: ', error);
    });
  };

  getCommonMsgList = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getList',
      params: {
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        this.setState({
          commonMsg : response.data,
          pageComplete: true,
        });
      })
      .catch((error) => {
        console.error('getCommonMsgList: ', error);
      });
  };

  // API : 수강권 체크 + 수강신청 /purchase/saveCouponPurchase
  getAvailableCouponPurchase = () => {

    if (this.state.notLogin) {
      this.alertMessage('PUR000040', true, 'move');
      return false;
    }

    httpDefaultClient.post('/purchase/saveCouponPurchase?couponNumber='+this.state.orderCode).then(res => {
      const json = res.data;
      if (json.data) {
        this.purchaseComplete(json.data);
        this.setState({
          pageComplete: false,
        });
      } else {
        this.alertMessage(json.messageCode, true, 'result true error');
      }
    }).catch((error) => {
      console.error('error: ', error);
    });
  };

  purchaseComplete = (v) => {
    if (this.freeOrder) return null;
    this.freeOrder = true;

    window.scrollTo(0, 0);
    httpDefaultClient.post('/purchase/complete?purchaseSeq='+v).then(res => {
      const json = res.data;
      if (json.result){
        if (json.status === 200){
          const purchaseSeq = json.data.purchaseSeq;
          this.go('/OrderResult/'+purchaseSeq);
          this.orderResult(purchaseSeq);
          window.scrollTo(0, 0);
        } else {
          this.alertMessage(json.messageCode, true, 'purFail');
          this.setState({pageComplete:true});
          this.freeOrder = false;
        }
      } else {
        this.alertMessage(json.messageCode ? json.messageCode : 'PUR000013', true, 'purFail');
        this.setState({pageComplete:true});
        this.freeOrder = false;
      }
    }, (error) => {
      this.alertMessage('PUR000013', true, 'purFail');
      this.setState({pageComplete:true});
      this.freeOrder = false;
    });
  };

  orderResult = (v) => {
    httpDefaultClient.get('/purchase/' + parseInt(v),)
      .then( (response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data.purchasestatusCode === 'COMPLETE') {
            this.setState({
              purchaseInfo: data,
              purchaseSeq: v,
            });
            setTimeout(() => {
              this.setState({
                payComplete: true,
              });
            }, 500);
          } else {
            this.props.history.push('/OrderCode');
            window.scrollTo(0, 0);
          }
        }
      })
      .catch((error) => {
        console.error('error******: ', error);
      });
  };

  onChangeOrderCode = (e) => {
    const v = e.target.value.toUpperCase();
    this.setState({
      orderCode: v,
      engNameError: '',
    });
  };

  getHasWaitingBill = () => {
    httpDefaultClient.get('/purchase/hasWaitingBill').then(res => {
      const json = res.data;
      this.setState({
        hasWaitBill: json.result
      });
      if (json.result) {
        this.alertMessage('COUPON0014', true, 'info');
        this.setState({
          unableEnter: true,
        });
      }
    }, (error) => {
      console.error(error);
    });
  };

  alertMessage = (v, com, type) => {
    if (com) {
      this.setState({
        eMessageTxt: this.state.commonMsg[v],
      });
    } else {
      this.setState({
        eMessageTxt: v,
      });
    }
    this.setState({
      alertType: type,
    });

    this.openPopup('eMessageFlag');
  };
  openPopup = (s, overlap) => {
    this.setState({
      [s] : true,
      popupOverlap : overlap,
    });
    utilFn.ScrollNone(true);
  };
  closePopup = (s, type) => {
    if (type === 'move') {
      this.moveJoin();
    }

    this.setState({
      [s] : false,
    });
    utilFn.ScrollNone();

    if (s === 'eMessageFlag') {
      this.setState({
        orderCode: '',
      });
      if (!this.state.unableEnter) {
        this.codeInput.focus();
      }
    }
  };

  moveJoin = () => {
    localStorage.setItem('orderCode', this.state.orderCode);
    const updateReferrerData = referrerStore.getState().updateData;
    updateReferrerData({
      entryType: 'redirect',
      prevPage: '/OrderCode',
    });
    this.go('/Join');
  };

  go = (t) => {
    this.props.history.push(t);
  };

  render() {
    const state = this.state;

    return (
      <>
        <div className={'orderCodePage '+(this.props.match.params.page ? this.props.match.params.page : 'p1')}>
          <div className={'step1'}>
            {!state.pageComplete ?
              <Loading />
              :
              <>
                <div className={'header'}>
                  <img src={process.env.REACT_APP_IMG_URL + '/common/bi_uphone_3.svg'} alt={'민병철유폰 3.0'}
									 onClick={() => this.go('/')} />
                </div>
                <div className={'titWrap'}>
                  <img src={process.env.REACT_APP_IMG_URL + '/order/tit_orderCode_' + this.ww + '.svg'} alt='타이틀'/>
                </div>
                <div className={'conWrap'}>
                  <div className={'subTit'} dangerouslySetInnerHTML={{__html: state.commonMsg['PUR000036']}} />
                  <div className={'inputBox'}>
                    <input type='text' name='code' id='orderCode' className='require'
										   placeholder={state.commonMsg['PUR000037']}
										   onChange={this.onChangeOrderCode}
										   disabled={state.unableEnter}
										   value={state.orderCode ? state.orderCode : ''}
										   ref={(input) => this.codeInput = input}
                    />
                  </div>
                  <button className={'btn border0' + (state.orderCode ? '' : ' disable')}
                    onClick={state.orderCode ? this.getAvailableCouponPurchase : null}>
                    {state.commonMsg['PUR000038']}
                  </button>
                  {state.notLogin &&
								<div className={'info'}>
								  <span className={'mark'}>잠깐!</span> 먼저 <span className={'bold'} onClick={this.moveJoin}>가입/로그인</span> 후 진행해주세요!
								</div>
                  }
                </div>
              </>
            }
          </div>

          <div className={state.toastOff ? 'toastPopup off' : 'toastPopup'}>
            {state.toastMsg}
          </div>

          {state.eMessageFlag &&
				<LayerPopup
				  tit={state.alertType === 'info' ? state.commonMsg['LESSON0031'] : state.eMessageTxt}
				  desc1={state.alertType === 'info' ? state.eMessageTxt : null}
				  btnCnt={1}
				  btnOkCmt={state.commonMsg['0000000094']}
				  btnOkFunction={() => this.closePopup('eMessageFlag', state.alertType === 'move' ? 'move' : null)}
				/>
          }
        </div>
        {state.pageComplete &&
				<>
				  <div className={'notice'}>
				    <SupportBoard supportId={'orderCodeNotice'} />
				  </div>
				  <Footer ww={this.ww}/>
				</>
        }
      </>
    );
  }
};
