import React, { useEffect, useRef, useState } from 'react';
import Swiper from 'react-id-swiper';
import DOMPurify from 'dompurify';

import reserveTrialStore from 'store/trial/reserve/reserveTrialStore';

const TrialGuide = () => {
  const swiperRef = useRef<any>(null);
  const lessonMode = reserveTrialStore((state) => state.lessonMode as 'AUDIO' | 'VIDEO');
  const fixedMode = reserveTrialStore((state) => state.fixedMode as 'AUDIO' | 'VIDEO' | 'JUNIOR');

  const mode: 'AUDIO' | 'VIDEO' = fixedMode ? (fixedMode === 'JUNIOR' ? 'VIDEO' : fixedMode) : lessonMode;
  const [activeIndex, setActiveIndex] = useState(0);

  const guide = {
    AUDIO: [
      { number: '01', imageUrl: '/trial/reserve/voice1@2x.png', message: '<b>02-1599-6846</b><br/>번호로  전화가 와요.' },
      { number: '02', imageUrl: '/trial/reserve/voice2@2x.png', message: '<b>외국인강사</b>와<br/>반갑게 인사를 해보세요.' },
      { number: '03', imageUrl: '/trial/reserve/voice3@2x.png', message: '전문 강사님의 리드에 따라<br/><b>교재로</b> 수업이 진행돼요.' },
      { number: '04', imageUrl: '/trial/reserve/voice4@2x.png', message: '질문에 답하다 보면<br/><b>시간 가는 줄 모를 거예요.</b>' },
      { number: '05', imageUrl: '/trial/reserve/voice5@2x.png', message: '수업이 끝나면<br/><b>결과리포트</b>를 보내드려요.' }
    ],
    VIDEO: [
      { number: '01', imageUrl: '/trial/reserve/face1_web@2x.png', message: '수업 시간에 맞춰<br/><b>화상 수업 링크</b>로 입장해주세요.' },
      { number: '02', imageUrl: '/trial/reserve/face2@2x.png', message: '<b>외국인강사</b>와<br/>반갑게 인사를 해보세요.' },
      { number: '03', imageUrl: '/trial/reserve/face3@2x.png', message: '전문 강사님의 리드에 따라<br/><b>교재로</b> 수업이 진행돼요.' },
      { number: '04', imageUrl: '/trial/reserve/face4@2x.png', message: '질문에 답하다 보면<br/><b>시간 가는 줄 모를 거예요.</b>' },
      { number: '05', imageUrl: '/trial/reserve/face5@2x.png', message: '수업이 끝나면<br/><b>결과리포트</b>를 보내드려요.' }
    ],
  };

  const swiperParam = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    centeredSlides: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    on: {
      slideChange: () => {
        if (swiperRef && swiperRef.current) {
          setActiveIndex(swiperRef.current.activeIndex);
        }
      },
    },
    getSwiper: (swiper: any) => {
      swiperRef.current = swiper;
    }
  };

  const goToFirstSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(0);
    }
  };

  useEffect(() => {
    goToFirstSlide();
  }, [lessonMode]);

  return (
    <section className={'freelessonGuide'}>
      <Swiper {...swiperParam}>
        {guide[mode]?.map((item: any, index: number) => (
          <div key={index}>
            <div className={'freelessonGuide__slide'} style={{ backgroundImage: `url(${process.env.REACT_APP_IMG_URL + item.imageUrl})` }} />
          </div>
        ))}
      </Swiper>
      {guide[mode]?.map((item: any, index: number) => (activeIndex === index &&
        <p key={item.number} className={'freelessonGuide__message'} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.message) }}></p>
      ))}
      {guide[mode]?.map((item: any, index: number) => (activeIndex === index &&
        <span key={item.number} className={'freelessonGuide__number'}>{item.number}</span>
      ))}
    </section>
  );
};
export default TrialGuide;
