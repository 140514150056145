import React from 'react';
import {useParams} from 'react-router-dom';
import '../css/textBook.scss';
import Book from 'component/book/Book';

interface RouteParams {
  seq: string;
  lessonSeq: string;
}

const TextBookPage: React.FC = () => {
  const { seq, lessonSeq } = useParams<RouteParams>();
  const closeBook = () => {
    window.close();
    window.location.href = process.env.REACT_APP_MAIN_URL + '/';
  };

  return (
    <div className='book-page'>
      <Book
        memberunitSeq={seq}
        lessonSeq={lessonSeq === 'null' ? undefined : lessonSeq}
        closeBook={closeBook}
      />
    </div>
  );
};

export default TextBookPage;
