import React from 'react';
import TextBook from 'component/TextBook';
import Book from 'component/book/Book';

interface PropsType {
  unitSeq?: string;
  timechangeUrl?: string;
  closeFunction: () => void;
}

export default function TrialBookPopup({ unitSeq, timechangeUrl, closeFunction}:PropsType) {
  return (
    <div className='trial-book-popup'>
      <div className='trial-book-popup__inner'>
        <div className='trial-book-popup__scroll'>
          <Book
            unitSeq={unitSeq}
            timechangeUrl={timechangeUrl}
            lessonType={'TRIAL'}
            closeBook={closeFunction}
          />
        </div>
      </div>
      <div className={'trial-book-popup__dim'}></div>
    </div>
  );
}
