import React from 'react';
import {completeTrialStore} from 'store/trial/complete/completeTrialStore';
import TrialCompleteTitle from 'component/trial/complete/TrialCompleteTitle';
import TrialCompleteLessonBookButton from 'component/trial/complete/TrialCompleteLessonBookButton';
import TrialCompleteAppDownloadButton from 'component/trial/complete/TrialCompleteAppDownloadButton';
import TrialCompleteFooterForPc from 'component/trial/complete/TrialCompleteFooterForPc';
import TrialCompleteAppDownloadPopup from 'component/trial/complete/TrialCompleteAppDownloadPopup';
import TrialBookPopup from 'component/trial/reserve/TrialBookPopup';
import LayerPopup from 'component/LayerPopup';
import TrialCompleteInfo from 'component/trial/complete/TrialCompleteInfo';

interface Props {
  timechangeUrl: string;
}

export default function TrialCompleteTypeA({timechangeUrl}: Props) {
  const trialInfo = completeTrialStore((state) => state.trialInfo);
  const appDownloadPopupFlag = completeTrialStore((state) => state.appDownloadPopupFlag);
  const isShowTrialBook = completeTrialStore((state) => state.isShowTrialBook);
  const setIsShowTrialBook = completeTrialStore((state) => state.setIsShowTrialBook);
  const isShowChangeAlert = completeTrialStore((state) => state.isShowChangeAlert);
  const setIsShowChangeAlert = completeTrialStore((state) => state.setIsShowChangeAlert);

  const goHome = () => {
    window.location.href = '/';
  };

  return (
    <div className='trialComplete'>
      <img className={'logo'} onClick={goHome} src={process.env.REACT_APP_IMG_URL + '/course/freelesson_bi.svg'} alt={'민병철유폰'} />
      <TrialCompleteTitle/>
      <TrialCompleteInfo/>
      {(trialInfo.lessonMode === 'VIDEO' && trialInfo.juniorFlag)
        ? <TrialCompleteLessonBookButton />
        : <TrialCompleteAppDownloadButton />}
      <TrialCompleteFooterForPc />
      {appDownloadPopupFlag && <TrialCompleteAppDownloadPopup />}
      {isShowTrialBook &&
        <TrialBookPopup
          timechangeUrl={timechangeUrl}
          closeFunction={() => setIsShowTrialBook(false)}
        />}

      {isShowChangeAlert &&
        <LayerPopup
          tit={'수업시간 변경 안내'}
          desc1={`무료수업 신청 진행 중, 선택한 수업 시간이 마감되어 <b>${trialInfo.lessonTime}</b> 으로 예약해 드렸어요.`}
          desc2={'<br/>내 수업 정보 확인 및 시간 변경은 카톡으로 발송된 메시지를 확인해주세요.'}
          btnCnt={1}
          btnOkCmt={'확인'}
          btnOkFunction={() => setIsShowChangeAlert(false)}
        />
      }
    </div>
  );
}
