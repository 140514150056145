import React, {Component, createRef} from 'react';
import '../css/teacher.scss';
import ProfilePlayer from '../component/ProfilePlayer';
import Footer from '../component/Footer';
import {httpDefaultClient} from 'configs/api';

export default class teacher extends Component {
  constructor(props) {
    super(props);
    this.contentRef = createRef();
    this.state = {
      page: '',
      view: 1,
      viewSize: 8,
      nationSort: ['P', 'U', 'C', 'J'],
    };
  }

  componentDidMount() {
    httpDefaultClient.get('/web/teacher/profile').then(res => {
      const json = res.data;
      const np = this.props.match.params.nation || Object.keys(json.nation)[0];

      this.tList = json.list[np].length;

      let nl = [];
      for (let a in json.nation){
        nl.push(a);
      }
      let tl = json.list;

      this.setState({
        nationCode: nl,
        nationList: json.nation,
        teacherList: tl,
        pageNation: np,
        paging: Math.ceil(this.tList / this.state.viewSize)
      });
    });
  };

  viewPageClick = (n) => {
    const ts = this.state;
    if (ts.view === n) return null;

    this.setState({
      view: n,
    });

    this.contentRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  render() {
    const ts = this.state;

    return (
      <div className={'teacher'}>
        <div className={'header'}>
          <div className={'header__top'}>
            <div className={'header__top-wrapper'}>
              <div className={'logo'} onClick={() => window.location.href = '/'}></div>
              <ul className={'menu'}>
                {ts.nationCode && ts.nationSort.map((v, i) => {
                  if (ts.nationCode.indexOf(v) >= 0)
                    return (
                      <li key={i}
                        className={v===ts.pageNation?'active':''}
                        onClick={() => window.location.href =  `/teacher/${v}`}>
                        {ts.nationList[v]}
                      </li>
                    );
                })}
              </ul>
            </div>
          </div>
          <div className={'header__content'}>
            <h1>Study different</h1>
            <h2>민병철유폰의 우수한 강사진과 함께,<br/>꾸준히 향상되는 회화 실력을 경험하세요.<br/>당신의 일상 속 민병철유폰</h2>
            <h3 className={'pc'}>* 본 사이트에서 제공되는 강사 개인정보의 무단 전재, 유포 등을 금합니다. 무단 전재, 유포 시 법적인 처벌을 받을 수 있습니다.</h3>
            <h3 className={'mobile'}>본 사이트에서 제공되는 강사 개인정보의 무단 전재, 유포 등을 금합니다.<br/>무단 전재, 유포 시 법적인 처벌을 받을 수 있습니다.</h3>
          </div>
        </div>
        <ul className={'menu-mobile'}>
          {ts.nationCode && ts.nationSort.map((v, i) => {
            if (ts.nationCode.indexOf(v) >= 0)
              return (
                <li key={i}
                  className={v===ts.pageNation?'active':''}
                  onClick={() => window.location.href =  `/teacher/${v}`}>
                  {ts.nationList[v]}
                </li>
              );
          })}
        </ul>

        <div className={'content'} ref={this.contentRef}>
          <div className={'list__wrap'}>
            {ts.pageNation &&
							ts.teacherList[ts.pageNation].map((v, i) => {
							  if (Math.ceil((i+1) / ts.viewSize) === ts.view)
							    return (
							      <div className={'item'} key={i}>
							        <div className={'list'}>
							          <div className={'picture'} style={{backgroundImage: `url(${v.profileimageUrl})`}}>
							            {v.voiceUrl && <ProfilePlayer t={v.voiceUrl} n={i} type={'profile'+ts.pageNation} nation={ts.pageNation}/>}
							          </div>
							          <div className={'name'}>{v.name}</div>
							          <div className={'row'}><div className={'title'}>학교</div><div className={'desc'}>{v.university}</div></div>
							          <div className={'row'}><div className={'title'}>전공</div><div className={'desc'}>{v.major}</div></div>
							          <div className={'row'}><div className={'title'}>학위</div><div className={'desc'}>{v.degree}</div></div>
							          <div className={'row'}><div className={'title'}>경력</div><div className={'desc'}>{v.experience}</div></div>
							          <div className={'row'}><div className={'title'}>강의시간</div><div className={'desc'}>{`${v.workstartTime}~${v.workendTime}`}</div></div>
							        </div>
							      </div>
							    );
							})
            }
          </div>
          {ts.paging &&
							<div className={'pageWrap'}>
							  <span className={'button p '}><img className={ts.view===1 ? 'none' : ''} onClick={() => this.viewPageClick(ts.view-1)} src={process.env.REACT_APP_IMG_URL + '/web/page_b.svg'} /></span>
							  {[...Array(ts.paging)].map((n, i) => {

							    if ((ts.view <= i+1 && ts.view+4 > i) || (ts.view+4 > i && ts.paging-5 < i+1)){
							      return (
							        <span className={`num ${ts.view === i + 1 ? 'b' : ''}`} onClick={() => this.viewPageClick(i+1)} key={i}>{i + 1}</span>
							      );
							    }
							  })}
							  <span className={'button n'}><img className={ts.view===ts.paging ? 'none' : ''} onClick={() => this.viewPageClick(ts.view+1)} src={process.env.REACT_APP_IMG_URL + '/web/page_b.svg'} /></span>
							</div>
          }
        </div>

        <Footer ww={'pc'}/>
      </div>
    );
  }
}
