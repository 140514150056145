import React, { useEffect } from 'react';
import { useDaumPostcodePopup } from 'react-daum-postcode';

import { useMultipleCommonCodes } from 'queries/common/useCommonCode';
import { commonCodeConverter, numberFormat } from '../../common/utilFn';
import { saveAccessDataByAccessFunction } from '../../services/accessData/accessAnalytics';
import { Loading } from 'component/Loading';
import LayerPopup from '../../component/LayerPopup';
import Top from '../../component/top';
import SupportBoard from '../common/SupportBoard';


const ClassPayment = ({ props }) => {
  const { isFetched: isFetchedCommonCodes, data: commonCodes = {} } = useMultipleCommonCodes(['ASSIGNTEM', 'TIMEZONE', 'NATIONCODE', 'PAYTYPE', 'LESSONMODE']);
  
  let {
    discountType, couponDiscount, couponValue,
  } = props;

  const {
    clickFreeBtn,
    panelStats, purchaseInfo,
    mileageInput,
    payStats,
    commonMsg,
    memberClass, mFocus, mMileage,
    couponList, couponCode, coupon, couponError,
    confirmGoBackAlert, unableLoadPurchaseInfo,
    diffTicket, inClassProductName,
    diffNationCode, diffLessonMode,
    timezoneCode, lessonMode, totalCouponCount,
    personalOrder, personalOrderInfo,
    courseSeqValue, courseNameValue,
    bookApplyFlag, changeBookApplyFlag,
    onChangeAddress, onChangeAddressDetail,
    bookAddress, bookAddressDetail,
    inputAddressRef,
  } = props;
    
  //props
  const {
    ww,
    history,
    go
  } = props;
    
  // etc
  const {
    openPopup,
    panelState,
    changeCoupon,
    onChangeCouponInput,
    registerCoupon,
    changeMileage,
    applyMaxMileage,
    setStateFalseValue,
    setStateTrueValue,
    payState,
    processStep3,
    updateMileageRef,
    updateCouponSelect,
    updateCouponNum,
    updatePayPanel,
    closePopup,
    goBackYes,
    goToOrderPage,
  } = props;
  
  const recommendClassPayment = localStorage.getItem('classRecommendPayment');

  const totalDiscountAmount = personalOrder && personalOrderInfo.pricechangeFlag
    ? purchaseInfo.productPrice.price - personalOrderInfo.paymentAmount
    : (purchaseInfo.productPrice.mindiscountPrice + purchaseInfo.productPrice.billdiscountPrice + purchaseInfo.productPrice.weekdiscountPrice + purchaseInfo.productPrice.productdiscountPrice + mileageInput + couponValue);
  const actualPaymentAmount = personalOrder ?personalOrderInfo.paymentAmount
    :(purchaseInfo.productPrice.realPrice - mileageInput - couponValue);
  const amountPerFourShares = personalOrder ? Math.floor(4*personalOrderInfo.paymentAmount/purchaseInfo.productPrice.productWeek)
    :((purchaseInfo.productPrice.realPrice / (purchaseInfo.productPrice.productWeek / 4)) - ((mileageInput + couponValue) / (purchaseInfo.productPrice.productWeek / 4)));

  if (personalOrderInfo && personalOrderInfo.couponDiscount) couponDiscount = personalOrderInfo.couponDiscount;
  if (personalOrderInfo && personalOrderInfo.discountType) discountType = personalOrderInfo.discountType;
  if (personalOrderInfo && personalOrderInfo.couponValue) couponValue = personalOrderInfo.couponValue;

  const open = useDaumPostcodePopup();

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (!(data.addressType === 'R' || data.addressType === 'J')) {
      return;
    }

    if (data.bname !== '') {
      extraAddress += data.bname;
    }
    if (data.buildingName !== '') {
      extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
    }
    fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';

    onChangeAddress(fullAddress);
  };

  const onClickOpenPostcodePopup = () => {
    open({ onComplete: handleComplete });
  };

  useEffect(() => {
    window.addEventListener('popstate', () => {
      localStorage.removeItem('classRecommendPayment');
      
      if (recommendClassPayment === 'pc') {
        window.location.reload();
      }
      history.push('/order');
    });
  }, [window]);
  
  useEffect(() => {
    saveAccessDataByAccessFunction('pay_referrer');
  }, []);

  if (clickFreeBtn) {
    return <Loading />;
  }

  if (!isFetchedCommonCodes) return null;

  return (
    <div className={'step2'}>
      {ww === 'mo' &&
        <Top title={'결제하기'} sub={''} history={history}
          noBg={ww === 'pc'}
          notFixed={ww === 'pc'}
          setTopBannerMargin={0}
          back={memberClass == 'B2B' && personalOrder? () => go('/') : () => go('/Order')}
        />
      }
      <article className='order-payment'>
        {ww === 'pc' &&
          <header className='order-payment-header'>
            <h1 className='order-payment-header__logo'><a href={process.env.REACT_APP_MAIN_URL + '/'}><span className='blind'>민병철유폰</span></a></h1>
            <h2 className='order-payment-header__title'>결제하기</h2>
          </header>
        }
        <section className='payment-product'>
          <h2 className='payment-product__title' dangerouslySetInnerHTML={{__html: purchaseInfo.productPrice.productName.replace('분', '분<br />')}}></h2>
          <div className='payment-product__info-group'>
            {purchaseInfo.productPrice.productType !== 'C' &&
              <dl className='payment-product__info-row'>
                <dt className='payment-product__info-title'>수업가능시간</dt>
                <dd className='payment-product__info-cont'>
                  {commonCodeConverter(commonCodes?.TIMEZONE, 'note5', purchaseInfo.productPrice.timezoneCode).replace(/ /gi, '').replace(',', '&')}
                </dd>
              </dl>
            }
            <dl className='payment-product__info-row'>
              <dt className='payment-product__info-title'>수강기간</dt>
              <dd className='payment-product__info-cont'>
                {!purchaseInfo.productPrice.billFlag &&
                  <span>{purchaseInfo.productPrice.productWeek}주{purchaseInfo.productPrice.productType!=='C' && `(총 ${Number.parseInt(commonCodeConverter(commonCodes?.ASSIGNTEM, 'note3', purchaseInfo.productPrice.templateCode)) * purchaseInfo.productPrice.productWeek}회 수업)`}</span>
                }
                <span>{purchaseInfo.startlessonDate?.replace(/-/g, '.')}~{purchaseInfo.productPrice.billFlag ? '정기수강' : purchaseInfo.endlessonDate?.replace(/-/g, '.')}</span>
                {purchaseInfo.productPrice.billFlag &&
                  <span>다음 결제 예정일 : <b>{purchaseInfo.endlessonDate?.replace(/-/g, '.')}</b></span>
                }
              </dd>
            </dl>
          </div>
          <ul className='payment-product__notice'>
            { purchaseInfo.productPrice.productType==='ML' && <li>수업시간은 추천 시간으로 설정되고 언제든 변경할 수 있습니다.</li> }
            { purchaseInfo.productPrice.productType==='P' && <li>수업시간은 수강신청 후 발송되는 희망수업시간 서베이를 제출하시면 선착순 배정하여 안내 드립니다.</li> }
            <li>{purchaseInfo.productPrice.billFlag ? `결제 예정일은 ${purchaseInfo.productPrice.productWeek}주(수업 ${purchaseInfo.productPrice.lessonCount * purchaseInfo.productPrice.productWeek}회) 마다 재설정되며 수강 상태와 학사일정에 따라 조정될 수 있습니다.` : '수강 상태와 학사일정에 따라 기간이 조정될 수 있습니다.'}</li>
          </ul>
        </section>

        {/* 전략어 교재 배송 */}
        { courseSeqValue && !(purchaseInfo.productPrice.languageType==='en' || purchaseInfo.productPrice.languageType==='cn' || purchaseInfo.productPrice.languageType==='jp') &&
          <section className='payment-book'>
            <h2 className='payment-sect__title'>교재 배송</h2>
            <ul className='payment-radio-list'>
              <li className='payment-radio-list__item'>
                <input
                  type='radio'
                  id='applyBookTrue'
                  name='applyBook'
                  checked={bookApplyFlag||false}
                  onChange={() => changeBookApplyFlag(true)}
                />
                <label htmlFor={'applyBookTrue'}>신청함</label>
              </li>
              <li className='payment-radio-list__item'>
                <input
                  type='radio'
                  id='applyBookFalse'
                  name='applyBook'
                  checked={!bookApplyFlag||false}
                  onChange={() => changeBookApplyFlag(false)}
                />
                <label htmlFor={'applyBookFalse'}>신청안함</label>
              </li>
            </ul>
            <ul className='payment-product__notice'>
              <li>이미 교재를 갖고 있거나 교재가 불필요한 경우 <b>‘신청안함’</b>을 선택해주세요.</li>
            </ul>
            { bookApplyFlag &&
              <>
                <div className='payment-book__box'>
                  <h3 className='payment-book__box-title'>과정명</h3>
                  <h4 className='payment-book__box-content'>{courseNameValue}</h4>
                </div>
                <div className='payment-book__box'>
                  <h3 className='payment-book__box-title'>주소</h3>
                  <div className='nickname-box'>
                    <input type='text'
                      className='nickname-item__input magnifying-glass-padding'
                      placeholder='도로명 주소를 입력하세요.'
                      readOnly
                      ref={inputAddressRef}
                      value={bookAddress}
                      onClick={onClickOpenPostcodePopup}
                    />
                    <img
                      className='magnifying-glass'
                      src={`${process.env.REACT_APP_IMG_URL}/order/magnifying_glass.svg`} alt={'magnifying_glass'}
                      onClick={onClickOpenPostcodePopup}
                    />
                  </div>
                  <input type='text'
                    className='nickname-item__input'
                    placeholder='상세 주소를 입력하세요.'
                    value={bookAddressDetail}
                    onChange={(e) => onChangeAddressDetail(e)}
                    maxLength={100}
                  />
                </div>
              </>
            }
          </section>
        }

        {memberClass !== 'B2B' && (!personalOrder || (!personalOrderInfo.pricechangeFlag && mileageInput>0)) &&
          <section className={`payment-discount payment-sect payment-sect${panelStats[0] ? '--open' : '--hide'}`}>
            <div className='payment-sect__head'>
              <h2 className='payment-sect__title'>적립금</h2>
              <div className='right-column'>
                <strong className='payment-discount__summary'>
                  {!panelStats[0] ?
                    <><em>{numberFormat(mileageInput)}</em>원</>
                    :
                    <>총 <em>{numberFormat(mMileage)}</em>원 보유</>
                  }
                </strong>
                <button type='button' className='btn-toggle' onClick={(e) => panelState(0)}><span className='blind'>상세내역 열기/닫기</span></button>
              </div>
            </div>
            <div className='payment-sect__body'>
              <div className='payment-discount__input-row'>
                <div className={'input-column input-column--reserve'}>
                  {!personalOrder &&
                    <>
                      <input
                        type={mFocus ? 'number' : 'text'}
                        value={mFocus ? (mileageInput?.toString().replace(/^0+/, '') || 0) : numberFormat(mileageInput)}
                        ref={updateMileageRef}
                        onBlur={() => setStateFalseValue('mFocus')}
                        onFocus={() => setStateTrueValue('mFocus')}
                        onChange={(e) => changeMileage(e.target.value)}
                        readOnly={personalOrder}
                      />
                      <span className='won'>원</span>
                    </>
                  }
                  {
                    personalOrder &&
                    <>
                      <span className='readonly'>{numberFormat(mileageInput)}</span>
                      <span className='won'>원</span>
                    </>
                  }
                </div>
                <button type='button' className={'btn inputBtn ' + (personalOrder?'disabled':'')} onClick={applyMaxMileage}>최대사용</button>
              </div>
              <ul className='payment-discount__notice'>
                <li>적립금 사용은 결제 금액의 최대 40%까지 사용 가능합니다.</li>
                <li>정기 결제 시 적립금은 최대 사용으로 적용됩니다.</li>
              </ul>
            </div>
          </section>
        }

        {memberClass !== 'B2B' && !personalOrder &&
          <section className={`payment-discount payment-sect payment-sect${panelStats[1] ? '--open' : '--hide'}`}>
            <div className='payment-sect__head'>
              <h2 className='payment-sect__title'>할인쿠폰</h2>
              <div className='right-column'>
                <strong className='payment-discount__summary'>
                  {(!panelStats[1] && couponDiscount) ?
                    <>
                      <em>
                        {numberFormat(discountType === 'RATE' ? couponDiscount : couponValue)}{discountType === 'RATE' ? '%' : '원'}
                      </em>할인
                      {discountType === 'RATE' && <><span> ({numberFormat(couponValue)}원)</span></>}
                    </>
                    :
                    <>총 <em>{couponList.length - 1}</em>개 보유</>
                  }
                </strong>
                <button type='button' className='btn-toggle' onClick={(e) => panelState(1)}>
                  <span className='blind'>상세내역 열기/닫기</span>
                </button>
              </div>
            </div>
            <div className='payment-sect__body'>
              <div className='payment-discount__select'>
                <select className={couponCode !== 'userInput' ? 'adj' : ''}
                  value={couponCode}
                  ref={updateCouponSelect}
                  onChange={(e) => changeCoupon(e.target.value)}>
                  {couponList.map((v, n) => {
                    return (
                      <option value={v.couponNumber} key={n} disabled={!v.purchaseusableFlag}>
                        {!v.purchaseusableFlag && '[적용불가]'}{v.couponNumber!=='userInput'&&`[${numberFormat(v.couponDiscount)}${v.discountType === 'RATE' ? '%' : '원'}] `}{v.couponName}
                      </option>
                    );
                  })}
                </select>
                {couponCode === 'userInput' &&
                          <div className='payment-discount__input-row'>
                            <div className={'input-column'}>
                              <input
                                type='text'
                                name='couponNum'
                                placeholder='할인쿠폰코드'
                                value={coupon}
                                ref={updateCouponNum}
                                onChange={onChangeCouponInput}
                              />
                            </div>
                            <div className='btn inputBtn' onClick={registerCoupon}>등록</div>
                          </div>
                }
                {couponError && <p className='errorM'>{couponError}</p>}
              </div>
              <ul className='payment-discount__notice'>
                <li>할인쿠폰은 적립금이 적용된 결제금액에 적용됩니다.</li>
                <li>할인쿠폰이 금액인 경우, 일부 금액 사용은 불가하며 쿠폰금액이 결제금액보다 크면 남은 금액은 반환되지 않습니다.</li>
                <li>할인쿠폰은 중복 사용이 불가합니다.</li>
              </ul>
            </div>
          </section>
        }

        {memberClass !== 'B2B' && personalOrder && !personalOrderInfo.pricechangeFlag && personalOrderInfo.membercouponSeq &&
          <section className={`payment-discount payment-sect payment-sect${panelStats[1] ? '--open' : '--hide'}`}>
            <div className='payment-sect__head'>
              <h2 className='payment-sect__title'>할인쿠폰</h2>
              <div className='right-column'>
                <strong className='payment-discount__summary'>
                  <em>{numberFormat(discountType === 'RATE' ? couponDiscount : couponValue)}{discountType === 'RATE' ? '%' : '원'}</em>할인
                  {discountType === 'RATE' && <><span> ({numberFormat(couponValue)}원)</span></>}
                </strong>
                <button type='button' className='btn-toggle' onClick={(e) => panelState(1)}>
                  <span className='blind'>상세내역 열기/닫기</span>
                </button>
              </div>
            </div>
            <div className='payment-sect__body'>
              <div className='payment-discount__select'>
                <div className='box'>
                          [{couponDiscount}{discountType === 'RATE' ? '%' : '원'}] {personalOrderInfo.couponName}
                </div>
              </div>
              <ul className='payment-discount__notice'>
                <li>할인쿠폰은 적립금이 적용된 결제금액에 적용됩니다.</li>
                <li>할인쿠폰이 금액인 경우, 일부 금액 사용은 불가하며 쿠폰금액이 결제금액보다 크면 남은 금액은 반환되지 않습니다.</li>
                <li>할인쿠폰은 중복 사용이 불가합니다.</li>
              </ul>
            </div>
          </section>
        }

        {memberClass !== 'B2B' &&
          <section className={`payment-price payment-sect payment-sect${panelStats[2] ? '--open' : '--hide'}`}>
            <div className='payment-sect__head'>
              <h2 className='payment-sect__title'>결제 금액</h2>
              <div className='right-column'>
                {!panelStats[2] &&
                          <ul className='payment-price__summary'>
                            <li className='month-row'><span className='month-row__weeks'>4주</span><span className='month-row__price'>{numberFormat(amountPerFourShares)}</span>원</li>
                            <li className='total-row'>총 {numberFormat(actualPaymentAmount)}원</li>
                          </ul>
                }
                <button type='button' className='btn-toggle' onClick={(e) => panelState(2)}>
                  <span className='blind'>상세내역 열기/닫기</span>
                </button>
              </div>
            </div>
            <div className='payment-sect__body'>
              <dl className='payment-price__row-item'>
                <dt>주문금액</dt>
                <dd>{numberFormat(purchaseInfo.productPrice.price)} 원</dd>
              </dl>
              {!personalOrderInfo.pricechangeFlag &&
                  <>
                    {totalDiscountAmount > 0 &&
                      <dl className='payment-price__row-item'>
                        <dt>총 할인금액</dt>
                        <dd><span>-{numberFormat(totalDiscountAmount)}</span> 원</dd>
                      </dl>
                    }
                    {purchaseInfo.productPrice.mindiscountRate !== 0 &&
                      <dl className='payment-price__row-item payment-price__row-item--disInfo'>
                        <dt>{purchaseInfo.productPrice.productMinute}분 할인({purchaseInfo.productPrice.mindiscountRate}%)</dt>
                        <dd>{numberFormat(purchaseInfo.productPrice.mindiscountPrice)} 원</dd>
                      </dl>
                    }
                    {purchaseInfo.productPrice.billdiscountPrice !== 0 &&
                      <dl className='payment-price__row-item payment-price__row-item--disInfo'>
                        <dt>정기 할인({purchaseInfo.productPrice.billdiscountRate}%)</dt>
                        <dd>{numberFormat(purchaseInfo.productPrice.billdiscountPrice)} 원</dd>
                      </dl>
                    }
                    {purchaseInfo.productPrice.weekdiscountRate !== 0 &&
                      <dl className='payment-price__row-item payment-price__row-item--disInfo'>
                        <dt>장기 할인({purchaseInfo.productPrice.weekdiscountRate}%)</dt>
                        <dd>{numberFormat(purchaseInfo.productPrice.weekdiscountPrice)} 원</dd>
                      </dl>
                    }
                    {purchaseInfo.productPrice.productdiscountRate !== 0 &&
                      <dl className='payment-price__row-item payment-price__row-item--disInfo'>
                        <dt>상품 할인({purchaseInfo.productPrice.productdiscountRate}%)</dt>
                        <dd>{numberFormat(purchaseInfo.productPrice.productdiscountPrice)} 원</dd>
                      </dl>
                    }
                    {mileageInput !== 0 &&
                      <dl className='payment-price__row-item payment-price__row-item--disInfo'>
                        <dt>적립금 사용</dt>
                        <dd>{numberFormat(mileageInput)} 원</dd>
                      </dl>
                    }
                    {couponValue > 0 &&
                      <dl className='payment-price__row-item payment-price__row-item--disInfo'>
                        <dt>할인쿠폰{discountType === 'RATE' ? `(${couponDiscount}%)` : null}</dt>
                        <dd>{numberFormat(couponValue)} 원</dd>
                      </dl>
                    }
                  </>}
              {personalOrderInfo.pricechangeFlag &&
                <>
                  {totalDiscountAmount > 0 &&
                        <dl className='payment-price__row-item'>
                          <dt>총 할인금액</dt>
                          <dd><span>-{numberFormat(totalDiscountAmount)}</span> 원</dd>
                        </dl>
                  }
                  {totalDiscountAmount>0 &&
                        <dl className='payment-price__row-item payment-price__row-item--disInfo'>
                          <dt>컨설턴트 할인</dt>
                          <dd>{numberFormat(totalDiscountAmount)} 원</dd>
                        </dl>
                  }
                </>
              }
              <dl className='payment-price__total'>
                <dt>최종 결제금액</dt>
                <dd>
                  <ul className='payment-price__summary'>
                    <li className='month-row'><span className='month-row__weeks'>4주</span><span className='month-row__price'>{numberFormat(amountPerFourShares)}</span>원</li>
                    {!(purchaseInfo.productPrice.billFlag && purchaseInfo.productPrice.productWeek === 4) &&
                      <li className='total-row'>총 {numberFormat(actualPaymentAmount)}원</li>
                    }
                  </ul>
                </dd>
              </dl>
            </div>
          </section>
        }

        {!(!purchaseInfo.productPrice.billFlag && (purchaseInfo.productPrice.realPrice - mileageInput - couponValue) === 0) &&      // 정기상품 아닐때 0원이면 결제수단 단락 숨기기
          <section className={`payment-method payment-sect payment-sect${panelStats[3] ? '--open' : '--hide'}`}>
            <div className='payment-sect__head' ref={updatePayPanel}>
              <h2 className='payment-sect__title'>결제수단</h2>
              <div className='right-column'>
                {!panelStats[3] &&
                  <strong className='payment-discount__summary'>{commonCodeConverter(commonCodes?.PAYTYPE, 'note1', payStats)}</strong>
                }
                <button type='button' className='btn-toggle' onClick={(e) => panelState(3)}>
                  <span className='blind'>상세내역 열기/닫기</span>
                </button>
              </div>
            </div>
            <div className='payment-sect__body'>
              {purchaseInfo.productPrice.billFlag && (<p>이후 단계에서 카드 또는 계좌 정보를 등록해주세요. </p>)}
              { purchaseInfo.productPrice.billFlag
                ?
                <ul className='payment-method__list'>
                  {actualPaymentAmount > 0 && memberClass !== 'B2B' &&
                    <li className='payment-method__item payment-method__item--n-pay'>
                      <button
                        type='button'
                        className={'payment-method__btn ' + (payStats === 'N_B_CARD' ? 'payment-method__btn--active' : '')}
                        onClick={() => payState('N_B_CARD')}
                      >
                        <img src={process.env.REACT_APP_IMG_URL + '/order/ico_naverPay.svg'} alt={'NPAY'}/>
                        &nbsp;정기 카드/계좌
                      </button>
                    </li>
                  }
                  <li className='payment-method__item payment-method__item--credit'>
                    <button
                      type='button'
                      className={'payment-method__btn ' + (payStats === 'B_CARD' ? 'payment-method__btn--active' : '')}
                      onClick={purchaseInfo.productPrice.billFlag ? () => payState('B_CARD') : () => payState('CARD')}
                    >
                      정기 신용/<br />체크카드
                    </button>
                  </li>
                </ul>
                :
                <ul className='payment-method__list'>
                  <li className='payment-method__item payment-method__item--credit'>
                    <button
                      type='button'
                      className={'payment-method__btn ' + (payStats === 'B_CARD' || payStats === 'CARD' ? 'payment-method__btn--active' : '')}
                      onClick={() => payState('CARD')}
                    >
                      신용/<br />체크카드
                    </button>
                  </li>
                  <li className='payment-method__item payment-method__item--t-pay'>
                    <button
                      type='button'
                      className={'payment-method__btn ' + (payStats === 'T_PAY' ? 'payment-method__btn--active' : '')}
                      onClick={() => payState('T_PAY')}
                    >
                      <img src={process.env.REACT_APP_IMG_URL + '/order/ico_tossPay.svg'} alt={'TossPay'}/>
                          토스페이
                    </button>
                  </li>
                  {actualPaymentAmount > 0 && memberClass !== 'B2B' &&
                  <li className='payment-method__item payment-method__item--n-pay'>
                    <button
                      type='button'
                      className={'payment-method__btn ' + (payStats === 'N_CARD' ? 'payment-method__btn--active' : '')}
                      onClick={() => payState('N_CARD')}
                    >
                      <img src={process.env.REACT_APP_IMG_URL + '/order/ico_naverPay.svg'} alt={'NPAY'}/>
                    </button>
                  </li>
                  }
                  {actualPaymentAmount >= 200 &&
                  <li className='payment-method__item'>
                    <button
                      type='button'
                      className={'payment-method__btn ' + (payStats === 'ACCOUNT' ? 'payment-method__btn--active' : '')}
                      onClick={() => payState('ACCOUNT')}
                    >
                          실시간 계좌이체
                    </button>
                  </li>
                  }
                </ul>
              }

              {(!payStats || payStats === 'CARD') && !purchaseInfo.productPrice.billFlag &&
                <button type='button' className='btn-popup' onClick={() => openPopup('cardInfoPopup')}>최대 12개월 <span>무이자 할부</span> 안내</button>
              }
              {(!!payStats && payStats.includes('N_')) &&
                <button type='button' className='btn-popup' onClick={() => openPopup('nPayGuide')}>네이버페이 결제 안내</button>
              }
            </div>
          </section>
        }

        {purchaseInfo.productPrice.productType !== 'C' &&
          <section className={`payment-caution payment-sect payment-sect${panelStats[4] ? '--open' : '--hide'}`}>
            <div className='payment-sect__head'>
              <h2 className='payment-caution__title'>{commonMsg['LESSON0079']}</h2>
              <div className='right-column'>
                <button type='button' className='btn-toggle' onClick={(e) => panelState(4)}>
                  <span className='blind'>상세내역 열기/닫기</span>
                </button>
              </div>
            </div>
            <div className='payment-sect__body'>
              <SupportBoard supportId={memberClass === 'B2B' ? 'cautionsB2B' 
                : (purchaseInfo.productPrice.billFlag ? (purchaseInfo.productPrice.lessonMode === 'VIDEO' ? 'cautionsBillFC' : 'cautionsBill') 
                  : (purchaseInfo.productPrice.lessonMode === 'VIDEO' ? 'cautionsFC' : 'cautionsB2C'))} />
            </div>
          </section>
        }
        <div className='payment-foot payment-foot--active'
          onClick={processStep3}>
          {memberClass === 'B2B' ?
            <button type='button' className='btn'>{purchaseInfo.productPrice.realPrice === 0 ? '수강신청하기' : <>총 <strong>{numberFormat(purchaseInfo.productPrice.realPrice)}</strong>원 결제하기</>}</button>
            :
            <button type='button' className='btn'>{purchaseInfo.productPrice.productWeek}주 총 <strong>{numberFormat(actualPaymentAmount)}</strong>원 {purchaseInfo.productPrice.billFlag && '정기'} 결제하기</button>
          }
        </div>
      </article>

      {confirmGoBackAlert &&
        <LayerPopup
          tit={'결제를 취소하겠습니까?'}
          btnCnt={2}
          btnNoCmt={'취소'}
          btnNoFunction={() => closePopup('confirmGoBackAlert')}
          btnYesCmt={'확인'}
          btnYesFunction={goBackYes}
        />
      }

      {unableLoadPurchaseInfo &&
        <LayerPopup
          tit={commonMsg['LESSON0031']}
          desc1={commonMsg['PUR000021']}
          btnCnt={1}
          btnOkCmt={commonMsg['0000000094']}
          btnOkFunction={goToOrderPage}
        />
      }

      {diffTicket &&
        <LayerPopup
          tit={commonMsg['PUR000028']}		// 현재 수업중인 수강권과 다릅니다.<br />계속 진행하시겠습니까?
          desc1={commonMsg['PUR000029']}	// 현재 수강권
          bottomRed={inClassProductName}
          ticketReplaceDescription={`∙${commonMsg['PUR000151']}`}
          chkNotOpenDays={1}
          chkNotOpenId={'DiffTicket'}
          chkNotOpenCmt={commonMsg['0000000294']}	// 오늘 하루 보지 않기
          btnCnt={2}
          btnNoCmt={commonMsg['0000000095']}		// 아니요
          btnNoFunction={goToOrderPage}
          btnYesCmt={commonMsg['PUR000030']}		// 네, 계속할게요
          btnYesFunction={() => closePopup('diffTicket')}
        />
      }

      {diffNationCode &&
        <LayerPopup
          tit={commonMsg['PUR000179']}
          chkYN={'Y'}
          chkCmt={totalCouponCount > 0 ? commonMsg['PUR000100'].replace('$1', totalCouponCount) : commonMsg['PUR000101']}
          btnCnt={2}
          btnNoCmt={commonMsg['0000000095']}
          btnNoFunction={goToOrderPage}
          btnYesCmt={commonMsg['PUR000030']}
          btnYesFunction={() => closePopup('diffNationCode')}
        />
      }

      {diffLessonMode &&
        <LayerPopup
          tit={commonMsg['PUR000099'].replace('$1', commonCodeConverter(commonCodes?.LESSONMODE, 'note1', lessonMode)).replace('$2', commonCodeConverter(commonCodes?.LESSONMODE, 'note1', purchaseInfo.productPrice.lessonMode))}
          chkYN={'Y'}
          chkCmt={totalCouponCount > 0 ? commonMsg['PUR000100'].replace('$1', totalCouponCount) : commonMsg['PUR000101']}
          btnCnt={2}
          btnNoCmt={commonMsg['0000000095']}
          btnNoFunction={goToOrderPage}
          btnYesCmt={commonMsg['PUR000030']}
          btnYesFunction={() => closePopup('diffLessonMode')}
        />
      }
    </div>
  );    
};

export default ClassPayment;
