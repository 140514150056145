import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import {httpDefaultClient} from 'configs/api';

export default class Release extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'p1',
      clickAble : true //연속클릭 방지
    };
  };

  eMessage = (t, v) => {
    axios({
      method:'get',
      url:process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (response.data.result){
          if (t === 'emailError'){
            this.setState({emailError: response.data.message});
          } else {
            alert(response.data.message);
            return null;
          }
        } else {
          alert(v);
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  onClickRelease = () => {
    const s = this.props.match.params.seq;
    if (!this.state.clickAble){
      alert('휴면 해제가 진행 중입니다. 잠시만 기다려 주세요.');
      return false;
    }
    this.setState({clickAble : false});
    axios({
      method:'POST',
      url:process.env.REACT_APP_API_URL + '/sign/release',
      params: {
        'memberSeq': s
      }
    })
      .then((response) => {
        if (response.status === 200){
          const c = this.props.location.state.detail;
          if (c){
            httpDefaultClient.post('/sign/logIn', c)
              .then(res => {
                const json = res;
                if (json.result){
                  debugger;
                  if (json.status === 200){
                    this.eMessage('alert', response.data.message);
                    setTimeout(() => {
                      this.props.history.push('/');
                    }, 10);

                  } else if (json.message === 'SIGN000010'){
                    //다시 휴면상태로 빠질 일 없음
                    this.setState({clickAble : true});
                  } else if (json.message === 'SIGN000009'){
                    //다른 기기 로그인 체크는 이전에 진행하고 넘어옴
                    this.setState({clickAble : true});
                  } else {
                    this.eMessage('alert', json.message);
                    return null;
                  }
                }
              })
              .catch((error) => console.error('error: ', error));
          } else {
            alert('다시 로그인을 진행해주세요.');
            this.props.history.push('/Login');
          }
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  onClickHome = () => {
    this.props.history.push('/');
  };

  render() {
    return (
      <div className={'release '+(this.props.match.params.page ? this.props.match.params.page : 'p1')} >
        <Link className={'logoLink'} to='/'><img src={process.env.REACT_APP_IMG_URL + '/course/freelesson_bi.svg'} alt={'view off'} /></Link>
        <div className='step1'>
          <h1>오랜만에 오셨군요!<br/>휴면 해제에 동의하십니까?</h1>
          <h2>장기간 이용 내역이 없어 관련 법령에 따라 <span>휴면 상태</span>입니다.<br/>휴면 상태를 해제하고 기존 계정을 활성화합니다.</h2>
          <button className='btn' onClick={this.onClickRelease}>휴면 해제하고 유폰 이용하기</button>
          <div className={'btnLine'} onClick={this.onClickHome}>휴면 유지하고 로그아웃</div>
        </div>
      </div>
    );
  }
}
