import { StateCreator } from 'zustand';

export interface AppDownloadPopupSliceT {
  appDownloadLink: string;
  isOpenAppDownloadPopup: boolean;
  setAppDownloadLink: (link: string) => void;
  openAppDownloadPopup: () => void;
  closeAppDownloadPopup: () => void;
}

const initialState = {
  appDownloadLink: '',
  isOpenAppDownloadPopup: false,
};

const appDownloadPopupSlice: StateCreator<
  AppDownloadPopupSliceT,
  any[],
  any[],
  AppDownloadPopupSliceT
> = (set) => ({
  ...initialState,
  setAppDownloadLink: (link: string) => {
    set({ appDownloadLink: link });
  },
  openAppDownloadPopup: () => {
    set({ isOpenAppDownloadPopup: true });
  },
  closeAppDownloadPopup: () => {
    set({ isOpenAppDownloadPopup: false });
  },
});

export default appDownloadPopupSlice;
