import React, { Component, createRef } from 'react';
import '../css/policy.scss';
import PolicyInfo from '../component/PolicyInfo';
import PolicyUse from '../component/PolicyUse';

export default class Policy extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  };

  componentDidMount() { // 처음 랜더링 될때
  }

  render() {
    return (
      <div className={'policyPage'}>
        {this.props.match.params.type === 'Privacy' ?
          <>
            <div className='title'>개인정보처리방침</div>
            <div className='content'>
              <PolicyInfo />
            </div>
          </>
          :
          <>
            <div className='title'>이용약관</div>
            <div className={'content'}>
              <PolicyUse />
            </div>
          </>
        }
      </div>
    );
  }
}
