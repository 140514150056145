import React, { useState } from 'react';

interface TemplateCodeList {
  groupCode: string;
  detailCode: string;
  languageCode: string;
  detailName: string;
  uppergroupCode: string;
  upperdetailCode: string;
  sort: number;
  note1: string;
  note2: string;
  note3: string;
  note4: string;
  note5?: string;
  note6?: string;
  useFlag: boolean;
}

interface Props {
  selectFilterValue:(type:string, index:number, arrayLength:number, selectedValue:string) => void;
  templateCodeList: TemplateCodeList[];
}

export default function TemplateSelect({ selectFilterValue, templateCodeList }: Props) {
  const [currentTemplateCode, setCurrentTemplateCode] = useState('');
  
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTemplateCode = event.target.value;
    setCurrentTemplateCode(selectedTemplateCode);
    
    const targetIndex = templateCodeList.findIndex((item:any) => item.detailCode === selectedTemplateCode);
    
    selectFilterValue('templateCode',
      targetIndex,
      templateCodeList.length,
      selectedTemplateCode
    );
  };
  
  return (
    <select
      name='templateCode'
      id='templateCode'
      className='order-tab-area__select'
      value={currentTemplateCode}
      onChange={handleChange}
    >
      {templateCodeList.map((tg:any, i:number) => {
        return (
          <option value={tg.detailCode} key={i}>{tg.detailName}</option>
        );
      })}
    </select>
  );
}
