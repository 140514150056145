import React, { Component, createRef } from 'react';
import { Link } from 'react-router-dom';
import AppleSignin from 'react-apple-signin-auth';
import axios from 'axios';
import moment from 'moment';
import lottie from 'lottie-web';

import KakaoLoginButton from '../component/kakao/KakaoLoginButton';
import PolicyInfo from '../component/PolicyInfo';
import PolicyUse from '../component/PolicyUse';
import PolicyReceiveMktInfo from '../component/PolicyReceiveMktInfo';
import LayerPopup from '../component/LayerPopup';
import ReactMeta from '../component/ReactMeta';

import { mobileChk } from '../common/utilFn';
import * as utilFn from '../common/utilFn';

import '../css/join.scss';
import referrerStore from 'store/referrer/referrerStore';
import {httpDefaultClient} from 'configs/api';

// 간편 로그인 관련 설정 변수
const kakao_client_id = process.env.REACT_APP_KAKAO_CLIENT_ID;
const naver_client_id = process.env.REACT_APP_NAVER_CLIENT_ID;
const apple_client_id = 'com.uphone3.app'; // serviceID, AppID 아님
const naver_redirectURI = encodeURI(window.location.protocol + '//' + window.location.host + '/Join/p2/naver');
const apple_redirectURI = encodeURI('https://' + (window.location.hostname) + '/Join/p2/apple');

let pwCompleted = false;
let pwChkCompleted = false;

export default class Join extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commonMsg: {},
      page: 'p1',
      pageT: '',
      joinType: 'email',
      chkAll: false,
      chkState: [false, false, false, false],
      juniorFlag: false,
      birthday: '',
      chkNext: false,
      email: '',
      emailError: '',
      password: '',
      pwError: '',
      pwView: 'password',
      passwordCheck: '',
      pwChkError: '',
      pwChkView: 'password',
      name: '',
      nameError: '',
      cellPhone: '',
      cellPhoneError: '',
      birthdayError: '',
      companyCode: '',
      companyAuth: false,
      // openCompany:false,
      CCodeError: '',
      pwChk: '',
      pwChk1: '',
      pwChk2: '',
      pwChk3: '',
      pwChk4: '',
      pop: '',

      authInput: '',//사용자 인증코드 입력 값
      authBtn: false,//인증코드 버튼 활성화
      authError: '',
      authTimeError: false,
      authCheckFlag: false, // 인증번호 확인 (맞는지 여부)
      authLayerPopup: '', // 인증번호 관련 오류 팝업 메시지
      authInfoView: false,//인증관련 안내
      disabled: false,//
      authState: false,//인증완료 상태
      authStart: '',
      authEnd: '',
      socialType: '', // 간편로그인 타입 (NAVER/APPLE/KAKAO)
      privateKey: '', // 간편로그인 시 받는 회원 고유 값
      kakaoMktFlag: false, // 카카오 가입 - 할인/이벤트 메일 sms 선택 약관 동의여부
      isQuickKakaoSignUp: false,

      CCInput: false,
      CCbtn: true,

      employeeNoInput: false,
      employeeNoBtn: true,
      employeeDivisionError: '',
      extraInfo: '',
      extraError: '',
      extraInfo2: '',
      extraError2: '',

      emailDomain: ['naver.com', 'gmail.com', 'daum.net', 'hanmail.net', 'nate.com'],
      domainFilter: [],
      countMin: '10',
      countSec: '00',
      time: 600,
      active: '',
      isToggleOn: true,
      joinNext: false,
      passwordError: '',
      boxError: '',

      fstChk: 'on',
      btnHold: false, //등록 누른 후 api 호출 리턴값 받을 때 다시 누르지 못하도록 설정

      layerPopupMessage: '',
    };
  };

  //비밀번호 확인
  inputPwChkRef = createRef();
  //이름
  inputNameRef = createRef();
  //휴대전화번호
  inputCellPhoneRef = createRef();
  //휴대전화 인증번호
  inputAuthRef = createRef();
  // 자녀 생년월일
  inputBirthRef = createRef();
  //이용약관 box
  contentView = createRef();

  componentWillMount() {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.getCommonMsgList();
  }

  componentDidMount() { // 처음 랜더링 될때
    window.addEventListener('resize', this.changeWw, true);

    // 기기별 스크롤 없이 전체 화면 css
    function setScreenSize() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    setScreenSize();
    window.addEventListener('resize', setScreenSize);
    
    // 새로고침시에 joinType이 초기화 됨을 방지
    if (this.props.match.params.type) {
      this.setState({ joinType: this.props.match.params.type });
    }

    // 로그인 페이지에서 미가입을 확인후 넘겨줬을경우 state setting
    if (this.props.location.state) {
      this.setState({
        email: this.props.location.state.detail.email,
        name: this.props.location.state.detail.name,
        privateKey: this.props.location.state.detail.privateKey,
        socialType: this.props.location.state.detail.socialType,
        plusfriendsFlag: this.props.location.state.detail.plusfriendsFlag
      });
    }
    // 소셜 로그인
    this.naverLoginInit();

    // 이전 페이지
    const {setReferrer} = referrerStore.getState();
    setReferrer(document.referrer);

    utilFn.sendFacebook('회원가입');
    
    const lottieBgElement = document.getElementById('lottie-signup-bg');
    const lottieBg = lottie.loadAnimation({
      container: lottieBgElement,
      renderer: 'svg',
      loop: true, // 반복 여부
      autoplay: false, // 자동 재생 비활성화
      path: process.env.REACT_APP_IMG_URL + '/common/json/login_signup_bg.json', // Lottie 애니메이션 파일 경로
    });
    
    const lottieObjElement = document.getElementById('lottie-signup-top');
    const lottieObj = lottie.loadAnimation({
      container: lottieObjElement,
      renderer: 'svg',
      loop: true, // 반복 여부
      autoplay: false, // 자동 재생 비활성화
      path: process.env.REACT_APP_IMG_URL + '/common/json/login_signup_top.json', // Lottie 애니메이션 파일 경로
    });
    
    setTimeout(() => {
      lottieObj.play();
      lottieBg.play();
    }, 500);
  }
  
  
  componentWillUnmount() { // 컴포넌트 제거 후
    window.removeEventListener('resize', this.changeWw);
    lottie.destroy();
  }

  changeWw = () => {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.setState({
      ww: this.ww
    });
  };

  naverLoginInit = () => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: naver_client_id,
      callbackUrl: naver_redirectURI,
      isPopup: false, /* 팝업을 통한 연동처리 여부 */
      loginButton: {/*color: "white", type: 3, height: 60*/ },
      callbackHandle: true
    });

    /* 설정정보를 초기화하고 연동을 준비 */
    naverLogin.init();
    document.querySelector('#naverIdLogin_loginButton').removeAttribute('href'); // 네아로 자동으로 버튼 생성시 href를 삭제하지 않으면 #으로 이동되는 현상때문에 속성 지움

    if (this.props.match.params.type === 'naver') {
      window.addEventListener('load', () => {
        naverLogin.getLoginStatus((status) => {
          if (status) {
            this.setState({
              email: naverLogin.user.email,
              name: naverLogin.user.name,
              privateKey: naverLogin.user.id,
              socialType: 'NAVER'
            });

            this.chkEmail(naverLogin.user.email);
            this.props.history.push('/Join/p2/naver');
          } else {
            this.props.history.push('/Join');
          }
        });
      });
    }
  };

  // 카카오 가입
  kakaoLoginInit = (kakaoLogin) => {
    const { email, name, phone_number } = kakaoLogin.profile.kakao_account;
    const { kakaoMktFlag } = kakaoLogin.response;

    if (email) {
      this.setState({
        email,
        id: email,
        name,
        privateKey: kakaoLogin.profile.id,
        socialType: 'KAKAO',
        plusfriendsFlag: kakaoLogin.response.scope.includes('plusfriends'),
        cellPhone: phone_number?.replace(/^(\+?)(82)(\s?)(1(0|1|6|7|8|9))(-?)(\d{3,4})(-?)(\d{4})$/g, '0$4$7$9'),
        kakaoCellphone: phone_number?.replace(/^(\+?)(82)(\s?)(1(0|1|6|7|8|9))(-?)(\d{3,4})(-?)(\d{4})$/g, '0$4$7$9'),
        kakaoMktFlag: !!kakaoMktFlag,
      }, () => { this.chkEmail(email); });
    } else {
      alert('다시 시도해 주세요.');
    }
  };

  onErrorKakaoLogin = (error) => {
    console.error('error: ', error);
  };

  // 애플 로그인 라이브러리 사용하기 위한 옵션 값
  appleAuthOptions = {
    clientId: apple_client_id,
    scope: 'email name',
    redirectURI: apple_redirectURI,
    state: new Date().getTime().toString(), //  CSRF공격을 완화하기 위한 OAuth 파라미터, 추정 할 수 없는 랜덤값
    nonce: 'nonce',
    usePopup: true,
  };

  convertJwt = (jwtcode) => {
    const base64cvt = (jwtcode.split('.')[1]).replace(/-/g, '+').replace(/_/g, '/');
    const json = decodeURIComponent(atob(base64cvt).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return json;
  };

  onSuccessAppleLogin = (response) => {
    const idToken = JSON.parse(this.convertJwt(response.authorization.id_token));

    this.setState({
      email: idToken.email,
      privateKey: idToken.sub,
      socialType: 'APPLE'
    }, () => { this.chkEmail(idToken.email); });
  };

  onErrorAppleLogin = (error) => {
    console.error('error: ', error);
  };

  listChk = (n) => {
    const t = this.state.chkState;
    t[n] = !t[n];
    this.setState({ chkState: t });
    this.chkChk(t);
  };
  listChkAll = () => {
    let c;
    if (this.state.chkAll) {
      c = [false, false, false, false];
    } else {
      c = [true, true, true, true];
    }

    this.setState({ chkState: c });
    this.chkChk(c);
  };
  chkChk = (c) => {
    let a, b;

    c[0] && c[1] && c[2] ? a = true : a = false;
    c[0] && c[1] && c[2] && c[3] ? b = true : b = false;

    this.setState({
      chkNext: a,
      chkAll: b
    });
  };

  uphone2login = () => {
    if (mobileChk() === 'MOBILE') {
      window.location.href = 'https://m.uphone.co.kr/?Login';
    } else {
      window.location.href = 'https://www.uphone.co.kr/?Login';
    }
  };

  next1 = (type) => {
    this.setState({ joinType: type, pop: '' });
    this.props.history.push(`/join/p2/${type ? type : ''}`);
  };

  inputPwRef = createRef();

  // Coustom Hook 이전
  onChangeName = (e) => {
    this.setState({ authCheckFlag: false });
    if (e.target.value.length <= e.target.maxLength) {
      const nameRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|ᆞ|ᆢ|ㆍ|ᆢ|ᄀᆞ|ᄂᆞ|ᄃᆞ|ᄅᆞ|ᄆᆞ|ᄇᆞ|ᄉᆞ|ᄋᆞ|ᄌᆞ|ᄎᆞ|ᄏᆞ|ᄐᆞ|ᄑᆞ|ᄒᆞ]/;
      let nameError = (nameRegex.test(e.target.value)) ? '' : '이름에 한글만 사용할 수 있습니다.';

      let t = e.target.value.replace(/([^가-힣ㄱ-ㅎㅏ-ㅣᆞ|ᆢ|ㆍ|ᆢ|ᄀᆞ|ᄂᆞ|ᄃᆞ|ᄅᆞ|ᄆᆞ|ᄇᆞ|ᄉᆞ|ᄋᆞ|ᄌᆞ|ᄎᆞ|ᄏᆞ|ᄐᆞ|ᄑᆞ|ᄒᆞ\x20])/g, '').substring(0, 6);
      this.setState({
        name: t,
        nameError: nameError,
        authInfoView: false,
        authError: '',
        authInput: '',
        authState: false,

        employeeNo: '',
        employeeNoInput: '',
        employeeNoError: '',
      });
      if (this.state.authInput !== '' && this.state.authError === '' && this.state.authInput.length === 6) {
        this.setState({ authInput: '', layerPopupMessage: '정보가 변경되어 휴대전화번호 재인증이 필요합니다' });
      }
      this.defaultData(t);
    }
  };
  onBlurName = (e) => {
    const t = e.target.value;
    const nameRex = /^[가-힣]+$/;
    const a = nameRex.test(t);
    let v;

    if (t === '') {
      v = '필수 정보입니다.';
    } else if (t.length < 2 || t.length > 6) {
      v = '이름은 한글 2~6자까지 사용 가능합니다.';
    } else {
      a ? v = '' : v = '이름은 한글 2~6자까지 사용 가능합니다.';
    }
    this.setState({ nameError: v });
    this.state.fstChk && this.setState({ fstChk: '' });

    /**
     * 이름 값이 잘못 들어갔을 경우, 즉시 수정합니다.
     * 참조이슈: RR1538
     */
    const renaming = t.replace(/([^가-힣ㄱ-ㅎㅏ-ㅣ\x20])/g, '').substring(0, 6);
    this.setState({ name: renaming });
  };

  onChangeEmail = (e) => { //이메일 input event
    const v = e.target.value.replace(/[\s*]/gi, ''); //공백 replace?
    if (v === '') { this.setState({ emailError: '' }); } //email input 공백이면 emailError?
    this.state.fstChk && this.setState({ fstChk: '' });
    this.setState({
      email: v,
      emailError: '',
      emailValueLength: e.target.value.length
    });
    this.onDomainFilter(e);
    this.defaultData(v);
  };
  onBluremail2 = (e) => {
    this.setState({
      emailValueLength: e.target.value.length
    });
  };
  onBluremail = (value, length, check) => {
    const email_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i; //이메일 형식 체크
    const bb = email_regex.test(value);
    let em = '';

    if (length === 0) {
      this.setState({ emailError: '필수 정보입니다.' });
      return null;
    } else {
      (bb) ? em = '' : em = '이메일 형식이 올바르지 않습니다.';
    }
    this.setState({ emailError: em }, () => this.defaultData());
    em === '' && this.chkEmail(value, check);
  };

  selDomain = (e, n) => {
    this.setState({
      email: this.state.domainFilterOrg[n],
      active: '',
      emailError: ''
    });
    this.chkEmail(this.state.domainFilterOrg[n]);
  };

  chkEmail = (v, check) => {
    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/sign/checkId?',
      params: {
        'id': v,
        'socialType': this.state.socialType,
        'privateKey': this.state.privateKey,
        'companyCode': this.state.companyCode,
      }
    })
      .then((json) => {
        //json.data.result===true 일때 SIGN000007 가입 가능
        if (json.data.result) {
          if (json.data.message === 'SIGN000007' && this.state.socialType === 'APPLE') {
            this.setState({ joinType: 'apple', pop: '' });
            this.props.history.push('/Join/p2/apple');
          }
          if (json.data.message === 'SIGN000007' && this.state.socialType === 'KAKAO') {
            this.setState({ joinType: 'kakao', pop: '' });
            const userName = this.state.name;
            const regex = /[!@#$%^&*(),.?":{}|<>0-9a-zA-Z\s]/g; // 숫자, 특수문자, 공백, 영어를 포함하는 정규식
            const hasInvalidChars = regex.test(userName); // 입력된 username 변수에 정규식 패턴과 일치하는 문자열이 있는지 검사
            const isUsernameValid = !hasInvalidChars && userName.length > 0; // 일치하는 문자열이 없고, 길이가 0보다 큰 경우 true 반환
            // 번호와 한글이름이 있으면 회원가입, 없으면 /Join/p2/kakao
            if (this.state.cellPhone && isUsernameValid) {
              this.setState({
                isQuickKakaoSignUp: true
              });

              this.next2();
              return;
            }

            let c = [true, true, true, this.state.kakaoMktFlag];
            this.setState({
              chkState: c,
              chkNext: true,
              joinNext: true,
              nameError: isUsernameValid ? '' : '이름에 한글만 사용할 수 있습니다.',
            });

            this.props.history.push('/Join/p2/kakao');
          }
        } else {
          if (json.data.message === 'SIGN000022') {
            // 이미 가입된 소셜 로그인 > 자동 로그인
            this.eMessage('system', json.data.message);

            const socialLoginInfo = {
              'privateKey': this.state.privateKey,
              'socialType': this.state.socialType,
              'deviceName': '',
            };
            this.loginAccess(socialLoginInfo);
          } else {
            // SIGN000003 이미 사용중인 이메일, SIGN000024 회사메일만 사용 가능
            if (json.data.message === 'SIGN000003' && this.state.socialType) {
              this.eMessage('system', json.data.message);
              this.props.history.push('/Login');
            } else {
              this.eMessage('emailError', json.data.message);
            }
          }
        }
      }).then(() => { this.defaultData(); })
      .catch((error) => {
        console.error('error: ', error);
      });
  };
  // 도메인 노출
  onDomainFilter = (e) => {
    let startindex = e.target.value.indexOf('@');
    let txt = e.target.value.substring(0, startindex);
    let f = e.target.value.substring(startindex + 1, 99);
    let domainNameHtml = [];
    let doaminNameOrg = [];
    let cnt = 0;

    //도메인 필터 노출
    if (startindex !== -1) {
      this.state.emailDomain.map((v, n) => {
        domainNameHtml[n] = v.indexOf(f) === 0 ? txt + '@<span>' + v + '</span>' : '';
        doaminNameOrg[n] = v.indexOf(f) === 0 ? txt + '@' + v : '';
        v.indexOf(f) === 0 && cnt++;
      });
    }
    this.setState({
      active: cnt === 0 ? '' : 'block',
      domainFilter: domainNameHtml,
      domainFilterOrg: doaminNameOrg
    });
  };
  clsDomain = () => {
    this.setState({
      active: ''
    });
  };

  onChangePhone = (e) => {
    this.setState({ authCheckFlag: false });
    const t = e.target.value;
    clearInterval(this.interval);
    if (t.length <= e.target.maxLength) {
      const v = this.formChk('cellphone', t);

      this.setState({
        authBtn: v[0],
        cellPhone: v[1],
        cellPhoneError: '',
        authInfoView: false,
        authError: '',
        authInput: '',
        authState: false
      });
      if (this.state.authInput !== '' && this.state.authError === '' && this.state.authInput.length === 6) {
        this.setState({ authInput: '', layerPopupMessage: '정보가 변경되어 휴대전화번호 재인증이 필요합니다' });
      }
      this.defaultData(v[1]);
    }
  };
  onBlurPhone = (e) => {
    let v = '';
    if (e.target.value === '') {
      v = '필수 정보입니다.';
    } else if (!this.state.authBtn) {
      v = '휴대전화번호 형식이 올바르지 않습니다. 정확하게 다시 입력해주세요.';
    }
    this.setState({
      cellPhoneError: v,
    });
  };

  formChk = (t, n) => {
    const phoneRex = /^\d{3}-\d{3,4}-\d{4}$/;
    let v = n;

    v = v.replace(/-/gi, '');

    if (v.length >= 2 && v.length <= 5) {
      const nn = /(\d{2})(\d{1,3})/;
      v.replace(nn, function (str, p1, p2) {
        v = p1 + '-' + p2;
      });
    } else if (v.length >= 6 && v.length <= 9) {
      const nn = /(\d{2})(\d{1,3})(\d{1,4})/;
      v.replace(nn, function (str, p1, p2, p3) {
        v = p1 + '-' + p2 + '-' + p3;
      });
    } else if (v.length === 10) {
      // 일반전화이면서 서울일때
      if (v.substring(1, 2) === '2') {
        const nn = /(\d{2})(\d{1,4})(\d{2,4})/;
        v.replace(nn, function (str, p1, p2, p3) {
          v = p1 + '-' + p2 + '-' + p3;
        });
      } else {
        const nn = /(\d{3})(\d{1,3})(\d{2,4})/;
        v.replace(nn, function (str, p1, p2, p3) {
          v = p1 + '-' + p2 + '-' + p3;
        });
      }
    } else if (v.length > 10) {
      const nn = /(\d{3})(\d{1,4})(\d{2,4})/;
      v.replace(nn, function (str, p1, p2, p3) {
        v = p1 + '-' + p2 + '-' + p3;
      });
    }
    const p = phoneRex.test(v);
    return [p, v];
  };

  onChangePassword = (e) => {
    const pw_regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/gi;
    const a = pw_regex.test(e.target.value);
    let v = e.target.value;
    if (a) {
      this.setState({ passwordError: '비밀번호에 한글을 사용할 수 없습니다.' });
    } else if (utilFn.hasUnicode(v)) {
      this.setState({ passwordError: '비밀번호에 유니코드 문자를 사용할 수 없습니다.' });
    } else if (v.length !== 0) {
      this.setState({ passwordError: '', boxError: '' });
    }

    const eng = /[a-zA-Z]/;
    const num = /[0-9]/;
    const sym = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/;
    let flagEng = eng.test(e.target.value);
    let flagNum = num.test(e.target.value);
    let flagSym = sym.test(e.target.value);

    let p1, p2, p3, p4;

    // 비밀번호 8~16자 확인
    (v.length >= 8 && v.length <= 16) ? p1 = ' len' : p1 = '';
    // 영문
    (flagEng === true) ? p2 = ' eng' : p2 = '';
    // 숫자
    (flagNum === true) ? p3 = ' num' : p3 = '';
    // 특수문자
    (flagSym === true) ? p4 = ' sym' : p4 = '';

    let checkCompelete = ((v.length >= 8 && v.length <= 16) && flagEng && flagNum && flagSym);
    this.setState({
      password: v,
      pwChk: p1 + p2 + p3 + p4,
      pwChk1: p1,
      pwChk2: p2,
      pwChk3: p3,
      pwChk4: p4
    });

    pwCompleted = checkCompelete;
    pwChkCompleted = (v === this.state.passwordCheck);
    this.defaultData();
  };
  onBlurPassword = (e) => {
    if (this.state.password === '') {
      this.setState({ passwordError: '필수 정보입니다.' });
      return null;
    } else if (this.state.pwChk1 === '' || this.state.pwChk2 === '' || this.state.pwChk3 === '' || this.state.pwChk4 === '') {
      this.setState({ boxError: 'error' });
      return null;
    } else {
      this.setState({ boxError: '' });
    }
    if (e && this.state.passwordCheck !== '') {
      this.onBlurPasswordChk();
    }
    this.defaultData();
  };

  // 비밀번호 재확인
  onChangePasswordChk = (e) => {
    const pw_regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/gi;
    const a = pw_regex.test(e.target.value);
    let v = e.target.value;

    if (a) {
      this.setState({ pwChkError: '비밀번호에 한글을 사용할 수 없습니다.' });
    } else if (utilFn.hasUnicode(v)) {
      this.setState({ pwChkError: '비밀번호에 유니코드 문자를 사용할 수 없습니다.' });
    } else if (v.length !== 0) {
      this.setState({ pwChkError: '' });
    }
    let checkCompelete = (v === this.state.password);
    this.setState({
      passwordCheck: v,
    });
    pwChkCompleted = checkCompelete;
    this.defaultData();
  };
  onBlurPasswordChk = (e) => {
    let s;
    const pw_regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/gi;
    if (this.state.password === '') {
      s = '비밀번호를 먼저 입력해주세요.';
    } else if (this.state.passwordCheck === '') {
      s = '필수 정보입니다.';

    } else {
      s = (this.state.password !== this.state.passwordCheck) ? '입력한 비밀번호와 동일하지 않습니다.'
        : (pw_regex.test(this.state.passwordCheck)) ? '비밀번호에 한글을 사용할 수 없습니다.'
          : utilFn.hasUnicode(this.state.passwordCheck) ? '비밀번호에 유니코드 문자를 사용할 수 없습니다.'
            : '';
    }
    this.setState({ pwChkError: s });
    if (e && this.state.password !== '') {
      this.onBlurPassword();
    }
    this.defaultData();
  };

  onChangeEmployeeNo = e => {
    // 사번 변경
    let cb = true;
    if (e.target.value.length > 0) {
      cb = false;
    }
    this.setState({
      employeeNo: e.target.value,
      employeeNoError: '',
      employeeNoBtn: cb
    });
    this.defaultData();
  };

  onClickPwView = (n) => {
    let s;

    if (n === 1) {
      this.state.pwView === 'password' ? s = 'text' : s = 'password';
      this.setState({ pwView: s });
    } else if (n === 2) {
      this.state.pwChkView === 'password' ? s = 'text' : s = 'password';
      this.setState({ pwChkView: s });
    }
  };

  checkAuthNumber = (eventType, authInputValue) => {
    // 인증번호랑 입력값 비교
    const params = {
      cellPhone: this.state.cellPhone.replace(/-/gi, ''),
      authNumber: authInputValue,
      authType: 'SIGN_UP'
    };
    const onSuccess = () => {
      this.setState({
        authError: '',
        authInfoView: false,
        authCheckFlag: true,
      });
      if (eventType === 'change') {
        clearInterval(this.interval);
        this.defaultData(authInputValue);
      }
    };
    const onFail = (commonMessage) => {
      this.setState({
        authError: this.state.commonMsg[commonMessage],
        authInfoView: true,
        authCheckFlag: false,
      });
    };
    utilFn.checkAuthNumber(params, onSuccess, onFail);
  };

  onChangeAuth = (e) => {
    let v = e.target.value;
    v = v.replace(/[*#+,;]/gi, '');
    if (v.length > 6) {
      v = v.slice(0, 6);
    }
    this.setState({
      authInput: v,
      authInfoView: true,
      authError: '',
      authCheckFlag: false,
    });

    clearInterval(this.interval);
    this.reqAuthCount();

    if (v.length === 6) {
      this.checkAuthNumber('change', v);
    } else {
      this.setState({
        authError: true,
        authInfoView: true,
      });
    }
  };

  onBlurAuth = (e) => {
    if (e.target.readOnly) {
      return null;
    }

    let v = this.state.authInput;

    if (v.length === 6) {
      this.checkAuthNumber('blur', v);
    } else if (v.length === 0) {
      this.setState({
        authError: '필수 정보입니다.',
        authInfoView: true,
      });
    } else {
      this.setState({
        authError: '인증번호를 다시 확인해주세요.',
        authInfoView: true,
      });
    }
    this.defaultData(e.target.value);
  };

  reqAuth = () => {
    if (!this.state.authBtn) {
      return null;
    }
    
    // 050, 030으로 시작하는 번호 체크
    if (this.state.cellPhone.startsWith('050') || this.state.cellPhone.startsWith('030')) {
      this.setState({ authInfoView: true, authTimeError: true });
      return null;
    }
    
    this.setState({ authCheckFlag: false });
    if (this.state.name === '') {
      this.setState({ nameError: '필수 정보입니다.' });
      return null;
    } else if (this.state.nameError !== '') {
      this.setState({ nameError: '한글 2~6자의 이름을 입력하고 재인증을 받아주세요.' });
      return null;
    } else {

    }

    clearInterval(this.interval);
    this.setState({
      disabled: false,
      authTimeError: false,
      countMin: '10',
      countSec: '00',
      time: 600,
      authInfoView: false,
      authError: '',
      authInput: '',
      authState: false
    });

    httpDefaultClient.post('/sign/findId', {}, {params: {
      'memberCellphone': this.state.cellPhone.replace(/-/g, ''),
      'memberName': encodeURI(this.state.name),
    }})
      .then(res => {
        const json = res.data;
        if (json.message === 'SIGN000002') {
          this.reqAuthS();
        } else {
          //SIGN000004 이미 가입된 이름과 휴대전화번호입니다.
          this.eMessage('alert', 'SIGN000004');
          return null;
        }
      })
      .catch((error) => {
        console.error('error: ', error);
        this.openLayerPopup('서버 통신에 실패 하였습니다.');
      });
  };

  reqAuthS = () => {
    // 인증번호 발송
    this.setState({ authCheckFlag: false });
    const params = {
      cellPhone: this.state.cellPhone.replace(/-/gi, ''),
      authType: 'SIGN_UP'
    };
    const onSuccess = () => {
      this.setState({
        authInfoView: true,
        authState: true,
        authStart: moment() + 0,
        authEnd: moment() + 600000
      });
      this.reqAuthCount();
      this.defaultData();
    };
    const onFail = (commonMessage) => {
      this.setState({
        authLayerPopup: this.state.commonMsg[commonMessage],
        authCheckFlag: false,
      });
      this.defaultData();
    };
    utilFn.sendAuthNumber(params, onSuccess, onFail);
  };

  reqAuthCount = () => {
    this.interval = setInterval(() => {
      this.timerHandler();
    }, 1000);
  };

  timerHandler = () => {
    const now = moment();

    if (now.diff(this.state.authStart) > 0 && now.diff(this.state.authEnd) < 0) {
      this.timesSetter();
    } else {
      this.setState({
        countSec: '00', //time - (countMin * 60),
        countMin: '00',
        disabled: true, // 시간이 지낫을때 인풋창 막기
        authTimeError: true,
        authInput: '',
        authStart: 0,
        authEnd: 0,
        authError: '유효시간이 지났습니다. 다시 인증을 진행해주세요.'
      });
      clearInterval(this.interval);
    }
  };

  timesSetter = () => {
    const now = moment();
    let s;
    let m;
    let t = now - this.state.authStart;
    t = 600 - Math.floor(t / 1000);
    m = Math.floor(t / 60);
    s = t - (m * 60);
    m === 0 ? m = '00' : m = '' + m;
    String(m).length === 1 ? m = '0' + m : m = '' + m;
    s === 0 ? s = '00' : s = '' + s;
    String(s).length === 1 ? s = '0' + s : s = '' + s;

    this.setState({
      countSec: s,
      countMin: m,
    });
  };

  onBlurCCode = (e) => {
    if (e.target.value.length === 0) {
      this.setState({ CCodeError: '필수 정보입니다.' });
    }
  };

  onChangeCCode = (e) => {
    let cb = true;
    if (e.target.value.length > 0) {
      cb = false;
    }
    this.setState({
      companyCode: e.target.value,
      CCodeError: '',
      CCbtn: cb
    }, () => this.defaultData());
  };

  chkCode = (e) => {
    if (e.target.className.indexOf('disable') >= 0) {
      return null;
    }

    httpDefaultClient.get('/sign/checkCompanyCode?companyCode=' + encodeURI(this.state.companyCode)).then(res => {
      const json = res.data;
      if (json.result === true && json.message === 'SIGN000005') {
        this.setState({
          CCodeError: '',
          companyAuth: true,
          CCInput: true,
          CCbtn: true,
          companyData: json.data,
        });

        if (json.data.divisionCode1) {
          this.commonCode(json.data.divisionCode1);
          this.setState({ [json.data.divisionCode1 + 'val']: '' });
        }
        if (json.data.divisionCode2) {
          this.setState({ [json.data.divisionCode2 + 'val']: '' });
        }
      } else {
        this.eMessage('CCodeError', json.message);
        this.setState({
          companyAuth: false,
          CCInput: false
        });
      }
      if (this.state.email) this.chkEmail(this.state.email);
    }).catch((error) => console.error('error: ', error));

    e.preventDefault();
  };

  emplyeeNoChk = (e) => {
    const ts = this.state;

    if (ts.employeeNo === '001') {
      this.setState({
        employeeNoError: '',
        employeeNoAuth: true,
        employeeNoInput: true,
        employeeNoBtn: true,
      });

      return null;
    }
    if (e.target.className.indexOf('disable') >= 0) {
      return null;
    }

    if (ts.name === '') {
      this.setState({ employeeNoError: '이름을 입력해 주세요.' });
      return false;
    }

    httpDefaultClient.post('/sign/checkEmployeeNumber', {
      'companyCode': ts.companyCode,
      'employeeName': ts.name,
      'employeeNumber': ts.employeeNo,
    })
      .then(response => {
        const json = response.data;
        if (json.result === true) {
          this.setState({
            employeeNoError: '',
            employeeNoAuth: true,
            employeeNoInput: true,
            employeeNoBtn: true,
          });
        } else {
          this.eMessage('employeeNoError', json.message);
          this.setState({
            employeeNoAuth: false,
            employeeNoInput: false
          });
        }
      })
      .then(() => this.defaultData())
      .catch((error) => console.error('error: ', error));

    e.preventDefault();
  };

  onChangeEmployeePo = e => {
    //직급 입력란 - onChange
    if (this.state.companyData.positionFlag) {
      this.setState({ employeePoError: (e.target.value === '') ? '필수 정보입니다.' : '' });
    }
    this.setState({ employeePo: e.target.value }, () => this.defaultData());
  };


  pad = (number, length) => {
    let str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  };

  onChangeBirthday = (e) => {
    const regex = /[^0-9]/g;
    let input = e.target.value;
    input = input.replace(regex, '');
    if (input.length <= e.target.maxLength) {
      let birthdayError = '';
      const regex = /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/;
      if (!regex.test(e.target.value)) {
        birthdayError = this.state.commonMsg['SIGN000044'];
      } else {
        const dateStr = e.target.value.substring(0,4)+'-'+e.target.value.substring(4,6)+'-'+e.target.value.substring(6,8);
        const dt = new Date(dateStr);
        const yyyyMMdd = dt.getFullYear().toString()
          +this.pad(dt.getMonth() + 1,2)
          +this.pad(dt.getDate(), 2);
        birthdayError = yyyyMMdd===e.target.value ? '' : this.state.commonMsg['SIGN000044'];
      }

      this.setState({
        birthday: input,
        birthdayError
      }, () => {
        this.defaultData();
      });
    }
  };

  onBlurBirthday = (e) => {
    const input = e.target.value;
    let birthdayError = '';
    if (!input || input==='' || input.length<8) {
      birthdayError = this.state.commonMsg['SIGN000044'];
    } else {
      const regex = /^\d{4}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/;
      if (!regex.test(e.target.value)) {
        birthdayError = this.state.commonMsg['SIGN000044'];
      } else {
        const dateStr = e.target.value.substring(0,4)+'-'+e.target.value.substring(4,6)+'-'+e.target.value.substring(6,8);
        const dt = new Date(dateStr);
        const yyyyMMdd = dt.getFullYear().toString()
          +this.pad(dt.getMonth() + 1,2)
          +this.pad(dt.getDate(), 2);
        birthdayError = yyyyMMdd===e.target.value ? '' : this.state.commonMsg['SIGN000044'];
      }
    }
    this.setState({ birthdayError: birthdayError }, () => {
      this.defaultData();
    });
  };

  defaultData = (e) => {
    const ts = this.state;
    // 필수 값에 따른 버튼 활성화
    let a = false;

    if (ts.socialType) {
      if (ts.email
        && ts.name
        && ts.authInput.length >= 5
        && ts.authError === ''
        && ts.authCheckFlag) {

        a = true;
      }
    } else {
      if (ts.email
        && ts.emailError === ''
        && ts.password
        && ts.name
        && ts.authInput.length >= 5
        && pwCompleted && pwChkCompleted
        && ts.authError === ''
        && ts.authCheckFlag) {

        if (ts.joinType === 'company') { // 기업 회원가입
          this.setState({
            juniorFlag: false,
            birthdayError: '',
            birthday: null
          });
          if (ts.CCInput) { // 제휴코드 입력했는지
            a = (ts.companyData.departmentFlag ? // 부서
              ((ts.companyData.divisionCode1) ? (ts[ts.companyData.divisionCode1 + 'val'] !== '' && ts[ts.companyData.divisionCode1 + 'val'] !== 'none') : true) &&
              ((ts.companyData.divisionCode2) ? (ts[ts.companyData.divisionCode2 + 'val'] !== '' && ts[ts.companyData.divisionCode2 + 'val'] !== 'none') : true)
              : true)
              && (ts.companyData.employeenoFlag ? ts.employeeNoInput : true) // 사번
              && (ts.companyData.positionFlag ? ts.employeePo : true) // 직급
              && (ts.companyData.extraFlag ? ts.extraInfo !== '' : true) // 자유 텍스트란
              && (ts.companyData.extra2Flag ? ts.extraInfo2 !== '' : true); // 자유 텍스트란2
          }
        } else {
          a = true;
        }

      }
    }
    if (ts.juniorFlag && (ts.birthdayError || !ts.birthday)) {
      a = false;
    }
    this.setState({ joinNext: a });
  };

  next2 = (e) => {
    const ts = this.state;

    if (ts.btnHold) { this.openLayerPopup('등록이 진행 중입니다.'); return false; }
    if (ts.joinType !== 'kakao') {
      if (e.target.className.indexOf('disable') > 0) {
        return null;
      }
    }
    /**
     * 요나 이슈 #1563 대응
     * 불특정한 이유로 카카오싱크 이후 password에 값이 들어감
     */
    if (ts.joinType === 'kakao') {
      this.setState({ password: '' });
    }

    /**
     * 카카오로 회원가입시 사용자의 이름을 받아와서 유폰 형식에 맞는지 검사합니다.
     * 참조 이슈: RR1538
     */
    const userName = ts.name;
    const nameValidation = /^[가-힣]+$/;

    const isThisNameOK = nameValidation.test(userName) && userName.length >= 2 && userName.length <= 6;
    const isThisNameNotOK = !isThisNameOK;

    if (ts.joinType === 'kakao' && isThisNameNotOK) {
      const virtualEvent = { target: { value: ts.name } };
      this.onBlurName(virtualEvent);
      this.inputNameRef.current.focus();
      return;
    }
    let c1 = (!ts.email || (!ts.socialType && this.emailRef2.current.attributes.value.value === '')) ? '필수 정보입니다.' : ts.emailError;
    let c2 = (!ts.password || this.inputPwRef.current.attributes.value.value === '') ? '필수 정보입니다.' : ts.passwordError;
    let c3 = (!ts.passwordCheck || this.inputPwChkRef.current.attributes.value.value === '') ? '필수 정보입니다.' : ts.pwChkError;
    let c4 = (!ts.name || this.inputNameRef.current.attributes.value.value === '') ? '필수 정보입니다.' : ts.nameError;
    let c5 = (!ts.cellPhone || this.inputCellPhoneRef.current.attributes.value.value === '') ? '필수 정보입니다.' : ts.cellPhoneError;
    let c6 = (!ts.authInput || this.inputAuthRef.current.attributes.value.value === '') ? '필수 정보입니다.' : ts.authError;
    let c7 = (!pwCompleted || !pwChkCompleted) ? '조건이 맞지 않습니다.' : ts.passwordError;

    let cc = null;

    if (!ts.socialType) { // 소셜로그인이 아닐때 확인
      if (c1 !== '') {
        //이메일 focus
        this.emailRef2.current.focus();
        return false;
      } else if (c2 !== '' || !(ts.pwChk1 !== '' && ts.pwChk2 !== '' && ts.pwChk3 !== '' && ts.pwChk4 !== '')) {
        //비밀번호 focus
        this.inputPwRef.current.focus();
        return false;
      } else if (c3 !== '') {
        //비밀번호확인 focus
        this.inputPwChkRef.current.focus();
        return false;
      } else if (c7 !== '') {
        //입력 조건이 안맞음
        this.inputPwRef.current.focus();
        return false;
      }
    } else {
      c1 = '';
      c2 = '';
      c3 = '';
      c7 = '';
    }

    if (ts.joinType !== 'kakao') {
      if (c4 !== '') {
        //이름 focus
        this.inputNameRef.current.focus();
        return false;
      } else if (c5 !== '') {
        //휴대전화번호 focus
        this.inputCellPhoneRef.current.focus();
        return false;
      } else if (c6 !== '') {
        //휴대전화 인증번호 focus
        this.inputAuthRef.current.focus();
        return false;
      }

      this.setState({
        emailError: c1,
        passwordError: c2,
        pwChkError: c3,
        nameError: c4,
        cellPhoneError: c5,
        authError: c6,
        pop: null,
      });
    }

    clearInterval(this.interval);
    ts.CCInput ? cc = ts.companyCode : cc = null;

    const birthday = ts.juniorFlag && ts.birthday ? ts.birthday.substring(0, 4)+'-'+ts.birthday.substring(4, 6)+'-'+ts.birthday.substring(6, 8) : null;

    let info;
    if (ts.socialType) {
      if (ts.joinType === 'kakao') {
        info = {
          'memberCellphone': ts.cellPhone.replace(/-/g, ''),
          'memberEmail': ts.email,
          'memberId': ts.email,
          'memberName': ts.name,
          'socialEmail': ts.email,
          'privateKey': ts.privateKey,
          'socialType': ts.socialType,
          'plusfriendsFlag': ts.plusfriendsFlag, // 카카오 채널 추가 상태
          'memberenglishName': 'not yet',
          'mktemailFlag': ts.kakaoMktFlag,
          'mktsmsFlag': ts.kakaoMktFlag,
          'mktpushFlag': ts.kakaoMktFlag,
          'studyemailFlag': true,
          'studypushFlag': true,
          'studysmsFlag': true,
          'companyCode': null,
          'deviceCode': mobileChk(),
          'juniorFlag': ts.juniorFlag,
          'birthday': birthday,
        };
      } else {
        info = {
          'memberCellphone': ts.cellPhone.replace(/-/g, ''),
          'memberEmail': ts.email,
          'memberId': ts.email,
          'memberName': ts.name,
          'socialEmail': ts.email,
          'privateKey': ts.privateKey,
          'socialType': ts.socialType,
          'plusfriendsFlag': ts.plusfriendsFlag, // 카카오 채널 추가 상태
          'memberenglishName': 'not yet',
          'mktemailFlag': ts.chkState[3],
          'mktsmsFlag': ts.chkState[3],
          'mktpushFlag': ts.chkState[3],
          'studyemailFlag': true,
          'studypushFlag': true,
          'studysmsFlag': true,
          'companyCode': cc,
          'deviceCode': mobileChk(),
          'juniorFlag': ts.juniorFlag,
          'birthday': birthday,
        };
      }
    } else {
      info = {
        'memberCellphone': ts.cellPhone.replace(/-/g, ''),
        'memberEmail': ts.email,
        'memberId': ts.email,
        'memberName': ts.name,
        'memberenglishName': 'not yet',
        'password': ts.password,
        'mktemailFlag': ts.chkState[3],
        'mktsmsFlag': ts.chkState[3],
        'mktpushFlag': ts.chkState[3],
        'studyemailFlag': true,
        'studypushFlag': true,
        'studysmsFlag': true,
        'companyCode': cc,
        'deviceCode': mobileChk(),
        'juniorFlag': ts.juniorFlag,
        'birthday': birthday,
      };
    }

    if (ts.CCInput) {
      info.employeeNumber = ts.employeeNo;
      info.division1 = ts[ts.companyData.divisionCode1 + 'val'];
      info.division2 = ts[ts.companyData.divisionCode2 + 'val'];
      info.position = ts.employeePo;
      info.extraInfo = ts.companyData?.extraFlag ? ts.extraInfo : null;
      info.extraInfo2 = ts.companyData?.extra2Flag ? ts.extraInfo2 : null;
    }

    /**
     * 회원가입시 어떤 마케팅 루트로 들어왔는지 정보수집
     */
    info.lastcookieCode = utilFn.getMarketingCodeValue('lmc') || '';
    info.mktparamCode = utilFn.getMarketingCodeValue('ref') || '';

    this.setState({ btnHold: true });

    httpDefaultClient.post('/sign/signUp', info)
      .then(res => {
        const json = res.data;
        if (json.result) {
          window.dataLayer.push({
            'event': 'Sign_Up'
          });

          let loginInfo;
          if (ts.socialType) {
            loginInfo = {
              'privateKey': ts.privateKey,
              'socialType': ts.socialType,
            };
          } else {
            loginInfo = {
              'memberId': ts.email,
              'password': ts.password
            };
          }
          this.loginAccess(loginInfo);
        } else {
          if (json.message === 'SIGN000024') { // 회사 이메일이 아님
            this.emailRef2.current.focus();
            return false;
          }
          this.eMessage('alert', json.message);
        }
      })
      .catch(error => {
        console.error('error: ', error);
      });
  };

  loginAccess = (loginInfo) => {
    httpDefaultClient.post('/sign/logIn', loginInfo)
      .then(res => {
        const json = res.data;
        if (json.status === 200) {
          if (json.result) {
            // 이동
            const { prevPage, resetData } = referrerStore.getState();
            this.resetInfo();
            if (prevPage) {
              resetData();
              window.location.replace(prevPage);
            } else {
              window.location.replace('/');
            }
          } else if (json.message === 'SIGN000010') {
            setTimeout(() => { this.props.history.replace(`/release/p1/${json.data}`); }, 10);
          } else {
            this.setState({ btnHold: false });
            this.eMessage('alert', json.message);
            return null;
          }
        }
      })
      .catch((error) => {
        this.setState({ btnHold: false });
        this.openLayerPopup('로그인 에러');
        console.error('error: ', error);
      });
  };

  clsPop = () => {
    this.setState({ pop: '' });
  };

  viewContent = (e) => {
    const wrap = document.getElementById('contentViewWrap');

    if (this.ww === 'pc') {
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: wrap.offsetTop + wrap.offsetHeight,
          behavior: 'smooth',
        });
      }, 100);
    }

    this.contentView.scrollTop = 0;
    this.setState({ pop: e.target.id });
  };

  eMessage = (t, v) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (response.data.result) {
          if (t === 'alert') {
            this.setState({ layerPopupMessage: response.data.message }); // 타입 alert의 경우 레이어 팝업
            return null;
          } if (t === 'system') {
            alert(response.data.message);
          } else {
            this.setState({ [t]: response.data.message }, () => { this.defaultData(); });
          }
        } else {
          this.setState({ layerPopupMessage: v });
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  emailRef = createRef();
  emailRef2 = createRef();

  onClickfChk = (e) => {
    this.setState({ fstChk: '' });
    /**
     * 카카오로 회원가입시 사용자의 이름을 받아와서 유폰 형식에 맞는지 검사합니다.
     * 참조 이슈: RR1538
     */
    if (e.target.name === 'name' && this.state.joinType === 'kakao') {
      this.onBlurName(e);
    }
  };
  go = (t) => {
    this.props.history.push(t);
  };
  resetInfo = () => {
    this.setState({
      email: '',
      password: '',
      passwordCheck: '',
      name: '',
      cellPhone: '',
      authInput: '',
      companyCode: '',
      chkAll: '',
      chkState: '',
      pwChk: '',
      pwChk1: '',
      pwChk2: '',
      pwChk3: '',
      pwChk4: '',
    });
  };

  shouldBlur = (e) => {
    e.target.blur();
  };

  seleChage = (e, v) => {
    const ts = this.state;

    if (e.target.value === 'none' || e.target.value === '') {
      this.setState({
        [ts.companyData.divisionCode2 + 'val']: '',
        employeeDivisionError: (ts.companyData.departmentFlag) ? '필수 정보입니다.' : '',
      });
    } else {
      if (v === ts.companyData.divisionCode2) {
        this.setState({ employeeDivisionError: '' });
      }
    }

    this.setState({
      [v + 'val']: e.target.value
    });

    if (v === ts.companyData.divisionCode1 && ts.companyData.divisionCode2) {
      this.setState({ [ts.companyData.divisionCode2 + 'val']: '' });
      this.commonCode(ts.companyData.divisionCode2, e.target.value);
    }
    this.defaultData();
  };

  getCommonMsgList = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getList',
      params: {
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        this.setState({
          commonMsg: response.data,
        });
      })
      .catch((error) => {
        console.error('getCommonMsgList: ', error);
      });
  };

  commonCode = (c, d) => {
    let a = d ? c + '/' + d : 'getList/' + c;
    httpDefaultClient.get('/commonCode/' + a)
      .then((response) => {
        this.setState({ [c]: response.data });
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  openLayerPopup = (message) => {
    this.setState({ layerPopupMessage: message });
  };

  closeLayerPopup = () => {
    this.setState({ layerPopupMessage: '' });
    this.defaultData();
  };

  handleKeyPress = (e) => {
    if (e.charCode === 13) { //  deprecated
      this.clsDomain();
    }
  };

  onChangeJuniorMember = () => {
    this.setState({ juniorFlag: !this.state.juniorFlag }, () => {
      this.defaultData();
    });
  };
  
  onChangeExtraInfo = (e) => {
    this.setState({
      extraInfo: e.target.value,
      extraError: ''
    }, () => this.defaultData());
  };
  
  onChangeExtraInfo2 = (e) => {
    this.setState({
      extraInfo2: e.target.value,
      extraError2: ''
    }, () => this.defaultData());
  };

  handleJoinByNaverClick = () => {
    const naverLoginButton = document.getElementById('naverIdLogin_loginButton');

    if (!naverLoginButton) { return; }
    naverLoginButton.click();
  };

  render() {
    const kakaoCellphoneUser = this.state.joinType === 'kakao' && this.state.kakaoCellphone;
    return (
      <>
        <ReactMeta title='전화영어, 화상영어 1위 업계 유일! 100% 만족 보장 제도' description='무료체험으로 지금 시작하세요.'/>
        <div className={'join ' + (this.props.match.params.page ? this.props.match.params.page : 'p1')}>
          <div className='step1 join-step1'>
            <section className='join-step1__free-lesson'>
              <Link className={'logoLink'} to='/'>
                <img src={process.env.REACT_APP_IMG_URL + '/web/logo_default.svg'} alt={'view off'} />
              </Link>
              <img src={process.env.REACT_APP_IMG_URL + '/join/welcome_web_mo_new.png'} alt='유폰 방문을 환영해요. 3초만에 가입하고 무료수업을 시작하세요.' className='mo-vis' />
              <img src={process.env.REACT_APP_IMG_URL + '/join/welcome_web_pc_new.png'} alt='유폰 방문을 환영해요. 3초만에 가입하고 무료수업을 시작하세요.' className='pc-vis' />
            </section>
            <section className='join-step1__buttons'>
              <div className='join-step1__button-wrap is-top'>
                <KakaoLoginButton
                  token={kakao_client_id}
                  onSuccess={(response) => { this.kakaoLoginInit(response); }}
                  onFail={(error) => { this.onErrorKakaoLogin(error); }}
                  service_terms='service_20220614_01,service_20220615_01,service_20220616_01'
                  render={(props) =>
                    <button {...props} className='join-step1__button join-step1__button--kakao'>
                      <div className='join-step1__button-image-wrap'>
                        <img src={process.env.REACT_APP_IMG_URL + '/join/icon_kakao.svg'} alt={'kakao'} />
                      </div>
                      카카오로 3초만에 시작하기
                    </button>
                  }
                >
                </KakaoLoginButton>
              </div>
              <div className='join-step1__button-wrap is-bottom'>
                <div className='join-step1__circle-button'>
                  <div className='join-step1__button join-step1__button--naver' onClick={this.handleJoinByNaverClick}>
                    {mobileChk() === 'MOBILE' ? (
                      <img className='join-step1__button-icon' src={process.env.REACT_APP_IMG_URL + '/join/ico_naver_circle.svg'} alt={'naver'} />
                    ) : (
                      <img className='join-step1__button-icon' src={process.env.REACT_APP_IMG_URL + '/join/ico_naver_circle_pc.svg'} alt={'naver'} />
                    )}
                    <div className='join-step1__button-text naver-button' id='naverIdLogin' />
                  </div>
                  <AppleSignin
                    authOptions={this.appleAuthOptions}
                    uiType='dark'
                    className='apple-auth-btn'
                    onSuccess={(response) => { this.onSuccessAppleLogin(response); }}
                    onError={(error) => { this.onErrorAppleLogin(error); }}
                    skipScript={false}
                    render={(props) =>
                      <div {...props} className='join-step1__button join-step1__button--apple'>
                        {mobileChk() === 'MOBILE' ? (
                          <img className='join-step1__apple-icon' src={process.env.REACT_APP_IMG_URL + '/join/ico_apple_circle.svg'} alt={'apple'} />
                        ) : (
                          <img className='join-step1__apple-icon' src={process.env.REACT_APP_IMG_URL + '/join/ico_apple_circle_pc.svg'} alt={'apple'} />
                        )}
                      </div>}
                  />
                </div>
                <div className='join-step1__text-buttons'>
                  <div className='text-button' onClick={() => this.next1('email')}>이메일 가입</div>
                  <div className='link__button__line'></div>
                  <div className='text-button' onClick={() => this.next1('company')}>기업회원 가입</div>
                  <div className='link__button__line'></div>
                  <div className='text-button text-button--login' onClick={() => this.props.history.push('/Login')}>로그인</div>
                </div>
              </div>
            </section>
          </div>

          <div className='step2 join-step2'>
            <Link className={'logoLink'} to='/'>
              <img src={process.env.REACT_APP_IMG_URL + '/web/logo_default.svg'} alt={'view off'} />
            </Link>
            <div className='contentTop'><p className='mainTxt mt'>{(this.state.joinType === 'company') ? '기업회원으' : (this.state.joinType === 'kakao') ? '카카오' : (this.state.joinType === 'naver') ? '네이버' : (this.state.joinType === 'apple') ? '애플' : '이메일'}로 가입하기.</p></div>
            <div className='contentBox'>
              <form>
                {/*기업가입일 경우 제휴코드*/}
                {this.state.joinType === 'company' &&
                  <div className={'companyWrap'}>
                    <div className='inputTitle'>제휴코드</div>
                    <input autoComplete='cCode' className={'inputBox ' + (this.state.CCodeError && 'error')}
                      name='cCode' placeholder='제휴코드 입력' readOnly={this.state.CCInput} value={this.state.companyCode}
                      onChange={this.onChangeCCode} onClick={this.onClickfChk} onBlur={(e) => this.onBlurCCode(e)} />
                    <div className={'btn inputBtn ' + (this.state.CCbtn && 'disable')} onClick={this.chkCode}>등록</div>
                    {this.state.CCInput && <div className={'completeTxt'}>
                      <svg xmlns='http://www.w3.org/2000/svg' width='13.386' height='9.227'
                        viewBox='0 0 13.386 9.227'>
                        <path d='M-419.842,158.372l4.058,3.988,7.207-7.417'
                          transform='translate(420.903 -153.883)' fill='none' stroke='#4C69FC' />
                      </svg>
                      등록완료</div>}
                  </div>}
                {this.state.CCodeError && <div className='error errorMsg'>{this.state.CCodeError}</div>}

                {/*이메일,기업일 경우 (소셜로그인이 아닐경우)*/}
                {(this.state.joinType === 'email' || this.state.joinType === 'company') &&
                  <>
                    <div className='inputTitle'>이메일</div>
                    <input tabIndex={1} ref={this.emailRef2} className={'email ' + (this.state.socialLogin && 'dis ') + (this.state.emailError && 'error ') + (this.state.fstChk)} readOnly={this.state.socialLogin && 'readOnly'} onFocus={this.state.socialLogin ? this.shouldBlur : null} name='uemail' type='email' autoCapitalize='off' autoComplete='off' placeholder='abc@uphone.co.kr' value={this.state.email} onChange={this.onChangeEmail} onBlur={(e) => this.onBluremail(e.target.value, e.target.value.length)} onClick={this.onClickfChk} style={{ 'imeMode': 'inactive' }} onKeyPress={(e) => this.handleKeyPress(e)} />
                    <div className={`dsmFilter ${this.state.active === 'block' ? 'block' : ''}`}>
                      {this.state.domainFilter.map((v, n) => {
                        return <p id={this.state.emailDomain[n]} onClick={(e) => this.selDomain(e, n)} key={n}
                          dangerouslySetInnerHTML={{ __html: v }} />;
                      })}
                    </div>
                    {this.state.active && <div className={'dsmFilterBg'} onClick={this.clsDomain} />}
                    {this.state.emailError && <div className='error errorMsg'>{this.state.emailError}</div>}

                    <div className='inputTitle'>비밀번호</div>
                    <div className={'box'}>
                      <input tabIndex={2} ref={this.inputPwRef} autoComplete='password' className={'msClear ' + (this.state.passwordError && 'error ') + (this.state.boxError)}
                        name='p1' type={this.state.pwView} placeholder='8~16자 영문, 숫자, 특수문자 사용' maxLength='16' value={this.state.password} onChange={this.onChangePassword} onBlur={this.onBlurPassword} onClick={this.onClickfChk} />
                      <div className={'viewIcon'} onClick={() => this.onClickPwView(1)}>
                        {this.state.pwView === 'password' ?
                          <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOff.svg'} alt={'view off'} /> :
                          <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOn.svg'} alt={'view on'} />}
                      </div>
                    </div>
                    <div className={'pwChkListWrap' + this.state.pwChk}>
                      <div className={'pwChkList len'} >8~16자</div>
                      <div className={'pwChkList eng'} >영문</div>
                      <div className={'pwChkList num'} >숫자</div>
                      <div className={'pwChkList sym'} >특수문자</div>
                    </div>
                    {this.state.passwordError &&
                      <div className='error errorMsg'>{this.state.passwordError}</div>}

                    <div className='inputTitle'>비밀번호 재확인</div>
                    <div className={'box'}>
                      <input tabIndex={3} ref={this.inputPwChkRef} autoComplete='off' className={'msClear ' + (this.state.pwChkError && 'error')} name='p2' type={this.state.pwChkView} maxLength={16} placeholder='입력한 비밀번호와 동일' value={this.state.passwordCheck} onChange={this.onChangePasswordChk} onBlur={this.onBlurPasswordChk} onClick={this.onClickfChk} />
                      <div className={'viewIcon'} onClick={() => this.onClickPwView(2)}>
                        {this.state.pwChkView === 'password' ?
                          <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOff.svg'} alt={'view off'} /> :
                          <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOn.svg'} alt={'view on'} />}
                      </div>
                    </div>
                    {this.state.pwChkError && <div className='error errorMsg'>{this.state.pwChkError}</div>}
                  </>
                }

                <div className='inputTitle'>이름</div>
                <input tabIndex={4} ref={this.inputNameRef} autoComplete='name' className={'name ' + (this.state.nameError && 'error')} name='name' placeholder='김유폰' maxLength={7} value={this.state.name} onChange={this.onChangeName} onBlur={this.onBlurName} onClick={this.onClickfChk} style={{ 'imeMode': 'active' }} />
                {this.state.nameError && <div className='error errorMsg'>{this.state.nameError}</div>}

                <div className='inputTitle'>휴대전화번호</div>
                {/* 카카오 & 휴대폰번호가 있으면 번호 확인만 가능 */}
                {kakaoCellphoneUser && (<input tabIndex={5} ref={this.inputCellPhoneRef} className={'cellPhone ' + (this.state.cellPhoneError && 'error')} name='cellPhone' maxLength={13} type='tel' placeholder='010-1234-5678' value={this.state.cellPhone} onChange={this.onChangePhone} onClick={this.onClickfChk} onBlur={this.onBlurPhone} disabled style={{ width: '100%' }} />)}
                {/* 카카오 & 휴대폰번호가 있으면 인증번호 절차 생략 */}
                {!kakaoCellphoneUser && (
                  <div className='authNumWrap'>
                    <div className='authNumBox'>
                      <input tabIndex={5} ref={this.inputCellPhoneRef} className={'cellPhone ' + (this.state.cellPhoneError && 'error')} name='cellPhone' maxLength={13} type='tel' placeholder='010-1234-5678' value={this.state.cellPhone} onChange={this.onChangePhone} onClick={this.onClickfChk} onBlur={this.onBlurPhone} />
                      <div className={'btn g inputBtn authBtn ' + (!this.state.authBtn && 'disable')} onClick={this.reqAuth}>인증번호 받기</div>
                    </div>
                    <input ref={this.inputAuthRef} className={'authInput ' + (this.state.authError && ' error ') + (!this.state.authState && ' dis ')} type='tel' min='0' max='999999' placeholder='인증번호를 입력하세요' value={this.state.authInput} onChange={this.onChangeAuth} disabled={this.state.disabled} onBlur={this.onBlurAuth} onClick={this.onClickfChk} readOnly={!this.state.authState} />
                    {(this.state.authInfoView && !this.state.authTimeError) && <span className='timer'>유효시간 {this.state.countMin}분 {this.state.countSec}초</span>}
                    {this.state.cellPhoneError && <div className='error errorMsg'>{this.state.cellPhoneError}</div>}
                  </div>
                )}
                {this.state.authCheckFlag &&
                  <div className={'completeTxt adj'}><img src={process.env.REACT_APP_IMG_URL + '/join/ico_check.svg'} alt={'check'} /> 인증완료</div>
                }
                {this.state.authError && <div className='error errorMsg'>{this.state.authError}</div>}
                {this.state.joinType !== 'company' && <div className={'form-description'}>{this.state.commonMsg['SIGN000045']}</div> }
                <div className={'authInfo ' + (this.state.authInfoView ? 'view' : 'hide')}>
                  <div className={'title'}>인증번호가 오지 않나요?</div>
                  <div className={'list'}>1. 휴대전화번호를 다시 확인해주세요.</div>
                  <div className={'list'}>2. 1599 번호가 스팸 문자로 등록되어 있으면 해제해주세요.</div>
                  <div className={'list'}>3. 가상전화번호는 인증번호를 받을 수 없습니다.</div>
                </div>

                {this.state.joinType === 'company' && this.state.companyData && this.state.companyData.employeenoFlag &&
                  <div className={`employeeNoWrap ${this.state.companyData.employeenoFlag}`}>
                    <div className='inputTitle'>사번</div>
                    <input className={'inputBox view ' + (this.state.employeeNoError && 'error')} name='employeeNo' placeholder='사번 입력' readOnly={this.state.employeeNoInput} value={this.state.employeeNo} onChange={this.onChangeEmployeeNo} onClick={this.onClickfChk} />
                    {this.state.companyData.employeenoFlag && <div className={'btn inputBtn view ' + (this.state.employeeNoBtn && 'disable')} onClick={this.emplyeeNoChk}>등록</div>}
                  </div>}
                {this.state.joinType === 'company' && this.state.employeeNoInput && <div className={'completeTxt'}><img src={process.env.REACT_APP_IMG_URL + '/join/ico_check.svg'} alt={'check'} /> 확인완료</div>}
                {this.state.joinType === 'company' && this.state.employeeNoError && <div className='error errorMsg'>{this.state.employeeNoError}</div>}

                {this.state.joinType === 'company' && this.state.companyData && this.state.companyData.divisionCode1 &&
                  <div className={'employeeDivWrap'}>
                    <div className='inputTitle'>소속 부서</div>
                    {this.state[this.state.companyData.divisionCode1] &&
                      <select value={this.state[this.state.companyData.divisionCode1 + 'val']} onChange={(e) => this.seleChage(e, this.state.companyData.divisionCode1)} onBlur={(e) => this.seleChage(e, this.state.companyData.divisionCode1)}>
                        <option value='none'>1차 소속</option>
                        {this.state[this.state.companyData.divisionCode1].map((v, i) => {
                          return (
                            <option key={v + i} value={v.detailCode}>{v.detailName}</option>
                          );
                        })}
                      </select>}
                    {this.state[this.state.companyData.divisionCode2] && this.state[this.state.companyData.divisionCode2].length !== 0 &&
                      <select value={this.state[this.state.companyData.divisionCode2 + 'val']} onChange={(e) => this.seleChage(e, this.state.companyData.divisionCode2)} onBlur={(e) => this.seleChage(e, this.state.companyData.divisionCode2)}>
                        <option value='none'>2차 소속</option>
                        {this.state[this.state.companyData.divisionCode2].map((v, i) => {
                          return (
                            <option key={v + i} value={v.detailCode}>{v.detailName}</option>
                          );
                        })}
                      </select>}
                  </div>}
                {this.state.joinType === 'company' && this.state.employeeDivisionError && <div className='error errorMsg'>{this.state.employeeDivisionError}</div>}
                {this.state.joinType === 'company' && this.state.companyData && this.state.companyData.positionFlag &&
                  <div className={'employeePoWrap'}>
                    <div className='inputTitle'>직급</div>
                    <input className={'inputBox view ' + (this.state.employeePoError && 'error')} name='employeePo' placeholder='직급 입력' value={this.state.employeePo || ''} onChange={e => this.onChangeEmployeePo(e)} onClick={this.onClickfChk} onBlur={e => this.onChangeEmployeePo(e)} />
                  </div>}
                {this.state.joinType === 'company' && this.state.employeePoError && <div className='error errorMsg'>{this.state.employeePoError}</div>}
             
                {this.state.joinType === 'company' && this.state.companyData && this.state.companyData.extraFlag &&
                <div className={'extraInfoWrap'}>
                  <div className='inputTitle'>{this.state.companyData.extraTitle}</div>
                  <input
                    className={'inputBox view ' + (this.state.extraError && 'error')}
                    name='extraInfo'
                    placeholder={`${this.state.companyData.extraTitle} 입력`}
                    value={this.state.extraInfo}
                    onChange={this.onChangeExtraInfo}
                    onClick={this.onClickfChk}
                  />
                  {this.state.extraError && <div className='error errorMsg'>{this.state.extraError}</div>}
                </div>
                }
                
                {this.state.joinType === 'company' && this.state.companyData && this.state.companyData.extra2Flag &&
                <div className={'extraInfoWrap'}>
                  <div className='inputTitle'>{this.state.companyData.extra2Title}</div>
                  <input
                    className={'inputBox view ' + (this.state.extraError2 && 'error')}
                    name='extraInfo2'
                    placeholder={`${this.state.companyData.extra2Title} 입력`}
                    value={this.state.extraInfo2}
                    onChange={this.onChangeExtraInfo2}
                    onClick={this.onClickfChk}
                  />
                  {this.state.extraError2 && <div className='error errorMsg'>{this.state.extraError2}</div>}
                </div>
                }
              </form>
            </div>
            {this.state.joinType !== 'company' &&
              <div className='contentBox labelBox'>
                <div className='checkbox-container'>
                  <div className='labelWrap'>
                    <div className='sectionTitle'>{this.state.commonMsg['SIGN000040']}</div>
                  </div>
                  <div className='labelWrap'>
                    <label className='bordered'>
                      <input type='checkbox' checked={this.state.juniorFlag} onChange={this.onChangeJuniorMember}/>
                      <span>{this.state.commonMsg['SIGN000041']}</span>
                    </label>
                  </div>
                  {
                    this.state.juniorFlag &&
                    <>
                      <input tabIndex={6} ref={this.inputBirthRef} autoComplete='off' className={'birthday ' + (this.state.birthdayError && 'error')} name='birthday' placeholder={this.state.commonMsg['SIGN000043']} maxLength={8} value={this.state.birthday} onChange={this.onChangeBirthday} onBlur={this.onBlurBirthday} />
                      {this.state.birthdayError && <div className='error errorMsg'>{this.state.birthdayError}</div>}
                      <div className={'form-description'}>{this.state.commonMsg['SIGN000042']}</div>
                    </>
                  }
                </div>
              </div>
            }
            <div className='contentBox'>
              {this.state.joinType !== 'kakao' && (
                <>
                  <label className='labelchkAll'><input className='chkbox chkAll' type='checkbox' name='chkAll' onChange={this.listChkAll} checked={this.state.chkAll} /><span>전체 약관 동의</span></label>
                  <label><input className='chkbox c1' type='checkbox' name='c1' value='1' onChange={() => this.listChk(0)} checked={this.state.chkState[0]} /><span>만 14세 이상입니다.</span></label>
                  <div className={'labelWrap'}>
                    <label><input className='chkbox c2' type='checkbox' name='c2' value='2' onChange={() => this.listChk(1)} checked={this.state.chkState[1]} /><span>이용약관 동의</span></label>
                    <div className={'info'} id='info1' onClick={this.viewContent}>내용보기</div>
                  </div>
                  <div className={'labelWrap'}>
                    <label><input className='chkbox c3' type='checkbox' name='c3' value='3' onChange={() => this.listChk(2)} checked={this.state.chkState[2]} /><span>개인정보 수집 및 이용 동의</span></label>
                    <div className={'info'} id='info2' onClick={this.viewContent}>내용보기</div>
                  </div>
                  <div className={'labelWrap'}>
                    <label><input className='chkbox c4' type='checkbox' name='c4' value='4' onChange={() => this.listChk(3)} checked={this.state.chkState[3]} /><span>이벤트 메일, SMS, 푸시 수신 (선택)</span></label>
                    <div className={'info'} id='info3' onClick={this.viewContent}>내용보기</div>
                  </div>
                </>
              )}
            </div>
            {/* 카카오 유저면 이름만 채워지면 가입가능 */}
            {kakaoCellphoneUser && (
              <button
                className={this.state.name ? 'btn complete' : 'btn complete disable'}
                onClick={this.next2}
                disabled={!(this.state.name)}
              >
                회원가입 완료
              </button>
            )}
            {!kakaoCellphoneUser && (
              <button
                className={this.state.chkNext && this.state.joinNext ? 'btn complete' : 'btn complete disable'}
                onClick={this.next2}
                disabled={!(this.state.chkNext && this.state.joinNext)}
              >
                회원가입 완료
              </button>
            )}
          </div>

          {this.state.layerPopupMessage &&
            <LayerPopup
              desc1={this.state.layerPopupMessage}
              btnCnt={1}
              btnOkCmt={'확인'}
              btnOkFunction={this.closeLayerPopup}
            />
          }

          {this.props.match.params.page === 'p2' &&
            <div id={'contentViewWrap'} className={'policyWrap ' + this.state.pop} ref={(ref) => { this.contentView = ref; }}>
              <div className='infoPop info1'>
                <div className='title'><span>이용약관</span><button className={'closeBtn'} onClick={() => this.clsPop()} /></div>
                <div className={'content'}>
                  <PolicyUse />
                </div>
              </div>
              <div className='infoPop info2'>
                <div className='title'><span>개인정보처리방침</span><button className={'closeBtn'} onClick={() => this.clsPop()} /></div>
                <div className='content'>
                  <PolicyInfo />
                </div>
              </div>
              <div className='infoPop info3'>
                <div className='title'><span>이벤트 메일, SMS, 푸시 수신</span><button className={'closeBtn'} onClick={() => this.clsPop()} /></div>
                <div className='content tg'>
                  <PolicyReceiveMktInfo />
                </div>
              </div>
            </div>
          }
          {this.state.authLayerPopup &&
            <LayerPopup
              tit={this.state.authLayerPopup}
              btnCnt={1}
              btnOkCmt={'확인'}
              btnOkFunction={() => this.setState({ authLayerPopup: '' })}
            />
          }
        </div>
      </>
 
    );
  }
}
