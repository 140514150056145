import {Component} from 'react';
import { withRouter } from 'react-router';
class ScrollToTop extends Component {
  saveScrollPosition = {};
  temp = [];

  componentWillMount() {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    window.prevPathNameArr = [];
    if (navigator.userAgent.indexOf('bcm_agent::') >= 0){
      const redirectServer =
				window.location.hostname==='webstage.uphone3.com' ? 'https://appstage.uphone3.com' :
				  window.location.hostname==='webdev.uphone3.com' ? 'https://appdev.uphone3.com' :
				    window.location.hostname==='webqa.uphone3.com' ? 'https://appqa.uphone3.com' :
				      window.location.hostname==='webcheck.uphone3.com' ? 'https://appcheck.uphone3.com' :
				        'https://app.uphone3.com';
      window.location.href = redirectServer;
    }
  }
  componentDidUpdate(prevProps) {

    const {
      history: { action },
      location: { pathname: newPath = 'root' }
    } = this.props;

    const {
      location: { pathname = 'root' }
    } = prevProps;

    if (action === 'POP') {

      const pos = this.saveScrollPosition[this.props.location.pathname];
      if (pos) {
        window.scrollTo({top :  pos[1]});
        setTimeout(() => window.scrollTo({top :  pos[1]}), 500);
        window.prevPathNameArr.pop();
      }
    } else if (action === 'PUSH'){
      window.prevPathNameArr.push(prevProps.location.pathname);
      this.saveScrollPosition[prevProps.location.pathname] = this.temp[ this.props.location.pathname];
      setTimeout(() => window.scrollTo(0,0), 50);

      /* 팝업을 열고 닫지 않고 그 상태에서 이동한 경우 html과 body 속성이 변경되지 않기 때문에 강제로 초기화 함 - 2021.02.15 남인식*/
      document.querySelectorAll('html')[0].style.marginTop = 0;
      document.querySelectorAll('html')[0].style.overflow = 'initial';
      document.querySelectorAll('body')[0].style.setProperty('position', 'initial');
    }

  }

  render() {
    (() => {
      const {
        history : { action },
        location : { pathname : pathname }
      } = this.props;
      if (action === 'PUSH'){
        this.temp[ this.props.location.pathname ] = [window.pageXOffset, window.pageYOffset];
      }
    })();
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
