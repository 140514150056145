import LayerPopup from 'component/LayerPopup';
import { Loading } from 'component/Loading';
import OrderCompleted from 'component/purchase/OrderCompleted';
import { httpDefaultClient } from 'configs/api';
import { useAllCommonMessage } from 'queries/common/useCommonMessage';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { PurchaseResult } from 'types/purchase/purchase.interface';


const OrderResult = () => {
  const { seq } = useParams<{ seq: string }>();
  const history = useHistory();
  const { isFetched: isCommonMsgFetched, data: commonMsg } = useAllCommonMessage();
  const [isB2BLimitedSoldOut, setIsB2BLimitedSoldOut] = useState(false);
  const [purchase, setPurchase] = useState<PurchaseResult | null>(null);
  const [purchaseError, setPurchaseError] = useState(false);
  const [purchaseErrorMsg, setPurchaseErrorMsg] = useState('');
  const [reload, setReload] = useState(false);
  const [purchaseComplete, setPurchaseComplete] = useState(false);

  useEffect(() => {
    getPurchaseInfo();
  }, []);

  const getPurchaseInfo = async () => {
    try {
      const response = await httpDefaultClient.get(`/purchase/${parseInt(seq)}`);
      const data = response.data;
      if (!data) {
        window.location.href = '/';
        return;
      }
      setPurchase(data);
      if (data.purchasestatusCode === 'COMPLETE') { // 결제 성공
        setPurchaseComplete(true);

        // 결제 완료 시 결제를 위해 저장해둔 정보 삭제       
        localStorage.removeItem('personal');
        localStorage.removeItem('purchaseAddress');
        localStorage.removeItem('purchaseAddressDetail');
      } else if (data.resultCode === 'PUR000108' || data.resultCode === 'PUR000109') { // B2B 전체 선착순 마감
        history.push('/Order/p4/full');
      } else {
        if (data.resultMessage === 'userCancel') {
          setPurchaseErrorMsg(data.productPrice.billFlag ? '정기결제 등록을 취소하셨습니다. 주문 내용 확인 후 다시 등록해주세요.' : '결제를 취소하셨습니다.주문 내용 확인 후 다시 결제해주세요.');
        } else if (data.resultMessage === 'OwnerAuthFail') {
          setPurchaseErrorMsg('타인 명의 카드는 결제가 불가능합니다. 회원 본인 명의의 카드로 결제해주세요.');
        } else if (data.resultMessage === 'paymentTimeExpire') {
          setPurchaseErrorMsg('결제 가능한 시간이 지났습니다. 주문 내용 확인 후 다시 결제해주세요.');
        } else if (data.resultMessage === 'webhookFail') {
          setPurchaseErrorMsg('webhookUrl 호출 응답 실패');
        } else if (data.resultCode === 'PUR000160' || data.resultCode === 'PUR000161') { // B2B 부분 선착순 마감
          setIsB2BLimitedSoldOut(true);
        }
        setPurchaseError(true);
      }
    } catch (error) {
      console.error('error******', error);
    }
  };

  // 주문 에러 팝업 사유
  const getErrorDescription = () => {
    if (isB2BLimitedSoldOut) {
      return '(사유: 선착순 마감되었습니다)';
    } else if (purchaseErrorMsg) {
      return '사유 : ' + purchaseErrorMsg;
    } else if (purchase && purchase.resultMessage) {
      return '사유 : ' + purchase.resultMessage.replace(/(<([^>]+)>)/ig, '');
    }
    return '사유 : ' + commonMsg?.['PUR000013'];
  };

  // 주문 에러 팝업 확인 버튼 클릭
  const handleErrorBtnOk = () => {
    if (isB2BLimitedSoldOut) {
      history.push('/Order');
      return;
    }
    if (purchase) {
      if (reload) {
        window.parent.location.href = '/Order/p2/' + purchase.productPrice.priceSeq + '/error';
      } else {
        history.replace('/Order/p2/' + purchase.productPrice.priceSeq + '/error');
      }
      return;
    }
    history.push('/Order');
  };

  if (!purchaseError && !purchaseComplete) {
    return <Loading />;
  }

  return (
    <div>
      {/* 주문 에러 */}
      {purchaseError && isCommonMsgFetched && (
        <LayerPopup
          tit={'결제취소'}
          desc1={'하기와 같은 사유로 결제가 취소되었습니다.'}
          desc2={getErrorDescription()}
          btnCnt={1}
          btnOkCmt={commonMsg?.['0000000094']}
          btnOkFunction={handleErrorBtnOk}
        />
      )}
      {/* 주문 완료 */}
      {purchaseComplete && purchase && (
        <OrderCompleted purchaseInfo={purchase} />
      )}
    </div>
  );
};

export default OrderResult;
