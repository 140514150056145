import React, {Component} from 'react';
import {onLogout, ScrollNone, resizeFn} from '../common/utilFn';
import { popupStore } from 'store/popup/popupStore';

import '../css/Header.scss';
import {httpDefaultClient, httpRefreshClient} from 'configs/api';

export default class Top extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mGnb: false, //모바일 사이즈에서 메뉴 열고 닫기 참조용
      pGnb: false,
      pItem1: false,
      pItem2: false,
      isLogin : false,
      topBannerData : [],
      topBannerDataNote5 : 0,
      loaded : false,
      nestedMenu : {
        why: false,
        free: false,
        b2b: false,
      },
      isB2BUser: false,
    };
  }

  componentWillMount(){
    resizeFn({ww:true}, this);

    httpRefreshClient.post('/sign/checkLogin').then(res => {
      if (res.data.result) {
        this.setState({isLogin: true, loaded: true});
        this.tokenOK();
        const memberClass = res.data.memberClass;
        const appDownloadLink = res.data.appDownloadLink || '';
        this.setState({ isB2BUser: memberClass === 'B2B' });
        popupStore.getState().setAppDownloadLink(appDownloadLink);
      } else {
        this.setState({loaded : true});
      }
    });
  }

  hisBack = () => {
    // 최상단 유지해주세요. 뒤로가기 제어시 사용됩니다.
    if (this.props.back) {
      this.props.back();
      return null;
    }
    this.props.history.goBack();
    if (this.props.chg) {
      this.props.chg();
    }
  };

  updateNestedMenuForKey = (clickedKey) => {
    this.setState(prevState => {

      const {nestedMenu} = prevState;

      const updatedNestedMenu = {
        ...nestedMenu,
        [clickedKey]: !this.state.nestedMenu[clickedKey]
      };

      return {
        ...prevState,
        nestedMenu: updatedNestedMenu
      };
    });
  };

  deactivateNestedMenu = (clickedKey) => {
    this.setState(prevState => {
      const updatedState = { ...prevState };
      const nestedState = updatedState.nestedMenu;

      Object.keys(nestedState).forEach(stateKey => {
        nestedState[stateKey] = false;
      });

      if (clickedKey !== 'reset') {
        nestedState[clickedKey] = true;
      }

      return updatedState;
    });
  };

  onHandleMenuClick = (menuKey) => {

    if (this.state.mGnb) {
      this.updateNestedMenuForKey(menuKey);
    }

    if (!this.state.mGnb && !this.state.nestedMenu[menuKey]) {
      this.deactivateNestedMenu(menuKey);
    }
  };

  onHandleMenuEnter = (menuKey) => {
    const valueToCheck = this.state.nestedMenu[menuKey];

    if (!this.state.mGnb && !valueToCheck) {
      this.updateNestedMenuForKey(menuKey);
    }
  };

  onHandleMenuLeave = () => {
    if (!this.state.mGnb) {
      this.deactivateNestedMenu('reset');
    }
  };

  viewMenu = e => {
    this.setState({
      mGnb: true,
    });
    ScrollNone(true);
  };
  hideMenu = e => {
    this.setState({
      mGnb: false,
    });
    ScrollNone(false);
  };

  tokenOK = () => {
    httpDefaultClient.get('/myInfo/list').then(res => {
      const json = res.data;
      if (json.result) {
        this.setState({
          memberName : json.data.memberName,
          memberSeq : json.data.memberSeq,
          memberClass: json.data.memberClass,
        });
        sessionStorage.setItem('uphone_fb_ms', json.data.memberSeq); // 세션스토리지에 멤버 seq 저장해서 facebook 전환 api 호출할 때 가져가서 호출함
        sessionStorage.setItem('uphone_fb_email', json.data.memberEmail); // 세션스토리지에 멤버 이메일 저장해서 facebook 전환 api 호출할 때 가져가서 호출함
        sessionStorage.setItem('uphone_fb_cellphone', json.data.memberCellphone); // 세션스토리지에 멤버 전번 저장해서 facebook 전환 api 호출할 때 가져가서 호출함
        document.cookie = `uphone_ga_email=${encodeURIComponent(json.data.memberEmail)}`; // 구글 애널리틱스용 이메일을 쿠키값으로 저장
      } else {
        this.props.history.push('/Login');
      }
    }).catch(error => { console.error('/myInfo/list error : ', error); });

    httpDefaultClient.get('/lectureRoom/lessonStatus').then(res => {
      this.setState({
        memStatus: res.data.schedulestatusCode,
        languageType: res.data.languageType
      });
    }).catch(error => {
      console.error('lessonStatus error : ', error);
    });
  };
  render(){
    const ts = this.state;

    const showOrderButton = !(this.ww === 'mo' && (this.props.history.location.pathname.toLowerCase().indexOf('/order') !== -1));
    const showTrialLandingButton = this.ww === 'mo' && (this.props.history.location.pathname.toLowerCase().indexOf('/order') === -1);

    {/**
		 this.props 데이터 사용 내역 정리
		 - noBg : (Order.js) 의 step1에서 pc 사이즈일 때 true
		 - notFixed : (Order.js) 의 step1에서 pc 사이즈일 때 true
		 - movePageName : (Order.js) 의 step1에서 mobile 사이즈일 때 상단 타이틀 오른쪽 애니메이션
		 - useTit(boolean) : (Order.js) 의 step1에서 mobile 사이즈일 때 상단 타이틀,
		 */}
    return (
      <>
        <header id='bcmHeader' className={`bcm-header is-top${(ts.pItem1 || ts.pItem2) ? ' adj' : ''}${this.props.noBg ? ' is-transparent' : ''}${this.props.notFixed ? ' is-not-fixed' : ''}${this.props.onn === 'on' || ts.pGnb ? ' is-active' : ''}`}
          style={{top : this.props.setTopBannerMargin ? this.props.setTopBannerMargin + 'px' : '0'}}
        >
          <div className='bcm-header__inner'>
            <h1 className='bcm-header__logo'><a href={process.env.REACT_APP_MAIN_URL + '/'}><span className='blind'>민병철유폰</span></a></h1>
            {this.props.useTit && <h2 className={`bcm-header__page-title ${this.props.theme === 'white' ? 'is-white':''}`}>{this.props.title}</h2>}
            { this.props.history.location && this.props.history.location.pathname
						&& (this.props.history.location.pathname.toLowerCase().indexOf('/order/p2') > -1
							|| (this.props.history.location.pathname.toLowerCase().indexOf('/order/p4') > -1 && this.props.back)
							|| (this.props.history.location.pathname.toLowerCase().indexOf('/order/p5') > -1)) ?
              <>
                <div className={'header--rf__headerInner--historyBack'} onClick={ this.hisBack }>
                  <svg id='btn_prev' xmlns='http://www.w3.org/2000/svg' width='100%' height='100%' viewBox='0 0 48 48'>
                    <rect id='btn_area' width='48' height='48' fill='#fff' opacity='0'/>
                    <g transform='translate(-66.5 -45)'>
                      <rect width='25' height='2' rx='1' transform='translate(67.5 68.678) rotate(-45)' fill={this.props.color === 'w' ? '#fff' : '#111'}/>
                      <rect width='25' height='2' rx='1' transform='translate(68.914 67.3) rotate(45)' fill={this.props.color === 'w' ? '#fff' : '#111'}/>
                      <rect width='44' height='2' rx='1' transform='translate(69.5 68)' fill={this.props.color === 'w' ? '#fff' : '#111'}/>
                    </g>
                  </svg>
                </div>
                <div className={'header--rf__headerInner__title'}>{this.props.title}</div>
              </>
              :
              <>
                <button type='button' className={`btn-menu ${this.props.theme === 'white' ? ' is-white':''}`} onClick={() => this.viewMenu()}><span className='blind'>모바일 메뉴 열기</span></button>
              </>
            }
            {this.props.movePageName &&
              <div className={'move-page'} onClick={this.props.movePage?() => this.go(this.props.movePage):this.props.openPopup}>
                {this.props.ani === 'wave' ?
                // this.state.movePageName.map((t, i) => {
                  this.props.movePageName.split('').map((t, i) => {
                    return (
                      <span key={i} className={(i === 7 || i === 8) ? 'adj' : (i === 2 || i === 6) ? 'space' : ''}>{t}</span>
                    );
                  })
                  :
                  this.props.movePageName
                }
              </div>
            }
            <nav className={`bcm-gnb ${ts.mGnb ? ' bcm-gnb--active' : ''}`}>
              { ts.loaded &&
                    <>
                      <div className='bcm-gnb__mo-head'>
                        <a href={process.env.REACT_APP_MAIN_URL + '/'} title='민병철유폰' className='btnLogo'>민병철유폰</a>
                        {ts.isLogin?
                          <div className='mem-info loginContent'>
                            <p className='gnbTopTxt'>
                              <strong className='name'>{ts.memberName}님</strong>
                            </p>
                            <a href={process.env.REACT_APP_ENV_URL + '/Order'} className={`btnOrder ${ts.isLogin ? ' loginContent' : ''}`}>수강신청</a>
                          </div>
                          :
                          <p className='message-welcome logoutContent'>
                            <a href={process.env.REACT_APP_ENV_URL + '/Join'} title='가입/로그인' className='btnLogin'>가입/로그인</a>하고
                                    영어회화 시작하세요.
                          </p>
                        }
                      </div>
                      <ul className='bcm-gnb__list'>
                        <li className={`bcm-gnb__item ${ts.nestedMenu.why?'on':''}`}>
                          <a href='#' className='first-depth first-depth--btn' onClick={(e) => {e.preventDefault(); this.onHandleMenuClick('why');}} onMouseEnter={() => this.onHandleMenuEnter('why')} onMouseLeave={() => this.onHandleMenuLeave()}>
                            <span className='eng'>Why</span>유폰</a>
                          <ul className={`second-depth-items second-depth-items--why ${(ts.nestedMenu.why && this.ww === 'mo')?'is-open':''}`}>
                            <li className='voice-column'>
                              <a href={process.env.REACT_APP_MAIN_URL + '/sub/VoiceCall'} className='second-depth'><span>전화영어</span> <br className='pc-br' />보이스콜</a>
                            </li>
                            <li className='face-column'>
                              <a href={process.env.REACT_APP_MAIN_URL + '/sub/FaceCall'} className='second-depth'><span>화상영어</span> <br className='pc-br' />페이스콜</a>
                            </li>
                            <li>
                              <a href={process.env.REACT_APP_MAIN_URL + '/sub/onetoone'} className='second-depth'>
                                외국인강사
                                <p>TESOL 수료 <br/>
                                  프로급 강사진</p>
                              </a>
                            </li>
                            <li>
                              <a href={process.env.REACT_APP_MAIN_URL + '/sub/coursesubs'} className='second-depth'>
                                무제한 과정
                                <p>ORIGINAL 콘텐츠 <br/>
                                  무제한 구독</p>
                              </a>
                            </li>
                            <li>
                              <a href={process.env.REACT_APP_MAIN_URL + '/sub/reinforcement'} className='second-depth'>
                                보강 100%
                                <p>ONLY 유폰! <br/>
                                  10분 전 취소, 보강</p>
                              </a>
                            </li>
                            <li>
                              <a href={process.env.REACT_APP_MAIN_URL + '/sub/speech-booster'} className='second-depth'>
                                유폰 GPT· <br className='pc-br' />
                                발음 부스터
                                <p>
                                  AI학습으로 수업준비<br/>
                                  발음 교정, 영작 첨삭까지</p>
                              </a>
                            </li>
                            <li>
                              <a href={process.env.REACT_APP_MAIN_URL + '/sub/App'} className='second-depth'>
                                유폰 APP
                                <p>앱 하나로 <br/>
                                  놀라운 학습 경험</p>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className='bcm-gnb__item'>
                          <a href={process.env.REACT_APP_MAIN_URL + '/course/list'} className='first-depth'>전체과정</a>
                        </li>
                        <li className='bcm-gnb__item'>
                          <a href={process.env.REACT_APP_MAIN_URL + '/junior'} className='first-depth first-depth--junior' target='_blank' rel='noreferrer'>유폰주니어70<img src={process.env.REACT_APP_IMG_URL + '/web/gnb/ico_junior.svg'} width={5.5} height={5.5} style={{objectFit : 'contain'}} alt='' /></a>
                        </li>
                        <li className={`bcm-gnb__item ${ts.nestedMenu.b2b?'on':''}`}>
                          <a href='#' className='first-depth first-depth--btn' onClick={(e) => {e.preventDefault(); this.onHandleMenuClick('b2b');}} onMouseEnter={() => this.onHandleMenuEnter('b2b')} onMouseLeave={() => this.onHandleMenuLeave()}>
                            기업교육</a>
                          <ul className={`second-depth-items second-depth-items--b2b ${(ts.nestedMenu.b2b && this.ww === 'mo')?'is-open':''}`}>
                            <li>
                              <a href='https://b2b.uphone.co.kr/' className='second-depth'>기업 단체교육
                                <span>전화·화상 출강 <br />기업 외국어교육</span>
                              </a>
                            </li>
                            <li>
                              <a href='https://salesmap.kr/web-form/4a04a516-a6b9-4cd0-8876-4f1c06b1f95c' className='second-depth'>기업 교육제휴
                                <span>전화·화상 영어 <br />교육예산 0원 복지</span>
                              </a>
                            </li>
                            <li>
                              <a href='https://uphone.channel.io/support-bots/26147' className='second-depth'>교육도입 문의
                                <span>02-6956-0509 <br />교육담당자 전용</span>
                              </a>
                            </li>
                          </ul>
                        </li>
                        <li className={`bcm-gnb__item ${ts.nestedMenu.free ? 'on':''}`}>
                          <a href='#' className='first-depth first-depth--btn' onClick={(e) => {e.preventDefault(); this.onHandleMenuClick('free');}} onMouseEnter={() => this.onHandleMenuEnter('free')} onMouseLeave={() => this.onHandleMenuLeave()}>{ts.isB2BUser ? '레벨테스트' : '무료수업'}</a>
                          <ul className={`second-depth-items ${(ts.nestedMenu.free && this.ww === 'mo')?'is-open':''}`}>
                            <li>
                              <a href={process.env.REACT_APP_MAIN_URL + '/lessonreview/fl'} className='second-depth'>수업 후기</a>
                            </li>
                            {ts.isB2BUser ? (
                              <li>
                                <a href='#' onClick={(e) => { e.preventDefault(); popupStore.getState().openAppDownloadPopup();}} className='second-depth'>예약하기</a>
                              </li>
                            ) : (
                              <li>
                                <a href={process.env.REACT_APP_ENV_URL + '/trial'} className='second-depth'>신청하기</a>
                              </li>
                            )}
                          </ul>
                        </li>
                        <li className='bcm-gnb__item bcm-gnb__item--mo'>
                          <a href={process.env.REACT_APP_MAIN_URL + '/lessonreview'} className='first-depth'>수업 후기</a>
                        </li>
                        <li className='bcm-gnb__item bcm-gnb__item--pc'>
                          {ts.isLogin
                            ?
                            <a href='#' onClick={(e) => {e.preventDefault(); onLogout( () => window.location.reload());}} className='first-depth loginContent'>로그아웃</a>
                            :
                            <a href={process.env.REACT_APP_ENV_URL + '/Join'} className='first-depth logoutContent'>가입/로그인</a>
                          }
                        </li>

                        {ts.isLogin && ts.memStatus === 'INCLASS' && (ts.languageType === 'en' || ts.languageType === 'jp' || ts.languageType === 'cn') &&
                          <li className={'bcm-gnb__item bcm-gnb__item--mo bcm-gnb__item--classroom loginContent'}>
                            <strong className='message-item'>
                              <img className={'info'} src={process.env.REACT_APP_IMG_URL + '/common/ico_excm.svg'} alt='info'/> 곧 수업인데 앱 사용이 어려운가요?
                            </strong>
                            <a href={process.env.REACT_APP_ENV_URL + '/ClassRoom'} title='웹 강의실 이용하기' className={'lnk-item move'}>웹 강의실 이용하기</a>
                          </li>
                        }

                      </ul>
                      <div className='bcm-gnb__mo-foot'>
                        {ts.isLogin  &&
                          <a href='#' title='로그아웃' onClick={(e) => {e.preventDefault(); onLogout( () => window.location.reload());}} className='btnLogout'>로그아웃</a>
                        }
                      </div>
                      <button type='button' className='btn-close' onClick={(e) => {e.preventDefault();this.hideMenu();}}>닫기</button>
                    </>
              }
            </nav>
            {
              (showOrderButton || showTrialLandingButton) && (
                <nav className='add-nav'>
                  { showOrderButton &&
                  <a href={process.env.REACT_APP_ENV_URL + '/Order'} title='수강신청' className={`lnk-order ${this.props.theme === 'white' ? ' is-white' : ''}`}>수강신청</a>
                  }
                  { showTrialLandingButton &&
                  <a href={`${process.env.REACT_APP_MAIN_URL}/adv/freetrial`} className={`lnk-trial-adv ${this.props.theme === 'white' ? ' is-white' : ''}`}>무료수업 안내 랜딩 바로가기</a>
                  }
                </nav>
              )
            }
          </div>
        </header>

        <h1 className={'moTitle'}>{this.props.title}
          <div className='historyBack' onClick={ () => {this.props.history.go(-1);}}>
            <svg id='btn_prev' xmlns='http://www.w3.org/2000/svg' width='2.4rem' height='2.4rem'
							 viewBox='0 0 48 48'>
              <rect id='btn_area' width='48' height='48' fill='#fff' opacity='0'></rect>
              <g transform='translate(-66.5 -45)'>
                <rect width='25' height='2' rx='1'
									  transform='translate(67.5 68.678) rotate(-45)' fill='#111'></rect>
                <rect width='25' height='2' rx='1' transform='translate(68.914 67.3) rotate(45)'
									  fill='#111'></rect>
                <rect width='44' height='2' rx='1' transform='translate(69.5 68)'
									  fill='#111'></rect>
              </g>
            </svg>
          </div>
        </h1>
      </>
    );
  }
};
