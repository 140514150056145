import React from 'react';
import {SmallTalkType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: SmallTalkType;
}

export default function SmallTalk({componentCode, content}: PropsType) {
  return (
    <div className={`page ${componentCode}`}>
      <div className={'leveltest-component-name'}>1.의사소통</div>
      <div className='component-desc'>강사님과 가볍게 오늘의 대화를 나눠보세요.</div>
      <div className='smalltalk'>
        <div className='smalltalk-header'>
          <div className='smalltalk-header__title'>오늘의 주제</div>
          <div className='smalltalk-header__subject'>{content.subject}</div>
        </div>
        {content.examples && content.examples.length > 0 && (
          <div className='tip'>
            <div className='tip__title'>
              <span>TIP</span>아래 단어를 활용해도 좋아요
            </div>
            <ul>
              {
                content.examples.map((item, index) => (
                  <li key={`k${index}`}>
                    {item.sentence}
                    {item.description ? <span>{item.description}</span> : ''}
                  </li>
                ))
              }
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}
