import React, {Component} from 'react';
import '../css/layerPopup.scss';
import moment from 'moment';
import * as utilFn from '../common/utilFn';

export default class LayerPopup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      type : this.props.type,
      li1 : this.props.li1,
      li2 : this.props.li2,
      li3 : this.props.li3,
      li4 : this.props.li4,
      btnFull : this.props.btnFull,
      btnFullFunction : this.props.btnFullFunction,
      btnLine : this.props.btnLine,
      btnLineFunction : this.props.btnLineFunction,
      tit : this.props.tit,
      desc1 : this.props.desc1,
      desc2 : this.props.desc2,
      desc3 : this.props.desc3,
      desc4 : this.props.desc4,
      ticketReplaceDescription : this.props.ticketReplaceDescription || '',
      topRed : this.props.topRed,
      topRed2 : this.props.topRed2,
      bottomRed : this.props.bottomRed,
      chkYN : this.props.chkYN,
      chkCmt : this.props.chkCmt,
      chkNotOpenDays : this.props.chkNotOpenDays,
      chkNotOpenId : this.props.chkNotOpenId,
      chkNotOpenCmt : this.props.chkNotOpenCmt,
      btnCnt : this.props.btnCnt,
      btnOkCmt : this.props.btnOkCmt,
      btnOkFunction : this.props.btnOkFunction,
      btnNoCmt : this.props.btnNoCmt,
      btnNoCmtClass : this.props.btnNoCmtClass || '',
      btnNoFunction : this.props.btnNoFunction,
      btnYesCmt : this.props.btnYesCmt,
      btnYesCmtClass : this.props.btnYesCmtClass || '',
      btnYesFunction : this.props.btnYesFunction,
      //popupView : true,
      checked : false,
      unmountScrollNone : this.props.unmountScrollNone ? this.props.unmountScrollNone : 'Y'
    };
  }

  componentWillUnmount() {
    if (this.state.unmountScrollNone === 'Y' ) utilFn.ScrollNone();
  }

  onClickChkBox = () => {
    this.setState({
      checked : !this.state.checked
    });

  };


  doNotOpenChkd = false;
  onClickNotOpenChkBox = () => {
    this.doNotOpenChkd = !this.doNotOpenChkd;
    if (this.doNotOpenChkd) {
      localStorage.setItem('doNotOpen'+this.state.chkNotOpenId, moment().add(this.state.chkNotOpenDays-1, 'days').format('YYYY-MM-DD'));
    } else {
      localStorage.removeItem('doNotOpen'+this.state.chkNotOpenId);
    }
  };


  render(){
    const state = this.state;
    const props = this.props;
    return (
      <div className={'dimmed'}>
        {state.type === 'type01' ?
          <div className={'alertBox type01'}>
            <div className={'tit'} dangerouslySetInnerHTML={{__html: state.tit}}/>
            <div className={'desc'}>
              {state.desc1 && <p dangerouslySetInnerHTML={{__html: state.desc1}}/>}
              {state.desc2 && <p dangerouslySetInnerHTML={{__html: state.desc2}}/>}
            </div>
            {state.li1 &&
						<ul>
						  {state.li1 && <li dangerouslySetInnerHTML={{__html: state.li1}}/>}
						  {state.li2 && <li dangerouslySetInnerHTML={{__html: state.li2}}/>}
						  {state.li3 && <li dangerouslySetInnerHTML={{__html: state.li3}}/>}
						  {state.li4 && <li dangerouslySetInnerHTML={{__html: state.li4}}/>}
						</ul>
            }
            {state.btnFull && <button className={'btn full'} onClick={state.btnFullFunction} dangerouslySetInnerHTML={{__html: state.btnFull}}/>}
            {state.btnLine && <div className={'btnLine'}><span onClick={state.btnLineFunction} dangerouslySetInnerHTML={{__html: state.btnLine}}/></div>}
            {props.caution && <div className={'cautionTxt'} dangerouslySetInnerHTML={{__html: props.caution}} />}
          </div>
          :
          <div className={'alertBox'}>
            <div className={'tit'} dangerouslySetInnerHTML={{__html: state.tit}}/>
            <div className={'desc'}>
              {state.topRed && <span>{state.topRed}</span>}
              {state.topRed2 && <span className={'red2'}>{state.topRed2}</span>}
              {state.desc1 && <p dangerouslySetInnerHTML={{__html: state.desc1}}/>}
              {state.desc2 && <p dangerouslySetInnerHTML={{__html: state.desc2}}/>}
              {state.bottomRed && <span>{state.bottomRed}</span>}
              {state.ticketReplaceDescription && <p className='ticket-replace-description'>{state.ticketReplaceDescription}</p>}
              {state.desc3 && <span className={'gray'} dangerouslySetInnerHTML={{__html: state.desc3}}/>}
              {state.desc4}
            </div>
            {state.chkYN === 'Y' &&
						<div className={'chkWrap'}>
						  <label>
						    <input type='checkbox' className={'inputChk'}/>
						    <span onClick={this.onClickChkBox}>{state.chkCmt}</span>
						  </label>
						</div>
            }
            {state.chkNotOpenCmt &&
						<div className={'chkWrap gray'}>
						  <label>
						    <input type='checkbox' className={'inputChk'}/>
						    <span onClick={this.onClickNotOpenChkBox}>{state.chkNotOpenCmt}</span>
						  </label>
						</div>
            }
            <div className={'btnWrap'}>
              {state.btnCnt === 1 ?
                <span className={'confirm'} onClick={state.btnOkFunction}>{state.btnOkCmt}</span>
                :
                <>
                  <span className={state.btnNoCmtClass} onClick={state.btnNoFunction}>{state.btnNoCmt}</span>
                  <span onClick={state.chkYN === 'Y' ? (state.checked ? state.btnYesFunction : null) : state.btnYesFunction} className={state.chkYN === 'Y' ? state.checked ? `yes active ${state.btnYesCmtClass}` : `yes ${state.btnYesCmtClass}` : `yes active ${state.btnYesCmtClass}`}>{state.btnYesCmt}</span>
                </>
              }
            </div>
          </div>
        }
      </div>
    );
  }
};
