export function freeLessonDataForGA4() {
  if (window.location.hostname === 'localhost') {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'LT_Conv'
  });
};
