import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import unitedTopBannerSlice, { IUnitedTopBanner, UnitedTopBannerSliceT } from './unitedTopBannerSlice';
import unitedTopBannerControlSlice, { UnitedTopBannerControlSliceT } from './unitedTopBannerControlSlice';

import { getUnitedTopBanner } from '../../services/topBanner/topBanner';

export const unitedTopBannerStore = create<
  UnitedTopBannerSliceT &
  UnitedTopBannerControlSliceT
>()(devtools((...all) => ({
  ...unitedTopBannerSlice(...all),
  ...unitedTopBannerControlSlice(...all),
}), { store: 'unitedTopBanner' }));

export default unitedTopBannerStore;

export async function readUnitedTopBanner({ 
  currentPathName, currentParamName }: { currentPathName: string, currentParamName: string, 
}) {
  const setUnitedTopBanner = unitedTopBannerStore.getState().setUnitedTopBanner;

  const unitedTopBanner: IUnitedTopBanner = await getUnitedTopBanner({ siteCode: 'app', path: currentPathName, param: currentParamName }) || {};
  setUnitedTopBanner(unitedTopBanner);
}
