import React from 'react';

import reserveTrialStore, { changeTrial, isChangeMode, memberReserveTrial, nonMemberReserveTrial } from 'store/trial/reserve/reserveTrialStore';

export default function ReserveTrialButton() {
  const isReserveButtonActive = reserveTrialStore((state) => state.isReserveButtonActive);
  const memberFlag = reserveTrialStore((state) => state.memberFlag);
  const buttonText = isChangeMode() ? '무료수업 신청 변경' : '무료수업 신청하기';
  
  const handleClickReserveButton = () => {
    if (!isReserveButtonActive) {
      return;
    }
    if (isChangeMode()) {
      changeTrial();
      return;
    }
    
    if (memberFlag) {
      memberReserveTrial();
    } else {
      nonMemberReserveTrial();
    }
  };

  return (
    <button className={`reserve-button ${!isReserveButtonActive && 'disabled'}`} onClick={handleClickReserveButton}>
      {buttonText}
    </button>
  );
}
