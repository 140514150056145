import React from 'react';
import { completeTrialStore, isChangeMode } from 'store/trial/complete/completeTrialStore';
import TrialCompleteFooterForPc from 'component/trial/complete/TrialCompleteFooterForPc';
import TrialCompleteAppDownloadPopup from 'component/trial/complete/TrialCompleteAppDownloadPopup';
import LayerPopup from 'component/LayerPopup';
import { saveAccessDataByAccessFunction } from '../../../services/accessData/accessAnalytics';

export default function TrialCompleteTypeB() {
  const trialInfo = completeTrialStore((state) => state.trialInfo);
  const originalDateTime = trialInfo.lessonDate;
  const formattedDateTime = originalDateTime.replace(/-/g, '.');
  const appDownloadPopupFlag = completeTrialStore((state) => state.appDownloadPopupFlag);
  const isShowChangeAlert = completeTrialStore((state) => state.isShowChangeAlert);
  const setIsShowChangeAlert = completeTrialStore((state) => state.setIsShowChangeAlert);
  const setAppDownloadPopupFlag = completeTrialStore((state) => state.setAppDownloadPopupFlag);
  const description = isChangeMode() ? '유폰 수업이 변경되었어요.' : '유폰 수업이 준비되었어요.';

  const goHome = () => {
    window.location.href = '/';
  };

  const openAppDownloadPopup = () => {
    saveAccessDataByAccessFunction('pop_up_app_down_button');
    if (window.innerWidth >= 1025) {
      setAppDownloadPopupFlag(true);
    } else {
      location.href = trialInfo.appDownloadLink;
    }
  };

  return (
    <div className='trialComplete trialCompleteTypeB'>
      <img className={'logo'} onClick={goHome} src={process.env.REACT_APP_IMG_URL + '/course/freelesson_bi.svg'} alt={'민병철유폰'} />
      <p className='trialCompleteTypeB__title'>
        {trialInfo.memberName}님을 위한<br />
        {description}
      </p>
      <ul className={'trialCompleteTypeB__progress'}>
        <li className={'trialCompleteTypeB__progress-item'}>
          <div className={'number'}>1</div>
          <div className={'title'}>예약 내역 확인</div>
          <div className={'card'}>
            <div className={'card__date-title'}>수업 일시</div>
            <div className={'card__date-content'}>{formattedDateTime} / {trialInfo.lessonTime}</div>
            <div className={'card__content'}>수업 방식 : {trialInfo.lessonMode === 'VIDEO' ? '화상수업' : '전화수업'} {trialInfo.lessonMinute}분</div>
            <div className={'card__content'}>수업 주제 : {trialInfo.courseName}</div>
            <div className={'card__change'} onClick={openAppDownloadPopup}>주제 변경 {'>'}</div>
          </div>
        </li>
        <li className={'trialCompleteTypeB__progress-item'}>
          <div className={'number'}>2</div>
          <div className={'title'}>앱 설치하고 수업준비</div>
          <div className={'card'}>
            <div className={'card__content'}>수업은 앱에서 진행됩니다. <br />앱을 꼭 설치하세요</div>
            <button className={'card__download'} onClick={openAppDownloadPopup} />
          </div>
        </li>
        <li className={'trialCompleteTypeB__progress-item'}>
          <div className={'number'}>3</div>
          <div className={'title'}>앱에서 무료수업 시작</div>
        </li>
      </ul>
      <button onClick={openAppDownloadPopup} className='trialComplete__book'>앱 설치하기</button>
      <TrialCompleteFooterForPc />
      {appDownloadPopupFlag && <TrialCompleteAppDownloadPopup />}
      {isShowChangeAlert &&
        <LayerPopup
          tit={'수업시간 변경 안내'}
          desc1={`무료수업 신청 진행 중, 선택한 수업 시간이 마감되어 <b>${trialInfo.lessonTime}</b> 으로 예약해 드렸어요.`}
          desc2={'<br/>내 수업 정보 확인 및 시간 변경은 카톡으로 발송된 메시지를 확인해주세요.'}
          btnCnt={1}
          btnOkCmt={'확인'}
          btnOkFunction={() => setIsShowChangeAlert(false)}
        />
      }
    </div>
  );
}
