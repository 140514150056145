import React, { Fragment, useState } from 'react';

import SupportBoard from '../../component/common/SupportBoard';
import * as utilFn from '../../common/utilFn';

import PurchaseSquareTab from './PurchaseSquareTab';
import TicketItem from './TicketItem';
import TemplateSelect from './TemplateSelect';

const TicketVendingMachine = ({ props }) => {

  // state
  const {
    ww,
    commonMsg,
    productMinuteList, productWeekList, templateCodeList, lessonModeList, timezoneCodeList, productTypeList,
    productMinuteState, productWeekState, templateCodeState, lessonModeState, timezoneCodeState, productTypeState,
    productMinuteValue, productWeekValue, lessonModeValue, timezoneCodeValue,
    timezoneCodeIndex,
    languageTypeList, languageTypeState,
    waitingFlag,
    viewProduct,
    isB2B,
    juniorFlag,
    inClassFlag,
  } = props;

  const {
    productClick,
    selectProductList,
    selectFilterValue,
    selectStrategyTab,
    showStrategy,
    showWaitingClassPopupToggle,
    presetWaitingClassPriceSeq,
    languageTypeValue,
    languageNameValue,
    courseSeqValue,
    courseNameValue,
    currentCourseSeq,
    strategyList,
    changeStrategyLanguage,
    isAliceOrder,
  } = props;

  const [showStrategyPopup, setShowStrategyPopup] = useState(false);
  const [selectedLanguageType, setSelectedLanguageType] = useState(null);
  const [selectedLanguageName, setSelectedLanguageName] = useState(null);
  const [selectedCourseSeq, setSelectedCourseSeq] = useState(null);
  const [selectedCourseName, setSelectedCourseName] = useState(null);
  const [courseList, setCourseList] = useState([]);


  const openStrategyPopup = () => {
    utilFn.ScrollNone(true);
    setShowStrategyPopup(true);
    setSelectedLanguageType(languageTypeValue);
    setSelectedLanguageName(languageNameValue);
    setSelectedCourseSeq(courseSeqValue);
    setSelectedCourseName(courseNameValue);
    setCourseList(strategyList.find(v => v.languageType===languageTypeValue).courses);
  };

  const closeStrategyPopup = () => {
    setShowStrategyPopup(false);
    utilFn.ScrollNone();
  };

  const selectLanguage = (languageType, languageName) => {
    if (selectedLanguageType===languageType) return;
    setSelectedLanguageType(languageType);
    setSelectedLanguageName(languageName);
    setSelectedCourseSeq(null);
    setSelectedCourseName(null);
    setCourseList(strategyList.find(v => v.languageType===languageType).courses);
  };

  const selectCourse = (courseSeq, courseName) => {
    setSelectedCourseSeq(courseSeq);
    setSelectedCourseName(courseName);
  };

  const applyStragegyLanguage = (selectedLanguageType, selectedLanguageName, selectedCourseSeq, selectedCourseName) => {
    changeStrategyLanguage(selectedLanguageType, selectedLanguageName, selectedCourseSeq, selectedCourseName);
    setShowStrategyPopup(false);
    utilFn.ScrollNone();
  };

  return (
    <section className='order-form'>
      <>
        {/* 언어 */}
        {languageTypeList?.length > 1 &&
					<section className='order-type'>
					  <div className='order-type__tab order-type__tab--b2b'>
					    <ul className='tab-list'>
					      {languageTypeList.map((item, i) => {
					        return (
					          <li className={'tab-item' + (languageTypeState[i] ? ' tab-item--active' : '')} key={i}>
					            <button type='button' className='tab-item__btn'
					              onClick={item.detailCode!=='etc'
					                ? () => selectFilterValue('languageType', i, languageTypeList.length, item.detailCode)
					                : () => selectStrategyTab()
					              }>
					              <span>{item.detailName}</span>
					            </button>
					          </li>
					        );})
					      }
					    </ul>
					  </div>
					</section>
        }

        {/* 전략어 언어/과정 */}
        { showStrategy &&
          <section className='order-tab-area'>
            <strong className='order-tab-area__title' dangerouslySetInnerHTML={{__html: commonMsg['PUR000162']}} />
            <ul className='order-square-tab__strategy'>
              <li className={'order-square-tab__strategy-card language'}>
                {languageNameValue}
              </li>
              <li className={`order-square-tab__strategy-card course ${courseSeqValue===currentCourseSeq?'currentCourse':''}`}>
                <span>{courseNameValue}</span>
              </li>
            </ul>
            <div className={'order-square-tab__strategy-change'} onClick={openStrategyPopup}>변경하기</div>
          </section>
        }

        {/* 전략어 언어/과정변경 팝업 */}
        {
          showStrategyPopup &&
          <>
            <div className='strategy-popup'>
              <div className='strategy-popup__header'>
                <span className='strategy-popup__header-title'>과정 변경</span>
                <img src={'https://img.uphone3.com/common/btn_close.svg'} alt={'close'} onClick={closeStrategyPopup} />
              </div>
              <div className='strategy-popup__body'>
                <div className='strategy-popup__title'>과정 변경</div>
                <div className='strategy-popup__subtitle'>어종 선택</div>
                <div className='strategy-popup__language'>
                  {
                    strategyList && strategyList.map((lang, i) => {
                      return (
                        <div key={i}
                          className={`strategy-popup__language-card ${lang.languageType===selectedLanguageType?'selected':''}`}
                          onClick={() => selectLanguage(lang.languageType, lang.languageName)}>
                          {lang.languageName}
                        </div>
                      );
                    })
                  }
                </div>
                <div className='strategy-popup__subtitle'>과정 선택</div>
                <div className='strategy-popup__course'>
                  {
                    courseList && courseList.map((course, i) => {
                      return (
                        <div key={i}
                          className={`strategy-popup__course-card ${course.courseSeq===selectedCourseSeq?'selected':''}`}
                          onClick={() => selectCourse(course.courseSeq, course.courseName)}>
                          <img src={process.env.REACT_APP_LMS_IMG_URL+course.imageUrl} />
                          <div className={'strategy-popup__course-card-subject'}>{course.courseName}</div>

                        </div>
                      );
                    })
                  }
                </div>
              </div>
              <div className={`strategy-popup__button  ${selectedCourseSeq?'active':''}`}
                onClick={selectedCourseSeq? () => applyStragegyLanguage(selectedLanguageType, selectedLanguageName, selectedCourseSeq, selectedCourseName) : () => {}}>
                과정 변경하기
              </div>
            </div>
            <div className={'msk'}/>
          </>
        }

        {/* 수업 유형을 선택하세요 */}
        {lessonModeList && !isAliceOrder &&
          <section className='order-tab-area'>
            <strong className='order-tab-area__title' dangerouslySetInnerHTML={{__html: commonMsg['PUR000139']}} />
            <PurchaseSquareTab
              type='radio'
              size='lg'
              dataTitle='lessonMode'
              tabList={lessonModeList}
              stateList={lessonModeState}
              detailName={'note1'}
              note={'note5'}
              onChange={selectFilterValue}
              isB2B={true}
            />
            {lessonModeValue === 'AUDIO' && (
              <SupportBoard supportId='voicecallNoticeB2B' />
            )}
            {lessonModeValue === 'VIDEO' &&
								<SupportBoard supportId='facecallNoticeB2B' />
            }
          </section>
        }

        {/* 수업 시간을 선택하세요 */}
        {lessonModeValue !== 'CONTENT' && productMinuteList?.length > 0 &&
					<section className='order-tab-area'>
					  <strong className='order-tab-area__title' dangerouslySetInnerHTML={{__html: commonMsg['PUR000140']}} />
					  <PurchaseSquareTab
					    type='radio'
					    size='lg'
					    dataTitle='productMinute'
					    tabList={productMinuteList}
					    stateList={productMinuteState}
					    detailName={'detailName'}
					    onChange={selectFilterValue}
					    isB2B={true}
					  />
					</section>
        }

        {/* 수업 횟수를 선택하세요 */}
        {productWeekList?.length > 0 && !isAliceOrder &&
					<section className='order-tab-area'>
					  <strong className='order-tab-area__title' dangerouslySetInnerHTML={{__html: commonMsg['PUR000141']}} />
					  <PurchaseSquareTab
					    type='radio'
					    size={'sm'}
					    dataTitle='productWeek'
					    tabList={productWeekList}
					    stateList={productWeekState}
					    detailName={'detailName'}
					    onChange={selectFilterValue}
					    isB2B={true}
					  />
					  {lessonModeValue === 'CONTENT' && productWeekList?.length > 1 &&
							<p className='order-tab-area__notice' dangerouslySetInnerHTML={{__html: commonMsg['PUR000138']}} />
					  }
					  {productWeekValue==='1' &&
              <div className={'order-warining'} dangerouslySetInnerHTML={{__html: commonMsg['UPCOUNT'].replace('$1', productMinuteValue)}}></div>
					  }
					</section>
        }

        {/* 타임존을 선택하세요 */}
        {lessonModeValue !== 'CONTENT' && timezoneCodeList?.length > 0 &&
					<section className='order-tab-area'>
					  <strong className='order-tab-area__title' dangerouslySetInnerHTML={{__html: commonMsg['PUR000142']}} />
					  <PurchaseSquareTab
					    type='radio'
					    size={'lg'}
					    alignTop={timezoneCodeList?.length>1}
					    dataTitle='timezoneCode'
					    tabList={timezoneCodeList}
					    stateList={timezoneCodeState}
					    detailName={'note2'}
					    note={languageTypeValue==='en'?'note1':undefined}
					    onChange={selectFilterValue}
					    isB2B={true}
					  />
					  { isB2B &&
            <>
              {timezoneCodeValue === 'A' && <p className='order-tab-area__notice' dangerouslySetInnerHTML={{__html: commonMsg['PUR000137']}} />}
              {timezoneCodeValue === 'D' && <p className='order-tab-area__notice' dangerouslySetInnerHTML={{__html: commonMsg['PUR000144']}} />}
            </>
					  }
					  { !isB2B &&
            <div className='order-timezone'>
					    {timezoneCodeValue &&
                <div className='order-timezone__bar'>
                  <strong className='order-timezone__bar-title'>
                    {timezoneCodeValue === 'D' ? timezoneCodeList?.[timezoneCodeIndex]?.note1 : timezoneCodeList?.[timezoneCodeIndex]?.detailName}
                    {timezoneCodeValue === 'A' ? <span>전체</span> : <span>{timezoneCodeList?.[timezoneCodeIndex]?.note3}시간</span>}
                  </strong>
                  <div className='order-timezone__bar-graph'>
                    <strong className={`current current--zone-${timezoneCodeList?.[timezoneCodeIndex]?.detailCode?.toLowerCase()}`}>
                      <span>{timezoneCodeList?.[timezoneCodeIndex]?.note4}h</span>
                      <span>{timezoneCodeList?.[timezoneCodeIndex]?.note5}h</span>
                    </strong>
                    {timezoneCodeValue === 'D' &&
                      <strong className='current current--zone-d2'>
                        <span>{timezoneCodeList?.[timezoneCodeIndex]?.note6}h</span>
                        <span>{timezoneCodeList?.[timezoneCodeIndex]?.note7}h</span>
                      </strong>
                    }
                  </div>
                </div>
					    }
					    <div className='order-timezone__desc'>
					      {timezoneCodeValue === 'A' ? (
					        <strong className='item item--inside' dangerouslySetInnerHTML={{__html: commonMsg['PUR000156']}} />
					      ) : (
					        <>
					          <strong className='item item--inside' dangerouslySetInnerHTML={{ __html: commonMsg['PUR000133'] }} />
					          <strong className='item item--outside' dangerouslySetInnerHTML={{__html: commonMsg['PUR000134']}} />
					        </>
					      )}
					      <p dangerouslySetInnerHTML={{__html: commonMsg['PUR000135']}} />
					    </div>
					  </div>
					  }
					</section>
        }

        {/* 수업 요일을 확인하세요 / 수업 요일을 선택하세요 */}
        {lessonModeValue !== 'CONTENT' && templateCodeList?.length > 0 &&
					<section className='order-tab-area'>
					  <strong className='order-tab-area__title' dangerouslySetInnerHTML={{__html: commonMsg[templateCodeList.length === 1 ? 'PUR000145' : 'PUR000143']}} />
					  {templateCodeList.length === 1 ?
					    <PurchaseSquareTab
					      type='radio'
					      size='sm'
					      dataTitle='templateCode'
					      tabList={templateCodeList}
					      stateList={templateCodeState}
					      detailName='detailName'
					      onChange={selectFilterValue}
					    />
					    :
					    <TemplateSelect selectFilterValue={selectFilterValue} templateCodeList={templateCodeList} />
					  }
					</section>
        }

        {/* 상품 타입을 선택하세요 */}
        { lessonModeValue !== 'CONTENT' && productTypeList?.length > 1 &&
          <section className='order-tab-area'>
            <strong className='order-tab-area__title' dangerouslySetInnerHTML={{__html: commonMsg['PUR000168']}} />
            <PurchaseSquareTab
              type='radio'
              size='lg'
              dataTitle='productType'
              tabList={productTypeList}
              stateList={productTypeState}
              detailName={'note1'}
              note={ww === 'pc'?'note3':'note2'}
              alignTop={true}
              bestTag={'note4'}
              onChange={selectFilterValue}
              isB2B={true}
            />
          </section>
        }

      </>
      <article className={`list-tickets ${isAliceOrder ? 'no-arrow' : ''}`} id='ticketProducts'>
        <strong className='list-tickets__title'>
          {waitingFlag ? '마감된 상품은 대기신청을 해주세요!' : '지금 수강신청하고 학습을 시작하세요!'}
        </strong>
        {viewProduct?.map((item, i) => {
          return (
            <Fragment key={i}>
              <TicketItem
                i={i}
                item={item}
                hideBestTag={true}
                waitingFlag={waitingFlag}
                isSummary={false}
                productClick={productClick}
                selectProductList={selectProductList}
                showWaitingClassPopupToggle={showWaitingClassPopupToggle}
                presetWaitingClassPriceSeq={presetWaitingClassPriceSeq}
                isAliceOrder={isAliceOrder}
                isB2B={isB2B}
                juniorFlag={juniorFlag}
                inClassFlag={inClassFlag}
              />
            </Fragment>
          );
        })}
      </article>
    </section>
  );
};
export default TicketVendingMachine;
