import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import * as utilFn from '../common/utilFn';
import LayerPopup from '../component/LayerPopup';
import {httpDefaultClient} from 'configs/api';

export default class AuthPW extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'p1',
      chkNext: false,
      cellPhone: '',
      phonenumError: false,
      authInput: '',
      authBtn: false,
      authError: false,
      authTimeError: false,

      authCheckFlag:false, // 인증번호 확인 (맞는지 여부)
      authLayerPopup: '', // 인증번호 관련 오류 팝업 메시지
      disabled: false,
      authStart: '',
      authEnd: '',

      countMin: '10',
      countSec: '00',
      time: 300,
      isToggleOn: true,
      name: '',
      nameError: false,

      email: '',
      emailError: '',
      password: '',
      passwordError: false,
      pwError: '',
      pwView: 'password',
      passwordCheck: '',
      boxError: '',
      pwChk: '',
      pwChk1: '',
      pwChk2: '',
      pwChk3: '',
      pwChk4: '',
      pwChkError: '',
      pwChkView: 'password',

      joinNext: false,

      phoneError: false,

      btnLnk: true,
      fstChk: ' on',
      sndChk: '',

      DormantMem: false,
      layerPopupMessage:'',
    };
  }

  componentWillMount() {
    this.getCommonMsgList();
  }

  componentDidMount() { // 처음 랜더링 될때
    const em = localStorage.getItem('email');
    localStorage.removeItem('email');
    if (em) {
      this.setState({
        email: em,
        fstChk: '',
        sndChk: ' on'
      });
    }
  };

  onChangeName = (e) => {
    this.setState({authCheckFlag:false});
    if (e.target.value.length <= e.target.maxLength) {
      const t = e.target.value.replace(/[a-z0-9]|[\[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"\'\\\/]|[\s*]/gi, '');
      this.setState({
        name: t,
        nameError: '',
        authInfoView: false,
        authError: '',
        authInput: '',
        authState: false
      });
      if (this.state.authInput !== '' && this.state.authError === '' && this.state.authInput.length === 6) {
        this.setState({authInput: ''});
        this.openLayerPopup('정보가 변경되어 휴대전화번호 재인증이 필요합니다');
      }
      this.defaultDate(t);
    }
  };

  onBlurName = (e) => {
    const t = e.target.value;
    const nameRex = /^[가-힣]+$/;
    const a = nameRex.test(t);
    let v = '';

    if (t === '') {
      v = '필수 정보입니다.';
    } else if (t.length < 2 || t.length > 6) {
      v = '이름은 한글 2~6자까지 사용 가능합니다.';
    } else {
      a ? v = '' : v = '이름은 한글 2~6자까지 사용 가능합니다.';
    }
    this.setState({nameError: v});
    this.state.fstChk && this.setState({fstChk: ''});
  };

  onChangeEmail = (e) => {
    const v = e.target.value.replace(/[\s*]/gi, '');
    if (v === '') {
      this.setState({emailError: ''});
    }
    this.state.fstChk && this.setState({fstChk: ''});
    this.setState({
      email: v,
      emailError: '',
      authInfoView: false,
      authError: '',
      authInput: '',
      authState: false
    });
    if (this.state.authInput!==''&&this.state.authError===''&&this.state.authInput.length===6){
      this.setState({authInput: ''});
      this.openLayerPopup('정보가 변경되어 휴대전화번호 재인증이 필요합니다');
    }
    this.defaultDate(v);
  };

  onBlurID = (e) => {
    let em = '';
    if (e.target.value.length === 0) {
      this.setState({emailError: '필수 정보입니다.'});
      return null;
    }
    this.setState({emailError: em});
  };

  chkEmail = (v) => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/sign/checkId?',
      params: {
        'id': v,
      }
    })
      .then((json) => {
        if (json.data.result) {
          if (json.data.message !== 'SIGN000003') {
            this.setState({'emailError': '입력하신 정보와 일치하는 회원정보가 없습니다.'});
          }
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  onChangePhone = (e) => {
    this.setState({authCheckFlag:false});
    const t = e.target.value;
    clearInterval(this.interval);
    if (t.length <= e.target.maxLength) {
      const v = this.formChk('cellphone', t);

      this.setState({
        authBtn: v[0],
        cellPhone: v[1],
        cellPhoneError: '',
        authInfoView: false,
        authError: '',
        authInput: '',
        authState: false
      });
      if (this.state.authInput!==''&&this.state.authError===''&&this.state.authInput.length===6){
        this.setState({authInput: ''});
        this.openLayerPopup('정보가 변경되어 휴대전화번호 재인증이 필요합니다');
      }
      this.defaultDate(v[1]);
    }
  };

  onBlurPhone = (e) => {
    let v = '';
    if (e.target.value === '') {
      v = '필수 정보입니다.';
    } else if (!this.state.authBtn) {
      v = '휴대전화번호 형식이 올바르지 않습니다. 정확하게 다시 입력해주세요.';
    }
    this.setState({
      cellPhoneError: v,
    });
  };

  formChk = (t, n) => {
    const phoneRex = /^\d{3}-\d{3,4}-\d{4}$/;
    let v = n;

    v = v.replace(/-/gi, '');

    if (v.length >= 2 && v.length <= 5) {
      const nn = /(\d{2})(\d{1,3})/;
      v.replace(nn, function (str, p1, p2) {
        v = p1 + '-' + p2;
      });
    } else if (v.length >= 6 && v.length <= 9) {
      const nn = /(\d{2})(\d{1,3})(\d{1,4})/;
      v.replace(nn, function (str, p1, p2, p3) {
        v = p1 + '-' + p2 + '-' + p3;
      });
    } else if (v.length === 10) {
      // 일반전화이면서 서울일때
      if (v.substring(1, 2) === '2') {
        const nn = /(\d{2})(\d{1,4})(\d{2,4})/;
        v.replace(nn, function (str, p1, p2, p3) {
          v = p1 + '-' + p2 + '-' + p3;
        });
      } else {
        const nn = /(\d{3})(\d{1,3})(\d{2,4})/;
        v.replace(nn, function (str, p1, p2, p3) {
          v = p1 + '-' + p2 + '-' + p3;
        });
      }
    } else if (v.length > 10) {
      const nn = /(\d{3})(\d{1,4})(\d{2,4})/;
      v.replace(nn, function (str, p1, p2, p3) {
        v = p1 + '-' + p2 + '-' + p3;
      });
    }
    const p = phoneRex.test(v);
    return [p, v];
  };

  onChangePassword = (e) => {
    const v = e.target.value;
    this.setState({
      password: v,
      passwordError: '',
    });

    const pw_regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/gi;
    const a = pw_regex.test(e.target.value);
    this.setState({password : v});
    if (v.length !== 0){
      this.setState({passwordError: ''});
    }
    if (a){
      this.setState({passwordError: '비밀번호에 한글을 사용할 수 없습니다.'});
      // v = v.replace(pw_regex, '');
    } else if (utilFn.hasUnicode(v)) {
      this.setState({passwordError: '비밀번호에 유니코드 문자를 사용할 수 없습니다.'});
    }

    const eng = /[a-zA-Z]/;
    const num = /[0-9]/;
    const sym = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/;
    let flagEng = eng.test(v);
    let flagNum = num.test(v);
    let flagSym = sym.test(v);

    let p1, p2, p3, p4;

    // 비밀번호 8~16자 확인
    (v.length >= 8 && v.length <= 16) ? p1 = ' len' : p1 = '';
    // 영문
    (flagEng === true) ? p2 = ' eng' : p2 = '';
    // 숫자
    (flagNum === true) ? p3 = ' num' : p3 = '';
    // 특수문자
    (flagSym === true) ? p4 = ' sym' : p4 = '';

    this.setState({
      pwChk: p1 + p2 + p3 + p4,
      pwChk1: p1,
      pwChk2: p2,
      pwChk3: p3,
      pwChk4: p4
    });

    if (this.state.password.length !== 0) {
      this.setState({
        boxError: '',
        pwChkError: ''
      });
    }
    
    if (v === this.state.passwordCheck){
	    this.defaultDate2(v);
	  } else {
	    this.setState({chkNext: false});
	  }
  };

  onBlurPassword = (e) => {
    if (this.state.password === '') {
      this.setState({passwordError: '필수 정보입니다.'});
      return null;
    } else if (this.state.pwChk1 === '' || this.state.pwChk2 === '' || this.state.pwChk3 === '' || this.state.pwChk4 === '') {
      this.setState({boxError: 'error '});
    } else {
      this.setState({boxError: ''});
    }
    if (!e) {
      this.defaultDate2(this.state.password);
    }
    if (e && this.state.passwordCheck !== ''){
      this.onBlurPasswordChk();
    }
  };

  // 비밀번호 재확인
  onChangePasswordChk = (e) => {
    const v = e.target.value;
    this.setState({
      passwordCheck: v,
      pwChkError: ''
    });

    const pw_regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/gi;
    const a = pw_regex.test(e.target.value);
    this.setState({pw : v});
    if (v.length !== 0){
      this.setState({pwChkError: ''});
    }
    if (a){
      this.setState({pwChkError: '비밀번호에 한글을 사용할 수 없습니다.'});
    } else if (utilFn.hasUnicode(v)) {
      this.setState({pwChkError: '비밀번호에 유니코드 문자를 사용할 수 없습니다.'});
    }

    if (v !== this.state.password) {
	    this.setState({chkNext: false});
	  } else if (this.state.passwordError === '' && this.state.pwChkError === '' && v === this.state.password && this.state.boxError === '') {
	    this.setState({chkNext: true});
	  } else {
	    this.setState({chkNext: false});
	  }
  };

  onBlurPasswordChk = (e) => {
    const pw_regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/gi;
    let s = '';
    if (this.state.password === '') {
      s = '비밀번호를 먼저 입력해주세요.';
    } else if (this.state.passwordCheck === '') {
      s = '필수 정보입니다.';
    } else {
      s = (this.state.password !== this.state.passwordCheck) ? '입력한 비밀번호와 동일하지 않습니다.'
        : (pw_regex.test(this.state.passwordCheck)) ? '비밀번호에 한글을 사용할 수 없습니다.'
          : utilFn.hasUnicode(this.state.passwordCheck) ? '비밀번호에 유니코드 문자를 사용할 수 없습니다.'
            : '';
    }
    this.setState({pwChkError: s});
    if (e) {
      this.onBlurPassword();
    } else {
      this.defaultDate2(this.state.passwordCheck);
    }
  };

  onClickPwView = (n) => {
    let s;

    if (n === 1) {
      this.state.pwView === 'password' ? s = 'text' : s = 'password';
      this.setState({pwView: s});
    } else if (n === 2) {
      this.state.pwChkView === 'password' ? s = 'text' : s = 'password';
      this.setState({pwChkView: s});
    }
  };

  checkAuthNumber = (eventType, authInputValue) => {
    // 인증번호랑 입력값 비교
    const params = {
      cellPhone: this.state.cellPhone.replace(/-/gi,''),
      authNumber: authInputValue,
      authType:'RESET_PASSWORD'
    };
    const onSuccess=() => {
      this.setState({
        authError: '',
        authInfoView: false,
        authCheckFlag: true,
      });
      if (eventType==='change') {
        clearInterval(this.interval);
        this.defaultDate(authInputValue);
      }
    };
    const onFail = (commonMessage) => {
      this.setState({
        authError: this.state.commonMsg[commonMessage],
        authInfoView: true,
        authCheckFlag: false,
      });
    };
    utilFn.checkAuthNumber(params, onSuccess, onFail);
  };

  onChangeAuth = (e) => {
    let v = e.target.value;
    v = v.replace(/[*#+,;]/gi, '');
    if (v.length > 6) {
      v = v.slice(0, 6);
    }
    this.setState({
      authInput: v,
      authInfoView: true,
      authError: '',
      authCheckFlag: false
    });

    clearInterval(this.interval);
    this.reqAuthCount();

    if (v.length === 6) {
      this.checkAuthNumber('blur', v);
    } else if (v.length === 0) {
      this.setState({
        authError: '필수 정보입니다.',
        authInfoView: true,
      });
    } else {
      this.setState({
        authError: '인증번호를 다시 확인해주세요.',
        authInfoView: true,
      });
    }
  };

  onBlurAuth = (e) => {
    if (e.target.readOnly) {
      return null;
    }
    let v = this.state.authInput;

    if (v.length === 6) {
      this.checkAuthNumber('blur', v);
    } else if (v.length === 0) {
      this.setState({
        authError: '필수 정보입니다.',
        authInfoView: true,
      });
    } else {
      this.setState({
        authError: '인증번호를 다시 확인해주세요.',
        authInfoView: true,
      });
    }
    this.defaultDate(e.target.value);
  };

  reqAuth = (e) => {
    let c = false;
    if (!this.state.authBtn) {
      return null;
    }
    this.setState({ authCheckFlag: false});
    if (this.state.email === '') {
      this.setState({emailError: '필수 정보입니다.'});
      c = true;
    } else if (this.state.emailError !== '') {
      this.setState({emailError: '올바른 이메일 주소를 입력하고 재인증을 받아주세요.'});
      c = true;
    }
    if (this.state.name === '') {
      this.setState({nameError: '필수 정보입니다.'});
      c = true;
    } else if (this.state.nameError !== '') {
      this.setState({nameError: '한글 2~6자의 이름을 입력하고 재인증을 받아주세요.'});
      c = true;
    }
    if (c){
      return null;
    }

    clearInterval(this.interval);
    this.setState({
      disabled  : false,
      authTimeError:false,
      countMin :'10',
      countSec: '00',
      time: 600,
      authInfoView:false,
      authError: '',
      authInput: '',
      // authCode: '',
      authState: false
    });

    httpDefaultClient.post('/sign/findMember', {
      'memberCellphone': this.state.cellPhone.replace(/-/g, ''),
      'memberName': this.state.name,
      'memberId': this.state.email
    }).then(res => {
      const json = res.data;
      if (json.result) {
        if (json.status === 200) {
          this.reqAuthS();
        } else {
          this.eMessage('alert', json.message);
        }
      }
    }).catch((error) => {
      this.openLayerPopup('서버 통신에 실패 하였습니다.');
    });
  };

  reqAuthS = () => {
    // 인증번호 발송
    this.setState({ authCheckFlag: false});
    const params={
      cellPhone: this.state.cellPhone.replace(/-/gi,''),
      authType: 'RESET_PASSWORD'
    };
    const onSuccess =() => {
      this.setState({
        authInfoView: true,
        authState:true,
        authStart: moment() + 0,
        authEnd: moment() + 600000
      });
      this.reqAuthCount();
      this.defaultDate();
    };
    const onFail = (commonMessage) => {
      this.setState({
        authLayerPopup: this.state.commonMsg[commonMessage],
        authCheckFlag: false,
      });
      this.defaultDate();
    };
    utilFn.sendAuthNumber(params, onSuccess, onFail);
  };

  reqAuthCount = () => {
    this.interval = setInterval(() => {
      this.timerHandler();
    }, 1000);
  };

  timerHandler = () => {
    const now = moment();

    if (now.diff(this.state.authStart) > 0 && now.diff(this.state.authEnd) < 0) {
      this.timesSetter();
    } else {
      this.setState({
        countSec: '00', //time - (countMin * 60),
        countMin :'00',
        disabled: true, // 시간이 지낫을때 인풋창 막기
        authTimeError:true,
        authInput: '',
        authStart: 0,
        authEnd: 0,
        authError: '유효시간이 지났습니다. 다시 인증을 진행해주세요.'
      });
      clearInterval(this.interval);
    }
  };

  timesSetter = () => {
    const now = moment();
    let s;
    let m;
    let t = now - this.state.authStart;
    t = 600 - Math.floor(t / 1000);
    m = Math.floor(t / 60);
    s = t - (m * 60);
    m === 0 ? m = '00' : m = '' + m;
    String(m).length === 1 ? m = '0' + m : m = '' + m;
    s === 0 ? s = '00' : s = '' + s;
    String(s).length === 1 ? s = '0' + s : s = '' + s;

    this.setState({
      countSec: s,
      countMin : m,
    });
  };

  findPage = (e) => {
    const t = this.state;

    const c1 = !t.email ? '필수 정보입니다.' : t.emailError;
    const c4 = !t.name ? '필수 정보입니다.' : t.nameError;
    const c6 = !t.authInput ? '필수 정보입니다.' : t.authError;
    let cc = null;

    if (t.email === '' || t.name === '' || t.authInput === '') {
      return null;
    }

    this.setState({
      emailError: c1,
      nameError: c4,
      authError: c6
    });

    if (c1 !== '' || c4 !== '' || c6 !== '') {
      return null;
    }

    clearInterval(this.interval);

    httpDefaultClient.post('/sign/findId', {},
      {
        params: {
          memberName: encodeURI(this.state.name),
          memberCellphone: this.state.cellPhone.replace(/-/g, '')
        }})
      .then(response => {
        const json = response.data;
        if (json.data && this.state.email !== json.data.memberId) {
          // 입력하신 정보와 일치하는 회원정보가 없습니다.
          this.eMessage('alert','SIGN000002');
          return null;
        } else if (json.message === 'SIGN000008'){
          // SIGN000008 아이디를 찾았습니다. => 일치하는 회원정보 존재
          // 비번 설정이 되어있다면 재설정 페이지, 아니면 (소셜) SIGN000034
          if (json.data.passwordStatus){
            this.props.history.push('/ResetPW/p2');
          } else {
            this.eMessage('alert','SIGN000034');
            return null;
          }
        } else if (json.message === 'SIGN000010') {
          // SIGN000010 장기간 이용 내역이 없어 관련 법령에 따라 휴면 상태입니다. 로그인 후, 휴면 해제에 동의해주시면 휴면이 해제됩니다.
          this.setState({
            fstChk: 'on',
            DormantMem: true
          });
          this.props.history.push('/ResetPW/p2');
        } else {
          this.eMessage('alert', json.message);
          return null;
        }
      })
      .catch((error) => {
        this.setState({
          nameError: true
        });
      });
  };

  resetPassword = (e) => {
    const t = this.state;

    if (t.boxError !== '' || t.pwChkError !== '' || e.target.className.indexOf('disable') >= 0) {
      return null;
    }
    httpDefaultClient.post('/sign/resetPassword',{
      'memberCellphone': this.state.cellPhone.replace(/-/g, ''),
      'memberId': this.state.email,
      'memberName': this.state.name,
      'password': this.state.password
    })
      .then(res => {
        const json = res.data;
        if (json.result) {
          if (json.status === 200) {
            if (this.state.email !== json.data) {
              this.openLayerPopup('입력하신 정보와 일치하는 회원정보가 없습니다.');
              return null;
            }
            alert('비밀번호가 재설정되었습니다.');
            this.props.history.push('/Login');
          } else {
            this.eMessage('alert', json.message);
          }
        }
      }
      )
      .catch((error) => {
        this.setState({
          nameError: true
        });
      });
  };

  defaultDate = (e) => {
    const t = this.state;

    // 필수 값에 따른 버튼 활성화
    if (t.emailError !== '' || t.nameError !== '') {
      this.setState({joinNext: false});
    } else if (t.email && t.name && this.state.authInput!=='' && this.state.authError==='' && this.state.authInput.length===6 && t.authCheckFlag) {
      this.setState({joinNext: true});
    } else {
      this.setState({joinNext: false});
    }
  };

  defaultDate2 = (e) => {
    const t = this.state;

    // 필수 값에 따른 버튼 활성화
    if (!t.password || !t.passwordCheck) {
      this.setState({chkNext: false});
    } else if (t.password !== '' && t.passwordCheck !== '' && t.passwordError === '' && t.pwChkError === '' && t.boxError === '' && t.password === t.passwordCheck) {
      this.setState({chkNext: true});
    } else {
      this.setState({chkNext: false});
    }
  };

  eMessage = (t, v) => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (response.data.result) {
          if (t === 'emailError') {
            this.setState({emailError: response.data.message});
          } else {
            this.setState({layerPopupMessage: response.data.message});
            return null;
          }
        } else {
          this.setState({layerPopupMessage: v});
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  getCommonMsgList = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getList',
      params: {
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        this.setState({
          commonMsg : response.data,
        });
      })
      .catch((error) => {
        console.error('getCommonMsgList: ', error);
      });
  };

  onClickfChk = () => {
    this.setState({fstChk: '', sndChk: ''});
  };

  openLayerPopup=(message) => {
    this.setState({layerPopupMessage: message});
  };

  closeLayerPopup=() => {
    this.setState({layerPopupMessage : ''});
    this.defaultDate();
  };

  render() {
    return (
      <div className={'idPw resetpw ' + (this.props.match.params.page ? this.props.match.params.page : 'p1')}>
        <Link className={'logoLink'} to='/'><img src={process.env.REACT_APP_IMG_URL + '/course/freelesson_bi.svg'} alt={'view off'} /></Link>
        <div className='step1'>
          <div className='contentBox'>
            <div className='mainTxt'>비밀번호 재설정을<br/>도와드릴게요.</div>

            <div className='inputTitle'>이메일</div>
            <input tabIndex={1} ref={this.emailRef} className={'email ' + (this.state.emailError && 'error ') + (this.state.fstChk)} name='uemail' type='email' autoCapitalize='off' autoComplete='off' placeholder='이메일 또는 기업회원ID' value={this.state.email} onChange={this.onChangeEmail} onBlur={this.onBlurID} onClick={this.onClickfChk}/>
            {this.state.emailError && <div className='error errorMsg'>{this.state.emailError}</div>}

            <div className='inputTitle adj'>이름</div>
            <input tabIndex={2} autoComplete='name' className={'name ' + (this.state.nameError && 'error ') + (this.state.sndChk)} name='name' placeholder='김유폰' maxLength={7} value={this.state.name} onChange={this.onChangeName} onBlur={this.onBlurName} onClick={this.onClickfChk}/>
            {this.state.nameError && <div className='error errorMsg'>{this.state.nameError}</div>}

            <div className='inputTitle'>휴대전화번호</div>
            <div className='authNumWrap'>
              <div className='authNumBox'>
                <input tabIndex={3} className={'cellPhone ' + (this.state.cellPhoneError && 'error')} name='cellPhone' maxLength={13} type='tel' placeholder='010-1234-5678' value={this.state.cellPhone} onChange={this.onChangePhone} onClick={this.onClickfChk} onBlur={this.onBlurPhone}/>
                <div className={'btn g inputBtn authBtn ' + (!this.state.authBtn && 'disable')}
									 onClick={this.reqAuth}>인증번호 받기
                </div>
              </div>
              <input className={'authInput ' + (this.state.authError && ' error ') + (!this.state.authState && ' dis ')} type='number' min='0' max='999999' placeholder='인증번호를 입력하세요' value={this.state.authInput} onChange={this.onChangeAuth} onBlur={this.onBlurAuth} disabled={this.state.disabled} onClick={this.onClickfChk} readOnly={!this.state.authState}/>

              {this.state.authInfoView &&
							<span className='timer'>유효시간 {this.state.countMin}분 {this.state.countSec}초</span>}
            </div>
            {this.state.cellPhoneError && <div className='error errorMsg adj'>{this.state.cellPhoneError}</div>}
            {this.state.authCheckFlag &&
							<div className={'completeTxt adj'}><img src={process.env.REACT_APP_IMG_URL + '/join/ico_check.svg'} alt={'check'} /> 인증완료</div>
            }
            {this.state.authError && <div className='error errorMsg'>{this.state.authError}</div>}
            <div className={'authInfo ' + (this.state.authInfoView ? 'view' : 'hide')}>
              <div className={'title'}>인증번호가 오지 않나요?</div>
              <div className={'list'}>1. 휴대전화번호를 다시 확인해주세요.</div>
              <div className={'list'}>2. 1599 번호가 스팸 문자로 등록되어 있으면 해제해주세요.</div>
              <div className={'list'}>3. 가상전화번호는 인증번호를 받을 수 없습니다.</div>
            </div>

            <div className='infoTxt tit'>왜 비밀번호 재설정을 해야 하나요?</div>
            <div className='infoTxt'>비밀번호는 암호화 저장되어 분실 시 찾아드릴 수 없는 정보입니다.<br/>본인 확인을 통해 비밀번호를 재설정 하실 수
							있습니다.
            </div>
          </div>
          <button className={'btn find adj ' + (!this.state.joinNext && 'disable')} onClick={this.findPage}>
						비밀번호 재설정하기
          </button>
        </div>

        <div className='step2'>
          <div className='contentBox'>
            <div className='mainTxt'>재설정할 비밀번호를<br/>입력해 주세요.</div>
            {this.state.DormantMem &&
						<div className={'info'}>장기간 이용 내역이 없어 관련 법령에 따라 <span className={'red'}>휴면 상태</span>입니다.<br/>로그인 후, 휴면 해제에 동의해주시면 휴면이 해제됩니다.</div>}

            <div className='inputTitle'>재설정할 비밀번호</div>
            <div className={'box'}>
              <input tabIndex={11} ref={this.inputPwRef} autoComplete='password' className={'msClear p1 ' + (this.state.passwordError && 'error ') + (this.state.boxError) + ' ' + (this.state.fstChk)} name='p1' type={this.state.pwView} placeholder='8~16자 영문, 숫자, 특수문자 사용' maxLength='16' value={this.state.password} onChange={this.onChangePassword} onBlur={this.onBlurPassword} onClick={this.onClickfChk} />
              <div className={'viewIcon'} onClick={() => this.onClickPwView(1)}>
                {this.state.pwView === 'password' ? <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOff.svg'} alt={'view off'} /> : <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOn.svg'} alt={'view on'} />}
              </div>
            </div>
            <div className={'pwChkListWrap' + this.state.pwChk}>
              <div className={'pwChkList len'} >8~16자</div>
              <div className={'pwChkList eng'} >영문</div>
              <div className={'pwChkList num'} >숫자</div>
              <div className={'pwChkList sym'} >특수문자</div>
            </div>
            {this.state.passwordError && <div className='error errorMsg'>{this.state.passwordError}</div>}

            <div className='inputTitle'>비밀번호 재확인</div>
            <div className={'box'}>
              <input tabIndex={12} ref={this.inputPwChkRef} autoComplete='off' className={'msClear p2 ' + (this.state.pwChkError && 'error')} name='p2' type={this.state.pwChkView} maxLength={16} placeholder='입력한 비밀번호와 동일' value={this.state.passwordCheck} onChange={this.onChangePasswordChk} onBlur={this.onBlurPasswordChk} onClick={this.onClickfChk} />
              <div className={'viewIcon'} onClick={() => this.onClickPwView(2)}>
                {this.state.pwChkView === 'password' ? <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOff.svg'} alt={'view off'} /> : <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOn.svg'} alt={'view on'} />}
              </div>
            </div>
            {this.state.pwChkError && <div className='error errorMsg'>{this.state.pwChkError}</div>}

          </div>
          <button className={this.state.chkNext ? 'btn mb0 fixBottom' : 'btn mb0 fixBottom disable'} onClick={this.resetPassword}>
						비밀번호 재설정 완료
          </button>
        </div>
        {this.state.layerPopupMessage&&
				<LayerPopup
				  desc1={this.state.layerPopupMessage}
				  btnCnt={1}
				  btnOkCmt={'확인'}
				  btnOkFunction={this.closeLayerPopup}
				/>}
        {this.state.authLayerPopup &&
				<LayerPopup
				  tit={this.state.authLayerPopup}
				  btnCnt={1}
				  btnOkCmt={'확인'}
				  btnOkFunction={() => this.setState({authLayerPopup:''})}
				/>
        }
      </div>
    );
  }
}
