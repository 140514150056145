import React from 'react';

export default function TrialCompleteFooterForPc() {
  return (
    <div className={'trialComplete__footer'}>
      <div className={'trialComplete__footer--slogan'}>모든 수업을 가치있게 <b>민병철유폰</b></div>
      <div className={'trialComplete__footer--copyright'}>Copyright © (주) 민병철교육그룹. 모든 권리 보유</div>
    </div>
  );
}
