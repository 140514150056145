import { ProductPrice } from 'types/purchase/purchase.interface';
import { StateCreator } from 'zustand';

export interface PurchaseShowcaseB2CT {
  productList: ProductPrice[];
  selectedProductWeek: number;
  selectedLessonMode: string;
  filteredProductList: ProductPrice[];
  showEmergencyPopup: boolean;
  selectedPriceSeqs: number[];
  setProductList: (productList: ProductPrice[]) => void;
  setSelectedProductWeek: (week: number) => void;
  setSelectedLessonMode: (lessonMode: string) => void;
  setFilteredProductList: (filteredProductList: ProductPrice[]) => void;
  setSelectedPriceSeqs: (priceSeqs: number[]) => void;
  setShowEmergencyPopup: (showEmergencyPopup: boolean) => void;
}

const initialState = {
  selectedProductWeek: 24,
  selectedLessonMode: 'AUDIO',
  productList: [],
  filteredProductList: [],
  selectedPriceSeqs: [],
  showEmergencyPopup: false,
};

export const purchaseShowcaseB2CSlice: StateCreator<
  PurchaseShowcaseB2CT,
  any[], 
  any[],
  PurchaseShowcaseB2CT
> = (set) => ({
  ...initialState,
  setProductList: (productList: ProductPrice[]) => {
    set((state) => ({ ...state, productList }));
  },
  setSelectedProductWeek: (week: number) => {
    set((state) => ({ ...state, selectedProductWeek: week }));
  },
  setSelectedLessonMode: (lessonMode: string) => {
    set((state) => ({ ...state, selectedLessonMode: lessonMode }));
  },
  setFilteredProductList: (filteredProductList: ProductPrice[]) => {
    set((state) => ({ ...state, filteredProductList }));
  },
  setSelectedPriceSeqs: (priceSeqs: number[]) => {
    set((state) => ({ ...state, selectedPriceSeqs: priceSeqs }));
  },
  setShowEmergencyPopup: (showEmergencyPopup: boolean) => {
    set((state) => ({ ...state, showEmergencyPopup }));
  },
});
export default purchaseShowcaseB2CSlice;
