import React from 'react';
import reserveTrialStore from 'store/trial/reserve/reserveTrialStore';
import * as utilFn from 'common/utilFn';

export default function TrialBook() {
  const topicList = reserveTrialStore((state) => state.topicList);
  const selectedTopic = reserveTrialStore((state) => state.selectedTopic);
  const setIsShowTrialBook = reserveTrialStore((state) => state.setIsShowTrialBook);
  const showLevel = reserveTrialStore((state) => state.showLevel);

  const openTrialBook = () => {
    utilFn.ScrollNone(true);
    setIsShowTrialBook(true);
  };

  if (!showLevel) {
    return null;
  }
  
  return (
    <div>
      <div className={'titleBox'}>
        <div className={'titleBox__title'}>수업 교재</div>
        <div className='titleBox__link'>
          <span onClick={openTrialBook}>교재 미리 보기 <img src={process.env.REACT_APP_IMG_URL + '/icon/arrow_right_5s.svg'} /></span>
        </div>
      </div>
      <div className='lessonBook'>
        {topicList.map((topic: any, index: number) => {
          return (
            <div key={index} className={`lessonBook__item ${selectedTopic.courseSeq === topic.courseSeq ? 'lessonBook__item--active' : ''}`}>
              <p className='course'>{topic.courseName}</p>
              <p className='subject'>{topic.subject}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

