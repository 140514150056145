import React from 'react';

import '../../css/purchase/PurchaseBanner.scss';

interface BannerObjectType {
  sort: number;
  description: string;
  deviceCode: string;
  bannerHeader: string;
  bannerBody: string;
  popupType: string;
  bannerColor: string;
  bannerImage: string;
  bannertextColor: string;
}

interface PropsType {
  item: BannerObjectType;
  onClickBanner: (item: BannerObjectType) => void;
}

export default function PurchaseBanner({ item, onClickBanner }: PropsType) {
  const { bannerColor, bannerHeader, bannerBody, bannerImage, popupType, bannertextColor } = item;
  
  return (
    <div onClick={() => onClickBanner(item)} className='purchase-banner'
      style={{
        backgroundColor: bannerColor ? bannerColor : 'none',
        backgroundImage: bannerImage ? `url(${bannerImage})` : 'none',
        cursor: popupType !== 'NONE' ? 'pointer' : 'auto',
      }}
    >
      {bannerHeader && bannerBody && (
        <>
          <p className={`purchase-banner__title purchase-banner__title--${bannertextColor}`}>{bannerHeader}</p>
          <p className={`purchase-banner__description purchase-banner__description--${bannertextColor}`}>{bannerBody}</p>
        </>
      )}
    </div>
  );
}
