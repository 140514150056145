import React, { useState, useEffect } from 'react';

const ProfilePlayer = props => {
  const audio = props.t;
  const badge = props.badge;

  const [txt, setTxt] = useState('');
  const [playing, setPlaying] = useState('play');
  const [loading, setLoading] = useState(true);
  const [audioURL, setAudioURL] = useState('');
  const [EventList, setEventList] = useState(false);
  let a = props.t;

  useEffect(() => {
    if (loading) {
      setLoading(false);

      setAudioURL(props.t);
    }
  });

  const onClickplayTxt = e => {
    const a = document.getElementById(`${props.type + props.n}`);
    const p = document.getElementsByTagName('audio');
    for (let a of p) {
      a.pause();
      if (a.id !== 'rAudio')  a.currentTime = 0;
    }

    if (!EventList){
      console.warn('----한번만 호출되야 함');
      setEventList(true);
      a.addEventListener('pause', function (_event) {
        setPlaying('play');
      });
      a.addEventListener('playing', function (_event) {
        console.warn('---- play',a.currentTime);
        setPlaying('pause');
      });
      a.play();
    } else {
      console.warn('리스너 이미 호출 했으니까 다음 누를 때는 이 콘솔이 노출 되어야 함');
      if (playing === 'play'){
        setPlaying('pause');
        a.play();
      } else {
        setPlaying('play');
        a.pause();
      }
    }
  };

  return (
    <div onClick={onClickplayTxt}>
      {audioURL &&
			<>
			  <img className={'btnPlayer '} src={`${process.env.REACT_APP_IMG_URL}/web/player_${playing}_w.svg`} alt={'Icon'}/>
			  <audio id={`${props.type + props.n}`} preload='auto' className={'polly'}>
			    <source src={audioURL} type='audio/mp3'/>
			  </audio>
			</>}
    </div>
  );
};

export default ProfilePlayer;
