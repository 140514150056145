import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import '../css/join.scss';
import Top from '../component/top';
import axios from 'axios';
import {isAndroid, isIOS} from 'react-device-detect';


export default class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'p1'
    };
  }

  componentWillMount() {
    window.location.replace(process.env.REACT_APP_MAIN_URL + '/' + this.props.location.search);
  };

  componentDidMount() {

  };

  render() {
    return (
      <>
		  </>
    );
  }
}
