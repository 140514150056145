import React from 'react';

const CheckIcon: React.FC = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 25.656 17.996'
  >
    <path
      d='M-419.843,161.03l7.2,7.081,12.8-13.168'
      transform='translate(422.671 -152.115)'
      fill='none'
      stroke='#9732fc'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='4'
    />
  </svg>
);

export default CheckIcon;
