import React from 'react';

import SupportBoard from '../common/SupportBoard';

const CardInfoPopup = ({ props }) => {
  // props
  const {
    closePopup,
  } = props;

  return (
    <>
      <div className={'popupWrap full'}>
        <div className='infoPop info1'>
          <div className='title'>무이자 할부 안내</div>
          <div className='clsBtn' onClick={() => closePopup('cardInfoPopup')}/>
          <div className={'content'}>
            <SupportBoard supportId={'cardInfo'} />
          </div>
        </div>
      </div>
      <div className='msk'></div>
    </>
  );
};

export default CardInfoPopup;
