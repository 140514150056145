import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import 'css/purchase/PurchaseShowcaseB2C.scss';
import useCheckLogin from 'queries/common/useCheckLogin';
import Top from '../top';
import PurchaseShowcaseB2CFaq from './PurchaseShowcaseB2CFaq';
import PurchaseShowcaseB2CProduct from './PurchaseShowcaseB2CProduct';
import PurchaseShowcaseB2CProductDetail from './PurchaseShowcaseB2CProductDetail';
import PurchaseShowcaseB2CReview from './PurchaseShowcaseB2CReview';
import PurchaseShowcaseB2CSales from './PurchaseShowcaseB2CSales';
import PurchaseShowcaseB2CEmergency from './PurchaseShowcaseB2CEmergency';
import PurchaseShowcaseB2CBottomButtons from './PurchaseShowcaseB2CBottomButtons';
import Footer from 'component/Footer';

type Props = {
  ww: string;
  goToPayment: (priceSeq: number, payType: string, pledge?: boolean) => void;
  openPopup: (popupName: string) => void;
};

const PurchaseShowcaseB2C: FC<Props> = ({ ww, goToPayment, openPopup }) => {
  const history = useHistory();
  const initialFetchRef = useRef(false);
  const { isFetched: loginFetched, refetch: refetchLogin } = useCheckLogin();
  const [showFlag, setShowFlag] = useState(false);

  useEffect(() => {
    if (loginFetched && !initialFetchRef.current) {
      initialFetchRef.current = true;
      refetchLogin().then(() => {
        setShowFlag(true);
      });
    } else if (loginFetched) {
      setShowFlag(true);
    }
  }, [loginFetched]);

  const bannerElement = document.querySelector('#TopBannerLMS');

  useEffect(() => {
    const hasTopBanner = !!bannerElement;

    if (hasTopBanner && !bannerElement.classList.contains('is-showcase')) {
      setTimeout(() => {
        bannerElement.classList.add('is-showcase');
      }, 10);
    }

    return () => {
      if (hasTopBanner) {
        bannerElement.classList.remove('is-showcase');
      }
    };
  }, [bannerElement, ww]);

  if (!showFlag) return null;
  return (
    <>
      <article className='purchase-showcase-b2c'>
        <Top
          title={'수강신청'} sub={''} history={history}
          noBg={true}
          notFixed={true}
          useTit={true}
          theme='white'
          setTopBannerMargin={0}
        />
        <PurchaseShowcaseB2CProduct goToPayment={goToPayment} openPopup={openPopup} />
        <PurchaseShowcaseB2CSales />
        <PurchaseShowcaseB2CProductDetail goToPayment={goToPayment} ww={ww} />
        <PurchaseShowcaseB2CReview />
        <PurchaseShowcaseB2CFaq />
        <PurchaseShowcaseB2CEmergency />
        <PurchaseShowcaseB2CBottomButtons ww={ww} />
      </article>
      <Footer ww={ww} />
    </>
  );
};

export default PurchaseShowcaseB2C;
