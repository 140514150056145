import { StateCreator } from 'zustand';
import moment from 'moment';
import referrerStore from './referrerStore';

export interface PrevPageData {
  entryType: EntryType;
  prevPage: string | null;
}

export type EntryType = 'none' | 'redirect' | 'manual';
export interface ReferrerT {
  entryType: EntryType;
  setTimestamp: number | null;
  prevPage: string | null;
  updateData: (data: PrevPageData) => void;
  resetData: () => void;
  setReferrer: (referrer: string | null | undefined) => void;
}

const initialState = {
  entryType: 'none' as EntryType,
  prevPage: null,
  setTimestamp: null,
};

export const referrerSlice: StateCreator<
  ReferrerT,
  any[],
  any[],
  ReferrerT
> = (set) => ({
  ...initialState,
  updateData: (data: PrevPageData) => {
    set(() => ({
      entryType: data.entryType,
      prevPage: data.prevPage,
      setTimestamp: +moment()
    }));
  },
  resetData: () => {
    set(() => ({
      entryType: 'none' as EntryType,
      prevPage: null,
      setTimestamp: null
    }));
  },
  setReferrer: (referrerUrl: string | null | undefined) => {
    const state = referrerStore.getState();
    if (referrerUrl) {
      const url = new URL(referrerUrl);
      if (!state.setTimestamp || +moment() - state.setTimestamp>2000) {
        set(() => ({
          entryType: 'manual' as EntryType,
          prevPage: url.pathname,
          setTimestamp: +moment()
        }));
      }
    }
  }
});
