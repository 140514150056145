import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';

const ReactMeta = ({ title, description }) => {

  useEffect(() => {
    const meteOgTitle = document.querySelector('meta[property="og:title"]');
    const meteOgDescription = document.querySelector('meta[property="og:description"]');
    const meteDescription = document.querySelector('meta[name="description"]');
    meteOgTitle.setAttribute('content', title);
    meteOgDescription.setAttribute('content', description);
    meteDescription.setAttribute('content', description);
  },[title,description]);

  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default ReactMeta;
