// ResizeObserver 에러 전역 처리
const ignoreResizeObserverErrors = () => {
  if (typeof window === 'undefined') return;

  window.addEventListener('error', (e) => {
    if (e.message === 'ResizeObserver loop completed with undelivered notifications.' ||
        e.message === 'ResizeObserver loop limit exceeded') {
      e.stopImmediatePropagation();
    }
  });
};

export default ignoreResizeObserverErrors;
