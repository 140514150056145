import React from 'react';
import {ElementType} from 'component/book/book-type';

interface PropsType {
  content: ElementType[];
}

export default function SpeakOut({content}: PropsType) {
  return (
    <div className={'page SO'}>
      <div className={'component-name'}>Speak Out Loud</div>
      <div className='component-desc'>본문을 소리내어 크게 읽어보세요.</div>
      <section>
        {content?.map((item, index) => (
          <div className='speakout-wrapper' key={`s${index}`}>
            <div className={'title'}>Script {index+1}</div>
            <div className={'text'}>{item.sentence}</div>
          </div>
        ))}
      </section>
    </div>
  );
}
