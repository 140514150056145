import React, {useEffect} from 'react';
import Lottie from 'react-lottie';

import completeTrialStore, {getServerDay, getTrialInfo} from 'store/trial/complete/completeTrialStore';
import loading from 'json/loading.json';

import 'css/trial/TrialComplete.scss';
import TrialCompleteTypeA from 'component/trial/complete/TrialCompleteTypeA';
import TrialCompleteTypeB from 'component/trial/complete/TrialCompleteTypeB';
import ReactMeta from 'component/ReactMeta';

export default function TrialComplete(props: any) {
  const isLoading = completeTrialStore((state) => state.isLoading);
  const ABType = completeTrialStore((state) => state.ABType);

  useEffect(() => {
    getServerDay();
    getTrialInfo(props?.match?.params?.seq);
  }, []);

  if (isLoading) {
    return (
      <>
        <ReactMeta title='무료수업으로 실력진단 | 민병철유폰 1:1영어회화' description='1:1수업부터 유폰 콘텐츠, AI학습까지 모두 무료체험으로 경험해보세요.'/>
        <div className={'lottieLoading'}>
          <Lottie options={{ loop: true, autoplay: true, animationData: loading }} />
        </div>
      </>
    );
  }

  if (ABType==='A')
    return (
      <>
        <ReactMeta title='무료수업으로 실력진단 | 민병철유폰 1:1영어회화' description='1:1수업부터 유폰 콘텐츠, AI학습까지 모두 무료체험으로 경험해보세요.'/>
        <TrialCompleteTypeA timechangeUrl={props?.match?.params?.seq} />
      </>
    );
  else
    return (
      <>
        <ReactMeta title='무료수업으로 실력진단 | 민병철유폰 1:1영어회화' description='1:1수업부터 유폰 콘텐츠, AI학습까지 모두 무료체험으로 경험해보세요.'/>
        <TrialCompleteTypeB />
      </>
    );
}
