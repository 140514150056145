import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import unitedTopBannerStore from '../../store/unitedTopBanner/unitedTopBannerStore';
import { useOptimizedResize } from '../../hooks/useOptimizedResize';

type IProps = {
  unitedTopBanner: {
    type: string,
    bannerColor: string,
    mobileImageHeight: number,
    mobileImageFile: string,
    pcImageHeight: number,
    pcImageFile: string,
    actionType: string,
    moveUrl: string,
  }
}

export default function UnitedTopBannerLink({ unitedTopBanner }: IProps) {
  const isDesktop = useMediaQuery({ query: '(min-width: 1025px)' });
  const setMobileBannerHeight = unitedTopBannerStore((state) => state.setMobileBannerHeight);
  const setPCBannerHeight = unitedTopBannerStore((state) => state.setPCBannerHeight);

  const handleMobileResize = useCallback((height: number) => {
    setMobileBannerHeight(height);
  }, [setMobileBannerHeight]);

  const handlePCResize = useCallback((height: number) => {
    setPCBannerHeight(height);
  }, [setPCBannerHeight]);

  const { ref: mobileRef } = useOptimizedResize(handleMobileResize);
  const { ref: pcRef } = useOptimizedResize(handlePCResize);

  const history = useHistory();

  const { 
    bannerColor,
    pcImageHeight,
    pcImageFile,
    mobileImageHeight,
    mobileImageFile,
    moveUrl,
  } = unitedTopBanner;

  if (isDesktop) {
    return (
      <div onClick={() => history.push(moveUrl)}
        ref={pcRef}
        id='TopBannerLMS'
        className={`top-banner-lms top-banner-lms--pc ${(window.location.pathname).toLowerCase().indexOf('/course/p3') >= 0 ? 'is-magazine' : ''} ${(window.location.pathname).toLowerCase().indexOf('/course') >= 0 ? 'course' : (window.location.pathname).toLowerCase().indexOf('/freereport/r') >= 0 ? 'freereport' :  (window.location.pathname).toLowerCase().indexOf('/guide') >= 0 ? 'guide': (window.location.pathname).toLowerCase().indexOf('/order') >= 0 ? 'order':''}`}
      >
        <div style={{ width: '100%', background: `#${bannerColor}`, height: `${pcImageHeight}px` }} className='pc'>
          <img src={pcImageFile} id={'TopBannerImg'} alt='' />
        </div>
      </div>
    );
  }

  if (!isDesktop) {
    return (
      <div onClick={() => history.push(moveUrl)}
        ref={mobileRef}
        id={'TopBannerLMS'}
        className={`top-banner-lms top-banner-lms--mo ${(window.location.pathname).toLowerCase().indexOf('/course') >= 0 ? 'course' : (window.location.pathname).toLowerCase().indexOf('/freereport/r') >= 0 ? 'freereport' :  (window.location.pathname).toLowerCase().indexOf('/guide') >= 0 ? 'guide': (window.location.pathname).toLowerCase().indexOf('/order') >= 0 ? 'order':''}`}
      >
        <div style={{ width: '100%', background: `#${bannerColor}`, height: `${mobileImageHeight}px` }} className='mo'>
          <img src={mobileImageFile} id={'TopBannerImg'} alt='' />
        </div>
      </div>
    );
  }

  return (<></>);
}
