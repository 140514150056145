import React, { useEffect, useRef, useState } from 'react';

import * as utilFn from 'common/utilFn';
import { httpDefaultClient } from 'configs/api';

import reserveTrialStore, { alertMessage, eMessage, validateInput } from 'store/trial/reserve/reserveTrialStore';

import LayerPopup from 'component/LayerPopup';
export default function InputContainer() {
  const [seconds, setSeconds] = useState(600);
  const [timerActive, setTimerActive] = useState(false);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const interval = useRef<NodeJS.Timeout | null>(null);
  const inputState = reserveTrialStore((state) => state.inputState);
  const setInputState = reserveTrialStore((state) => state.setInputState);
  const recommendInfo = reserveTrialStore((state) => state.recommendInfo);

  useEffect(() => {
    return () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    };
  }, []);

  const authBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.readOnly) {
      return null;
    }

    const value = inputState.auth;

    if (value.length === 6) {
      checkAuthNumber(value);
    } else if (value.length === 0) {
      setInputState({
        ...inputState,
        authError: '필수 정보입니다.',
        authInfoView: true,
      });
    } else {
      setInputState({
        ...inputState,
        authError: '인증번호를 다시 확인해주세요.',
        authInfoView: true,
      });
    }
    validateInput();
  };

  const onChangeAuthInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    if (value.length > 6) {
      value = value.slice(0, 6);
      setInputState({
        ...inputState,
        auth: value,
        authError: '',
        authPass: false,
        authInfoView: false,
      });
      checkAuthNumber(value);
    } else if (value.length === 6) {
      setInputState({
        ...inputState,
        auth: value
      });
      checkAuthNumber(value);
    } else {
      setInputState({
        ...inputState,
        auth: value,
        authError: '',
        authPass: false,
        authInfoView: false,
      });
    }
  };

  const checkAuthNumber = (authInputValue: string) => {
    // 인증번호랑 입력값 비교

    const params = {
      cellPhone: inputState.cellPhone.replace(/-/gi, ''),
      authNumber: authInputValue,
      authType: 'FREELESSON_APPLY'
    };
    const onSuccess = () => {
      setInputState({
        ...inputState,
        authError: '',
        auth: authInputValue,
        authInfoView: false,
        authPass: true,
        authDisabled: true,
      });
      setSeconds(600);
      validateInput();
    };

    const onFail = async (commonMessage: string) => {
      setInputState({
        ...inputState,
        auth: authInputValue,
        authError: await utilFn.getCommonMessage(commonMessage),
        authInfoView: true,
        authPass: false,
      });
    };

    utilFn.checkAuthNumber(params, onSuccess, onFail);
  };

  const getAuthNumber = async() => {
    if (!inputState.isAuthCodeButton) return null;

    setInputState({ ...inputState, authPass: false });

    if (inputState.name === '') {
      setInputState({ ...inputState, nameError: '필수 정보입니다.' });
      return null;
    } else if (inputState.nameError !== '') {
      setInputState({ ...inputState, nameError: '이름은 한글 2~6자까지 사용가능합니다.' });
      return null;
    }

    if (inputState.cellPhone === '') {
      setInputState({ ...inputState, cellPhoneError: '필수 정보입니다.' });
      return null;
    } else if (inputState.cellPhoneError !== '') {
      return null;
    }
    
    if (inputState.cellPhone.replace(/-/g, '') === recommendInfo?.lessonPhone) { // 추천인=입력번호
      utilFn.removeMarketingCodeValue('rcmdCode');
      alertMessage(await utilFn.getCommonMessage('FRIEND0012'), '/');
      return false;
    }
    
    stopTimer();

    setInputState({
      ...inputState,
      authDisabled: false,
      authTimeError: false,

      authInfoView: false,
      authError: '',
      authInput: '',
      authState: false,
    });
    setSeconds(600);

    checkIsMember();
  };

  const checkIsMember = async () => {
    try {
      const { data } = await httpDefaultClient.get('/trial/isMember?name=' + encodeURI(inputState.name) + '&cellphone=' + inputState.cellPhone.replace(/-/g, ''));

      if (data.message === 'FL00000001') {
        alertMessage(await utilFn.getCommonMessage('FL00000001'), '/Join');
        return null;
      } else {
        setInputState({
          ...inputState,
          authPass: false
        });

        const params = {
          cellPhone: inputState.cellPhone.replace(/-/gi, ''),
          authType: 'FREELESSON_APPLY'
        };

        const onSuccess = () => {
          setInputState({
            ...inputState,
            authInfoView: true,
            authState: true,
            authDisabled: false,
          });

          startTimer();
          validateInput();
        };
        const onFail = async (commonMessage: string) => {
          setInputState({
            ...inputState,
            authPass: false,
          });
          alertMessage(await utilFn.getCommonMessage(commonMessage), '');
          validateInput();
        };
        utilFn.sendAuthNumber(params, onSuccess, onFail);
      }
          
    } catch (error) {
      console.error('/freelesson/isMember' + error);
      eMessage('alert', '서버 통신에 실패 하였습니다.');
    }
  };

  const onBlurInput = (type: string) => {
    const errorMessage = (utilFn as any)[`${type}Chk`]('ko', `${inputState[type]}`);

    setInputState({
      ...inputState,
      [`${type}Error`]: errorMessage
    });

    validateInput();
  };

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>, type: string, ml?: any) => {
    const value = e.target.value;
    const sliceValue = (utilFn as any)[`${type}Input`]('ko', value, ml);

    if (type === 'name' && sliceValue === '' && value !== '') {
      eMessage('nameError', 'SIGN000039');
      return false;
    }

    setInputState({
      ...inputState,
      [type]: sliceValue,
      [`${type}Error`]: '',
      authPass: false,
      auth: '',
      authInfoView: false,
      authDisabled: true,
      isAuthCodeButton: type === 'name' ? inputState.cellPhone.length >= 12 : type === 'cellPhone' ? sliceValue.length >= 12 : false,
    });
  };


  const startTimer = () => {
    if (timerActive) return;
    setTimerActive(true);
    const id = setInterval(() => {
      setSeconds(s => {
        if (s === 1) { // 마지막 초 처리
          clearInterval(id);
          setTimerActive(false);
          setIntervalId(null);
          return 0;
        }
        return s - 1;
      });
    }, 1000);
    setIntervalId(id);
  };

  const stopTimer = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      setTimerActive(false);
    }
  };

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes}분 ${secondsLeft.toString().padStart(2, '0')}초`;
  };

  return (
    <>
      <div className={`memberInput ${inputState.name ? 'v' : ''}`}>
        <input name={'name'} className={`input ${inputState.nameError ? 'error' : ''}`} id={'name'}
          value={inputState.name} onChange={(e) => onChangeInput(e, 'name', 7)}
          onBlur={(e) => onBlurInput('name')} />
        <label htmlFor={'name'}>이름</label>
      </div>
      {inputState.nameError && <div className='error'>{inputState.nameError}</div>}

      <div className={`memberInput t2 ${inputState.cellPhone ? 'v' : ''}`}>
        <input name={'cellPhone'} id={'cellPhone'} className={`input--tel input ${inputState.cellPhoneError ? 'error' : ''}`}
          type={'tel'} maxLength={13} value={utilFn.cellPhoneInput('ko', inputState.cellPhone)}
          onChange={(e) => onChangeInput(e, 'cellPhone')}
          onBlur={(e) => onBlurInput('cellPhone')}
        />
        <label htmlFor={'cellPhone'}>휴대전화번호</label>
        <div className={`btn inputBtn reqAuthBtn ${!inputState.isAuthCodeButton ? 'disable' : ''}`}
          onClick={getAuthNumber}>인증번호 받기
        </div>
      </div>
      {inputState.cellPhoneError && <div className='error'>{inputState.cellPhoneError}</div>}

      <div className={`memberInput ${inputState.auth ? 'v' : ''}`}>
        <input name={'auth'} id={'auth'} className='input' type='number'
          value={inputState.auth}
          onChange={(e) => onChangeAuthInput(e)} onBlur={authBlur} readOnly={inputState.authDisabled}
          disabled={inputState.authDisabled} />
        <label htmlFor={'auth'}>인증번호 입력</label>

        {inputState.authInfoView &&
          <span className='timer'>유효시간 {formatTime()}</span>}
        {inputState.authPass &&
          <div className={'completeTxt adj'}><img
            src={process.env.REACT_APP_IMG_URL + '/join/ico_check.svg'}
            alt={'check'} /> 인증완료</div>
        }
      </div>
      {inputState.authError && <div className='error'>{inputState.authError}</div>}
      <div className={`authInfo ${inputState.authInfoView ? 'view' : 'hide'}`}>
        <div className='title'>인증번호가 오지 않나요?</div>
        <div className='list'>1. 휴대전화번호를 다시 확인해주세요.</div>
        <div className='list'>2. 1599 번호가 스팸 문자로 등록되어 있으면 해제해주세요.</div>
        <div className='list'>3. 가상 전화번호는 인증번호를 받을 수 없습니다.</div>
      </div>

      {inputState.alertText &&
        <LayerPopup
          tit={inputState.alertText}
          btnCnt={1}
          btnOkCmt={'확인'}
          unmountScrollNone={'N'}
          btnOkFunction={inputState.alertFunction}
        />
      }
    </>
  );
}
