import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import completeTrialSlice, { CompleteTrialT } from './completeTrialSlice';
import { httpDefaultClient } from 'configs/api';


export const completeTrialStore = create<
CompleteTrialT 
>()(devtools((...all) => ({
  ...completeTrialSlice(...all),
}), { store: 'completeTrial' }));

export default completeTrialStore;

export const isChangeMode = () => {
  return window.location.pathname.toLowerCase().includes('/trialChangeComplete'.toLowerCase());
};

export async function getServerDay() {
  const setServerDay = completeTrialStore.getState().setServerDay;
  try {
    const { data } = await httpDefaultClient.get('/trial/day');
    setServerDay(data);
  } catch (error) {
    console.error('/trial/day' + error);
  }
}

export const getTrialInfo = async (trialCode: string) => {
  const setTrialInfo = completeTrialStore.getState().setTrialInfo;
  const setIsLoading = completeTrialStore.getState().setIsLoading;
  const setIsShowChangeAlert = completeTrialStore.getState().setIsShowChangeAlert;
  const setABType = completeTrialStore.getState().setABType;
  setIsLoading(true);
  try {
    const { data } = await httpDefaultClient.get(`/trial/infoByCode/${trialCode}`);
    setABType(data.juniorFlag ? 'A' : 'B');
    if (process.env.REACT_APP_PHASE === 'prod') {
      const ABType = completeTrialStore.getState().ABType;
      const queryStringIndex = data.appDownloadLink.indexOf('?');
      const queryString = queryStringIndex !== -1 ? data.appDownloadLink.substring(queryStringIndex) : '';
      data.appDownloadLink = ABType === 'A' ? 'https://uphone3dev.onelink.me/Fj4y/c2yd7rnd'+queryString : 'https://uphone3dev.onelink.me/Fj4y/5551ntzy'+queryString;
    }
    setTrialInfo(data);
    setIsShowChangeAlert(!isChangeMode() && data?.originalLessonTime);
  } catch (error) {
    console.error('Failed to /trial/infoByTrialSeq/' + error);
  } finally {
    setIsLoading(false);
  }
};

export const getTrialDate = () => {
  const trialInfo = completeTrialStore.getState().trialInfo;
  const serverDay = completeTrialStore.getState().serverDay;
  const { today, tomorrow } = serverDay;
  const dateArr = trialInfo?.lessonDate.split('-');
  const originalTime = trialInfo?.lessonTime;
  const formattedTime = originalTime?.replace(/(\d{2}):(\d{2})/, '$1시 $2분');
  if (trialInfo.lessonDate === today) {
    return `오늘(${parseInt(dateArr[1]) + '/' + parseInt(dateArr[2])}) ${formattedTime}`;
  } else if (trialInfo.lessonDate === tomorrow) {
    return `내일(${parseInt(dateArr[1]) + '/' + parseInt(dateArr[2])}) ${formattedTime}`;
  } else {
    const dateArr = trialInfo.lessonDate.split('-');
    return `${parseInt(dateArr[1]) + '/' + parseInt(dateArr[2])} ${formattedTime}`;
  }
  
};
