import React, {useEffect, useMemo, useRef, useState} from 'react';
import { httpDefaultClient } from 'configs/api';
import { BookType } from 'component/book/book-type';
import Script from 'component/book/Script';
import 'css/Book.scss';
import Dialog from 'component/book/Dialog';
import Toeic from 'component/book/Toeic';
import TodayImage from 'component/book/TodayImage';
import Element from 'component/book/Element';
import Exercise from 'component/book/Exercise';
import Answer from 'component/book/Answer';
import SmallTalk from 'component/book/SmallTalk';
import Picture from 'component/book/Picture';
import Greeting from 'component/book/Greeting';
import Closing from 'component/book/Closing';
import Vod from 'component/book/Vod';
import SpeakOut from 'component/book/SpeakOut';
import TodayExpression from 'component/book/TodayExpression';
import Discussion from 'component/book/Discussion';

interface PropsType {
  leveltestunitSeq?: string;
  timechangeUrl?: string;
  unitSeq?: string;
  memberunitSeq?: string;
  lessonSeq?: string;
  lessonType?: string;
  closeBook?: () => void;
}

interface ComponentMapEntry {
  name: string;
  desc: string;
  component: React.ComponentType<any>;
}

interface ComponentMap {
  [key: string]: ComponentMapEntry;
}

export default function Book({
  leveltestunitSeq,
  timechangeUrl,
  unitSeq,
  memberunitSeq,
  lessonSeq,
  lessonType,
  closeBook
}: PropsType) {
  const refs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({});
  const [visibleComponent, setVisibleComponent] = useState<string | null>(null);
  const [showShortcut, setShowShortcut] = useState<boolean>(false);
  const [book, setBook] = useState<BookType>({});

  const componentMap: ComponentMap = useMemo(() => ({
    greeting: {
      name: 'Greeting',
      desc: '강사님과 첫인사 하기',
      component: Greeting,
    },
    closing: {
      name: 'Goodbye',
      desc: lessonType==='TRIAL'?'무료수업 출석 혜택 확인하기':'강사님과 끝인사 하기',
      component: Closing,
    },
    VW: {
      name: 'VOD Watching',
      desc: '동영상 보기',
      component: Vod,
    },
    SR: {
      name: 'Script Reading',
      desc: '스크립트 읽기',
      component: Script,
    },
    MD: {
      name: 'Main Dialogue',
      desc: '오늘의 대화',
      component: Dialog,
    },
    MD1: {
      name: 'Main Dialogue 1',
      desc: '오늘의 대화 1',
      component: Dialog,
    },
    MD2: {
      name: 'Main Dialogue 2',
      desc: '오늘의 대화 2',
      component: Dialog,
    },
    TS1: {
      name: 'Read a Text Aloud',
      desc: '문장 읽기',
      component: Toeic,
    },
    TS2: {
      name: 'Describe a Picture',
      desc: '사진 묘사',
      component: Toeic,
    },
    TS3: {
      name: 'Respond to Questions',
      desc: '듣고 질문에 답하기',
      component: Toeic,
    },
    TS4: {
      name: 'Respond Using Information',
      desc: '정보 활용하여 답하기',
      component: Toeic,
    },
    TS6: {
      name: 'Express an Opinion',
      desc: '의견 제시하기',
      component: Toeic,
    },
    TE: {
      name: 'Today\'s Expression',
      desc: '의견 제시하기',
      component: TodayExpression,
    },
    TI: {
      name: 'Today\'s Image',
      desc: '오늘의 이미지',
      component: TodayImage,
    },
    SO: {
      name: 'Speak Out Loud',
      desc: '소리 내서 읽기',
      component: SpeakOut,
    },
    PD: {
      name: 'Pattern Drills',
      desc: '패턴 익히기',
      component: Element,
    },
    KE: {
      name: 'Key Expressions',
      desc: '주요 표현 익히기',
      component: Element,
    },
    EX: {
      name: 'Exercise',
      desc: '동사 활용하기',
      component: Exercise,
    },
    VO: {
      name: 'Vocabulary',
      desc: '단어 활용하기',
      component: Element,
    },
    AI: {
      name: 'Answering with Information',
      desc: '정보 이용하여 답하기',
      component: Answer,
    },
    QU: {
      name: 'Questions',
      desc: '질문에 답하기',
      component: Element,
    },
    OD: {
      name: 'Open Discussion',
      desc: '글의 인사이트 정리하기',
      component: Discussion,
    },
    ST: {
      name: 'Part 1. 의사소통',
      desc: '대화 나누기',
      component: SmallTalk,
    },
    RS: {
      name: 'Part 2. 발음',
      desc: '문장 읽기',
      component: Element,
    },
    FB: {
      name: 'Part 3. 문법',
      desc: '문장 만들기',
      component: Element,
    },
    DP: {
      name: 'Part 4. 유창성&이해력',
      desc: '사진 묘사하기',
      component: Picture,
    },
  }), []);

  useEffect(() => {
    const fetchBook = async () => {
      let res;
      if (leveltestunitSeq) {
        res = await httpDefaultClient(`/content/leveltest/book/${leveltestunitSeq}`);
      } else if (timechangeUrl) {
        res = await httpDefaultClient.get(`/trial/callStudy/${timechangeUrl}`);
      } else if (memberunitSeq) {
        res = await httpDefaultClient.get(`/content/memberUnit/${memberunitSeq}/callStudy`, { params: { ...(lessonSeq && { lessonSeq }) } });
      } else if (unitSeq) {
        res = await httpDefaultClient(`/content/unit/${unitSeq}/nonmember/callStudy`);
      }
      if (res && res.data) {
        res.data.components.unshift('greeting');
        res.data.components.push('closing');
        setBook({ ...res.data });
        if (res.data.components) {
          res.data.components.forEach((c: string) => {
            if (!refs.current[c]) {
              refs.current[c] = React.createRef();
            }
          });
        }
      }
    };
    fetchBook();
  }, [leveltestunitSeq, timechangeUrl, unitSeq, memberunitSeq, lessonSeq]);

  useEffect(() => {
    const currentRefs = Object.values(refs.current);
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const key = entry.target.getAttribute('data-key');
            setVisibleComponent(key);
          }
        });
      },
      {
        root: null,
        threshold: 0.5,
      }
    );
    if (book.components) {
      currentRefs.forEach(ref => {
        if (ref.current) {
          observer.observe(ref.current);
        }
      });

      return () => {
        currentRefs.forEach(ref => {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        });
      };
    }
  }, [Object.values(refs.current)]);

  useEffect(() => {
    if (showShortcut) {
      const activeItem = document.querySelector('.shortcut-item.active');
      if (activeItem) {
        activeItem.scrollIntoView({ behavior: 'auto', block: 'nearest' });
      }
    }
  }, [showShortcut, visibleComponent]);

  const scrollToComponent = (componentKey: string) => {
    const element = document.querySelector(`[data-key="${componentKey}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      setShowShortcut(false);
    }
  };

  return (
    <div className={'book'}>
      <section className={'shortcut'}>
        {
          closeBook &&
          <img className={'close'}
            onClick={closeBook}
            src={`${process.env.REACT_APP_IMG_URL}/icon/btn_close_circle.svg`} alt={'Close Button'}
          />
        }
        <img className={'shortcut-button'}
          onClick={() => setShowShortcut((v) => !v)}
          src={showShortcut ? `${process.env.REACT_APP_IMG_URL}/icon/btn_close_circleblack.svg` : `${process.env.REACT_APP_IMG_URL}/icon/btn_menu_circle.svg`} alt={'menu'}
        />
        {showShortcut &&
          <>
            <div className={'shortcut-body'}>
              <div className={'shortcut-body__inner'}>
                {book.components?.map((c, i) => {
                  if (componentMap[c]) {
                    return (
                      <div key={c} className={`shortcut-item ${visibleComponent === c ? 'active' : ''}`} onClick={() => scrollToComponent(c)}>
                        <div className={'shortcut-item__name'}>{componentMap[c].name}</div>
                        <div className={'shortcut-item__desc'}>{componentMap[c].desc}</div>
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
            <div className={'shortcut-dim'}></div>
          </>
        }
      </section>
      <section className={'body'}>
        {book.components?.map((c) => {
          if (componentMap[c]) {
            return (
              <div key={c} data-key={c} ref={refs.current[c]}>
                {React.createElement(componentMap[c].component, {
                  lessonType: lessonType,
                  componentCode: c,
                  book: book,
                  content: book[c],
                  scrollToComponent,
                })}
              </div>
            );
          }
          return null;
        })}
      </section>
    </div>
  );
}
