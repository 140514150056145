import React, { Component, createRef } from 'react';

import 'css/order.scss';
import 'css/orderImprove.scss';

import moment from 'moment';
import { isIOS } from 'react-device-detect';
import { httpDefaultClient, httpRefreshClient } from 'configs/api';
import * as utilFn from '../common/utilFn';
import referrerStore from 'store/referrer/referrerStore';
import ChannelService from '../component/ChannelService';
import ReactMeta from '../component/ReactMeta';
import { saveAccessDataByAccessFunction } from 'services/accessData/accessAnalytics';
import purchaseShowcaseB2CStore from 'store/purchase/purchaseShowcaseB2CStore';

import Footer from '../component/Footer';
import LayerPopup from '../component/LayerPopup';
import ToastPopup from 'component/common/ToastPopup';
import PersonalPopup from 'component/PersonalPopup';
import CardInfoPopup from 'component/purchase/CardInfoPopup';
import PurchaseShowcaseB2C from 'component/purchase/PurchaseShowcaseB2C';
import ClassEnrollment from 'component/purchase/ClassEnrollment';
import PurchasePopup from 'component/purchase/PurchasePopup';
import ClassEnrollmentClosed from 'component/purchase/ClassEnrollmentClosed';
import ClassPledge from 'component/purchase/ClassPledge';
import ClassPayment from 'component/purchase/ClassPayment';
import UpsellingPopup from 'component/purchase/UpsellingPopup';
import NPayGuidePopup from 'component/purchase/NPayGuidePopup';
import PaymentGatewayIntel from 'component/purchase/PaymentGatewayIntel';

export default class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      memberClass: null,
      page: 'p1',
      inclassFlag: false,
      toastOff : true,

      item: [],
      viewProduct: [],
      selectProduct: {},
      selectProductList: [],
      productWeek: '0',
      productInfo: '',
      mMileage:0,
      mileageInput:0,
      couponList:[],
      coupon:'',
      couponInput:'',
      couponError: '',
      payStats: null,
      couponDiscount: 0,
      couponValue: 0,
      membercouponSeq: null,
      panelStats: [false, false, false, true, true],    // 0: 적립금, 1: 할인쿠폰, 2: 결제금액, 3: 결제수단, 4: 주의사항

      purchaseSeq: null,
      todayDate : moment().format('YYYY-MM-DD'),

      showUpsellingPopup : false, // 4주 정기 유도 팝업 플래그 - 22.09.06 남인식
      purchaseInfoUpsellingItem : null, // 4주 정기상품 정보 가져옴
      couponValueUpsellingItem : 0, // 4주 정기 상품 유도 팝업 내 정기 상품 금액 계산을 위한 쿠폰 할인금액
      mileageInputUpsellingItem : 0, // 4주 정기 상품의 마일리지 노출은 이걸로 계산해야 함
      before4weekPrice : 0,
      orderFull: false, // 선착순 수강신청 마감 여부
      totalorderFull: false, // 선착순 수강신청 마감 여부(전체 인원수 마감)
      waitingFlag: false, // 대기 수강신청 사용여부
      waitingPriceSeq: 0, // 대기 수강신청 대상 티켓번호

      languageTypeState: [false, false, false, false],
      productMinuteState: [false, false, false],
      templateCodeState: [false, false, false],
      lessonModeState: [false, false, false],
      timezoneCodeState: [false, false, false],
      productTypeState: [false, false, false],

      personalOrder: false,
      personalOrderInfo: {},
      bookApplyFlag: false,

      TIMEZONEF: [],
      ASSIGNTEM: [],
      RETAKE: [],
      STUDYMIN: [],
      PRODWEEK: [],
      PRODMODE: [],

      showEmergencyPopup: purchaseShowcaseB2CStore.getState().showEmergencyPopup,
    };
    this.couponSelect = createRef();
    this.couponNum = createRef();
    this.payPanel = createRef();
    this.mileageRef = createRef();
    this.inputAddressFocus = this.inputAddressFocus();
  }

  async componentDidMount() {this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.freeOrder = false;
    this.getCommonMsgList();
    this.commonCodes('TIMEZONEF,ASSIGNTEM,PRODTYPE,RETAKE,STUDYMIN,PRODWEEK,PRODMODE');

    window.addEventListener('resize', this.changeWw, true);
    window.addEventListener('scroll', this.scrollCheckCardInfo.bind(this), true);

    utilFn.ScrollNone();
    this.checkPurchaseDevice();

    if (this.props.match.params.page === 'p4' && this.props.match.params.sub !== 'full') {
      this.props.history.replace('/Order');
    }

    let login = await this.getLoginStatus();
    if (login) {
      await this.getLessonStatus();
      this.processPage();
    } else {
      this.setState({
        notLogin: true,
        memberClass: 'NONMEMBER',
      });
      if (this.props.match.params.page === 'personal') {
        this.props.history.replace('/Order');
      }
    }
    utilFn.sendFacebook('수강신청');

    window.confirmGoBack = () => {
      this.openPopup('confirmGoBackAlert');
    };

    window.addEventListener('popstate', (event) => {
      if (this.props.match.params.page === 'p2' && this.state.purchaseInfo?.purchasestatusCode === 'COMPLETE') {
        this.props.history.replace('/Order');
      }
      this.setState({
        showUpsellingPopup: false,
        diffTicket: false,
        diffNationCode: false,
        diffLessonMode: false,
        purchaseAgreePage: false,
      });
    });

    // store 구독 추가
    this.unsubscribe = purchaseShowcaseB2CStore.subscribe(() => {
      this.handleStoreChange(); // 또는 관련 state 업데이트
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeWw);
    window.removeEventListener('scroll', this.scrollCheckCardInfo.bind(this), true);
    if (this.unsubscribe) this.unsubscribe();
  }

  // 스토어 구독 콜백
  handleStoreChange = () => {
    this.setState({
      showEmergencyPopup: purchaseShowcaseB2CStore.getState().showEmergencyPopup,
    });
  };

  // 공통코드 호출
  commonCodes = (codes) => {
    httpDefaultClient.get('/commonCode/multi/'+codes)
      .then( (response) => {
        if (response.data) {
          this.setState({...response.data});
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  // 공통메시지 호출
  getCommonMsgList = () => {
    httpDefaultClient.get('/commonMessage/getList')
      .then((response) => {
        this.setState({
          commonMsg : response.data,
        });
      })
      .catch( (error) => {
        console.error('getCommonMsgList: ', error);
      });
  };

  // alert 레이어팝업 호출
  alertMessage = (v, com, type) => {
    if (v === 'PUR000017') {
      this.setState({
        eMessageTitle : '결제 취소',
        eMessageTxt: this.state.commonMsg[v],
      });
    } else if (com) {
      this.setState({
        eMessageTitle : this.state.commonMsg[v] ? this.state.commonMsg[v] : v,
        eMessageTxt: '',
      });
    } else {
      this.setState({
        eMessageTitle : v,
        eMessageTxt: '',
      });
    }
    this.setState({
      alertType: type,
    });

    this.openPopup('eMessageFlag');
  };

  // 선택 상품 초기화
  unselectProduct = () => {
    this.setState({
      selectProductList: this.state.initSelectProductList,
      selectBestProductList: this.state.initSelectProductList,
      productInfo: '',
    });
  };

  // 팝업 열기
  openPopup = (s, type) => {
    console.log('openPopup', s, type);
    this.setState({
      [s] : true,
      popupType : type,
    });
    const timer = setTimeout(function(){
      utilFn.ScrollNone(true);
      clearTimeout(timer);
    }, 0);
    ChannelService.hideBtn();
  };

  // 팝업 닫기
  closePopup = (popupName) => {
    // 팝업 닫힐때 채널톡 버튼 표시
    if (this.props.match?.params?.page!=='p2' && this.ww === 'pc' || (this.ww === 'mo' && !this.props.match.params.page && !this.state.productInfo)) {
      ChannelService.showBtn();
    }

    /* 팝업 종류
    Order.js
    eMessageFlag: 오류메시지 공통팝업
    before14: 원클릭재수강
    unablePurchase: 정기결제 대기건 존재
    unablePurchaseRegular: 홀딩중 정기결제 불가
    tempHistoryB2B: 비회원->결제하기->b2b로그인

    ClassEnrollment.js
    unablePurchaseB2B: B2B 이미 수강신청 완료한 경우
    isShowWaitingClassPopup: B2B 대기신청 확인 팝업
    isShowFinishWaitingClassPopup: B2B 대기신청 완료 팝업
    isShowAlreadyDoneWaitingClassPopup: B2B 중복대기신청 팝업

    ClassPayment.js
    confirmGoBackAlert: 뒤로가기 확인 팝업
    diffTicket: 이전 수강권과 다를때 확인 팝업
    diffNationCode: 이전 국가코드와 다를때 확인 팝업
    diffLessonMode: 이전 수업형태와 다를때 확인 팝업  
    */

    // 팝업 내리기
    utilFn.ScrollNone();
    this.setState({
      [popupName] : false,
      alertPriority: false,
    });

    // 비회원->로그인 후 팝업 닫을 때 쿠키 제거
    localStorage.removeItem('tempHistory');

    // 홀딩중 정기결제 불가 팝업 닫을 때 상품 초기화
    if (popupName === 'unablePurchaseRegular') {
      this.unselectProduct();
    }

    // 비회원->로그인 후 결제하기 불가인 경우 Order 페이지로 이동
    if (this.props.match.params.page === 'p2' && (popupName === 'tempHistoryB2B' || popupName === 'unablePurchase' || popupName === 'unablePurchaseM' || popupName === 'unablePurchaseRegular')) {
      this.props.history.replace('/Order');
    }

    // B2B 수강신청 이미 완료한 경우
    if (popupName === 'unablePurchaseB2B') {
      this.go('/');
    }

    // 공통 에러메시지 처리
    if (popupName === 'eMessageFlag') {
      if (this.state.couponCode === 'userInput' && this.state.alertType !== 'payStats') { // 쿠폰 입력 관련 오류
        if (this.state.memberCouponReturn !== 'COUPON0006') { // 코드를 다시 확인해주세요. 외에 쿠폰코드 비우기
          this.setState({
            coupon : ''
          });
        }
        if (this.state.memberCouponReturn === 'COUPON0003') { // 이미 등록한 할인 코드입니다. 보유한 할인쿠폰을 확인해주세요.
          this.couponSelect.focus();
          const payPanelTop = window.pageYOffset + this.couponSelect.getBoundingClientRect().top - (window.innerWidth/375*100);
          setTimeout(() => {
            window.scrollTo(0, payPanelTop);
          }, 100);
        } else {
          if (this.state.memberClass !== 'B2B' && this.state.alertType === 'couponError') {
            this.couponNum.focus();
          }
        }
      } else if (this.state.alertType === 'payStats') { // 결제수단 선택 오류
        const payPanelTop = window.pageYOffset + this.payPanel.getBoundingClientRect().top - (window.innerWidth/375*50);
        setTimeout(() => {
          window.scrollTo(0, payPanelTop);
        }, 100);
        if (!this.state.panelStats[3]) {
          this.panelState(3);
        }
      }
    }
  };

  // 이동
  go = (t) => {
    this.props.history.push(t);
  };

  // replace 로 이동 (web only)
  replaceHistory = (t) => {
    this.props.history.replace(t);
  };

  // 백버튼 컨펌창 확인
  goBackYes = () => {
    this.unselectProduct();
    this.closePopup('confirmGoBackAlert');
    if (this.state.personalOrder) {
      this.props.history.go(-2);
    } else if (this.state.prevPage) {
      this.props.history.go(-3);
    } else if (this.state.payCancel) {
      this.props.history.go(-2);
    } else {
      this.props.history.goBack();
    }
    this.setStateFalseValue('mileageInput');
    this.setStateFalseValue('couponValue');
    // 패널 결제수단..
    this.setState({
      panelStats: [false, false, false, true, true],
      payStats: null,
    });
  };

  // 개인결제 (web only)
  goPersonalOrder = () => {
    window.location.href='/Order/personal';
  };

  // 가로 사이즈 체크 (web only)
  changeWw = () => {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.setState({
      ww : this.ww
    });
  };

  // 구매 디바이스 체크 (web only)
  checkPurchaseDevice = () => {
    const mobileKeyWords = new Array('iPhone', 'iPod', 'BlackBerry', 'Android', 'Windows CE', 'Windows CE;', 'LG', 'MOT', 'SAMSUNG', 'SonyEricsson', 'Mobile', 'Symbian', 'Opera Mobi', 'Opera Mini', 'IEmobile');
    let purchaseDevice;
    for (let word in mobileKeyWords) {
      if (navigator.userAgent.match(mobileKeyWords[word]) != null) {
        purchaseDevice = 'MOBILE';
        break;
      } else {
        if (isIOS && navigator.maxTouchPoints >= 5) {
          purchaseDevice = 'MOBILE';
        } else {
          purchaseDevice = 'WEB';
        }
      }
    }

    // 지원 불가 브라우저일 경우 alert
    if (purchaseDevice === 'WEB') {
      var userAgent=navigator.userAgent.toLowerCase();
      var browser, browserAlert;
      if (userAgent.indexOf('edge')>-1){
        browserAlert = false;
      } else if (userAgent.indexOf('whale')>-1){
        browserAlert = false;
      } else if (userAgent.indexOf('chrome')>-1){
        browserAlert = false;
      } else if (userAgent.indexOf('firefox')>-1){
        browserAlert = true;
      } else if (userAgent.indexOf('safari')>-1){
        browserAlert = false;
      } else {
        browserAlert = true;
      }

      if (browserAlert && !!utilFn.getCookie('accessToken')) {
        alert('수강신청은 Chrome, Microsoft Edge 브라우저에서 진행해주세요.');
        window.location.href = '/';
      }

      this.setState({
        browserAlert
      });
    }
    this.setState({
      purchaseDevice,
    });
  };

  // B2C 수강신청 스크롤 시 상단 무이자 혜택 노출 여부(pc) (web only)
  scrollCheckCardInfo = () => {
    const TopBnrPC = document.getElementById('topBnrPC');
    if (!TopBnrPC) return false;
    const scrollY = window.scrollY;
    const TopBnrPCPositionBottom = scrollY + TopBnrPC.getBoundingClientRect().bottom;
    this.setState({
      fixedCardInfo : scrollY > 0 && scrollY >= TopBnrPCPositionBottom,
    });
  };

  // 토큰 리프레시 시도 및 로그인 상태 반환 (web only)
  getLoginStatus = async () => {
    const { data : { result }} = await httpRefreshClient.post('/sign/checkLogin');
    return result;
  };

  // (회원) 현재 수강상태 조회
  getLessonStatus = async () => {
    try {
      const { data: { data }} = await httpDefaultClient.get('/purchase/lessonStatus');
      window.memberInfo = {
        memberSeq: data.memberSeq,
        memberEmail: data.memberEmail,
        memberCellphone: data.memberCellphone
      };

      this.setState({
        lessonStatus: data,
        levelCode: data.levelCode,
        memberSeq: data.memberSeq,
        memberName: data.memberName,
        memberClass: data.memberClass,
        companyCode: data.companyCode || '',
        companyName: data.companyName || '',
        employeeNo: data.employeeNumber,
        memberCellphone: data.memberCellphone,
        productMinute: data.productMinute,
        lessonCount: data.lessonCount,
        studyCount: data.studyCount,
        timezoneCode: data.timezoneCode,
        nationCode: data.nationCode,
        lessonMode: data.lessonMode,
        templateCode: data.templateCode,
        studytemplateCode: data.studytemplateCode,
        lessonTime: data.lessonTime ? data.lessonTime.substr(0, 5) : '',
        priceSeqForRetake: data.priceSeqForRetake,
        juniorFlag: data.juniorFlag,
        hasWaitBill: data.waitingBillFlag,
        hasWaitBillSeq: data.waitingBillpurchaseSeq,
        lastPurchasePayType: data.lastPayType,
        nextPurchase: data.nextPurchaseFlag,
        b2b2cproductmanageFlag: data.b2b2cproductmanageFlag,
      });

      if (data.currentScheduleStatusCode === 'INCLASS' || data.currentScheduleStatusCode === 'HOLDING') {
        const gapEnd = moment(data.endlessonDate, 'YYYY-MM-DD').diff((moment(this.state.todayDate, 'YYYY-MM-DD')), 'days');
        const deadline = moment(data.endlessonDate).add(1, 'days').format('YYYY-MM-DD HH:mm:ss');
        this.getTotalLessonCouponCount();
        this.setState({
          inClassFlag: true,
          inClassProductCode: data.productCode,
          inClassProductName: data.productName,
          beforeSevenDays: gapEnd < 7,
          deadline,
        });
      } else if (data.memberClass === 'B2B' && data.currentScheduleStatusCode === 'WAITING' && data.purchasestatusCode === 'COMPLETE') {
        this.setState({
          inClassFlag: true,
          inClassProductCode: data.productCode,
        });
      } else {
        this.setState({
          inClassFlag: false,
        });
      }

      if (data.memberClass === 'B2B' || data.b2b2cproductmanageFlag) {
        if (data.memberClass === 'B2B') {
          await this.getB2BInfo();
        } else {
          this.setState({
            'companyCode': data.companyCode,
            'companyName': data.companyName,
            'periodFlag': true,
            'closeFlag': false,
            'purchaseagreeCode': 'NONE',
            'languageTypeList': [
            ],
            'strategyList': [],
            'waitingFlag': false,
            'completeFlag': false
          });
        }
        this.getPurchaseBannerList(); //수강신청 배너
        await this.getProductPriceList(this.state.inClassFlag); // 상품가격 조회

        if (data.b2b2cproductmanageFlag) {
          this.selectFilterValue('languageType', 0, 1, 'en');
        }
      }

      if (data.memberClass !=='B2B') {
        if (this.props.match.params.page==='personal') { // B2C 개인결제 페이지 접속
          await this.getPersonalOrder();
          if (this.state.personalOrder) { // 데이터 존재할 경우 결제하기 이동
            this.processStep2(this.state.personalOrderInfo.priceSeq, 'personal');
          } else { // 데이터 없을 경우 신청화면 이동
            this.props.history.replace('/Order');
          }
        } else if (!this.props.match.params.page) { // 수강신청 메인 페이지 접속
          await this.getPersonalOrderForPopup();
          if (this.state.productDesc) { // 개인결제 존재할 경우 팝업 실행
            this.openPopup('personalPopup');
          }
        }
      }
    } catch {
      console.error('error******');
    }
  };

  // 페이지 처리
  processPage = () => {
    const page = this.props.match.params.page || 'p1';
    const sub = this.props.match.params.sub;
    const error = this.props.match.params.error;

    if (page === 'p1') {
      // 재수강 유도 팝업
      if (!this.state.personalOrder && this.state.priceSeqForRetake && this.state.inClassProductCode && !this.state.productDesc) {
        this.openPopup('before14');
      }
    } else if (page === 'p2') {
      if (error) {
        this.processStep2(this.props.match.params.sub, 'error');
        this.setState({
          prevPage: 'OrderResult',
        });
      } else if (!this.state.personalOrder) {
        if (sub) {
          this.processStep2(this.props.match.params.sub, 'payCancel');
        } else {
          const priceSeq = localStorage.getItem('priceSeq');
          const type = localStorage.getItem('tempHistory') ? 'temp' : 'payCancel'; // temp: 로그인 후 처리를 위해 임시 저장, payCancel: 결제취소 팝업
          if (type === 'temp' && this.state.memberClass === 'B2B') { // 비로그인 상태로 상품 선택 후 로그인 했는데 B2B인 경우 alert 띄우기
            this.openPopup('tempHistoryB2B');
          } else {
            this.processStep2(priceSeq, type);
          }
          localStorage.removeItem('tempHistory');
        }
      }
    }
  };

  // sub:: 수업쿠폰 개수 가져오기 (현재 수강상태 조회 후 INCLASS/HOLDING 일 때)
  getTotalLessonCouponCount = () => {
    httpDefaultClient.get('/myInfo/lessonCoupon/have')
      .then(response => {
        const json = response.data;
        if (json) {
          let totalCouponCount = 0;
          json.map((v) => {
            totalCouponCount += v.couponCount;
          });
          this.setState({
            totalCouponCount,
          });
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  // (B2C/B2B2C) 팝업 실행을 위한 개인결제 조회 (web only)
  getPersonalOrderForPopup = async () => {
    await httpDefaultClient.get('/purchase/guide')
      .then( (response) => {
        const data = response.data;
        if (data) {
          const {
            priceSeq,
            productCode,
            paymentAmount,
            productDesc,
          } = response.data;
          if (priceSeq && productCode) {
            this.setState({
              personalOrderInfo: {
                paymentAmount,
              },
              productDesc: productDesc
            });
          }
        }
      })
      .catch((error) => {
        console.error('error******: ', error);
      });
  };

  // (B2C/B2B2C) 개인결제 조회
  getPersonalOrder = async () => {
    await httpDefaultClient.get('/purchase/guide')
      .then( (response) => {
        const data = response.data;
        if (data) {
          const {
            guideSeq,
            priceSeq,
            productCode,
            productName,
            price,
            mileageAmount,
            couponAmount,
            paymentAmount,
            membercouponSeq,
            discountType,
            couponDiscount,
            couponName,
            couponNumber,
            pricechangeFlag,
            applyFlag,
            guideProduct,
          } = response.data;
          if (priceSeq && productCode) {
            localStorage.setItem('productCode', guideProduct.productCode);
            localStorage.setItem('priceSeq', guideProduct.priceSeq);
            this.setState({
              personalOrder: true,
              selectProduct: guideProduct,
              selectProductBillFlag: guideProduct.billFlag,
              selectProductCode: guideProduct.productCode,
              personalOrderInfo: {
                guideSeq,
                priceSeq,
                productCode,
                productName,
                price,
                mileageAmount,
                couponAmount,
                paymentAmount,
                membercouponSeq,
                discountType,
                couponDiscount,
                couponName,
                couponNumber,
                pricechangeFlag,
                applyFlag,
              }
            });
          }
        }
      })
      .catch((error) => {
        console.error('error******: ', error);
      });
  };

  // (B2B) B2B 수강신청을 위한 정보 조회
  getB2BInfo = async () => {
    await httpDefaultClient.get('/company/infoForB2B')
      .then( (response) => {
        const {
          companyCode,
          companyName,
          termSeq,
          termStartdate,
          termEnddate,
          personalPayment,
          guideSeq,
          priceSeq,
          price,
          paymentAmount,
          periodFlag,
          closeFlag,
          waitingFlag,
          termlimitCount,
          completeFlag,
          purchaseagreeCode,
          purchaseagreeContent,
          purchaseagreeTitle,
          languageTypeList,
          strategyList,
          address,
          addressDetail,
          guideProduct
        } = response.data;
        this.setState({
          purchaseagreeCode,
          purchaseagreeContent,
          purchaseagreeTitle,
          isB2BOrderPeriod : periodFlag,
          orderFull: waitingFlag ? false : closeFlag,
          totalorderFull: closeFlag,
          waitingFlag,
          completeFlag,
          termlimitCount,
          languageTypeList,
          strategyList,
          address,
          addressDetail,
          companyPurchaseInfo : {
            companyCode,
            companyName,
            termSeq,
            termStartdate,
            termEnddate,
            personalPayment
          },
        });

        if (completeFlag) this.openPopup('unablePurchaseB2B');

        if (localStorage.getItem('purchaseAddress')) {
          this.setState({
            bookAddress: localStorage.getItem('purchaseAddress'),
            bookAddressDetail: localStorage.getItem('purchaseAddressDetail'),
          });
        } else {
          this.setState({
            bookAddress: address,
            bookAddressDetail: addressDetail
          });
        }

        if ((closeFlag && !waitingFlag) // 마감이면서 대기 아닌경우
            || !periodFlag // 수강신청 기간 아닌 경우
            || this.props.match.params.sub === 'full') // 주문 결과 마감일경우
        {
          this.props.history.replace('/Order/p4');
        }

        if (periodFlag && !closeFlag && !completeFlag && guideSeq && guideProduct) { // 개인결제로 바로 이동 (priceSeq)
          localStorage.setItem('productCode', guideProduct.productCode);
          localStorage.setItem('priceSeq', guideProduct.priceSeq);
          this.setState({
            personalOrder: true,
            selectProduct: guideProduct,
            selectProductBillFlag: guideProduct.billFlag,
            selectProductCode: guideProduct.productCode,
            personalOrderInfo: {
              guideSeq,
              priceSeq,
              price,
              mileageAmount:0,
              couponAmount:0,
              paymentAmount,
              membercouponSeq:null,
              pricechangeFlag:false
            }
          });
          if (purchaseagreeCode === 'NONE') { // 동의서없음
            this.processStep2(guideProduct.priceSeq, 'personal', true);
          } else {
            this.setState({
              purchaseAgreePage: true,
            });
            window.scrollTo(0,0);
            this.props.history.replace('/Order/p5');
          }
        }
      })
      .catch((error) => {
        console.error('error******: ', error);
      });
  };

  // 수강신청 배너 조회
  getPurchaseBannerList = () => {
    httpDefaultClient.get('/web/purchaseBanner')
      .then(response => {
        const json = response.data;
        if (!json) {
          return false;
        }
        if (json) {
          this.setState({
            purchaseBanner: json
          });
        }
      })
      .catch((error) => console.error('/seb/purchaseBanner error: ', error));
  };

  // 수강혜택 배너 클릭
  onClickPurchaseBannerClick = (content) => {
    this.setState({
      purchasePopup: true,
      purchasePopupContent: content,
    });
  };

  // state false 세팅
  setStateFalseValue = (s) => {
    this.setState({
      [s] : false,
    });
  };

  // state true 세팅
  setStateTrueValue = (s) => {
    this.setState({
      [s] : true,
    });
  };

  // state value toggle
  toggleStateValue = (s) => {
    this.setState({
      [s] : !this.state[s],
    });
  };

  // 상품 조회
  getProductPriceList = async (inclassFlag) => {
    // 수강권 리스트
    try {
      const response = await httpDefaultClient.get('/product/priceList');
      const json = response.data;
      if (!json.data) {
        return false;
      }
      json.data.forEach(v => {
        v.lessonMode = v.productMode;
        if (v.studyCount && v.studyCount>0) v.lessonCount=v.studyCount;
      });

      const hialice = ['hialice', '하이앨리스', 'ai', '하이', '앨리스'];
      const isAliceOrder = json.data.length === 1 && json.data[0].productType === 'C' && hialice.some(keyword => json.data[0].productName.toLowerCase().includes(keyword));

      let productList = json.data.reverse();
      this.productListSort(productList);
      let b=[];
      for (let m = 0; m < productList.length; m++){
        b.push(false);
      }
      let list = [];
      let inClassNotSoldOut;
      if (inclassFlag) {
        const a0 = productList.filter(ctg => ctg.productCode === this.state.inClassProductCode);
        if (a0[0]) {
          inClassNotSoldOut = !a0[0].soldoutFlag;    // 인클래스이지만 수강 중인 상품이 없을 수도 있음
        }
        a0.forEach((c, i, arr) => {
          let b0 = [];
          const b1 = {inClassProductFlag : true};
          b0 = Object.assign(c, b1);
          a0.concat(b0);
        });

        this.setState({
          isValidInClassProductCode: productList.filter(ctg => ctg.productCode === this.state.inClassProductCode ||
                ctg.anotherProductPrice?.productCode === this.state.inClassProductCode).length > 0
        });
      }
      list = productList;

      const copyArray = [...list];
      let bestPrd = list.filter(f => f.recommendSort > 0);
      this.recommendProdSort(bestPrd);

      if (json.result){
        const contentOnly = productList.filter(v => v.productType!=='C').length === 0;
        this.setState({
          item:copyArray,
          viewProduct: list,
          bestProduct: bestPrd,
          selectBestProductList: b,
          selectProductList: b,
          initSelectProductList: b,
          inClassNotSoldOut,
          contentOnly,
          isAliceOrder,
        });
        if (!this.state.personalOrder) { // 개인결제 아닐때만 첫번째 상품 선택
          this.setState({
            selectProduct: list[0],
          });
        }
      }

      if (this.state.memberClass === 'B2B') {
        if (this.state.languageTypeList[0].detailCode==='etc') {
          await this.getCurrentCourse();
          this.selectStrategyTab();
        } else {
          this.selectFilterValue('languageType', 0, this.state.languageTypeList.length, this.state.languageTypeList[0].detailCode);
        }
      }
      // 전략어 탭 선택
      if (this.state.memberClass==='B2B' && this.state.lessonStatus.languageType && !this.state.personalOrder) {
        if (this.state.strategyList && this.state.strategyList.length>0) {
          const strategyLanguage = this.state.strategyList.find(v => v.languageType===this.state.lessonStatus.languageType);
          if (strategyLanguage) {
            await this.getCurrentCourse();
            this.selectStrategyTab();
          }
        }
      }
      setTimeout(() => {
        this.setState({
          pageComplete : true,
        }, () => {
          if (this.ww === 'mo') {
            const topBnr = document.getElementById('TopBannerLMS');
            if (!topBnr) return false;
          }
        });
      }, 500);
    } catch (error) {
      console.error('error: ', error);
    }
  };

  // sub:: 상품 정렬 (상품조회, 학습 목표에 따른 추천 수강권 불러오기)
  productListSort = (arr) => {
    arr.sort((a, b) => {if (a.templateCodeSort < b.templateCodeSort){ return -1;}});         // 템플릿코드 정렬순 (B2B 전용)
    arr.sort((a, b) => {if (a.billFlag > b.billFlag){ return -1;}});                         // 정기 상품 정렬 우선
    arr.sort((a, b) => {if (a.productWeek > b.productWeek){ return -1;}});                   // 수강 기간
    arr.sort((a, b) => {if (a.timezoneCode < b.timezoneCode){ return -1;}});                 // 존
    arr.sort((a, b) => {if (a.productMinute > b.productMinute){ return -1;}});               // 20분 > 10분
    arr.sort((a, b) => {if (a.studyCount ? a.studyCount : a.lessonCount > b.studyCount ? b.studyCount : b.lessonCount){ return -1;}});   // 5회 > 3회 > 2회
    arr.sort((a, b) => {if (a.nationCode < b.nationCode){ return -1;}});                     // 일반 > 북미
    arr.sort((a, b) => {if (a.nationCodeSort < b.nationCodeSort){ return -1;}});             // 제2외국어
    arr.sort((a, b) => {if ( (a.lessonMode === 'AUDIO' ? 1 : a.lessonMode === 'VIDEO' ? 2 : a.productType === 'C' ? 3 : 4)
        < (b.lessonMode === 'AUDIO' ? 1 : b.lessonMode === 'VIDEO' ? 2 : b.productType === 'C' ? 3 : 4)){ return -1;}});// 전화 > 화상 > 콘텐츠
  };

  // sub:: 추천 상품 정렬 (상품조회)
  recommendProdSort = (arr) => {
    arr.sort((a, b) => {
      return a.recommendSort < b.recommendSort ? -1 : a.recommendSort > b.recommendSort ? 1 : 0;
    });
  };

  // 필터 추출
  extractFilterList = (parentFilterList, selectedValue, parentProductList, filterKey, extractKey, stateName, commonCode, codeKey, sortCondition) => {
    if (!parentProductList) return null;

    const selectedFilter = filterKey !== 'languageType'
      ? parentFilterList.filter(item => item.detailCode === selectedValue || item.upperdetailCode === selectedValue || item.languageType == selectedValue)
      : [{'detailCode':selectedValue}];

    const filterList = selectedFilter.flatMap(item => {
      const filteringProduct = parentProductList.filter(product => product[filterKey] == item.detailCode || item.languageType);
      const extractList = [...new Set(filteringProduct?.map(product => product[extractKey]))];
      if (sortCondition) {
        extractList.sort(sortCondition);
      }
      return [extractList, filteringProduct];
    });

    const extractList = filterList[0]; // 하위 필터 리스트
    const filteringProduct = filterList[1]; // 필터 상품 리스트

    const filteredCommonCodeList = extractList.flatMap(item1 => {
      const filtered = this.state[commonCode].filter(item2 => item2[codeKey] == item1);
      return filtered;
    });

    // ASSIGNTEM과 PRODTYPE은 sort 값으로 정렬
    if (commonCode === 'ASSIGNTEM' || commonCode === 'PRODTYPE') {
      filteredCommonCodeList.sort((a, b) => a.sort - b.sort);
    }

    this.setState({
      [stateName] : filteredCommonCodeList,
      [`${stateName}Filtered`] : filteringProduct,
    }, () => {
      switch (extractKey) {
      case 'lessonMode' :
        let defaultLessonModeValue = this.state.lessonModeValue || this.state.lessonModeList[0].detailCode;
        let defaultLessonModeIndex = this.state.lessonModeList?.findIndex(f => f.detailCode === defaultLessonModeValue);
        if (defaultLessonModeIndex === -1) {
          defaultLessonModeValue = this.state.lessonModeList[0].detailCode;
          defaultLessonModeIndex = 0;
        }
        this.selectFilterValue('lessonMode', defaultLessonModeIndex, this.state.lessonModeList.length, defaultLessonModeValue);
        break;
      case 'productMinute' :
        let defaultProductMinuteValue = this.state.productMinuteValue || this.state.productMinuteList[0].detailCode;
        let defaultProductMinuteIndex = this.state.productMinuteList?.findIndex(f => f.detailCode === defaultProductMinuteValue);
        if (defaultProductMinuteIndex === -1) {
          defaultProductMinuteValue = this.state.productMinuteList[0].detailCode;
          defaultProductMinuteIndex = 0;
        }
        this.selectFilterValue('productMinute', defaultProductMinuteIndex, this.state.productMinuteList.length, defaultProductMinuteValue);
        break;
      case 'lessonCount' :
        let defaultProductWeekValue = this.state.productWeekValue || this.state.productWeekList[0].detailCode;
        let defaultProductWeekIndex = this.state.productWeekList?.findIndex(f => f.detailCode === defaultProductWeekValue);
        if (defaultProductWeekIndex === -1) {
          defaultProductWeekValue = this.state.productWeekList[0].detailCode;
          defaultProductWeekIndex = 0;
        }
        this.selectFilterValue('productWeek', defaultProductWeekIndex, this.state.productWeekList.length, defaultProductWeekValue);
        break;
      case 'studyCount' :
        this.selectFilterValue('productWeek', 0, this.state.productWeekList.length, this.state.productWeekList[0]?.detailCode);
        break;
      case 'timezoneCode' :
        let defaultTimezoneCodeValue = this.state.timezoneCodeValue || this.state.timezoneCodeList[0].detailCode;
        let defaultTimezoneCodeIndex = this.state.timezoneCodeList?.findIndex(f => f.detailCode === defaultTimezoneCodeValue);
        if (defaultTimezoneCodeIndex === -1) {
          defaultTimezoneCodeValue = this.state.timezoneCodeList[0].detailCode;
          defaultTimezoneCodeIndex = 0;
        }
        this.selectFilterValue('timezoneCode', defaultTimezoneCodeIndex, this.state.timezoneCodeList.length, defaultTimezoneCodeValue);
        break;
      case 'templateCode' :
        let defaultTemplateCodeValue = this.state.templateCodeValue || this.state.templateCodeList[0].detailCode;
        let defaultTemplateCodeIndex = this.state.templateCodeList?.findIndex(f => f.detailCode === defaultTemplateCodeValue);
        if (defaultTemplateCodeIndex === -1) {
          defaultTemplateCodeValue = this.state.templateCodeList[0].detailCode;
          defaultTemplateCodeIndex = 0;
        }
        this.selectFilterValue('templateCode', defaultTemplateCodeIndex, this.state.templateCodeList.length, defaultTemplateCodeValue);
        break;
      case 'productType' :
        let defaultProductTypeValue = this.state.productTypeValue || this.state.productTypeList[0].detailCode;
        let defaultProductTypeIndex = this.state.productTypeList?.findIndex(f => f.detailCode === defaultProductTypeValue);
        if (defaultProductTypeIndex === -1) {
          defaultProductTypeValue = this.state.productTypeList[0].detailCode;
          defaultProductTypeIndex = 0;
        }
        this.selectFilterValue('productType', defaultProductTypeIndex, this.state.productTypeList.length, defaultProductTypeValue);
        break;
      }
    });
  };

  // 필터 선택
  selectFilterValue = (type, index, arrayLength, selectedValue) => {
    if (!arrayLength) return null;

    if (type === 'languageType') {
      this.setState({
        lessonModeValue: null,
        productMinuteValue: null,
        productWeekValue: null,
        timezoneCodeValue: null,
        templateCodeValue: null,
        productTypeValue: null,
        courseSeqValue: null,
        courseNameValue: null,
        bookApplyFlag: false,
      });
    } else if (type !== 'productType') {
      this.setState({
        templateCodeValue: null,
        productTypeValue: null,
      });
    }

    this.setState(() => {
      const selectedState = new Array(arrayLength);
      selectedState[index] = true;
      return {
        [`${type}Index`]: index,
        [`${type}State`]: selectedState,
        [`${type}Value`]: selectedValue || this.state[`${type}List`][index].detailCode,
      };
    }, () => {
      switch (type) {
      case 'languageType':
        this.setState({showStrategy: false});
        this.extractFilterList(
          this.state.languageTypeList,
          selectedValue,
          this.state.item,
          'languageType',
          'lessonMode',
          'lessonModeList',
          'PRODMODE',
          'detailCode',
          (a, b) => b - a
        );
        break;
      case 'lessonMode':
        this.extractFilterList(
          this.state.lessonModeList,
          selectedValue,
          this.state.lessonModeListFiltered,
          'lessonMode',
          'productMinute',
          'productMinuteList',
          'STUDYMIN',
          'detailCode',
          (a, b) => a - b
        );
        break;
      case 'productMinute':
        this.extractFilterList(
          this.state.productMinuteList,
          selectedValue,
          this.state.productMinuteListFiltered,
          'productMinute',
          'lessonCount',
          'productWeekList',
          'PRODWEEK',
          'detailCode',
          (a, b) => b - a
        );
        break;
      case 'productWeek':
        this.extractFilterList(
          this.state.productWeekList,
          selectedValue,
          this.state.productWeekListFiltered,
          'lessonCount',
          'timezoneCode',
          'timezoneCodeList',
          'TIMEZONEF',
          'detailCode',
          (a, b) => b - a
        );
        break;
      case 'timezoneCode':
        this.extractFilterList(
          this.state.timezoneCodeList,
          selectedValue,
          this.state.timezoneCodeListFiltered,
          'timezoneCode',
          'templateCode',
          'templateCodeList',
          'ASSIGNTEM',
          'detailCode',
          (a, b) => b - a
        );
        break;
      case 'templateCode':
        this.extractFilterList(
          this.state.templateCodeList,
          selectedValue,
          this.state.templateCodeListFiltered,
          'templateCode',
          'productType',
          'productTypeList',
          'PRODTYPE',
          'detailCode',
          (a, b) => b - a
        );
        break;
      case 'productType':
        this.setState({
          viewProduct: this.findClassTicket(),
        });
        break;
      }
    });
  };

  // 전략어 탭 선택
  selectStrategyTab = () => {
    this.setState({
      bookApplyFlag: true,
      lessonModeValue: null,
      productMinuteValue: null,
      productWeekValue: null,
      timezoneCodeValue: null,
      templateCodeValue: null,
      productTypeValue: null,
      showStrategy: true,
    });
    const arrayLength = this.state.languageTypeList.length;
    const index = arrayLength - 1;
    const selectedState = new Array(arrayLength);
    selectedState[index] = true;

    let strategyLanguage = this.state.strategyList[0];
    if (this.state.lessonStatus.languageType) {
      let lastStrategyLanguage = this.state.strategyList.find(v => v.languageType===this.state.lessonStatus.languageType);
      if (lastStrategyLanguage) {
        strategyLanguage = lastStrategyLanguage;
      }
    }
    let languageTypeValue = strategyLanguage.languageType;
    let languageNameValue = strategyLanguage.languageName;

    let courses = strategyLanguage.courses;
    let courseSeqValue = courses[0].courseSeq;
    let courseNameValue = courses[0].courseName;
    if (this.state.currentCourseSeq) {
      const course = strategyLanguage.courses.find(v => v.courseSeq===this.state.currentCourseSeq);
      if (course) {
        courseSeqValue = course.courseSeq;
        courseNameValue = course.courseName;
      }
    }

    this.setState({
      languageTypeIndex: index,
      languageTypeState: selectedState,
      languageTypeValue,
      languageNameValue,
      courseSeqValue,
      courseNameValue
    }, () => {
      this.extractFilterList(this.state.strategyList, this.state.languageTypeValue, this.state.item, 'languageType', 'lessonMode', 'lessonModeList', 'PRODMODE', 'detailCode', (a, b) => b - a);
    });
  };

  // 전략어 언어/과정 변경
  changeStrategyLanguage = (languageType, languageName, courseSeq, courseName) => {
    this.setState({
      lessonModeValue: null,
      productMinuteValue: null,
      productWeekValue: null,
      timezoneCodeValue: null,
      templateCodeValue: null,
      productTypeValue: null,
      showStrategy: true,
      languageTypeValue: languageType,
      languageNameValue: languageName,
      courseSeqValue: courseSeq,
      courseNameValue: courseName,
      bookApplyFlag: true,
    }, () => {
      this.extractFilterList(this.state.strategyList, this.state.languageTypeValue, this.state.item, 'languageType', 'lessonMode', 'lessonModeList', 'PRODMODE', 'detailCode', (a, b) => b - a);
    });
  };

  // 상품 선택
  productClick = (productCode, index, direct) => {
    if (this.ww === 'mo') {
      ChannelService.hideBtn();
    }
    localStorage.setItem('productCode', productCode);
    let pd = this.state.item.filter(p => p.productCode === productCode);
    if (pd.length === 0) return false;
    pd = pd[0];

    let s=[], a = this.state.selectProductList.length;
    for (let b = 0; b < a; b++){
      (b === index) ? s.push('on') : s.push(false);
    }

    localStorage.setItem('priceSeq', pd.priceSeq);

    this.setState({
      selectProduct: pd,
      selectProductBillFlag: pd.billFlag,
      selectProductCode: productCode,
      selectProductIdx: index,
      productInfo: 'on',
      selectProductList: s,
      selectBestProductList: this.state.initSelectProductList,
    });

    if (direct) {
      this.processStep2(pd.priceSeq);
      this.closePopup('before14');
      this.setState({
        directStep2: true,
      });
    }
  };

  // 재수강 팝업 확인 클릭
  onClickReOrderMyProduct = ({ priceSeq, productCode }) => {
    if (!priceSeq) { return; }
    if (!productCode) { return; }

    localStorage.setItem('priceSeq', priceSeq);
    localStorage.setItem('productCode', productCode);

    this.processStep2(priceSeq);
    this.closePopup('before14');
    this.setState({ directStep2: true });
  };

  // BEST 상품 클릭
  bestProductClick = (i, n, direct) => {
    if (this.ww === 'mo') {
      ChannelService.hideBtn();
    }

    localStorage.setItem('productCode', i);
    let pd = this.state.item.filter(p => p.productCode === i);
    pd = pd[0];

    let s=[], a = this.state.selectBestProductList.length;
    for (let b = 0; b < a; b++){
      (b === n) ? s.push('on') : s.push(false);
    }

    localStorage.setItem('priceSeq', pd.priceSeq);

    this.setState({
      selectProduct: pd,
      selectProductBillFlag: pd.billFlag,
      selectBestProductCode: i,
      selectBestProductIdx: n,
      productInfo: 'on',
      selectBestProductList: s,
      selectProductList: this.state.initSelectProductList,
    });

    if (direct) {
      this.processStep2(pd.priceSeq);
      this.closePopup('before14');
      this.setState({
        directStep2: true,
      });
    }
  };

  // 수강권 찾기(필터링 후 수강권 출력)
  findClassTicket = () => {

    this.unselectProduct();

    const {
      item,
      languageTypeValue, // only b2b
      lessonModeValue,
      productMinuteValue,
      productWeekValue,
      timezoneCodeValue,
      templateCodeValue, // only b2b
      productTypeValue, // only b2b
    } = this.state;

    let viewProduct = item;

    if (languageTypeValue) viewProduct = viewProduct.filter(product => product.languageType === languageTypeValue);

    if (lessonModeValue !== 'C') viewProduct = viewProduct.filter(product => product.lessonMode === lessonModeValue);

    viewProduct = viewProduct.filter(product => (product.productMinute === Number(productMinuteValue)
			&& (product.lessonCount === Number(productWeekValue) || product.studyCount === Number(productWeekValue))
			&& product.timezoneCode === timezoneCodeValue
    ));

    if (templateCodeValue) viewProduct = viewProduct.filter(product => product.templateCode === templateCodeValue);

    if (productTypeValue) viewProduct = viewProduct.filter(product => product.productType === productTypeValue);

    return viewProduct;
  };

  // 교재 배송 선택
  changeBookApplyFlag = (value) => {
    this.setState({
      bookApplyFlag: value
    });
  };

  // 교재 주소 입력
  onChangeAddress = (e) => {
    this.setState({
      bookAddress: e,
      bookAddressDetail: ''
    });
  };

  // 교재 상세 주소 입력
  onChangeAddressDetail = (e) => {
    this.setState({
      bookAddressDetail: e.target.value
    });
  };

  // 적립금 ref
  updateMileageRef = (input) => {
    this.mileageRef = input;
  };

  // 할인쿠폰 ref
  updateCouponSelect = (ref) => {
    this.couponSelect = ref;
  };

  // 할인쿠폰입력 ref
  updateCouponNum = (ref) => {
    this.couponNum = ref;
  };

  // 결제수단 ref
  updatePayPanel = (ref) => {
    this.payPanel = ref;
  };

  // 결제하기 패널 토글
  panelState = (n) => {
    const t = this.state.panelStats;
    t[n] = !t[n];
    this.setState({panelStats: t});
  };

  // 결제 수단
  payState = (v) => {
    if (!v && !this.state.panelStats[3]) this.panelState(3);
    this.setState({
      payStats: v,
    });
  };

  // 결제하기에 필요한 정보 조회: 할인쿠폰, 적립금, 네이버페이모드, 수강일, 재수강코드, 가격정보
  // 1) 결제하기 페이지 이동 시 호출
  // 2) 쿠폰 직접 등록 시 호출
  getPaymentInfo = async (priceSeq, alertFlag=true) => {
    const registeredCoupon = this.state.coupon;
    const { data } = await httpDefaultClient.get('/purchase/paymentInfo?priceSeq='+priceSeq);
    const { npayMode, couponList, purchaseInfo, mileage } = data;

    this.setState({
      npayMode,
      purchaseInfo,
      acceptUpselling: false,
    });

    if (this.state.memberClass !== 'B2B') {
      const purchaseusableCoupon = couponList.filter(f => f.purchaseusableFlag);
      const directRegistration = [{couponNumber : 'userInput', purchaseusableFlag : true, couponName : '할인쿠폰을 직접 등록해주세요.', discountType:''}];
      const purchaseUnusableCoupon = couponList.filter(f => !f.purchaseusableFlag);
      if (purchaseusableCoupon.length > 0 && this.state.panelStats[1]) this.panelState(1); // 할인쿠폰 존재할 때 열려있으면 닫기
      const couponOptionList = [...purchaseusableCoupon, ...directRegistration, ...purchaseUnusableCoupon];

      this.setState({
        couponList: couponOptionList,
        coupon: '',
        mMileage: mileage
      });

      // 개인결제 쿠폰, 적립금 적용
      if (this.state.personalOrder) {
        this.changeMileage(this.state.personalOrderInfo.mileageAmount);
        this.setState({
          couponCode: this.state.personalOrderInfo.couponNumber,
          couponDiscount: this.state.personalOrderInfo.couponDiscount,
          couponValue: this.state.personalOrderInfo.couponAmount,
          membercouponSeq: this.state.personalOrderInfo.membercouponSeq,
        });
        this.setState({
          panelStats: [false, false, false, true, true],
        });
      } else { // 개인결제 아닌 경우 쿠폰,적립금 적용
        if (registeredCoupon) {
          const coupon = couponOptionList.find(f => f.couponNumber === registeredCoupon);
          if (coupon) this.changeCoupon(coupon.couponNumber);
          else this.changeCoupon(couponOptionList[0].couponNumber);
        } else {
          this.changeCoupon(couponOptionList[0].couponNumber, alertFlag);
        }
      }
    }
  };

  // 적립금 최대사용 가능 금액 계산
  getMaxApplicableMileage = (e) => {
    let applyMileage=0;
    const discountType = this.state.discountType;
    const couponDiscount = this.state.couponDiscount;
    const availMaxMileage = this.state.mMileage > this.state.purchaseInfo.productPrice.maxMileage ? this.state.purchaseInfo.productPrice.maxMileage : this.state.mMileage; // 적용 가능한 최대 마일리지
    if (discountType==='PRICE') {
      if (couponDiscount > this.state.purchaseInfo.productPrice.realPrice) { // 금액 쿠폰이 상품가격보다 큰 경우
        applyMileage = 0;
      } else if (availMaxMileage > this.state.purchaseInfo.productPrice.realPrice - couponDiscount ) { // 적용가능한 적립금이 (가격-쿠폰할인가)보다 많은 경우
        applyMileage = this.state.purchaseInfo.productPrice.realPrice - couponDiscount;
      } else {
        applyMileage = availMaxMileage;
      }
    } else if (discountType==='RATE' && couponDiscount === 100) {
      applyMileage = 0;
    } else {
      applyMileage = availMaxMileage;
    }
    return applyMileage;
  };

  // 적립금 수기변경
  changeMileage = (val) => {
    let mileageInput = parseInt(val);
    const maxApplicableMileage = this.getMaxApplicableMileage();
    const mMileage = this.state.mMileage; // 보유 마일리지
    if (!mileageInput || mileageInput < 0) { // 자연수 아님
      mileageInput = 0;
    }
    else if (mMileage < mileageInput) { // 보유 마일리지가 입력한 값보다 작을때
      this.mileageRef.blur();
      this.alertMessage('적립금은 보유한 적립금 내에서 사용 가능합니다.');
      mileageInput = maxApplicableMileage;
    } else if (this.state.purchaseInfo.productPrice.maxMileage < mileageInput) { // 최대 적용 가능 초과 시
      this.mileageRef.blur();
      this.alertMessage('적립금은 실 결제금액의 40%까지 사용 가능합니다.');
      mileageInput = maxApplicableMileage;
    } else if (mileageInput>maxApplicableMileage) { // 최대 적용 가능 초과 시
      this.mileageRef.blur();
      this.alertMessage('결제금액 0원으로 최소 적립금을 적용합니다.');
      mileageInput = maxApplicableMileage;
    }

    let couponValue = 0;
    const selectedCoupon = this.state.selectedCoupon;
    if (selectedCoupon) {
      if (selectedCoupon.discountType==='PRICE') {
        couponValue = selectedCoupon.couponDiscount>this.state.purchaseInfo.productPrice.realPrice ? this.state.purchaseInfo.productPrice.realPrice : selectedCoupon.couponDiscount;
      } else {
        couponValue = Math.floor((this.state.purchaseInfo.productPrice.realPrice-mileageInput) * selectedCoupon.couponDiscount / 100);
      }
    }

    this.setState({
      mileageInput: mileageInput,
      couponValue
    }, () => {
      this.changePayState();
    });
  };

  // 적립금 최대사용
  applyMaxMileage = (e) => {
    const mileageInput = this.getMaxApplicableMileage();
    let couponValue = 0;
    const selectedCoupon = this.state.selectedCoupon;
    if (selectedCoupon) {
      if (selectedCoupon.discountType==='PRICE') {
        couponValue = selectedCoupon.couponDiscount>this.state.purchaseInfo.productPrice.realPrice ? this.state.purchaseInfo.productPrice.realPrice : selectedCoupon.couponDiscount;
      } else {
        couponValue = Math.floor((this.state.purchaseInfo.productPrice.realPrice-mileageInput) * selectedCoupon.couponDiscount / 100);
      }
    }

    this.setState({
      mileageInput,
      couponValue,
    }, () => {
      this.changePayState();
    });
  };

  // 할인 쿠폰 선택 변경
  changeCoupon = (couponNumber, alertFlag=true) => {
    const availMaxMileage = this.state.mMileage > this.state.purchaseInfo.productPrice.maxMileage ? this.state.purchaseInfo.productPrice.maxMileage : this.state.mMileage; // 적용 가능한 최대 마일리지

    if (couponNumber !== 'userInput') {
      const coupon = this.state.couponList.find(p => p.couponNumber === couponNumber);
      let applyMileage = 0;

      if (coupon) {
        if (coupon.discountType === 'RATE' && coupon.couponDiscount === 100) { // 100% 쿠폰일 시 마일리지 사용 안함
          this.setState({
            selectedCoupon: coupon,
            couponCode: coupon.couponNumber,
            discountType: coupon.discountType,
            couponDiscount: coupon.couponDiscount,
            couponValue: this.state.purchaseInfo.productPrice.realPrice,
            membercouponSeq: coupon.membercouponSeq,
            mileageInput: 0,
          }, () => {
            this.changePayState();
          });
        }
        else { // 100% 쿠폰 아님
          if (coupon.discountType === 'PRICE') {
            if (coupon.couponDiscount > this.state.purchaseInfo.productPrice.realPrice) { // 금액 쿠폰이 상품가격보다 큰 경우
              applyMileage = 0;
              if (alertFlag) {
                setTimeout(() => this.alertMessage('할인액이 결제금액보다 큰 경우 쿠폰 차액은 반환 및 환불 처리되지 않습니다.'), 500);
              }
            } else if (availMaxMileage > this.state.purchaseInfo.productPrice.realPrice - coupon.couponDiscount ) { // 적용가능한 적립금이 (가격-쿠폰할인가)보다 많은 경우
              applyMileage = this.state.purchaseInfo.productPrice.realPrice - coupon.couponDiscount;
            } else {
              applyMileage = availMaxMileage;
            }
          } else {
            applyMileage = availMaxMileage;
          }

          const priceAfterMileage = this.state.purchaseInfo.productPrice.realPrice-applyMileage;
          const discountPrice = coupon.discountType === 'RATE' ? priceAfterMileage * coupon.couponDiscount / 100 : coupon.couponDiscount;

          this.setState({
            selectedCoupon: coupon,
            couponCode: coupon.couponNumber,
            discountType: coupon.discountType,
            couponDiscount: coupon.couponDiscount,
            couponValue: priceAfterMileage - discountPrice < 0 ? Math.floor(priceAfterMileage): Math.floor(discountPrice),
            membercouponSeq: coupon.membercouponSeq,
            mileageInput: applyMileage,
          }, () => {
            this.changePayState();
          });
        }
      }
    } else {
      this.setState({
        selectedCoupon: null,
        couponCode: couponNumber,
        discountType: '',
        couponDiscount: 0,
        couponValue: 0,
        membercouponSeq: null,
        mileageInput: availMaxMileage,
      }, () => {
        this.changePayState();
      });
    }
  };
  
  // 할인 쿠폰 직접 입력 변경
  onChangeCouponInput = (e) => {
    this.setState({coupon: e.target.value.toUpperCase()});
  };

  // 할인 쿠폰 등록
  registerCoupon = (e) => {
    if (this.state.coupon === ''){
      this.alertMessage('할인 코드를 입력해주세요.');
      this.setState({
        alertType: 'couponError'
      });
      return null;
    }

    httpDefaultClient.post('/purchase/memberCoupon/save', {}, {params: {
      'priceSeq': this.state.purchaseInfo.productPrice.priceSeq,
      'couponNumber': this.state.coupon,
    }})
      .then( (response) => {
        if (response.status===200){
          const msg = response.data.messageCode;
          if (msg === 'COUPON0005') { // 등록 완료
            this.getPaymentInfo(this.state.purchaseInfo.productPrice.priceSeq);
          } else if (response.data.result) { // 등록은 되었지만 사용할 수 없음
            const data = response.data.data.coupon;
            let temp = [{couponNumber : data.couponNumber, purchaseusableFlag : false, couponName : data.couponName, couponDiscount : data.couponDiscount, discountType : data.discountType}];
            this.alertMessage(msg, true, 'couponError',);
            this.setState({
              memberCouponReturn : msg,
              couponList : [...this.state.couponList, ...temp],
            });
          } else { // 등록 불가
            this.alertMessage(msg, true, 'couponError');
            this.setState({
              memberCouponReturn : msg,
            });
          }
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  // 결제수단 변경
  changePayState = () => {
    if (this.state.purchaseInfo.productPrice.realPrice === 0) {
      this.payState('FREE');
    } else if (this.state.purchaseInfo.productPrice.realPrice - this.state.mileageInput - this.state.couponValue === 0 && !this.state.purchaseInfo.productPrice.billFlag) {
      this.payState('FREE');
    } else if (this.state.inClassFlag && this.state.lastPurchasePayType) {
      const type = this.state.lastPurchasePayType;
      let payStats;
      if (type && type !== 'FREE' && !(this.state.purchaseInfo.productPrice.billFlag && type === 'ACCOUNT') && !(this.state.memberClass === 'B2B' && type.indexOf('N_') !== -1)) {
        if (this.state.panelStats[3]) {
          this.panelState(3);
        }
      }
      if (type.indexOf('N_') !== -1) {
        if (this.state.memberClass === 'B2B') {
          payStats = null;
        } else if (type.indexOf('BANK') !== -1) {
          payStats = (this.state.purchaseInfo.productPrice.billFlag ? 'N_B_BANK' : 'N_BANK');
        } else {
          payStats = (this.state.purchaseInfo.productPrice.billFlag ? 'N_B_CARD' : 'N_CARD');
        }
      } else if (type.indexOf('T_') !== -1) {
        payStats = (this.state.purchaseInfo.productPrice.billFlag ? null : 'T_PAY');
      } else if (type.indexOf('CARD') !== -1) {
        payStats = (this.state.purchaseInfo.productPrice.billFlag ? 'B_CARD' : 'CARD');
      } else if (type.indexOf('ACCOUNT') !== -1) {
        payStats = (this.state.purchaseInfo.productPrice.billFlag ? null : 'ACCOUNT');
      } else if (type.indexOf('FREE') !== -1) {
        if (this.state.purchaseInfo.productPrice.realPrice > 0) {
          payStats = null;
        } else {
          payStats = 'FREE';
        }
      }

      // 0원 정기일 경우, 0원 결제 가능한 B_CARD가 아닐 경우에는 수단 초기화
      if (this.state.purchaseInfo.productPrice.realPrice - this.state.mileageInput - this.state.couponValue === 0
        && this.state.purchaseInfo.productPrice.billFlag
        && payStats!=='B_CARD'
      ) {
        payStats = null;
      }

      this.payState(payStats);
    } else {
      this.payState(null);
    }
  };

  /**
   * 결제하기(p2)
   * @param priceSeq 상품가격일련번호
   * @param type (personal:개인결제, error:결제완료 후 에러, payCancel:결제취소, temp:비회원상태에서 임시 저장후 재호출, pass:해피아워 팝업에서 선택한 경우(업셀링 없이 통과)
   * @param pledge (true: B2B동의서 통해서 호출, false: default)
   */
  processStep2 = async (priceSeq, type, pledge = false) => {
    localStorage.setItem('priceSeq', priceSeq);
    this.closePopup('purchaseAgreePage');

    if (type === 'personal') {
      if (this.state.personalOrder) localStorage.setItem('personal', true); // 개인결제중 스토리지 세팅(취소 시 이용)
    }

    // 개인결제 취소로 들어온 경우 개인결제 다시 세팅
    if (type === 'payCancel'||type === 'error') {
      if (localStorage.getItem('personal')) {
        await this.getPersonalOrder();
        if (this.state.personalOrder) {
          type = 'personal';
          this.alertMessage('PUR000017', true);
        }
      }
      const languageTypeValue = localStorage.getItem('languageTypeValue');
      const languageNameValue = localStorage.getItem('languageNameValue');
      const courseSeqValue = localStorage.getItem('courseSeqValue');
      const courseNameValue = localStorage.getItem('courseNameValue');
      if (courseSeqValue) {
        this.setState({
          languageTypeValue,
          languageNameValue,
          courseSeqValue,
          courseNameValue,
          bookApplyFlag: true,
        });
      }
    } else { // 정상 진입
      if (this.state.courseSeqValue) {
        localStorage.setItem('languageTypeValue', this.state.languageTypeValue);
        localStorage.setItem('languageNameValue', this.state.languageNameValue);
        localStorage.setItem('courseSeqValue', this.state.courseSeqValue);
        localStorage.setItem('courseNameValue', this.state.courseNameValue);
      } else {
        localStorage.removeItem('languageTypeValue');
        localStorage.removeItem('languageTypeValue');
        localStorage.removeItem('courseSeqValue');
        localStorage.removeItem('courseNameValue');
      }
    }
    if (type !== 'personal') {
      localStorage.removeItem('personal');
    }

    if (this.state.browserAlert) {
      alert('수강신청은 Chrome, Microsoft Edge 브라우저에서 진행해주세요.');
      return false;
    }

    if (this.state.notLogin) { // 로그인하지 않고 결제 진행
      localStorage.setItem('tempHistory', 'PUR000026');		// 로그인하지 않고 결제 진행
      const updateReferrerData = referrerStore.getState().updateData;
      updateReferrerData({
        entryType: 'redirect',
        prevPage: '/Order/p2',
      });
      this.props.history.push('/Join');
      return false;
    }

    // 정기 대기 존재할 경우 얼럿
    if (this.state.hasWaitBill) {
      this.openPopup(this.state.purchaseDevice === 'WEB' ? 'unablePurchase' : 'unablePurchaseM');
      return false;
    }

    // 결제 정보 가져오기
    await this.getPaymentInfo(priceSeq);

    const purchaseInfo = this.state.purchaseInfo;
    if (!purchaseInfo) { // 결제정보 못가져올 경우 수강신청으로 이동
      this.props.history.replace('/Order');
      return false;
    }

    // 홀딩 중에 정기 수강권 결제 막기
    if (this.state.lessonStatus?.currentScheduleStatusCode === 'HOLDING' && this.state.selectProductBillFlag) {
      this.openPopup('unablePurchaseRegular');
      return false;
    }

    localStorage.setItem('productCode', purchaseInfo.productPrice.productCode);
    localStorage.setItem('priceSeq', purchaseInfo.productPrice.priceSeq);

    if (type === 'payCancel') {
      setTimeout(() => {
        window.scrollTo(0, 0);
        this.setState({
          payCancel: true,
        });
      }, 200);
      // 사용자 결제 취소
      this.alertMessage('PUR000017', true);
      this.productClick(localStorage.getItem('productCode'));
    } else if (type === 'error') {
      this.setState({
        payCancel: true,
      });
      this.productClick(localStorage.getItem('productCode'));
    }

    if (pledge || type === 'personal') {
      this.props.history.replace('/Order/p2');
    } else {
      this.props.history.push('/Order/p2');
    }
    window.scrollTo(0, 0);

    // 수강중 상품 비교
    if (this.state.inClassFlag) {
      let selectProductCode = this.state.purchaseInfo.productPrice.productCode;
      if (type !== 'payCancel' && type !== 'error') {
        if (this.state.memberClass !== 'B2B' && this.state.purchaseInfo.productPrice.nationCode !== this.state.nationCode) {
          this.setState({
            diffNationCode: true,
            diffLessonMode: false,
          });
        } else if (this.state.memberClass !== 'B2B' && this.state.purchaseInfo.productPrice.lessonMode !== this.state.lessonMode) {
          this.setState({
            diffLessonMode: true,
            diffNationCode: false,
          });
        } else if (selectProductCode !== this.state.inClassProductCode && type !== 'personal' && this.state.isValidInClassProductCode) {
          const cookieChk = localStorage.getItem('doNotOpenDiffTicket');
          if (!cookieChk) {
            this.setState({
              diffTicket: true,
              alertPriority: true,
            });
          } else if (cookieChk < moment().format('YYYY-MM-DD')) {
            this.setState({
              diffTicket: true,
              alertPriority: true,
            });
            localStorage.removeItem('doNotOpenDiffTicket');
          }
        }
      }
    }

    localStorage.removeItem('tempHistory');
    this.changePayState();
  };

  // B2B 수강신청하기 클릭
  movingToB2BClassPayment = (type) => {
    let {
      purchaseagreeCode,
      selectProduct,
    } = this.state;


    // 수강신청하기 버튼 클릭 시, 호응하는 프로 상품이 존재할 경우 팝업 노출
    if (!type && selectProduct.multiProductPrice) {
      this.setState({
        alertUpgradeMultiProduct: true,
      });
      return;
    }

    // 팝업 닫기
    this.setState({
      alertUpgradeMultiProduct: false
    });

    // 프로 유도 팝업에서 업그레이드 클릭 시 상품 변경
    if (type==='change') {
      selectProduct = selectProduct.multiProductPrice;
      this.setState({
        selectProduct
      });
    }

    // 동의서 여부에 따라 이동
    if (purchaseagreeCode === 'NONE') {
      this.processStep2(selectProduct.priceSeq);
    } else {
      this.setState({
        purchaseAgreePage: true,
      });
      this.unselectProduct();
      if (this.ww === 'mo') {
        window.scrollTo(0,0);
        if (this.state.personalOrder) this.props.history.replace('/Order/p5');
        else this.props.history.push('/Order/p5');
      }
    }
  };

  // 수강신청 페이지로 이동
  goToOrderPage = () => {
    this.closePopup('unableLoadPurchaseInfo');
    this.closePopup('diffTicket');
    this.closePopup('diffNationCode');
    this.go('/Order');
  };

  // 네이버페이 승인
  nPayApproval = (v, billFlag, encrypt) => {
    const naverPayMode = this.state.npayMode;		// development or production

    const nPay = window.Naver.Pay.create({
      'payType' : billFlag ? 'recurrent' : 'normal',
      'mode' : naverPayMode,
      'clientId': process.env.REACT_APP_NAVERPAY_CLIENT_ID, // clientId
      'openType': 'page'
    });

    const paymentAmount = this.state.personalOrder
      ? this.state.personalOrderInfo.paymentAmount
      : this.state.purchaseInfo.productPrice.realPrice - this.state.mileageInput - this.state.couponValue;

    if (billFlag) {
      nPay.open({
        'actionType' : 'NEW',
        'productCode' : String(this.state.purchaseInfo.productPrice.priceSeq),
        'productName': this.state.purchaseInfo.productPrice.productName,
        'totalPayAmount': paymentAmount,
        'returnUrl': process.env.REACT_APP_API_URL + '/purchase/nPayApproval/'+encrypt
      });
    } else {
      nPay.open({
        'merchantUserKey' : String(this.state.memberSeq),
        'merchantPayKey' : String(v),
        'productName': this.state.purchaseInfo.productPrice.productName,
        'totalPayAmount': paymentAmount,
        'taxScopeAmount': 0,
        'taxExScopeAmount': paymentAmount,
        'returnUrl': process.env.REACT_APP_API_URL + '/purchase/nPayApproval/'+encrypt,
        'productItems': [{
          'categoryType' : 'PRODUCT',
          'categoryId' : 'DIGITAL_CONTENT',
          'uid' : String(this.state.purchaseInfo.productPrice.priceSeq),
          'name' : this.state.purchaseInfo.productPrice.productName,
          'count' : 1
        }]
      });
    }
  };

  // 수강신청 만료 페이지 이동
  goToOrderFullPage = (type) => {
    this.setState({
      orderFull: true,
    });
    this.unselectProduct();
    if (type === 'push') {
      this.props.history.push('/Order/p4');
    } else {
      this.props.history.replace('/Order/p4');
    }
  };

  // 0원결제 (결제완료 처리)
  purchaseComplete = (encryptPurchaseSeq, purchaseSeq) => {
    if (this.freeOrder) return null;
    this.freeOrder = true;

    window.scrollTo(0, 0);
    httpDefaultClient.post('/purchase/complete?purchaseSeq='+encryptPurchaseSeq)
      .then(res => {
        this.go('/OrderResult/' + purchaseSeq);
      })
      .catch(error => {
        console.error('purchaseCompleteFail: ', error);
        this.setState({clickFreeBtn:false});
        this.alertMessage('PUR000013', true, 'purchaseCompleteFail');
      });
  };

  // LGD 창열기
  launchCrossPlatform = () => {
    if (this.state.lg.LGD_OSTYPE_CHECK === 'M') {
      window.open_paymentwindow(document.getElementById('LGD_PAYINFO'), this.state.lg.CST_PLATFORM, this.state.lg.LGD_WINDOW_TYPE, null, '', '');
    } else {
      utilFn.ScrollNone(true);
      window.openXpay(document.getElementById('LGD_PAYINFO'), this.state.lg.CST_PLATFORM, this.state.lg.LGD_WINDOW_TYPE, null, '', '');
    }
  };

  // LGD 데이터 호출
  lgdCrossPlatform = (v) => {
    httpDefaultClient.post('/purchase/lgdCrossPlatform?purchaseDevice='+this.state.purchaseDevice+'&actionType=NEW&purchaseSeq='+v)
      .then(res => {
        const json = res.data;
        if (json){
          let data = json;
          if (json.LGD_EASYPAY_ONLY === 'TOSSPAY') {
            data.LGD_WINDOW_TYPE = 'submit'; // 소문자
            data.LGD_CUSTOM_SWITCHINGTYPE = 'SUBMIT'; // 대문자
          }
          this.setState({
            lg : data,
          });
        }
      })
      .then(() => {
        this.launchCrossPlatform();
      })
      .catch(error => {
        console.error('lgdCrossPlatform Error : ', error);
      });
  };

  // 결제 요청
  processStep3 = () => {
    // 전략어 교재
    const courseSeq = !(this.state.purchaseInfo?.productPrice?.languageType==='en' || this.state.purchaseInfo?.productPrice?.languageType==='cn' || this.state.purchaseInfo?.productPrice?.languageType==='jp') && this.state.courseSeqValue ? this.state.courseSeqValue : null;
    const applyFlag = courseSeq ? this.state.bookApplyFlag : null;
    if (applyFlag && (!this.state.bookAddress || !this.state.bookAddressDetail)) {
      this.inputAddressFocus.setFocus();
      this.alertMessage('PUR000163', true);
      return;
    }
    if (applyFlag) {
      localStorage.setItem('purchaseAddress', this.state.bookAddress);
      localStorage.setItem('purchaseAddressDetail', this.state.bookAddressDetail);
    } else {
      localStorage.removeItem('purchaseAddress');
      localStorage.removeItem('purchaseAddressDetail');
    }

    if (this.state.inClassFlag && this.state.hasWaitBill) {
      this.openPopup(this.state.purchaseDevice === 'WEB' ? 'unablePurchase' : 'unablePurchaseM');
      return false;
    }

    const data = this.state.personalOrder
      ? {
        'couponAmount': this.state.personalOrderInfo.couponAmount,
        'memberCouponSeq': this.state.personalOrderInfo.membercouponSeq,
        'mileageAmount': this.state.personalOrderInfo.mileageAmount,
        'payType': this.state.payStats,
        'paymentAmount': this.state.personalOrderInfo.paymentAmount,
        'price': this.state.personalOrderInfo.price,
        'priceSeq': this.state.personalOrderInfo.priceSeq,
        'purchaseDevice': this.state.purchaseDevice,
        'lessonTime': '00:00',
        'lastcookieCode': utilFn.getMarketingCodeValue('lmc') || '',
        'mktparamCode': utilFn.getMarketingCodeValue('ref') || '',
        'guideSeq': this.state.personalOrderInfo.guideSeq,
        'courseSeq': courseSeq,
        'applyFlag': applyFlag,
        'address': applyFlag ? this.state.bookAddress : null,
        'addressDetail': applyFlag ? this.state.bookAddressDetail : null,
      }
      : {
        'couponAmount': this.state.couponValue,
        'memberCouponSeq': this.state.membercouponSeq,
        'mileageAmount': this.state.mileageInput,
        'payType': this.state.payStats,
        'paymentAmount': this.state.purchaseInfo.productPrice.realPrice - this.state.mileageInput - this.state.couponValue,
        'price': this.state.purchaseInfo.productPrice.realPrice,
        'priceSeq': this.state.purchaseInfo.productPrice.priceSeq,
        'purchaseDevice': this.state.purchaseDevice,	// PURDEVICE
        'lessonTime': '00:00',
        'lastcookieCode': utilFn.getMarketingCodeValue('lmc') || '',
        'mktparamCode': utilFn.getMarketingCodeValue('ref') || '',
        'courseSeq': courseSeq,
        'applyFlag': applyFlag,
        'address': applyFlag ? this.state.bookAddress : null,
        'addressDetail': applyFlag ? this.state.bookAddressDetail : null,
      };

    if (!this.state.payStats) {
      this.alertMessage('결제 수단을 선택해주세요.', null, 'payStats');
      return false;
    } else if (this.state.purchaseDevice === 'MOBILE' && this.state.payStats === 'ACCOUNT' && data.paymentAmount > 2000000) {
      this.alertMessage('PUR000103', true, 'payStats');
      return false;
    }

    if (this.state.personalOrder) localStorage.setItem('personal', true); // 개인결제중 스토리지 세팅(취소 시 이용)

    const purchaseSave = () => {
      httpDefaultClient.post('/purchase/save', data)
        .then(res => {
          const json = res.data;
          if (json.result === true){
            if (json.status === 200){
              this.setState({
                purchaseSeq: json.data
              });
              if (this.state.payStats.includes('N_')) {
                const bill = this.state.payStats.includes('B_');
                this.nPayApproval(json.purchaseSeq, bill, json.data);
              } else if (this.state.payStats === 'FREE') {
                this.setState({
                  clickFreeBtn : true,
                });
                this.purchaseComplete(json.data, json.purchaseSeq);
              } else {
                this.lgdCrossPlatform(json.data);
              }
              utilFn.sendFacebook('수강 신청 완료', data.paymentAmount);
            } else {
              this.alertMessage(json.messageCode, true, 'purchaseSaveError');
            }
          }
        })
        .catch((error) => console.error('error: ', error));
    };

    // 정기/장기 업셀링
    if (
      this.state.acceptUpselling === false && // 업업셀링 방지
      (this.state.purchaseInfo.productPrice.billFlag === false // 일반 결제 (정기로 유도)
        || (this.state.purchaseInfo.productPrice.productWeek < 24 && this.state.purchaseInfo.productPrice.billFlag === true)) // 4주/12주 정기결제 (장기로 유도)
      && this.state.payStats !== 'ACCOUNT' && this.state.payStats !== 'T_PAY' // 계좌이체, 토스페이 아님
      && data.paymentAmount > 0 // 결제 금액 존재
      && this.state.memberClass !== 'B2B' // B2B 아님
      && !this.state.personalOrder // 개인결제 아님
      && !this.state.showUpsellingPopup // 업셀링 팝업 안열려 있는 경우
    ){
      // 업셀링 상품 찾기
      httpDefaultClient.get('/purchase/anotherPaymentInfo?priceSeq='+data.priceSeq).then(async (res) => {
        if (!res.data) {
          this.purchaseSave(data); //업셀링 상품이 없을 경우 결제로 바로 가기
          return false;
        }
        const { purchaseInfo: anotherPurchaseInfo, couponList: anotherCouponList, mileage: anotherMileage, originalDiscountRate: anotherOriginalDiscountRate} = res.data;

        // 가격, 쿠폰, 적립금 조회
        const purchaseusableCoupon = anotherCouponList.filter(f => f.purchaseusableFlag);
        const availMaxMileage = anotherMileage > anotherPurchaseInfo.productPrice.maxMileage ? anotherPurchaseInfo.productPrice.maxMileage : anotherMileage; // 적용 가능한 최대 마일리지

        let applyMileage = 0;
        let applyCouponValue = 0;
        let applyMembercouponSeq = null;
        if (purchaseusableCoupon.length>0) { // 적용 가능 쿠폰 있음
          const coupon = purchaseusableCoupon[0];
          applyMembercouponSeq = coupon.membercouponSeq;
          if (coupon.discountType === 'RATE' && coupon.couponDiscount === 100) { // 100% 쿠폰일 시 마일리지 사용 안함
            applyMileage = 0;
            applyCouponValue = anotherPurchaseInfo.productPrice.realPrice;
          }
          else { // 100% 쿠폰 아님
            if (coupon.discountType === 'PRICE') {
              if (coupon.couponDiscount > anotherPurchaseInfo.productPrice.realPrice) { // 금액 쿠폰이 상품가격보다 큰 경우
                applyMileage = 0;
                applyCouponValue = anotherPurchaseInfo.productPrice.realPrice;
              } else if (availMaxMileage > anotherPurchaseInfo.productPrice.realPrice - coupon.couponDiscount ) { // 적용가능한 적립금이 (가격-쿠폰할인가)보다 많은 경우
                applyMileage = anotherPurchaseInfo.productPrice.realPrice - coupon.couponDiscount;
                applyCouponValue = coupon.couponDiscount;
              } else {
                applyMileage = availMaxMileage;
                applyCouponValue = coupon.couponDiscount;
              }
            } else {
              applyMileage = availMaxMileage;
              applyCouponValue = (anotherPurchaseInfo.productPrice.realPrice-applyMileage) * coupon.couponDiscount / 100;
            }
          }
        } else {
          applyMileage = availMaxMileage;
          applyCouponValue = 0;
        }
        this.setState({
          showUpsellingPopup: true, // 팝업 활성화
          upsellingPopupType: this.state.purchaseInfo.productPrice.billFlag === false ? 'bill' : 'week',
          purchaseInfoUpsellingItem: anotherPurchaseInfo, // 업셀링 유도 대상 상품 정보
          anotherOriginalDiscountRate: anotherOriginalDiscountRate, // 업셀링 유도 대상 상품 할인율
          couponValueUpsellingItem: applyCouponValue, // 업셀링 유도 대상 상품 적용 할인쿠폰 금액
          membercouponSeqUpsellingItem: applyMembercouponSeq, // 업셀링 유도 대상 상품 할인쿠폰 일련번호
          mileageInputUpsellingItem: applyMileage, // 업셀링 유도 대상 상품 적립금
          before4weekPrice: utilFn.numberFormat(data.paymentAmount) // 원래 선택한 상품 수강시 가격
        });
      }).catch(error => {
        console.error('업셀링 유도 대상 상품 조회 에러');
        this.purchaseSave(data); //업셀링 유도 대상 상품이 없을 경우 결제로 바로 가기
        return false;
      });
    } else {
      //기존 프로세스로 진행할 경우
      purchaseSave();
    }
  };

  // 업셀링 상품 전환
  handleSwitchClass = () => {
    if (this.state.payStats.includes('N_')){
      this.payState('N_B_CARD');
    }
    this.setState({
      acceptUpselling: true,
      purchaseInfo : this.state.purchaseInfoUpsellingItem,
      couponValue : this.state.couponValueUpsellingItem,
      membercouponSeq: this.state.membercouponSeqUpsellingItem,
      mileageInput : this.state.mileageInputUpsellingItem
    },this.processStep3);
  };

  // 현재 과정
  getCurrentCourse = async () => {
    await httpDefaultClient.get('/course/currentCourse')
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          this.setState({
            course : data.courseName,
            currentCourseSeq : data.courseSeq
          });
        }
      })
      .catch( (error) => {
        console.error('getCurrentCourse: ', error);
      });
  };

  // 이미 구매한 정기상품 앱에서 확인
  openOneLink = (type, popup) => {
    let link;
    if (type === 'complete') {
      link = '23f5b5e8';
    } else if (type === 'unablePurchaseM') {
      link = 'ca354a1e';
    }
    if (popup) {
      this.closePopup(type);
    }
    window.open('https://uphone3dev.onelink.me/Fj4y/' + link);
  };

  // 선착순 대기신청 대상 티켓정보 설정 함수
  presetWaitingClassPriceSeq = (targetPriceSeq) => {
    this.setState({ waitingPriceSeq: targetPriceSeq });
  };

  // 전략어 주소 포커스
  inputAddressFocus = () => {
    const ref = React.createRef();
    const setFocus = () => { ref.current &&  ref.current.focus(); };

    return { setFocus, ref };
  };

  render() {
    const state = this.state;
    const params1 = {
      slidesPerView: 1,
      loop: true,
      shouldSwiperUpdate: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: (index, className) => {
          return '<span class="' + className + '">' + (index + 1) + '</span>';
        }
      },
      navigation: this.ww === 'pc' ? {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      } : false,
    };

    // app, mobile 상단 슬라이드
    const params2 = {
      slidesPerView: 'auto',
      spaceBetween: 10,
      centeredSlides: true,
      pagination: {
        clickable: false,
      },
      shouldSwiperUpdate: true,
    };

    // 추천 상품
    const params3 = {
      slidesPerView: 'auto',
      spaceBetween: this.ww === 'mo' ? 10 : 20,
      centeredSlides: this.ww === 'mo',
      pagination: {
        clickable: false,
      },
      shouldSwiperUpdate: true,
      navigation: this.ww === 'pc' && this.state.bestProduct && this.state.bestProduct.length > 3 ? {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      } : false,
      allowTouchMove: this.ww !== 'pc',
    };
    
    // 수강혜택
    const params4 = {
      slidesPerView: 'auto',
      spaceBetween: this.ww === 'mo' ? 10 : 20,
      centeredSlides: this.ww === 'mo',
      pagination: {
        clickable: false,
      },
      shouldSwiperUpdate: true,
      navigation: this.ww === 'pc' ? {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      } : false,
      allowTouchMove: this.ww !== 'pc',
    };

    const forClassEnrollmentProps = {
      ...state,
      go: this.go,
      history: this.props.history,
      toggleStateValue: this.toggleStateValue,
      ww: this.ww,
      params2,
      params3,
      params4,
      openPopup: this.openPopup,
      closePopup: this.closePopup,
      productClick: this.productClick,
      bestProductClick: this.bestProductClick,
      selectFilterValue: this.selectFilterValue,
      selectStrategyTab: this.selectStrategyTab,
      changeStrategyLanguage: this.changeStrategyLanguage,
      movingToB2BClassPayment: this.movingToB2BClassPayment,
      getPaymentInfo: this.getPaymentInfo,
      processStep2: this.processStep2,
      presetWaitingClassPriceSeq: this.presetWaitingClassPriceSeq,
      purchaseBannerClick: this.onClickPurchaseBannerClick,
    };

    const forClassPaymentProps = {
      ...state,
      ww: this.ww,
      history: this.props.history,
      openPopup: this.openPopup,
      closePopup: this.closePopup,
      panelState: this.panelState,
      changeCoupon: this.changeCoupon,
      onChangeCouponInput: this.onChangeCouponInput,
      registerCoupon: this.registerCoupon,
      changeMileage: this.changeMileage,
      applyMaxMileage: this.applyMaxMileage,
      setStateFalseValue: this.setStateFalseValue,
      setStateTrueValue: this.setStateTrueValue,
      payState: this.payState,
      processStep3: this.processStep3,
      updateMileageRef: this.updateMileageRef,
      updateCouponSelect: this.updateCouponSelect,
      updateCouponNum: this.updateCouponNum,
      updatePayPanel: this.updatePayPanel,
      goBackYes: this.goBackYes,
      goToOrderPage: this.goToOrderPage,
      go: this.go,
      changeBookApplyFlag: this.changeBookApplyFlag,
      onChangeAddress: this.onChangeAddress,
      onChangeAddressDetail: this.onChangeAddressDetail,
      inputAddressRef: this.inputAddressFocus.ref,
    };

    const forClassPledgeProps = {
      ...state,
      history: this.props.history,
      go: this.go,
      ww: this.ww,
      processStep2: this.processStep2,
      closePopup: this.closePopup,
    };

    const forClassEnrollmentClosedProps = {
      ...state,
      history: this.props.history,
      replaceHistory: this.replaceHistory,
    };

    const forPersonalPopupProps = {
      ...state,
      closePopup: this.closePopup,
      goPersonalOrder: this.goPersonalOrder,
    };

    const forCardInfoPopupProps = {
      closePopup: this.closePopup
    };

    const forNPayGuidePopupProps = {
      ww: this.ww,
      closePopup: this.closePopup,
    };

    const forTicketGuidancePopupProps = {
      ...state,
      closePopup: this.closePopup,
    };

    const forPurchasePopupProps = {
      ...state,
      closePopup: this.closePopup,
    };

    const forToastPopupProps = {
      ...state,
    };

    const forUpsellingPopupProps = {
      ...state,
      processStep3: this.processStep3,
      handleSwitchClass: this.handleSwitchClass,
      memberClass: this.state.memberClass,
      closePopup: this.closePopup,
    };

    const forPaymentGatewayIntelProps = {
      ...state,
    };

    return (
      <>
        <ReactMeta title='전화영어, 화상영어 1위 업계 유일! 100% 만족 보장 제도' description='업계 유일! 100% 만족 보장 제도, 4주 이내 전액 환불을 약속드려요.' />
        {/* B2B 수강신청 */}
        { !this.props.match.params.page && !(this.state.memberClass==='B2B' || this.state.b2b2cproductmanageFlag) && 
          <PurchaseShowcaseB2C ww={this.ww} goToPayment={this.processStep2} openPopup={this.openPopup} />
        }

        <div className={'orderPage orderPage--confirm '+(this.props.match.params.page ? this.props.match.params.page : 'p1')+(state.purchaseInfo && !(this.props.match.params.page === 'p3' && !state.payComplete) && !(this.props.match.params.page === 'p2' && state.clickFreeBtn) ? ' on ' : ' ')}>

          {/* 수강신청 */}
          { !this.props.match.params.page && (this.state.memberClass==='B2B' || this.state.b2b2cproductmanageFlag) && 
            <ClassEnrollment props={forClassEnrollmentProps}/> 
          }
          { (this.state.personalOrder && this.props.match.params.page==='p5' && this.ww === 'pc') &&
					<div className={'orderPage__emptyarea'}></div>
          }

          {/* 개인결제 팝업 */}
          { state.personalPopup && !state.showEmergencyPopup &&
              <PersonalPopup
                props={forPersonalPopupProps}
              />
          }

          {/* 수강신청, 결제하기: 무이자 할부 안내 팝업 */}
          {state.cardInfoPopup &&
					<CardInfoPopup
					  props={forCardInfoPopupProps}
					/>
          }

          {/* 수강혜택 배너 */}
          {state.purchasePopup &&
					<PurchasePopup
					  props={forPurchasePopupProps}
					/>
          }

          {/* 수강신청:: 수강신청 동의 팝업 */}
          {state.purchaseAgreePage && !(this.ww === 'mo' && !this.props.match.params.page) &&
					<ClassPledge
					  props={forClassPledgeProps}
					/>
          }

          {/* 수강신청:: 수강신청 마감 팝업 */}
          {(state.orderFull || !state.isB2BOrderPeriod) &&
					<ClassEnrollmentClosed
					  props={forClassEnrollmentClosedProps}
					/>
          }

          {/* 결제하기 */}
          {state.purchaseInfo &&
					<ClassPayment
					  props={forClassPaymentProps}
					/>
          }

          {/* 결제하기: 일반 -> 정기 유도 팝업 */}
          {state.showUpsellingPopup &&
					<UpsellingPopup
					  props={forUpsellingPopupProps}
					/>
          }

          {/* 결제하기: 네이버페이 안내 팝업 */}
          {state.nPayGuide &&
					<NPayGuidePopup
					  props={forNPayGuidePopupProps}
					/>
          }

          {/* 토스트 팝업 */}
          <ToastPopup
            props={forToastPopupProps}
          />

          {/* 공통:: 오류 메시지 팝업 */}
          {state.eMessageFlag && !state.alertPriority &&
					<LayerPopup
					  tit={state.eMessageTitle}
					  desc1 = {state.eMessageTxt}
					  btnCnt={1}
					  btnOkCmt={state.commonMsg['0000000094']}
					  btnOkFunction={() => this.closePopup('eMessageFlag')}
					/>
          }

          {/* 수강신청:: 수강중인 수강권으로 재수강 팝업 */}
          {
            state.before14 && !state.showEmergencyPopup &&
            <LayerPopup
              tit={'수업 중인 수강권으로<br />지금 바로 재수강하겠습니까?'}
              desc1={state.commonMsg['PUR000029']}  // 현재 수강권
              bottomRed={state.lessonStatus.productName}
              btnCnt={2}
              btnNoCmt={'다른 수강권 보기'}
              btnNoFunction={() => this.closePopup('before14')}
              btnNoCmtClass={'ga-btn-see-other-product'}
              btnYesCmt={'네, 지금 할게요'}
              btnYesFunction={() => {
                saveAccessDataByAccessFunction('reg_popup_ok');
                this.onClickReOrderMyProduct({ priceSeq: state.priceSeqForRetake, productCode: state.inClassProductCode });
              }}
              btnYesCmtClass={'ga-btn-re-order'}
            />
          }

          {/* 수강신청:: B2B 담임->멀티 유도 팝업*/}
          {
            state.alertUpgradeMultiProduct &&
            <LayerPopup
              type={'type01'}
              tit={state.commonMsg['PUR000169']}
              li1={state.commonMsg['PUR000170']}
              li2={state.commonMsg['PUR000171']}
              li3={state.commonMsg['PUR000172']}
              li4={state.commonMsg['PUR000173']}
              btnFull={state.commonMsg['PUR000174']}
              btnFullFunction={() => this.movingToB2BClassPayment('change')}
              btnLine={state.commonMsg['PUR000175']}
              btnLineFunction={() => this.movingToB2BClassPayment('keep')}
            />
          }
         
          {/* 수강신청/결제하기:: 정기결제 대기건이 존재하는 경우 (수강신청 불가) */}
          {state.unablePurchase &&
					<LayerPopup
					  tit={state.commonMsg['PUR000027']}  // 이미 구매한 정기 상품이 있습니다. 앱에서 확인해 주세요.
					  btnCnt={1}
					  btnOkCmt={state.commonMsg['0000000094']}

					  btnOkFunction={() => {
					    this.closePopup('unablePurchase');
					  }}
					/>
          }
          {state.unablePurchaseM &&
					<LayerPopup
					  tit={state.commonMsg['PUR000034']}  // 이미 구매한 정기 상품이 있습니다. 앱에서 확인하시겠습니까?
					  btnCnt={2}
					  btnNoCmt={'취소'}
					  btnNoFunction={() => {
					    this.closePopup('unablePurchaseM', 'reset');
					  }}
					  btnYesCmt={'확인'}
					  btnYesFunction={() => {
					    this.openOneLink('unablePurchaseM', true);
					  }}
					/>
          }

          
          {/* 수강신청/결제하기::홀딩중일 경우 정기결제 불가 */}
          {state.unablePurchaseRegular &&
					<LayerPopup
					  tit={state.commonMsg['PUR000098']}
					  btnCnt={1}
					  btnOkCmt={state.commonMsg['0000000094']}
					  btnOkFunction={() => {
					    this.closePopup('unablePurchaseRegular');
					    this.props.history.replace('/Order');
					  }}
					/>
          }

          {/* 비회원->결제하기->B2B 아이디로 로그인 한 경우 */}
          {state.tempHistoryB2B &&
					<LayerPopup
					  tit={state.commonMsg['PUR000032']}		// 회원님은 기업 회원 입니다.<br />기업 수강신청 페이지로 이동합니다.
					  desc1={state.commonMsg['PUR000033']}	// 기존에 선택한 상품정보는 초기화됩니다.
					  btnCnt={1}
					  btnOkCmt={state.commonMsg['0000000303']}	// 이동하기
					  btnOkFunction={() => this.closePopup('tempHistoryB2B')}
					/>
          }

          {/* 결제 모듈 */}
          {state.purchaseSeq &&
					<PaymentGatewayIntel
					  props={forPaymentGatewayIntelProps}
					/>
          }
        </div>
        {state.pageComplete &&
				<Footer ww={this.ww} />
        }
      </>
    );
  }
}
