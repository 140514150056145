import { useQuery, UseQueryOptions } from 'react-query';
import { httpDefaultClient } from 'configs/api';

export interface CommonCode {
  groupCode: string;
  detailCode: string;
  languageCode: string;
  detailName: string;
  uppergroupCode?: string;
  upperdetailCode?: string;
  upperlanguageCode?: string;
  sort: number;
  note1?: string;
  note2?: string;
  note3?: string;
  note4?: string;
  note5?: string;
  note6?: string;
  note7?: string;
  note8?: string;
  note9?: string;
  useFlag: boolean;
}

/* 1건 호출 */
const getCommonCode = async (groupCode: string): Promise<CommonCode[]> => {
  const { data } = await httpDefaultClient.get(`/commonCode/getList/${groupCode}`);
  return data;
};

export const useCommonCode = (
  groupCode: string,
  options?: UseQueryOptions<any, Error>
) => {
  const queryKey = ['commonCode', groupCode];
  return useQuery({
    queryKey: queryKey,
    queryFn: () => getCommonCode(groupCode),
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    ...options,
  });
};

/* 여러 건 호출 */
const getMultipleCommonCodes = async (groupCodes: string[]): Promise<Record<string, CommonCode[]>> => {
  const { data } = await httpDefaultClient.post('/commonCode/multi', groupCodes);
  return data;
};

export const useMultipleCommonCodes = (
  groupCodes: string[],
  options?: UseQueryOptions<any, Error>
) => {
  const queryKey = ['commonCodes', groupCodes];
  return useQuery({
    queryKey: queryKey,
    queryFn: () => getMultipleCommonCodes(groupCodes),
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
    ...options,
  });
};
