import React, { useEffect } from 'react';
import reserveTrialStore, { clearRequestDateTime, getLessonBook, validateInput } from 'store/trial/reserve/reserveTrialStore';

export default function FreeLessonDuration() {
  const levelCodeLMSData = reserveTrialStore((state) => state.levelCodeLMSData);
  const levelCode = reserveTrialStore((state) => state.levelCode);
  const setLevelCode = reserveTrialStore((state) => state.setLevelCode);
  const showLevel = reserveTrialStore((state) => state.showLevel);
  const setLessonMinute = reserveTrialStore((state) => state.setLessonMinute);
  const juniorFlag = reserveTrialStore((state) => state.juniorFlag);
  
  const clickLevel = (levelCode:string, lessonMinute:string) => {
    setLevelCode(levelCode);
    clearRequestDateTime();
    getLessonBook();
    validateInput();
  };
  
  useEffect(() => {
    const targetLmsData = levelCodeLMSData.find((item: any) => item.detailCode === levelCode);
    setLessonMinute(Number(juniorFlag ? targetLmsData?.note4 : targetLmsData?.note3));
  },[levelCodeLMSData, levelCode]);

  if (!showLevel) {
    return null;
  }

  return (
    <>
      <div className={'titleBox'}>
        <div className={'titleBox__title'}>수업 레벨</div>
      </div>
      <ul className={'levelCode'}>
        {
          levelCodeLMSData &&
          levelCodeLMSData.map((v, i) => {
            return (
              <li key={i} className={`levelCode__item ${levelCode === v.detailCode ? 'levelCode__item--active' : ''}`} onClick={() => clickLevel(v.detailCode, v.note2)}>
                <div className={'levelCode__item--name'}>
                  <div className={'levelCode__item--name--wrap'}>
                    <span className={`${i === 0 ? 'bul' : ''}`}>{v.detailName}</span>
                  </div>
                </div>
                <div className={'levelCode__item--desc'}>
                  <div dangerouslySetInnerHTML={{ __html: v.note1 }} />
                  {v.note2 && <div className={'levelCode__item--desc__extra'} dangerouslySetInnerHTML={{ __html: v.note2 }} />}
                </div>
              </li>
            );
          })
        }
      </ul>
    </>
  );
}
