import React from 'react';
import DOMPurify from 'dompurify';
import BottomPopup from '../../component/BottomPopup';
import { numberFormat } from 'common/utilFn';
import CheckIcon from './CheckIcon';
const UpsellingPopup = ({ props }) => {
  // state
  const {
    upsellingPopupType,
    purchaseInfo,
    purchaseInfoUpsellingItem,
    mileageInputUpsellingItem,
    couponValueUpsellingItem,
    before4weekPrice,
    anotherOriginalDiscountRate,
    commonMsg,
    closePopup,
  } = props;
    // props
  const {
    processStep3,
    handleSwitchClass,
  } = props;

  const onClickApproval = () => {
    closePopup('showUpsellingPopup');
    handleSwitchClass();
  };

  const onClickRefusal = () => {
    processStep3();
  };

  if (upsellingPopupType === 'week') {
    return (
      <BottomPopup type={'center bill4week bill12over'} >
        <div className={'popTitle bill4week'}>
          {commonMsg['USPW000001']?.replace('${productWeek}', purchaseInfoUpsellingItem.productPrice.productWeek) /* ${productWeek}주 결제 등록하고 */}
          <br/>
          <span className={'purple'}>
            {commonMsg['USPW000002']?.replace('${price}', numberFormat(4*(purchaseInfoUpsellingItem.productPrice.realPrice - mileageInputUpsellingItem - couponValueUpsellingItem)/purchaseInfoUpsellingItem.productPrice.productWeek)) /*4주 ${price}원으로 수강하세요!*/}
          </span>
        </div>
        <ul className={'popDesc bill4week'}>
          <li>
            <CheckIcon />{commonMsg['USPW000003'] /* 지금 선택한 상품 그대로 */}
          </li>
          <li>
            <CheckIcon />{commonMsg['USPW000004']?.replace('${discountRate}', anotherOriginalDiscountRate) /* 결제마다 ${discountRate}% 추가 할인 적용 */}
          </li>
          <li>
            <CheckIcon />{commonMsg['USPW000005']?.replace('${productWeek}', purchaseInfoUpsellingItem.productPrice.productWeek) /* ${productWeek}주마다 정기 결제로 꾸준한 영어 공부 */}
          </li>
          <li>
            <CheckIcon />{commonMsg['USPW000006'] /* 수강 응원 할인 지원금 포함 */}
          </li>
        </ul>
        <button className={'btn complete bill4week upselling_week_approval'} onClick={onClickApproval}>
          {commonMsg['USPW000007']?.replace('${productWeek}', purchaseInfoUpsellingItem.productPrice.productWeek) /*N주 정기 수강하기*/}
          <br/>
          <span>{commonMsg['USP0000001']?.replace('${price}', numberFormat(purchaseInfoUpsellingItem.productPrice.realPrice - mileageInputUpsellingItem - couponValueUpsellingItem)) /*결제금액 ${price}원*/}</span>
        </button>
        <div className='popNotice bill4week upselling__week_refusal' onClick={onClickRefusal}>
          {commonMsg['USPW000007']?.replace('${productWeek}', purchaseInfo.productPrice.productWeek) /* ${productWeek}주 정기 수강하기 */}
          <br/>
          {commonMsg['USP0000001']?.replace('${price}', before4weekPrice) /* 결제금액 ${price}원 */}
        </div>
        { /* ${productWeek}주 정기권 구매 시, ${productWeek}주 마다 수강료가 <em>일시불로<br/> 자동 결제</em>됩니다. 할부는 지원되지 않습니다. */}
        <div className={'bill4week__infor'}  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(commonMsg['USPW000008']?.replaceAll('${productWeek}', purchaseInfoUpsellingItem.productPrice.productWeek)) }} />
      </BottomPopup>
    );
  } else if (upsellingPopupType === 'bill') {
    return (
      <BottomPopup type={`center bill4week${ purchaseInfo.productPrice.productWeek >= 12 ? ' bill12over' : ''}`} >
        <div className={'popTitle bill4week'}>
  				{commonMsg['USPB000001'] /* 정기 결제 등록하고 */}
          <br/>
          <span className={'purple'}>
            {commonMsg['USPB000002']?.replace('${billdiscountRate}', purchaseInfoUpsellingItem.productPrice.billdiscountRate) /* ${billdiscountRate}% 추가 할인 받으세요! */}
          </span>
        </div>
        <ul className={'popDesc bill4week'}>
          <li>
            <CheckIcon />{commonMsg['USPB000003'] /* 지금 선택한 상품 그대로 */}
          </li>
          <li>
            <CheckIcon />{commonMsg['USPB000004']?.replace('${billdiscountRate}', purchaseInfoUpsellingItem.productPrice.billdiscountRate) /* 결제마다 ${billdiscountRate}% 추가 할인 적용 */}
          </li>
          <li>
            <CheckIcon />{commonMsg['USPB000005']?.replace('${productWeek}', purchaseInfoUpsellingItem.productPrice.productWeek) /* ${productWeek}주마다 정기 결제로 꾸준한 영어 공부 */}
          </li>
        </ul>
        <button className={'btn complete bill4week upselling_regular_payment_approval'} onClick={onClickApproval}>
          {commonMsg['USPB000006'] /* 정기 수강하기 */}
          <br/>
          <span>{commonMsg['USP0000001']?.replace('${price}', numberFormat(purchaseInfoUpsellingItem.productPrice.realPrice - mileageInputUpsellingItem - couponValueUpsellingItem)) /* 결제금액 ${price}원 */}</span>
        </button>
        <div className='popNotice bill4week upselling_regular_payment_refusal' onClick={onClickRefusal}>
          <span>
            {commonMsg['USPB000007']?.replace('${productWeek}', purchaseInfo.productPrice.productWeek) /* ${productWeek}주만 수강하기 */}
            <br/>
            {commonMsg['USP0000001']?.replace('${price}', before4weekPrice) /* 결제금액 ${price}원 */}
          </span>
        </div>
        {/* ${productWeek}주 정기권 구매 시, ${productWeek}주 마다 수강료가 <em>일시불로<br/> 자동 결제</em>됩니다. 할부는 지원되지 않습니다. */}
        {
          (purchaseInfo.productPrice.productWeek >= 12) &&
  				<div className={'bill4week__infor'} dangerouslySetInnerHTML={{__html : DOMPurify.sanitize(commonMsg['USPB000008'])}} />
        }
      </BottomPopup>
    );
  } else {
    return null;
  };
};

export default UpsellingPopup;
