import React, { FC, useMemo } from 'react';
import DOMPurify from 'dompurify';
import 'css/purchase/PurchaseShowcaseB2C.scss';
import { CommonCode, useCommonCode } from 'queries/common/useCommonCode';

const PurchaseShowcaseB2CSales: FC = () => {
  const { isFetched, data = [] } = useCommonCode('PURSALES');

  const salesTitle = useMemo(() => data.find((item: CommonCode) => item.detailCode === 'TITLE'), [isFetched]);
  const salesList = useMemo(() => data.filter((item: CommonCode) => item.detailCode !== 'TITLE'), [isFetched]);

  return (
    <section className='purchase-showcase-sales'>
      <header className='purchase-showcase-sales__header'>
        <strong className='pre-title-completion'>
          <em className='pre-title-completion__value'><span>85.6</span><span className='percent'>%</span></em>
          <em className='pre-title-completion__desc'>24년 역대 <br />최대 재수강률</em>
        </strong>
        <div className='section-title'>
          <div className='top-row'>
            <strong className='is-gradient'>유폰 수강생 <br />10명 중 9명이</strong>
          </div>
          <strong className='is-white'>만족을 넘어 재수강을 <br />선택한 이유</strong>
        </div>
      </header>
      <div className='purchase-showcase-sales__body'>
        {salesList?.map((item: CommonCode) => (
          <dl key={item.detailCode} className='sales-item' style={{ backgroundImage: item.note5 ? `url(${process.env.REACT_APP_IMG_URL + item.note5})` : 'none' }}>
            <dt className='sales-item__title'>
              {item.note1} <br /><span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.note2 || '') }} />
            </dt>
            <dd className='sales-item__desc'>
              <em className='value'>{item.note3}</em>
              {item.note4}
            </dd>
          </dl>
        ))}
      </div>
    </section>
  );
};

export default PurchaseShowcaseB2CSales;
