import React from 'react';
import {ToeicType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: ToeicType;
}

const componentName = {
  TS1: 'Read a Text Aloud',
  TS2: 'Describe a Picture',
  TS3: 'Respond to Questions',
  TS4: 'Respond Using Information',
  TS6: 'Express an opinion',
};
const componentDesc = {
  TS1: '본문을 정확하게 읽어보세요.',
  TS2: '사진을 최대한 자세하게 묘사해보세요.',
  TS3: '상황을 듣고 질문에 답해보세요.',
  TS4: '정보를 숙지하고 질문에 답해보세요.',
  TS6: '질문에 대해 자유롭게 의견을 표현해보세요.',
};
export default function Toeic({componentCode, content}: PropsType) {
  return (
    <div className={`page ${componentCode}`}>
      <div className='component-name'>{componentName[componentCode]}</div>
      <div className='component-desc'>{componentDesc[componentCode]}</div>
      {content.imgUrl && componentCode==='TS4' && (
        <img className='picture' src={content.imgUrl} alt='' />
      )}
      <div>
        {content.subs &&
            content.subs.map((item, index) => (
              <div className='toeic-wrapper' key={`q${index}`}>
                <div className='title'>
                  {item.subtitle ? item.subtitle : 'Situation'}
                </div>
                {item.sentence && <div className={`content ${!item.subtitle&&'situation'}`}>{item.sentence}</div>}
                {item.imgUrl &&
                  <img
                    className='picture'
                    src={item.imgUrl}
                    alt=''
                  />
                }
              </div>
            ))}
      </div>
    </div>
  );
}
