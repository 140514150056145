import { useState, useEffect } from 'react';
import {httpDefaultClient} from 'configs/api';

const useSupportBoard = (props) => {
  const [data, setData] = useState();

  const { supportId, replaceData1, replaceData2, replaceData3 } = props;

  const getSupportBoard = (supportId, replaceData1, replaceData2, replaceData3) => {
    httpDefaultClient.get('/sign/getSupport?supportId=' + supportId)
      .then(res => {
        const json = res.data;
        if (!json.result) {
          return;
        }

        let data = json.data;
        if (replaceData1) {data = data.replace('$1', replaceData1);}
        if (replaceData2) {data = data.replace('$2', replaceData2);}
        if (replaceData3) {data = data.replace('$3', replaceData3);}

        setData(data);
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };
  useEffect(() => {
    getSupportBoard(supportId, replaceData1, replaceData2, replaceData3);
  }, [supportId]);

  return {
    data,
  };
};

export default useSupportBoard;
