import React, { FC } from 'react';
import DOMPurify from 'dompurify';
import 'css/purchase/PurchaseShowcaseB2C.scss';
import { useFaqList } from 'queries/purchase/usePurchaseService';

const PurchaseShowcaseB2CFaq: FC = () => {
  const { data: faqList = [] } = useFaqList();
  const [openFaqs, setOpenFaqs] = React.useState<Set<number>>(new Set());

  const handleToggle = (boardSeq: number) => {
    setOpenFaqs((prev) => {
      const newOpenFaqs = new Set(prev);
      if (newOpenFaqs.has(boardSeq)) {
        newOpenFaqs.delete(boardSeq);
      } else {
        newOpenFaqs.add(boardSeq);
      }
      return newOpenFaqs;
    });
  };

  return (
    <section className='purchase-showcase-faq'>
      <h2 className='section-title'>자주 묻는 질문</h2>
      <div className='faq-list'>
        {faqList?.map((faq) => (
          <dl key={faq.boardSeq} className={`faq-item ${openFaqs.has(faq.boardSeq) ? 'is-open' : ''}`}>
            <dt className='faq-item__title' onClick={() => handleToggle(faq.boardSeq)}>
              <span>Q.</span> {faq.title}
            </dt>
            {openFaqs.has(faq.boardSeq) && (
              <dd className='faq-item__content' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(faq.content) }}></dd>
            )}
          </dl>
        ))}
      </div>
    </section>
  );
};

export default PurchaseShowcaseB2CFaq;
