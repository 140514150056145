import { StateCreator } from 'zustand';

export interface UnitedTopBannerControlSliceT {
  mobileBannerHeight: number;
  pcBannerHeight: number;
  setMobileBannerHeight: (height: number) => void
  setPCBannerHeight: (height: number) => void
}

const initialState = {
  mobileBannerHeight: 0,
  pcBannerHeight: 0,
};

const unitedTopBannerControlSlice: StateCreator<
UnitedTopBannerControlSliceT, 
  any[], 
  any[], 
  UnitedTopBannerControlSliceT
> = (set) => ({
  ...initialState,
  setMobileBannerHeight: (height) => set((state) => ({ ...state, mobileBannerHeight: height })),
  setPCBannerHeight: (height) => set((state) => ({ ...state, pcBannerHeight: height })),
});

export default unitedTopBannerControlSlice;
