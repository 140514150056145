import React, { FC, useState, useMemo, useRef, useEffect, useCallback } from 'react';

import DOMPurify from 'dompurify';
import 'css/purchase/PurchaseShowcaseB2C.scss';
import purchaseShowcaseB2CStore from 'store/purchase/purchaseShowcaseB2CStore';
import { CommonCode, useMultipleCommonCodes } from 'queries/common/useCommonCode';
import { commonCodeConverter, numberFormat, ScrollNone } from 'common/utilFn';
import { ProductPrice } from 'types/purchase/purchase.interface';
import LayerPopup from 'component/LayerPopup';

type Props = {
  goToPayment: (priceSeq: number, payType: string, pledge?: boolean) => void;
  ww: string;
};

const PurchaseShowcaseB2CProductDetail: FC<Props> = ({ goToPayment: goToPaymentFn, ww }) => {
  const filteredProductList = purchaseShowcaseB2CStore((state) => state.filteredProductList);
  const selectedProductWeek = purchaseShowcaseB2CStore((state) => state.selectedProductWeek);
  const selectedLessonMode = purchaseShowcaseB2CStore((state) => state.selectedLessonMode);
  const { isFetched: commonCodesFetched, data: commonCodes = {} } = useMultipleCommonCodes(['PURPROD', 'PURPRODD4', 'PURPRODD12', 'PURPRODD24', 'LESSONMODE']);
  const [currentIndex, setCurrentIndex] = useState(2);
  const [firstProductNote, setFirstProductNote] = useState<string>('note3');
  const [secondProductNote, setSecondProductNote] = useState<string>('note4');
  const [viewFullDetail, setViewFullDetail] = useState<boolean>(false);
  const [showSoldoutPopup, setShowSoldoutPopup] = useState(false);

  const detailList = useMemo(() => {
    if (!commonCodesFetched) return [];

    if (viewFullDetail) {
      return commonCodes[`PURPRODD${selectedProductWeek}`] || [];
    } else {
      const list = commonCodes[`PURPRODD${selectedProductWeek}`] || [];
      return list.filter((item: CommonCode) => item.note5 === 'Y');
    }
  }, [commonCodesFetched, viewFullDetail, selectedProductWeek]);

  // < 이전 버튼
  const onClickPrevious = () => {
    if (currentIndex === 0) return;
    const previousIndex = currentIndex - 1;
    setCurrentIndex(previousIndex);
    setFirstProductNote('note' + (previousIndex + 1));
    setSecondProductNote('note' + (previousIndex + 2));
  };

  // > 다음 버튼
  const onClickNext = () => {
    if (currentIndex >= filteredProductList.length - 2) return;
    const nextIndex = currentIndex + 1;
    setCurrentIndex(nextIndex);
    setFirstProductNote('note' + (nextIndex + 1));
    setSecondProductNote('note' + (nextIndex + 2));
  };

  const handleSwiperControl = (arrow: string) => {
    arrow === 'prev' ? onClickPrevious() : onClickNext();

    if (productTable.current && isSticky) {
      const tableTop = productTable.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: tableTop });
    }
  };

  // 4주당 가격 계산
  const pricePerMonth = (product: ProductPrice) => {
    if (!product) return '';
    if (product.showAnotherProductPrice && product.anotherProductPrice)
      return numberFormat(4 * product.anotherProductPrice?.realPrice / product.anotherProductPrice?.productWeek);
    return numberFormat(4 * product.realPrice / product.productWeek);
  };

  // 결제 페이지로 이동
  const goToPayment = useCallback((product: ProductPrice) => {
    if (product.showAnotherProductPrice && product.anotherProductPrice) goToPaymentFn(product.anotherProductPrice.priceSeq, '');
    else goToPaymentFn(product.priceSeq, '');
  }, []);

  // sold out 알림창
  const alertSoldOut = useCallback(() => {
    ScrollNone(true);
    setShowSoldoutPopup(true);
  }, []);


  const [isSticky, setIsSticky] = useState(false);
  const productTable = useRef<HTMLTableElement | null>(null);
  const productTableHead = useRef<HTMLTableSectionElement | null>(null);

  useEffect(() => {

    const handleScroll = () => {
      if (!productTable.current || !productTableHead.current) return;

      const { top: productTableTop, bottom: productTableBottom } = productTable.current.getBoundingClientRect();

      const stickyStartPoint = productTableTop + window.scrollY;
      const stickyEndPoint = productTableBottom + window.scrollY - productTableHead.current.offsetHeight;

      setIsSticky(window.scrollY >= stickyStartPoint && window.scrollY <= stickyEndPoint);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const pcTableData = commonCodes.PURPROD || [];

  const renderTableCells = useCallback((item: CommonCode) => {
    if (item.detailCode === 'LESSONWEEK') {
      return (
        <>
          <td>{selectedProductWeek}주</td>
          <td>{selectedProductWeek}주</td>
        </>
      );
    } else if (item.detailCode === 'LESSONMODE') {
      return (
        <>
          <td>{commonCodeConverter(commonCodes.LESSONMODE, 'detailName', selectedLessonMode)}<br />({commonCodeConverter(commonCodes.LESSONMODE, 'note1', selectedLessonMode)})</td>
          <td>{commonCodeConverter(commonCodes.LESSONMODE, 'detailName', selectedLessonMode)}<br />({commonCodeConverter(commonCodes.LESSONMODE, 'note1', selectedLessonMode)})</td>
        </>
      );
    }

    return (
      <>
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item[firstProductNote as keyof CommonCode] as string) }}></td>
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item[secondProductNote as keyof CommonCode] as string) }}></td>
      </>
    );
  }, [commonCodes, selectedProductWeek, selectedLessonMode, firstProductNote, secondProductNote]);

  const renderTableCellsForPc = useCallback((item: CommonCode) => {
    if (item.detailCode === 'LESSONWEEK') {
      return (
        <>
          <td>{selectedProductWeek}주</td>
          <td>{selectedProductWeek}주</td>
          <td>{selectedProductWeek}주</td>
          <td>{selectedProductWeek}주</td>
        </>
      );
    } else if (item.detailCode === 'LESSONMODE') {
      return (
        <>
          <td>{commonCodeConverter(commonCodes.LESSONMODE, 'detailName', selectedLessonMode)}<br />({commonCodeConverter(commonCodes.LESSONMODE, 'note1', selectedLessonMode)})</td>
          <td>{commonCodeConverter(commonCodes.LESSONMODE, 'detailName', selectedLessonMode)}<br />({commonCodeConverter(commonCodes.LESSONMODE, 'note1', selectedLessonMode)})</td>
          <td>{commonCodeConverter(commonCodes.LESSONMODE, 'detailName', selectedLessonMode)}<br />({commonCodeConverter(commonCodes.LESSONMODE, 'note1', selectedLessonMode)})</td>
          <td>{commonCodeConverter(commonCodes.LESSONMODE, 'detailName', selectedLessonMode)}<br />({commonCodeConverter(commonCodes.LESSONMODE, 'note1', selectedLessonMode)})</td>
        </>
      );
    }

    return (
      <>
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.note1 as string) }}></td>
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.note2 as string) }}></td>
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.note3 as string) }}></td>
        <td dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.note4 as string) }}></td>
      </>
    );
  }, [commonCodes, selectedProductWeek, selectedLessonMode]);

  return (
    <section className='purchase-showcase-details'>
      <h1 className='section-title'>수강권 상세 안내</h1>
      <div className='table-container'>
        {
          isSticky &&
          <div className='scroll-bg' style={{ minHeight: (productTableHead.current?.offsetHeight + 'px') || 0 }}></div>
        }
        <table id='mobileProductTable' ref={ww === 'mo' ? productTable : null} className='purchase-showcase-table custom'>
          <thead ref={ww === 'mo' ? productTableHead : null} className='custom'>
            <tr>
              <th className='control-head'>
                <button type='button' className={'control-head__btn-prev ' + (currentIndex === 0 ? 'is-disabled' : '')} onClick={() => handleSwiperControl('prev')}><span className='blind'>이전 상품보기</span></button>
                <button type='button' className={'control-head__btn-next ' + (currentIndex >= filteredProductList.length - 2 ? 'is-disabled' : '')} onClick={() => handleSwiperControl('next')}><span className='blind'>다음 상품보기</span></button>
              </th>
              <th>
                <div className='sticky-item'>
                  <strong className='sticky-item__title'>{commonCodeConverter(commonCodes.PURPROD, 'detailName', filteredProductList?.[currentIndex]?.productCategory)}</strong>
                  <em className='sticky-item__price'><span>{pricePerMonth(filteredProductList?.[currentIndex])}</span>원 / 4주</em>
                  {!filteredProductList?.[currentIndex]?.soldoutFlag
                    ? <button className='sticky-item__btn-start' type='button' onClick={() => goToPayment(filteredProductList?.[currentIndex])}>시작하기</button>
                    : <button className='sticky-item__btn-soldout' type='button' onClick={alertSoldOut}>Sold out</button>
                  }
                  {(() => {
                    const isBest = Boolean(commonCodeConverter(commonCodes.PURPROD, 'note8', filteredProductList?.[currentIndex]?.productCategory));
                    return isBest && <span className='sticky-item__tag-best'>Best</span>;
                  })()}
                </div>
              </th>
              <th>
                <div className='sticky-item'>
                  <strong className='sticky-item__title'>{commonCodeConverter(commonCodes.PURPROD, 'detailName', filteredProductList?.[currentIndex + 1]?.productCategory)}</strong>
                  <em className='sticky-item__price'><span>{pricePerMonth(filteredProductList?.[currentIndex + 1])}</span>원 / 4주</em>
                  {!filteredProductList?.[currentIndex + 1]?.soldoutFlag
                    ? <button className='sticky-item__btn-start' type='button' onClick={() => goToPayment(filteredProductList?.[currentIndex + 1])}>시작하기</button>
                    : <button className='sticky-item__btn-soldout' type='button' onClick={alertSoldOut}>Sold out</button>
                  }
                  {(() => {
                    const isBest = Boolean(commonCodeConverter(commonCodes.PURPROD, 'note8', filteredProductList?.[currentIndex + 1]?.productCategory));
                    return isBest && <span className='sticky-item__tag-best'>Best</span>;
                  })()}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className='custom'>
            {detailList.map((item: CommonCode, index: number) => {
              const groupTitleRow = item.note6 === 'header';
              const colspan = groupTitleRow ? 3 : 1;

              return (
                <tr key={index} className={`${item.note6}-row`}>
                  <th colSpan={colspan} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.detailName as string) }}></th>
                  {!groupTitleRow && renderTableCells(item)}
                </tr>
              );
            })}
          </tbody>
        </table>
        <table id='pcProductTable' ref={ww === 'pc' ? productTable : null} className='purchase-showcase-table custom'>
          <thead ref={ww === 'pc' ? productTableHead : null} className='custom'>
            <tr>
              <th className='control-head'>
              </th>
              {pcTableData.map((item: any, index: number) => {
                return (
                  <th key={index}>
                    <div className='sticky-item'>
                      <strong className='sticky-item__title'>{item.detailName}</strong>
                      <em className='sticky-item__price'><span>{pricePerMonth(filteredProductList?.[index])}</span>원 / 4주</em>
                      {!filteredProductList?.[index]?.soldoutFlag
                        ? <button className='sticky-item__btn-start' type='button' onClick={() => goToPayment(filteredProductList?.[index])}>시작하기</button>
                        : <button className='sticky-item__btn-soldout' type='button' onClick={alertSoldOut}>Sold out</button>
                      }
                      {
                        item.note8 && <span className='sticky-item__tag-best'>Best</span>
                      }
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className='custom'>
            {detailList.map((item: CommonCode, index: number) => {
              const groupTitleRow = item.note6 === 'header';
              const colspan = groupTitleRow ? 5 : 1;

              return (
                <tr key={index} className={`${item.note6}-row`}>
                  <th colSpan={colspan} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.detailName as string) }}></th>
                  {!groupTitleRow && renderTableCellsForPc(item)}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {!viewFullDetail && <button className='btn-more' type='button' onClick={() => setViewFullDetail(true)}>더보기</button>}

      {/* Sold out 버튼 클릭 시 알림 */}
      {showSoldoutPopup && (
        <LayerPopup
          tit={'상품 준비중'}
          desc1={'해당 수강권은 솔드아웃으로 현재 상품 준비 중입니다. 지금 바로 시작 가능한 다른 상품을 둘러보세요.'}
          btnCnt={1}
          btnOkCmt={'확인'}
          btnOkFunction={() => {
            ScrollNone();
            setShowSoldoutPopup(false);
          }}
        />
      )}
    </section>
  );
};

export default PurchaseShowcaseB2CProductDetail;
