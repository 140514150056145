import React, { useEffect, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { numberFormat } from 'common/utilFn';

import MovingToClassPaymentButton from './MovingToClassPaymentButton';

import 'css/purchase/ClassQuotationSection.scss';

type Props = {
  props: PurchaseType.ClassQuotationSection;
};

const ClassQuotationSection = ({ props: quotation }: Props) => {
  const IMG_URL = process.env.REACT_APP_IMG_URL;
  const mileageTooltipRef = useRef<HTMLDivElement>(null);
  const mileageTooltipButtonRef = useRef<HTMLButtonElement>(null);
  const couponTooltipRef = useRef<HTMLDivElement>(null);
  const couponTooltipButtonRef = useRef<HTMLButtonElement>(null);
  const recurringBillingTooltipRef = useRef<HTMLDivElement>(null);
  const recurringBillingTooltipButtonRef = useRef<HTMLButtonElement>(null);
  const satisfyTooltipRef = useRef<HTMLDivElement>(null);
  const satisfyTooltipButtonRef = useRef<HTMLButtonElement>(null);
  const [showMileageTooltip, setShowMileageTooltip] = useState(false);
  const [showCouponTooltip, setShowCouponTooltip] = useState(false);
  const [showRecurringBillingTooltip, setShowRecurringBillingTooltip] = useState(false);
  const [showSatisfyTooltip, setShowSatisfyTooltip] = useState(false);
  const [showProductBenefitDetail, setShowProductBenefitDetail] = useState(false);
  const [showMemberBenefitDetail, setShowMemberBenefitDetail] = useState(true);

  // 적립금 툴팁 외부 클릭시 닫기
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (mileageTooltipRef.current && !mileageTooltipRef.current.contains(e.target as Node)
        && mileageTooltipButtonRef.current && !mileageTooltipButtonRef.current.contains(e.target as Node)
      ) {
        setShowMileageTooltip(false);
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => window.removeEventListener('mousedown', handleClick);
  }, [mileageTooltipRef, mileageTooltipButtonRef]);

  // 쿠폰 툴팁 외부 클릭시 닫기
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (couponTooltipRef.current && !couponTooltipRef.current.contains(e.target as Node)
        && couponTooltipButtonRef.current && !couponTooltipButtonRef.current.contains(e.target as Node)
      ) {
        setShowCouponTooltip(false);
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => window.removeEventListener('mousedown', handleClick);
  }, [couponTooltipRef]);

  // 정기 툴팁 외부 클릭시 닫기
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (recurringBillingTooltipRef.current && !recurringBillingTooltipRef.current.contains(e.target as Node)
        && recurringBillingTooltipButtonRef.current && !recurringBillingTooltipButtonRef.current.contains(e.target as Node)
      ) {
        setShowRecurringBillingTooltip(false);
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => window.removeEventListener('mousedown', handleClick);
  }, [recurringBillingTooltipRef]);

  // 만족보장 툴팁 외부 클릭시 닫기
  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (satisfyTooltipRef.current && !satisfyTooltipRef.current.contains(e.target as Node)
        && satisfyTooltipButtonRef.current && !satisfyTooltipButtonRef.current.contains(e.target as Node)
      ) {
        setShowSatisfyTooltip(false);
      }
    };
    window.addEventListener('mousedown', handleClick);
    return () => window.removeEventListener('mousedown', handleClick);
  }, [satisfyTooltipRef]);

  // state
  const {
    memberClass,
    selectProduct,
    selectProductBillFlag: isSelectedProductRecurringBilling,
    commonMsg,
    discountType,
    couponDiscount,
    couponValue,
    mileageInput,
    mMileage,
    memberName,
    satisfyFlag,
  } = quotation;

  // function
  const {
    processStep2,
    toggleStateValue,
  } = quotation;

  const totalAmount = (selectProduct.billFlag === isSelectedProductRecurringBilling ? selectProduct.realPrice : selectProduct.anotherProductPrice.realPrice) - couponValue - mileageInput;
  const fourWeekAmount = Math.floor(4 * totalAmount / selectProduct.productWeek);

  const numberMinusFormat = (value: number) => {
    return !value || value === 0 ? '0' : `-${numberFormat(value)}`;
  };

  type createButtonTextProps = {
    selectProduct: PurchaseType.SelectProduct,
    isSelectedProductRecurringBilling: boolean,
  };
  const createButtonText = ({ selectProduct, isSelectedProductRecurringBilling }: createButtonTextProps) => {
    const { realPrice = 0 } = selectProduct;

    const isPaymentAmountExists = realPrice > 0;
    const isRecurringBilling = isSelectedProductRecurringBilling;

    if (!isPaymentAmountExists) {
      return '수강신청하기';
    }

    if (isRecurringBilling) {
      return '정기 결제하기';
    }

    if (!isRecurringBilling) {
      return '결제하기';
    }

    return '결제하기';
  };

  const MovingToClassPaymentButtonText = createButtonText({ selectProduct, isSelectedProductRecurringBilling });

  type handleClickMovingToClassPaymentProps = {
    memberClass: string,
    selectProduct: PurchaseType.SelectProduct,
    isSelectedProductRecurringBilling: boolean,
  };
  const handleClickMovingToClassPayment = ({ memberClass, selectProduct, isSelectedProductRecurringBilling }: handleClickMovingToClassPaymentProps) => {
    const { billFlag = false, anotherProductPrice, priceSeq: originPriceSeq = 0 } = selectProduct;
    const { priceSeq: anotherPriceSeq = 0 } = anotherProductPrice || { priceSeq: 0 };

    const isRecurringBilling = billFlag;
    const recurringBillingProductPriceSeq = originPriceSeq;
    const nonRecurringBillingProductPriceSeq = anotherPriceSeq;

    const currentPriceSeq = (isRecurringBilling === isSelectedProductRecurringBilling) ? recurringBillingProductPriceSeq : nonRecurringBillingProductPriceSeq;

    processStep2(currentPriceSeq);
  };

  const moveToClassPayment = () => handleClickMovingToClassPayment({ memberClass, selectProduct, isSelectedProductRecurringBilling });

  return (
    <section className='quotation-section'>
      <div className={'quotation-section__wrapper'}>
        <div className='price-list'>
          <div className='price-list__title'>정가</div>
          <div className='price-list__price'>{numberFormat(selectProduct.price)}원</div>
        </div>
        <div className={`price-list icon ${showProductBenefitDetail ? 'open' : 'close'}`} onClick={() => setShowProductBenefitDetail(value => !value)}>
          <div className='price-list__title'>상품 할인 혜택 <span>{numberFormat(selectProduct.weekdiscountRate + selectProduct.mindiscountRate)}%</span></div>
          <div className='price-list__price'>{numberMinusFormat(selectProduct.weekdiscountPrice + selectProduct.mindiscountPrice)}원</div>
        </div>
        {showProductBenefitDetail &&
          <ul className='price-detail'>
            <li className='price-detail__item'>
              <span className='price-detail__item-title'>
                {selectProduct.productWeek}주 기본 {selectProduct.weekdiscountRate > 0 ? `${selectProduct.weekdiscountRate}% ` : ''}할인
              </span>
              <span className='price-detail__item-price'>
                {numberFormat(selectProduct.weekdiscountPrice)}원
              </span>
            </li>
            <li className='price-detail__item'>
              <span className='price-detail__item-title'>
                {selectProduct.productMinute}분 수업 {selectProduct.mindiscountRate === 0 ? '' : `${selectProduct.mindiscountRate}% `}할인
              </span>
              <span className='price-detail__item-price'>
                {numberFormat(selectProduct.mindiscountPrice)}원
              </span>
            </li>
          </ul>
        }
        <div className={'price-list icon'}>
          {selectProduct.anotherProductPrice &&
            <input
              type='checkbox'
              className={`price-list__checkbox price-list__checkbox${isSelectedProductRecurringBilling ? '--checked' : '--unchecked'} pointer`}
              checked={isSelectedProductRecurringBilling || false}
              onChange={selectProduct.anotherProductPrice ? () => toggleStateValue('selectProductBillFlag') : () => { }}
            />
          }
          <div className={'price-list__title pointer'}
            onClick={selectProduct.anotherProductPrice ? () => toggleStateValue('selectProductBillFlag') : () => { }}>
            정기 결제 등록 <span>{selectProduct.billFlag ? selectProduct.billdiscountRate : selectProduct.anotherProductPrice?.billdiscountRate}% 추가 할인</span>
          </div>
          <div className='price-list__price'>
            {isSelectedProductRecurringBilling ? numberMinusFormat(selectProduct.billFlag ? selectProduct.billdiscountPrice : selectProduct.anotherProductPrice?.billdiscountPrice) : '0'}원
          </div>
        </div>
        <div className={`price-list icon ${showMemberBenefitDetail ? 'open' : 'close'}`} onClick={() => setShowMemberBenefitDetail(value => !value)}>
          <div className='price-list__title'>{`${memberName}님만의 혜택`}</div>
          <div className='price-list__price'>{numberMinusFormat(mileageInput + couponValue)}원</div>
        </div>
        {showMemberBenefitDetail &&
          <ul className='price-detail'>
            <li className='price-detail__item'>
              <span className='price-detail__item-title'>
                보유 적립금 {numberFormat(mMileage)}원
              </span>
              <section className={'price-tooltip'}>
                <button
                  ref={mileageTooltipButtonRef}
                  type='button'
                  className='price-tooltip__button'
                  onClick={() => setShowMileageTooltip((state) => !state)}
                />
                {showMileageTooltip &&
                  <div className='price-tooltip__message' ref={mileageTooltipRef}>
                    <img className='price-tooltip__message-close-button' src={`${IMG_URL}/common/btn_close_common.svg`} alt='닫기' onClick={() => setShowMileageTooltip(false)} />
                    <div className='price-tooltip__message-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(commonMsg['PURPOP0002']) }} />
                  </div>
                }
              </section>
              <span className='price-detail__item-price'>
                {numberFormat(mileageInput)}원
              </span>
            </li>
            {couponDiscount > 0 &&
              <li className='price-detail__item'>
                <span className='price-detail__item-title'>
                  보유 할인 쿠폰 {numberFormat(couponDiscount)} {discountType === 'RATE' ? '%' : '원'}
                </span>
                <section className={'price-tooltip'}>
                  <button
                    ref={couponTooltipButtonRef}
                    type='button'
                    className='price-tooltip__button'
                    onClick={() => setShowCouponTooltip((state) => !state)}
                  />
                  {showCouponTooltip &&
                    <div className='price-tooltip__message' ref={couponTooltipRef}>
                      <img className='price-tooltip__message-close-button' src={`${IMG_URL}/common/btn_close_common.svg`} alt='닫기' onClick={() => setShowCouponTooltip(false)} />
                      <div className='price-tooltip__message-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(commonMsg['PURPOP0004']) }} />
                    </div>
                  }
                </section>
                <span className='price-detail__item-price'>
                  {numberFormat(couponValue)}원
                </span>
              </li>
            }
            {satisfyFlag &&
              <li className='price-detail__item'>
                <span className='price-detail__item-title'>
                  만족보장 이용권
                </span>
                <section className={'price-tooltip'}>
                  <button
                    ref={satisfyTooltipButtonRef}
                    type='button'
                    className='price-tooltip__buttonex'
                    onClick={() => setShowSatisfyTooltip((state) => !state)}
                  />
                  {showSatisfyTooltip &&
                    <div className='price-tooltip__message' ref={satisfyTooltipRef}>
                      <img className='price-tooltip__message-close-button' src={`${IMG_URL}/common/btn_close_common.svg`} alt='닫기' onClick={() => setShowSatisfyTooltip(false)} />
                      <div className='price-tooltip__message-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(commonMsg['PURPOP0006']) }} />
                    </div>
                  }
                </section>
                <span className='price-detail__item-notice'>
                  4주 이내 전액 환불
                </span>
              </li>
            }
          </ul>
        }
        <div className='price-summary'>
          <div className='price-summary__amount'>
            <span className='price-summary__amount-title'>
              {selectProduct.productWeek}{isSelectedProductRecurringBilling ? '주마다 결제금액' : '주 총금액'}
            </span>
            {isSelectedProductRecurringBilling &&
              <section className={'price-tooltip'}>
                <button
                  ref={recurringBillingTooltipButtonRef}
                  type='button'
                  className='price-tooltip__button'
                  onClick={() => setShowRecurringBillingTooltip((state) => !state)}
                />
                {showRecurringBillingTooltip &&
                  <div className='price-tooltip__message' ref={recurringBillingTooltipRef}>
                    <img className='price-tooltip__message-close-button' src={`${IMG_URL}/common/btn_close_common.svg`} alt='닫기' onClick={() => setShowRecurringBillingTooltip(false)} />
                    <div className='price-tooltip__message-text' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(commonMsg['PURPOP0001']) }} />
                  </div>
                }
              </section>
            }
            <div className='price-summary__amount-price'>
              {numberFormat(totalAmount)}
            </div>
            <div className='price-summary__amount-unit'>
              원
            </div>
          </div>
          {selectProduct.productWeek > 4 &&
            <div className='price-summary__description'>
              {isSelectedProductRecurringBilling &&
                <span className='price-summary__description-warning'>
                  할부 미지원
                </span>
              }
              <span className='price-summary__description-text'>
                4주 당
              </span>
              <span className='price-summary__description-price'>
                {numberFormat(fourWeekAmount)}
              </span>
              <span className='price-summary__description-unit'>
                원
              </span>
            </div>
          }
        </div>
        <MovingToClassPaymentButton
          isSelectedProductRecurringBilling={isSelectedProductRecurringBilling}
          MovingToClassPaymentButtonText={MovingToClassPaymentButtonText}
          moveToClassPayment={moveToClassPayment}
        />
      </div>
    </section>
  );
};

export default ClassQuotationSection;
