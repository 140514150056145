import { StateCreator } from 'zustand';

export interface LessonTimeType {
  scheduleDate: string;
  scheduleTime: string;
  availCount: number;
  leveltestCount: number | null;
  leftCount: number | null;
  scheduleTimestamp: number;
}

interface SlotType {
  scheduleDate: string;
  scheduleTime: string;
  availCount: number;
}

export interface RecommendTimeT {
  recommendTimes: LessonTimeType[];
  isSlotLoading: boolean;
  slotAll: any[];
  selectedTime: string;
  selectedDate: string;
  displayRequestDate: string;
  showSlotPopup: boolean;
  recommendTimeOne: any;
  recommendTimeTwo: any;
  recommendTimeThree: any;
  recommendTimeFour: any;
  isShowMoreTime: boolean;
  isSlotChoice: boolean;
  saveRecommendTime: (recommendTimes: LessonTimeType[]) => void;
  setIsSlotLoading: (bool: boolean) => void;
  setSlotAll: (slotData: any[]) => void;
  selectTime: (time:string) => void;
  selectDate: (date: string) => void;
  setDisplayRequestDate: (date: string) => void;
  setShowSlotPopup: (bool: boolean) => void;
  setRecommendTimeOne:(time: any) => void;
  setRecommendTimeTwo:(time: any) => void;
  setRecommendTimeThree:(time: any) => void;
  setRecommendTimeFour: (time: any) => void;
  setIsShowMoreTime: (bool: boolean) => void;
  setIsSlotChoice: (bool: boolean) => void;
}

const initialState = {
  recommendTimes: [],
  selectedIdx: null,
  isSlotLoading:true,
  slotAll: [],
  selectedTime: '',
  selectedDate: '',
  displayRequestDate: '',
  showSlotPopup: false,
  recommendTimeOne: {},
  recommendTimeTwo:  {},
  recommendTimeThree: {},
  recommendTimeFour: {},
  isShowMoreTime: true,
  isSlotChoice: false,
};

export const recommendTimeSlice: StateCreator<
  RecommendTimeT, 
  any[], 
  any[], 
  RecommendTimeT
> = (set) => ({
  ...initialState,
  saveRecommendTime: (recommendTimes:LessonTimeType[]) => {
    set((state) => ({ ...state, recommendTimes: recommendTimes }));
  },
  setShowSlotPopup: (isShow) => {
    set((state) => ({ ...state, showSlotPopup: isShow }));
  },
  setIsShowMoreTime: (bool) => {
    set((state) => ({ ...state, isShowMoreTime: bool }));
  },
  setIsSlotLoading: (bool:boolean) => {
    set((state) => ({ ...state,isSlotLoading: bool }));
  },
  setSlotAll: (slotData) => {
    set((state) => ({ ...state, slotAll: slotData }));
  },
  setDisplayRequestDate: (date:string) => {
    set((state) => ({ ...state, displayRequestDate: date }));
  },
  selectDate: (date:string) => {
    set((state) => ({ ...state, selectedDate: date }));
  },
  selectTime: (time:string) => {
    set((state) => ({ ...state, selectedTime: time }));
  },
  setRecommendTimeOne: (time) => {
    set((state) => ({ ...state, recommendTimeOne: time }));
  },
  setRecommendTimeTwo: (time) => {
    set((state) => ({ ...state, recommendTimeTwo: time }));
  },
  setRecommendTimeThree: (time) => {
    set((state) => ({ ...state, recommendTimeThree: time }));
  },
  setRecommendTimeFour: (time) => {
    set((state) => ({ ...state, recommendTimeFour: time }));
  },
  setIsSlotChoice: (bool) => {
    set((state) => ({ ...state, isSlotChoice: bool }));
  },
});
export default recommendTimeSlice;
