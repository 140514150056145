import { useResizeDetector } from 'react-resize-detector';
import { useEffect, useRef } from 'react';

export const useOptimizedResize = (onResize: (height: number) => void) => {
  const frameRef = useRef<number>();
  const previousHeight = useRef<number>();

  const { height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 100,
    skipOnMount: true,
    handleHeight: true,
    handleWidth: false,
    onResize: (width, height) => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }

      if (height !== undefined && height !== previousHeight.current) {
        frameRef.current = requestAnimationFrame(() => {
          previousHeight.current = height;
          onResize(height);
        });
      }
    }
  });

  useEffect(() => {
    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, []);

  return { ref };
};
