import React, {Component} from 'react';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';

import LayerPopup from '../component/LayerPopup';
import * as utilFn from '../common/utilFn';
import { mobileChk } from '../common/utilFn';

import '../css/join.scss';
import {httpDefaultClient} from 'configs/api';

export default class Findemail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page : 'p1',
      nameError: '',
      cellPhone : '',
      phonenumError: '',
      authInput : '',
      authBtn: false,
      authError:false,
      authTimeError:false,
      authCheckFlag:false, // 인증번호 확인 (맞는지 여부)
      authLayerPopup: '', // 인증번호 관련 오류 팝업 메시지
      authStart: '',
      authEnd: '',
      disabled  : false,

      countMin :'10',
      countSec : '00',
      time: 300,
      active : '',
      isToggleOn: true,
      name:'',

      email : '', //가입 이메일
      signType: '', // 가입 타입 (EMAIL,NAVER,APPLE,KAKAO..)
      socialEmails:[], // 연결된 계정들

      joinNext : false,
      fstChk: 'on',

      DormantMem:false,
      layerPopupMessage:'',

    };
  };

  componentWillMount() {
    this.getCommonMsgList();
  }

  checkAuthNumber = (eventType, authInputValue) => {
    // 인증번호랑 입력값 비교
    const params = {
      cellPhone: this.state.cellPhone.replace(/-/gi,''),
      authNumber: authInputValue,
      authType:'FIND_EMAIL'
    };
    const onSuccess=() => {
      this.setState({
        authError: '',
        authInfoView: false,
        authCheckFlag: true,
      });
      if (eventType==='change') {
        clearInterval(this.interval);
        this.defaultDate(authInputValue);
      }
    };
    const onFail = (commonMessage) => {
      this.setState({
        authError: this.state.commonMsg[commonMessage],
        authInfoView: true,
        authCheckFlag: false,
      });
    };
    utilFn.checkAuthNumber(params, onSuccess, onFail);
  };

  onChangeAuth = (e) => {
    let v = e.target.value;
    v = v.replace(/[*#+,;]/gi, '');
    if (v.length > 6) {
      v = v.slice(0, 6);
    }
    this.setState({
      authInput: v,
      authInfoView:true,
      authError: '',
      authCheckFlag: false,
    });

    clearInterval(this.interval);
    this.reqAuthCount();

    if (v.length === 6) {
      this.checkAuthNumber('change', v);
    } else {
      this.setState({
        authError: true,
        authInfoView:true,
      });
    }
  };

  onBlurAuth = (e) => {
    if (e.target.readOnly){
      return null;
    }
    let v = this.state.authInput;

    if (v.length === 6) {
      this.checkAuthNumber('blur', v);
    } else if (v.length === 0){
      this.setState({
        authError: '필수 정보입니다.',
        authInfoView: true,
      });
    } else {
      this.setState({
        authError: '인증번호를 다시 확인해주세요.',
        authInfoView: true,
      });
    }
    this.defaultDate(e.target.value);
  };

  reqAuth = () => {
    if (!this.state.authBtn){
      return null;
    }
    this.setState({ authCheckFlag: false});
    if (this.state.name === '') {
      this.setState({nameError: '필수 정보입니다.'});
      return null;
    } else if (this.state.nameError !== ''){
      this.setState({nameError: '한글 2~6자의 이름을 입력하고 재인증을 받아주세요.'});
      return null;
    } else {

    }

    clearInterval(this.interval);
    this.setState({
      disabled  : false,
      authTimeError:false,
      countMin :'10',
      countSec: '00',
      time: 600,
      authInfoView:false,
      authError: '',
      authInput: '',
      authState: false
    });

    httpDefaultClient.post('/sign/findId', {}, {params:{
      'memberCellphone': this.state.cellPhone.replace(/-/g, ''),
      'memberName': encodeURI(this.state.name),
    }})
      .then(res => {
        const json = res.data;
        if (json.message !== 'SIGN000002') {
          this.reqAuthS();
        } else {
          this.eMessage('alert', json.message);
        }
      })
      .catch((error) => {
        console.error('error: ', error);
        this.openLayerPopup('서버 통신에 실패 하였습니다.');
      });
  };

  reqAuthS = () => {
    // 인증번호 발송
    this.setState({ authCheckFlag: false});
    const params={
      cellPhone: this.state.cellPhone.replace(/-/gi,''),
      authType: 'FIND_EMAIL'
    };
    const onSuccess =() => {
      this.setState({
        authInfoView: true,
        authState:true,
        authStart: moment() + 0,
        authEnd: moment() + 600000
      });
      this.reqAuthCount();
      this.defaultDate();
    };
    const onFail = (commonMessage) => {
      this.setState({
        authLayerPopup: this.state.commonMsg[commonMessage],
        authCheckFlag: false,
      });
      this.defaultDate();
    };
    utilFn.sendAuthNumber(params, onSuccess, onFail);
  };

  reqAuthCount = () => {
    this.interval = setInterval(() => {
      this.timerHandler();
    }, 1000);
  };

  timerHandler = () => {
    const now = moment();

    if (now.diff(this.state.authStart) > 0 && now.diff(this.state.authEnd) < 0) {
      this.timesSetter();
    } else {
      this.setState({
        countSec: '00', //time - (countMin * 60),
        countMin :'00',
        disabled: true, // 시간이 지낫을때 인풋창 막기
        authTimeError:true,
        // authCode: '',
        authInput: '',
        authStart: 0,
        authEnd: 0,
        authError: '유효시간이 지났습니다. 다시 인증을 진행해주세요.'
      });
      clearInterval(this.interval);
    }
  };

  timesSetter = () => {
    const now = moment();
    let s;
    let m;
    let t = now - this.state.authStart;
    t = 600 - Math.floor(t / 1000);
    m = Math.floor(t / 60);
    s = t - (m * 60);
    m === 0 ? m = '00' : m = '' + m;
    String(m).length === 1 ? m = '0' + m : m = '' + m;
    s === 0 ? s = '00' : s = '' + s;
    String(s).length === 1 ? s = '0' + s : s = '' + s;

    this.setState({
      countSec: s,
      countMin : m,
    });
  };

  defaultDate = (e) => {
    const t = this.state;
    // 필수 값에 따른 버튼 활성화
    if (t.authInput!=='' && t.authError==='' && t.authInput.length===6 && t.authCheckFlag){
      this.setState({joinNext : true});
    } else {
      this.setState({joinNext : false});
    }
  };

  onChangePhone = (e) => {
    this.setState({authCheckFlag:false});
    const t = e.target.value;
    clearInterval(this.interval);
    if (t.length <= e.target.maxLength) {
      const v = this.formChk('cellphone', t);

      this.setState({
        authBtn: v[0],
        cellPhone: v[1],
        cellPhoneError: '',
        authInfoView:false,
        authError: '',
        authInput: '',
        authState: false
      });

      if (this.state.authInput!==''&&this.state.authError===''&&this.state.authInput.length===6){
        this.setState({authInput: ''});
      }
      this.defaultDate(v[1]);
    }
  };

  onBlurPhone = (e) => {
    let v = '';
    if (e.target.value === ''){
      v =  '필수 정보입니다.';
    } else if (!this.state.authBtn){
      v = '휴대전화번호 형식이 올바르지 않습니다. 정확하게 다시 입력해주세요.';
    }
    this.setState({
      cellPhoneError: v,
    });
  };

  formChk = (t, n) => {
    const phoneRex = /^\d{3}-\d{3,4}-\d{4}$/;
    let v = n;

    v = v.replace( /-/gi , '');

    if (v.length >= 2  &&  v.length <= 5 ){
      const nn =  /(\d{2})(\d{1,3})/;
      v.replace(nn, function(str, p1, p2){
        v = p1 + '-' + p2;
      });
    } else if (v.length >= 6 && v.length <= 9){
      const nn =  /(\d{2})(\d{1,3})(\d{1,4})/;
      v.replace(nn, function(str, p1, p2, p3){
        v = p1 + '-' + p2 + '-' + p3;
      });
    } else if (v.length === 10){
      // 일반전화이면서 서울일때
      if (v.substring(1, 2) === '2') {
        const nn =  /(\d{2})(\d{1,4})(\d{2,4})/;
        v.replace(nn, function(str, p1, p2, p3){
          v = p1 + '-' + p2 + '-' + p3;
        });
      } else {
        const nn =  /(\d{3})(\d{1,3})(\d{2,4})/;
        v.replace(nn, function(str, p1, p2, p3){
          v = p1 + '-' + p2 + '-' + p3;
        });
      }
    } else if (v.length > 10){
      const nn =  /(\d{3})(\d{1,4})(\d{2,4})/;
      v.replace(nn, function(str, p1, p2, p3){
        v = p1 + '-' + p2 + '-' + p3;
      });
    }
    const p = phoneRex.test(v);
    return [p, v];
  };

  onChangeName = (e) => {
    this.setState({authCheckFlag:false});
    if (e.target.value.length <= e.target.maxLength){
      const t = e.target.value.replace(/[a-z0-9]|[\[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"\'\\]|[\s*]/gi, '');
      this.setState({
        name: t,
        nameError: '',
        authInfoView:false,
        authError: '',
        authInput: '',
        authState: false
      });
      if (this.state.authInput!==''&&this.state.authError===''&&this.state.authInput.length===6){
        this.setState({authInput: ''});
        this.openLayerPopup('정보가 변경되어 휴대전화번호 재인증이 필요합니다');
      }
      this.defaultDate(t);
    }
  };

  onBlurName = (e) => {
    const t = e.target.value;
    const nameRex = /^[가-힣]+$/;
    const a = nameRex.test(t);
    let v;

    if (t === '') {
      v = '필수 정보입니다.';
    } else if (t.length < 2 || t.length > 6){
      v = '이름은 한글 2~6자까지 사용 가능합니다.';
    } else {
      a ? v = '' : v = '이름은 한글 2~6자까지 사용 가능합니다.';
    }
    this.setState({nameError: v});
    this.state.fstChk && this.setState({fstChk: ''});
  };

  findPage = () => {
    const t = this.state;

    const c4 = !t.name ? '필수 정보입니다.' : t.nameError;
    const c6 = !t.authInput ? '필수 정보입니다.' : t.authError;

    if (t.name === '' || t.authInput === ''){
      return null;
    }

    this.setState({
      nameError: c4,
      authError: c6
    });

    if (c4 !== '' || c6 !== ''){
      return null;
    }

    clearInterval(this.interval);
    httpDefaultClient.post('/sign/findId', {},{params:{
      'memberCellphone': this.state.cellPhone.replace(/-/g, ''),
      'memberName': encodeURI(this.state.name),
    }})
      .then(
        res => {
          const json = res.data;
          if (json.message === 'SIGN000002') {
            // SIGN000002 입력하신 정보와 일치하는 회원정보가 없습니다.
            this.eMessage('alert', json.message);
            return null;
          } else if (json.message === 'SIGN000008'|| json.message === 'SIGN000010') {
            // SIGN000008 아이디를 찾았습니다. => 일치하는 회원정보 존재
            // SIGN000010 장기간 이용 내역이 없어 관련 법령에 따라 휴면 상태입니다. 로그인 후, 휴면 해제에 동의해주시면 휴면이 해제됩니다.
            const socials = (json.message === 'SIGN000008')?json.data.memberSocials:json.data.backupMemberSocials;
            this.setState({
              page: 'p2',
              email: json.data.memberId,
              signType: json.data.signType,
              socialPwFlag: json.data.passwordStatus, // 비밀번호가 설정된 계정인지 여부
              socialEmails: socials ? socials : [],
              emailKakao: socials && socials.filter(f => f.socialType === 'KAKAO'),
              emailNaver: socials && socials.filter(f => f.socialType === 'NAVER'),
              emailApple: socials && socials.filter(f => f.socialType === 'APPLE'),
            });
            // 소셜만 있을 경우 소셜들을 메인 이메일로 뿌려줌
            if (!json.data.passwordStatus){
              this.setState({
                mainEmails: [
                  this.state.emailKakao && this.state.emailKakao[0],
                  this.state.emailNaver && this.state.emailNaver[0],
                  this.state.emailApple && this.state.emailApple[0]
                ],
              });
            }
            if (json.message === 'SIGN000010') {
              this.setState({
                fstChk: 'on',
                DormantMem: true
              });
            }
            localStorage.setItem('email', json.data.memberId);
            this.props.history.push('/Findemail/p2');
          } else {
            this.eMessage('alert', json.message);
            return null;
          }
        }
      )
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  goLogin = () => {
    this.props.history.replace('/Login');
  };
  goResetPW = () => {
    this.props.history.replace('/ResetPW');
  };

  eMessage = (t, v) => {
    axios({
      method:'GET',
      url:process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (response.data.result){
          if (t === 'emailError'){
            this.setState({emailError: response.data.message});
          } else {
            this.setState({layerPopupMessage: response.data.message});
            return null;
          }
        } else {
          this.setState({layerPopupMessage: v});
        }
      })
      .catch( (error) => {
        console.error('error: ', error);
      });
  };

  getCommonMsgList = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getList',
      params: {
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        this.setState({
          commonMsg : response.data,
        });
      })
      .catch( (error) => {
        console.error('getCommonMsgList: ', error);
      });
  };

  onClickfChk = () => {
    this.setState({fstChk: ''});
  };

  openLayerPopup=(message) => {
    this.setState({layerPopupMessage: message});
  };
  
  render() {
    return (
      <div className={'idPw ' + (this.props.match.params.page ? this.props.match.params.page : 'p1')}>
        <Link className={'logoLink'} to='/'><img src={process.env.REACT_APP_IMG_URL + '/course/freelesson_bi.svg'} alt={'view off'} /></Link>
        <div className='step1'>
          <div className='contentBox'>
            <div className='mainTxt'>가입한 이메일/ID를<br/>알려드릴게요!</div>
            <div className='inputTitle'>이름</div>
            <input tabIndex={1} autoComplete='name' className={'name ' + (this.state.nameError && 'error') + (this.state.fstChk)} name='name' placeholder='김유폰' maxLength={7} value={this.state.name} onChange={this.onChangeName} onClick={this.onClickfChk} onBlur={this.onBlurName} />
            {this.state.nameError && <div className='error errorMsg'>{this.state.nameError}</div>}
            <div className='inputTitle'>휴대전화번호</div>
            <div className='authNumWrap'>
              <div className='authNumBox'>
                <input tabIndex={2} className={'cellPhone ' + (this.state.cellPhoneError && 'error')} name='cellPhone' maxLength={13} type='tel' placeholder='010-1234-5678' value={this.state.cellPhone} onChange={this.onChangePhone} onClick={this.onClickfChk} onBlur={this.onBlurPhone}/>
                <div className={'btn g inputBtn authBtn '+ (!this.state.authBtn && 'disable')} onClick={this.reqAuth}>인증번호 받기</div>
              </div>
              <input className={'authInput ' + (this.state.authError && ' error ') + (!this.state.authState && ' dis ')} type='number' min='0' max='999999' placeholder='인증번호를 입력하세요' value={this.state.authInput} disabled={this.state.disabled} onChange={this.onChangeAuth}  onClick={this.onClickfChk} onBlur={this.onBlurAuth} readOnly={!this.state.authState} />
              {this.state.authInfoView && <span className='timer'>유효시간 {this.state.countMin}분 {this.state.countSec}초</span>}
              {this.state.cellPhoneError && <div className='error errorMsg'>{this.state.cellPhoneError}</div>}
            </div>
            {this.state.authCheckFlag &&
							<div className={'completeTxt adj'}><img src={process.env.REACT_APP_IMG_URL + '/join/ico_check.svg'} alt={'check'} /> 인증완료</div>
            }
            {this.state.authError && <div className='error errorMsg'>{this.state.authError}</div>}
            <div className={'authInfo ' + (this.state.authInfoView ? 'view' : 'hide')}>
              <div className={'title'}>인증번호가 오지 않나요?</div>
              <div className={'list'}>1. 휴대전화번호를 다시 확인해주세요.</div>
              <div className={'list'}>2. 1599 번호가 스팸 문자로 등록되어 있으면 해제해주세요.</div>
              <div className={'list'}>3. 가상전화번호는 인증번호를 받을 수 없습니다.</div>
            </div>
            <button className={'btn find fixBottom ' + (!this.state.joinNext && 'disable')} onClick={this.findPage}>이메일/ID 찾기</button>
          </div>
        </div>
        <div className='step2'>
          <div className='contentBox'>
            {this.state.name.length < 5 || mobileChk() === 'MOBILE' ? (
              <div className='mainTxt email'>{this.state.name}님의 이메일/ID를<br/>알려드릴게요.</div>
            ) : (
              <div className='mainTxt email'>{this.state.name}님의 이메일/ID를 알려드릴게요.</div>
            )}
            {this.state.socialPwFlag?
              <div className={'mainEmail EMAIL'}>{this.state.email}</div>
              : this.state.mainEmails&&
							this.state.mainEmails.map((item,index) => {
							  return (item) &&<div className={`mainEmail ${item.socialType}`} key={index}>{item.socialEmail}</div>;
							})
            }
            { this.state.socialEmails.length > (this.state.socialPwFlag? 0: 1) && (!this.state.mainEmails) &&
						<div className='subEmailBox'>
						  <p>*위와 연결된 계정</p>
						  {this.state.emailKakao.length > 0 &&<div className={'subEmail KAKAO'}>{this.state.emailKakao[0].socialEmail}</div>}
						  {this.state.emailNaver.length > 0 &&<div className={'subEmail NAVER'}>{this.state.emailNaver[0].socialEmail}</div>}
						  {this.state.emailApple.length > 0 &&<div className={'subEmail APPLE'}>{this.state.emailApple[0].socialEmail}</div>}
						</div>
            }
            {this.state.DormantMem &&
						<div className={'info'}>장기간 이용 내역이 없어 관련 법령에 따라 <span className={'red'}>휴면 상태</span>입니다.<br/>로그인 후, 휴면 해제에 동의해주시면 휴면이 해제됩니다.</div>}

            <div className={'fixBottom'}>
              <button className='btn border0' onClick={this.goLogin}>로그인 하기</button>
              {this.state.socialPwFlag && <button className='btn subBtn' onClick={this.goResetPW}>비밀번호 재설정하기</button>}
            </div>
          </div>
        </div>
        {this.state.layerPopupMessage&&
				<LayerPopup
				  desc1={this.state.layerPopupMessage}
				  btnCnt={1}
				  btnOkCmt={'확인'}
				  btnOkFunction={() => {this.setState({layerPopupMessage : ''});}}
				/>}
        {this.state.authLayerPopup &&
				<LayerPopup
				  tit={this.state.authLayerPopup}
				  btnCnt={1}
				  btnOkCmt={'확인'}
				  btnOkFunction={() => this.setState({authLayerPopup:''})}
				/>
        }
      </div>
    );
  }
}
