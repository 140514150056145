import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import reserveTrialSlice, { ReserveTrialT } from './reserveTrialSlice';
import trialSlotSlice, { LessonTimeType, RecommendTimeT } from './trialSlotSlice';
import trialInputSlice, { TrialInputSliceT } from './trialInputSlice';

import {httpDefaultClient, isAxiosError} from 'configs/api';
import { ScrollNone } from 'common/utilFn';
import * as utilFn from 'common/utilFn';
import { freeLessonDataForGA4 } from 'services/googleAnalytics/googleAnalytics';
import referrerStore from 'store/referrer/referrerStore';

export const reserveTrialStore = create<
  ReserveTrialT & RecommendTimeT & TrialInputSliceT
>()(devtools((...all) => ({
  ...reserveTrialSlice(...all),
  ...trialSlotSlice(...all),
  ...trialInputSlice(...all),
}), { store: 'reserveFreeLesson' }));

export default reserveTrialStore;

export const isChangeMode = () => {
  return window.location.pathname.toLowerCase().includes('/trial/change'.toLowerCase());
};

export async function getServerDay() {
  const setServerDay = reserveTrialStore.getState().setServerDay;
  try {
    const { data } = await httpDefaultClient.get('/trial/day');
    setServerDay(data);
  } catch (error) {
    console.error('/trial/day' + error);
  }
}

export async function getCommonCodes(codes: string) {
  const setLessonModeLMSData = reserveTrialStore.getState().setLessonModeLMSData;
  const setLevelCodeLMSData = reserveTrialStore.getState().setLevelCodeLMSData;
  try {
    const { data } = await httpDefaultClient.get('/commonCode/multi/' + codes);
    setLessonModeLMSData(data?.LESSONMODE);
    setLevelCodeLMSData(data?.TRIALLEVEL);
  } catch (error) {
    console.error('/commonCode/multi' + error);
  }
}

export const compareTimestamp = async () => {
  const lessonTimestamp = reserveTrialStore.getState().lessonTimestamp;
  const isClassStartingInTenMinutes = utilFn.isTimestampWithinRange(lessonTimestamp, 10);
  if (isClassStartingInTenMinutes) {
    alertMessage(await utilFn.getCommonMessage('TRIAL00008'));
  }
  return isClassStartingInTenMinutes;
};

export const onClickRequestDateTime = async(date: any, time: any) => {
  const selectDate = reserveTrialStore.getState().selectDate;
  const selectTime = reserveTrialStore.getState().selectTime;
  const setDisplayRequestDate = reserveTrialStore.getState().setDisplayRequestDate;
  const setIsSlotChoice = reserveTrialStore.getState().setIsSlotChoice;
  const setShowSlotPopup = reserveTrialStore.getState().setShowSlotPopup;
  const serverDay = reserveTrialStore.getState().serverDay;
  const { today, tomorrow } = serverDay;
  if (isChangeMode()) {
    const isClassStartingInTenMinutes = await compareTimestamp();
    if (isClassStartingInTenMinutes) {
      return;
    }
  }

  setIsSlotChoice(true);
  selectDate(date);
  selectTime(time);
  validateInput();
  if (date === today) {
    setDisplayRequestDate(`오늘 <span>${time}</span>`);
  } else if (date === tomorrow) {
    setDisplayRequestDate(`내일 <span>${time}</span>`);
  } else {
    const dateArr = date.split('-');
    setDisplayRequestDate(`${parseInt(dateArr[1]) + '월 ' + parseInt(dateArr[2]) + '일'} <span>${time}</span>`);
  }

  setTimeout(() => {
    ScrollNone(false);
    setShowSlotPopup(false);
  }, 500);
};

export function parserShowRecommendTime(recommendTime: LessonTimeType) {
  const serverDay = reserveTrialStore.getState().serverDay;
  const { today, tomorrow } = serverDay;

  const { scheduleDate, scheduleTime } = recommendTime;
  const reommendDate = scheduleDate === today ? '오늘' : scheduleDate === tomorrow ? '내일' : `${parseInt(scheduleDate.split('-')[1])}월 ${parseInt(scheduleDate.split('-')[2])}일`;
  const reommendTime = scheduleTime;
  return `${reommendDate}<span>${reommendTime}</span>`;
}


export const clearRequestDateTime = () => {
  const selectDate = reserveTrialStore.getState().selectDate;
  const selectTime = reserveTrialStore.getState().selectTime;
  const setDisplayRequestDate = reserveTrialStore.getState().setDisplayRequestDate;

  selectDate('');
  selectTime('');
  setDisplayRequestDate('');
};

const latestRequestTime = 0;
export async function getTrialSlots() {
  const saveRecommendTime = reserveTrialStore.getState().saveRecommendTime;
  const setIsSlotLoading = reserveTrialStore.getState().setIsSlotLoading;
  const lessonMode = reserveTrialStore.getState().lessonMode;
  const lessonMinute = reserveTrialStore.getState().lessonMinute;
  const setSlotAll = reserveTrialStore.getState().setSlotAll;
  const setRecommendTimeOne = reserveTrialStore.getState().setRecommendTimeOne;
  const setRecommendTimeTwo = reserveTrialStore.getState().setRecommendTimeTwo;
  const setRecommendTimeThree = reserveTrialStore.getState().setRecommendTimeThree;
  const setRecommendTimeFour = reserveTrialStore.getState().setRecommendTimeFour;
  setIsSlotLoading(true);
  if (!lessonMinute) return;
  if (!lessonMode) return;

  const { data: trialBlockData } = await httpDefaultClient.get('/commonCode/getList/TRIALBLOCK');
  const recommendAvailHours = trialBlockData.filter((item: any) => item.note2 === 'O').map((item: any) => item.detailCode);

  try {
    const { data } = await httpDefaultClient.post('/trial/slots', {
      lessonMinute,
      lessonMode,
    });

    const dataArr: any = [];
    let vDate: any;
    let da = -1;
    if (data.length > 0) {
      data.forEach((v: any, i: number) => {
        if (vDate !== data[i].scheduleDate) {
          da += 1;
          dataArr[da] = [];
          vDate = data[i].scheduleDate;
        }
        dataArr[da].push(data[i]);
      });

      const availableSlots = data
        .filter((timeItem: any) => timeItem.availCount > 0)
        .filter((timeItem: any) => recommendAvailHours.includes(timeItem.scheduleTime.split(':')[0]))
        .slice(0, 4);
      saveRecommendTime(availableSlots);
      setSlotAll(dataArr);
      setRecommendTimeOne(availableSlots[0]);
      setRecommendTimeTwo(availableSlots[1]);
      setRecommendTimeThree(availableSlots[2]);
      setRecommendTimeFour(availableSlots[3]);
    } else {
      setSlotAll([]);
    }
    setIsSlotLoading(false);
  } catch (error) {
    setIsSlotLoading(false);
    console.error('/trial/slots' + error);
  }
}

export const changeTrial = async () => {
  const selectedDate = reserveTrialStore.getState().selectedDate;
  const selectedTime = reserveTrialStore.getState().selectedTime;
  const lessonMode = reserveTrialStore.getState().lessonMode;
  const levelCode = reserveTrialStore.getState().levelCode;
  const lessonMinute = reserveTrialStore.getState().lessonMinute;
  const setIsLoading = reserveTrialStore.getState().setIsLoading;
  const setRedirectPath = reserveTrialStore.getState().setRedirectPath;
  const selectedTopic = reserveTrialStore.getState().selectedTopic;
  const trialSeq = reserveTrialStore.getState().trialSeq;
  setIsLoading(true);
  try {
    const { data } = await httpDefaultClient.post('/trial/change', {
      trialSeq,
      lessonMode,
      levelCode,
      lessonMinute,
      lessonDate: selectedDate,
      lessonTime: selectedTime,
    });
    setIsLoading(false);

    if (data.result) {
      setRedirectPath('/trialChangeComplete/' + data.data.timechangeUrl); 
      return;
    }

    if (data.messageCode === 'TRIAL00005') { // 선택하신 시간이 마감되었습니다. 시간을 다시 선택해주세요
      alertMessage(data.message);
      return;
    }

    alertMessage(data.message, '/');
  } catch (error) {
    console.error('/trial/change' + error);
    if (isAxiosError(error)) {
      if (error.response?.data?.message) {
        alertMessage(error.response.data?.message);
      }
    }
    setIsLoading(false);
  }
};

export const memberReserveTrial = async () => {
  const selectedDate = reserveTrialStore.getState().selectedDate;
  const selectedTime = reserveTrialStore.getState().selectedTime;
  const lessonMode = reserveTrialStore.getState().lessonMode;
  const levelCode = reserveTrialStore.getState().levelCode;
  const lessonMinute = reserveTrialStore.getState().lessonMinute;
  const setIsLoading = reserveTrialStore.getState().setIsLoading;
  const setRedirectPath = reserveTrialStore.getState().setRedirectPath;
  const juniorFlag = reserveTrialStore.getState().juniorFlag;
  const selectedTopic = reserveTrialStore.getState().selectedTopic;
  const recommendInfo = reserveTrialStore.getState().recommendInfo;
  const fixedMode = reserveTrialStore.getState().fixedMode;
  const mode = fixedMode ? fixedMode === 'JUNIOR' ? 'VIDEO' : fixedMode : lessonMode;
  
  setIsLoading(true);
  
  try {
    const { data } = await httpDefaultClient.post('/trial/member/create', {
      deviceCode: utilFn.mobileChk(),
      juniorFlag,
      lessonMode:mode,
      levelCode,
      courseSeq: selectedTopic.courseSeq,
      lessonMinute,
      lessonDate: selectedDate,
      lessonTime: selectedTime,
      recommenderSeq:recommendInfo?.memberSeq || null,
      firstcookieCode: utilFn.getMarketingCodeValue('fmc'),
      lastcookieCode: utilFn.getMarketingCodeValue('lmc'),
      mktparamCode: utilFn.getMarketingCodeValue('ref'),
      applyPath: window.location.pathname,
    });
    if (data.result) {
      setRedirectPath('/trialComplete/' + data.data.timechangeUrl); 
      utilFn.sendFacebook('무료수업 신청 완료', 0, 'Lead');
      freeLessonDataForGA4();
      return;
    }

    if (data.messageCode === 'TRIAL00005') { // 선택하신 시간이 마감되었습니다. 시간을 다시 선택해주세요
      alertMessage(data.message);
      return;
    }

    alertMessage(data.message, '/');
  } catch (error) {
    console.error('/trial/member/create' + error);
    if (isAxiosError(error)) {
      if (error.response?.data?.message) {
        alertMessage(error.response.data?.message);
      }
    }
    setIsLoading(false);
  }
};

export const nonMemberReserveTrial = async () => {
  const selectedDate = reserveTrialStore.getState().selectedDate;
  const selectedTime = reserveTrialStore.getState().selectedTime;
  const lessonMode = reserveTrialStore.getState().lessonMode;
  const levelCode = reserveTrialStore.getState().levelCode;
  const lessonMinute = reserveTrialStore.getState().lessonMinute;
  const setIsLoading = reserveTrialStore.getState().setIsLoading;
  const setRedirectPath = reserveTrialStore.getState().setRedirectPath;
  const juniorFlag = reserveTrialStore.getState().juniorFlag;
  const selectedTopic = reserveTrialStore.getState().selectedTopic;
  const inputState = reserveTrialStore.getState().inputState;
  const checkState = reserveTrialStore.getState().checkState;
  const recommendInfo = reserveTrialStore.getState().recommendInfo;
  const fixedMode = reserveTrialStore.getState().fixedMode;
  const mode = fixedMode ? fixedMode === 'JUNIOR' ? 'VIDEO' : fixedMode : lessonMode;
  
  setIsLoading(true);
  try {
    const { data } = await httpDefaultClient.post('/trial/nonMember/create', {
      deviceCode: utilFn.mobileChk(),
      juniorFlag,
      lessonMode:mode,
      levelCode,
      courseSeq: selectedTopic.courseSeq,
      lessonMinute,
      lessonDate: selectedDate,
      lessonTime: selectedTime,
      recommenderSeq:recommendInfo?.memberSeq || null,
      recommendCode:recommendInfo?.recommendCode || '',
      memberName: inputState.name,
      cellPhone: inputState.cellPhone.replace(/-/g, ''),
      mktsmsFlag: checkState[1],
      firstcookieCode: utilFn.getMarketingCodeValue('fmc'),
      lastcookieCode: utilFn.getMarketingCodeValue('lmc'),
      mktparamCode: utilFn.getMarketingCodeValue('ref'),
      applyPath: window.location.pathname,
    });
    setIsLoading(false);

    if (data.result) {
      setRedirectPath('/trialComplete/' + data.data.timechangeUrl); 
      utilFn.sendFacebook('무료수업 신청 완료', 0, 'Lead');
      freeLessonDataForGA4();
      return;
    }

    if (data.messageCode === 'TRIAL00005') { // 선택하신 시간이 마감되었습니다. 시간을 다시 선택해주세요
      alertMessage(data.message);
      return;
    }

    alertMessage(data.message, '/');
  } catch (error) {
    console.error('/trial/nonmember/create' + error);
    if (isAxiosError(error)) {
      if (error.response?.data?.message) {
        alertMessage(error.response.data?.message);
      }
    }
    setIsLoading(false);
  }
};

export const eMessage = async (target: any, messageCode: string) => {
  try {
    const { data } = await httpDefaultClient.get('/commonMessage/getCommonMessage', {
      params: {
        'messageCode': messageCode,
        'languageCode': 'ko'
      }
    });

    if (data.result) {
      if (target === 'chkBoxLayerPopup') {
        alertMessage(data.message, '');
      } else {
        alert(data.message);
      }
    } else {
      alert(messageCode);
    }

  } catch (error) {
    console.error('commonMessage/getCommonMessage' + error);
  }
};

export const alertMessage = (alertText: string, moveUrl?: string) => {
  const alertInfo = reserveTrialStore.getState().alertInfo;
  const setAlertInfo = reserveTrialStore.getState().setAlertInfo;
  const setIsLoading = reserveTrialStore.getState().setIsLoading;

  setAlertInfo({
    ...alertInfo,
    alertText,
    alertFunction: moveUrl ? () => { window.location.href = process.env.REACT_APP_ENV_URL + moveUrl; } : () => { setAlertInfo({ ...alertInfo, alertText: '' }); setIsLoading(false); },
  });
  window.scrollTo(0, document.body.scrollHeight);
};

export const runForMember = async () => {
  const setMemberFlag = reserveTrialStore.getState().setMemberFlag;
  setMemberFlag(true);
  await checkBeforeCreate();
  await getDefaultTrial();
  await getLessonBook();
  await getRecommender();
  await getMemberInfo();
};

export const runForNonMember = () => {
  const setMemberFlag = reserveTrialStore.getState().setMemberFlag;
  const setReplacePath = reserveTrialStore.getState().setReplacePath;
  const lastcookieCode = utilFn.getMarketingCodeValue('lmc') || '';
  const allowNonMember = lastcookieCode.indexOf('SNSfb_') > -1 || lastcookieCode.indexOf('DAcarrot_') > -1;
  setMemberFlag(false);
  if (!allowNonMember) {
    const updateReferrerData = referrerStore.getState().updateData;
    updateReferrerData({
      entryType: 'redirect',
      prevPage: window.location.pathname,
    });
    setReplacePath('/Join');
    return;
  } else {
    getLessonBook();
    const setIsLoading = reserveTrialStore.getState().setIsLoading;
    setIsLoading(false);
  }
};

export const runForChange = () => {
  const path = window.location.pathname;
  const segments = path.split('/');
  const timechangeUrl = segments.pop();
  getChangeDefaultTrial(String(timechangeUrl));
  return;
};

export const getMemberInfo = async () => {
  const setInputState = reserveTrialStore.getState().setInputState;
  const inputState = reserveTrialStore.getState().inputState;
  const recommendInfo = reserveTrialStore.getState().recommendInfo;
  
  try {
    const { data } = await httpDefaultClient.get('/myInfo/list');
    setInputState({
      ...inputState,
      name: data.data.memberName,
      cellPhone: utilFn['cellPhoneInput']('ko', data.data.memberCellphone),
      memberClass: data.data.memberClass
    });
    if (data.data.recommendCode === recommendInfo?.recommendCode) { // 추천인 코드와 본인 추천인 코드 동일
      alertMessage(await utilFn.getCommonMessage('FRIEND0005'), '/');
      utilFn.removeMarketingCodeValue('rcmdCode');
    } else if (data.data.memberCellphone === recommendInfo?.lessonPhone) { // 추천인 휴대폰 번호와 본인 휴대폰 번호 동일
      alertMessage(await utilFn.getCommonMessage('FRIEND0012'), '/');
      utilFn.removeMarketingCodeValue('rcmdCode');
    }
  } catch (error) {
    console.error('Failed to /myInfo/list' + error);
  }
};

export const getDefaultTrial = async () => {
  const setJuniorFlag = reserveTrialStore.getState().setJuniorFlag;
  const setLessonMode = reserveTrialStore.getState().setLessonMode;
  const setLevelCode = reserveTrialStore.getState().setLevelCode;
  const setShowLevel = reserveTrialStore.getState().setShowLevel;
  try {
    const { data } = await httpDefaultClient.get('/trial/apply/default');

    const pathName = window.location.pathname.toUpperCase();

    if (pathName.includes('AUDIO')) {
      setLessonMode('AUDIO');
      setJuniorFlag(data.juniorFlag);
      setLevelCode(data.levelCode);
      setShowLevel(data.showLevel);
    }
    else if (pathName.includes('VIDEO')) {
      setLessonMode('VIDEO');
      setJuniorFlag(data.juniorFlag);
      setLevelCode(data.levelCode);
      setShowLevel(data.showLevel);
    }
    else if (pathName.includes('JUNIOR')) {
      setJuniorFlag(true);
      setLessonMode('VIDEO');
      setLevelCode(data.levelCode);
      setShowLevel(data.showLevel);
    } else {
      setJuniorFlag(data.juniorFlag);
      setLessonMode(data.lessonMode);
      setLevelCode(data.levelCode);
      setShowLevel(data.showLevel);
    }
  } catch (error) {
    console.error('Failed to /trial/apply/default' + error);
  }
};

export const getChangeDefaultTrial = async (timechangeUrl: string) => {
  const setJuniorFlag = reserveTrialStore.getState().setJuniorFlag;
  const setLessonMode = reserveTrialStore.getState().setLessonMode;
  const setLevelCode = reserveTrialStore.getState().setLevelCode;
  const setShowLevel = reserveTrialStore.getState().setShowLevel;
  const selectDate = reserveTrialStore.getState().selectDate;
  const selectTime = reserveTrialStore.getState().selectTime;
  const setLessonTimestamp = reserveTrialStore.getState().setLessonTimestamp;
  const setTrialSeq = reserveTrialStore.getState().setTrialSeq;
  const setIsLoading = reserveTrialStore.getState().setIsLoading;
  const setOriginalLessonInfo = reserveTrialStore.getState().setOriginalLessonInfo;
  
  try {
    const { data } = await httpDefaultClient.get(`/trial/apply/default?timechangeUrl=${timechangeUrl}`);
    setJuniorFlag(data.juniorFlag);
    setLessonMode(data.lessonMode);
    setLevelCode(data.levelCode);
    setShowLevel(data.showLevel);
    setOriginalLessonInfo(data);
    setLessonTimestamp(data.lessonTimestamp);
    setTrialSeq(data.trialSeq);
    selectDate('');
    selectTime('');
    if (data.message) {
      alertMessage(data.message, '/');
    }
  } catch (error) {
    console.error('Failed to /trial/apply/default change' + error);
  } finally {
    setIsLoading(false);
  }
};

export const checkBeforeCreate = async () => {
  const setIsLoading = reserveTrialStore.getState().setIsLoading;
  try {
    const { data } = await httpDefaultClient.get('/trial/checkBeforeCreate');
    setIsLoading(false);
    if (data.reportUrl) {
      alertMessage(data.message, `/trialreport/${data.reportUrl}`);
    } if (data.oldReportUrl) {
      alertMessage(data.message, `/FreeReport/${data.oldReportUrl}`);
    } else {
      alertMessage(data.message, '/');
    }
  } catch (error) {
    setIsLoading(false);
    console.error('Failed to /trial/checkBeforeCreate' + error);
  } 
};

export const validateInput = () => {
  const inputState = reserveTrialStore.getState().inputState;
  const memberFlag = reserveTrialStore.getState().memberFlag;
  const selectedDate = reserveTrialStore.getState().selectedDate;
  const selectedTime = reserveTrialStore.getState().selectedTime;
  const lessonMode = reserveTrialStore.getState().lessonMode;
  const levelCode = reserveTrialStore.getState().levelCode;
  const setIsReserveButtonActive = reserveTrialStore.getState().setIsReserveButtonActive;
  const originalLessonInfo = reserveTrialStore.getState().originalLessonInfo;
  
  let valid;

  if (memberFlag) {
    valid = lessonMode
      && levelCode
      && selectedDate
      && selectedTime
      && inputState.name
      && inputState.cellPhone;
  } else {
    valid = lessonMode
      && levelCode
      && selectedDate
      && selectedTime
      && inputState.name
      && inputState.cellPhone
      && inputState.authPass
      && !inputState.nameError
      && !inputState.cellPhoneError;
  }
  
  if (isChangeMode()) {
    const isModeOrLevelChanged = (originalLessonInfo.lessonMode !== lessonMode || originalLessonInfo.levelCode !== levelCode);
    const isDateTimeChanged = (originalLessonInfo.lessonDate + originalLessonInfo.lessonTime !== selectedDate + selectedTime);
    valid = lessonMode
      && levelCode
      && selectedDate
      && selectedTime
      && (isDateTimeChanged || isModeOrLevelChanged || (!isModeOrLevelChanged && isDateTimeChanged));
  }
  if (valid) {
    setIsReserveButtonActive(true);
  } else {
    setIsReserveButtonActive(false);
  }
};

export const getLessonBook = async () => {
  const levelCode = reserveTrialStore.getState().levelCode;
  const juniorFlag = reserveTrialStore.getState().juniorFlag;
  const setTopicList = reserveTrialStore.getState().setTopicList;
  const selectTopic = reserveTrialStore.getState().selectTopic;
  try {
    const { data } = await httpDefaultClient.get(`/trial/topic?levelCode=${levelCode}&juniorFlag=${juniorFlag}`);
    setTopicList(data);
    selectTopic(data[0]);
  } catch (error) {
    console.error('Failed to /trial/apply/default change' + error);
  }
};

export const getRecommender = async () => {
  const rcmdCode = utilFn.getMarketingCodeValue('rcmdCode') || null;
  const setRecommendInfo = reserveTrialStore.getState().setRecommendInfo;
  if (!rcmdCode) {
    return;
  }
  
  try {
    const { data } = await httpDefaultClient.get(`/trial/recommender?recommendCode=${rcmdCode}`);
    setRecommendInfo(data.data);
  } catch (error) {
    console.error('Failed to /trial/recommender' + error);
  }
};

export const setLessonModeAndJuniorFlag = () => {
  const setLessonMode = reserveTrialStore.getState().setLessonMode;
  const setFixedMode = reserveTrialStore.getState().setFixedMode;
  const setJuniorFlag = reserveTrialStore.getState().setJuniorFlag;
  const pathName = window.location.pathname.toUpperCase();
  
  if (pathName.includes('AUDIO')) {
    setFixedMode('AUDIO');
    setLessonMode('AUDIO');
    return;
  } 
  if (pathName.includes('VIDEO')) {
    setFixedMode('VIDEO');
    setLessonMode('VIDEO');
    return;
  }
  if (pathName.includes('JUNIOR')) {
    setJuniorFlag(true);
    setFixedMode('JUNIOR');
    setLessonMode('VIDEO');
    return;
  }
};

export async function getLessonReviews() {
  const setLessonReviews = reserveTrialStore.getState().setLessonReviews;
  const juniorFlag = reserveTrialStore.getState().juniorFlag;
  const fixedMode = reserveTrialStore.getState().fixedMode;
  try {
    
    let requestURL = '';
    if (juniorFlag || fixedMode === 'JUNIOR'){
      requestURL = '/myInfo/review/list?page=0&categoryCode=FREELESSON&orderBy=desc&size=10&juniorFlag=true';
    } else {
      requestURL = '/myInfo/review/list?page=0&categoryCode=FREELESSON&orderBy=desc&size=10';
    }
    const { data } = await httpDefaultClient.get(requestURL);
    setLessonReviews(data.data || []);
  } catch (error) {
    console.error(`/myInfo/review/list: ${error}`);
  } 
}

export const runBeforeLoginCheck = async() => {
  await utilFn.sendFacebook('무료수업 신청페이지 조회');
  await getRecommender();
  await getServerDay();
  await getCommonCodes('LESSONMODE,TRIALLEVEL');
  await setLessonModeAndJuniorFlag();
  await getLessonReviews();
};
