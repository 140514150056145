import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Lottie from 'react-lottie';
import ReactMeta from 'component/ReactMeta';
import LayerPopup from 'component/LayerPopup';
import TrailHeader from 'component/trial/reserve/TrailHeader';
import TrialBody from 'component/trial/reserve/TrialBody';
import TrialBookPopup from 'component/trial/reserve/TrialBookPopup';

import useCheckLoginAndExecute from 'hooks/common/useCheckLoginAndExecute';
import reserveTrialStore, {
  clearRequestDateTime,
  isChangeMode,
  runBeforeLoginCheck,
  runForChange,
  runForMember, runForNonMember,
} from 'store/trial/reserve/reserveTrialStore';

import loading from 'json/loading.json';
import * as utilFn from 'common/utilFn';

import 'css/trial/Trial.scss';

export default function Trial() {
  const history = useHistory();

  const bodyRef = useRef<HTMLDivElement>(null);
  const alertInfo = reserveTrialStore((state) => state.alertInfo);
  const setFixedTitle = reserveTrialStore((state) => state.setFixedTitle);
  const juniorFlag = reserveTrialStore((state) => state.juniorFlag);
  const isLoading = reserveTrialStore((state) => state.isLoading);
  const redirectPath = reserveTrialStore((state) => state.redirectPath);
  const setRedirectPath = reserveTrialStore((state) => state.setRedirectPath);
  const replacePath = reserveTrialStore((state) => state.replacePath);
  const setReplacePath = reserveTrialStore((state) => state.setReplacePath);
  const isShowTrialBook = reserveTrialStore((state) => state.isShowTrialBook);
  const selectedTopic = reserveTrialStore((state) => state.selectedTopic);
  const setIsShowTrialBook = reserveTrialStore((state) => state.setIsShowTrialBook);
  const resetTrialState = reserveTrialStore((state) => state.resetTrialState);
  const resetTrialInputState = reserveTrialStore((state) => state.resetTrialInputState);
  
  const [mode, setMode] = useState('');
  const [bodyHiddenSpace, setBodyHiddenSpace] = useState('');
  const [bodyTop, setBodyTop] = useState('');

  useEffect(() => {
    runBeforeLoginCheck();
  }, []);
  
  if (isChangeMode()) {
    useCheckLoginAndExecute(runForChange, runForChange);
  } else {
    useCheckLoginAndExecute(runForMember, runForNonMember);
  }

  useEffect(() => {
    function setScreenSize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    setScreenSize();
    startScroll();
    handlerResize(); 
  }, [window.innerWidth,window.innerHeight ]);
  
  const handlerResize = () => {
    setMode(window.innerWidth >= 1025 ? 'pc' : 'mobile');
    utilFn.ScrollNone(true);
  };
  
  const scrollHandler = () => {
    const oneRem = window.innerWidth * 2 / 75;
    if (!bodyRef.current) {
      return null;
    }
    const top = bodyRef.current.scrollTop;
    const bodyTopValue = oneRem * 25 - top;
    const applyFormTitleText = document.getElementById('applyFormTitleText');
    if (!applyFormTitleText) {
      return null;
    }
    const applyFormTitleTextTop = window.scrollY + applyFormTitleText.getBoundingClientRect().top;
    const TopBannerIsActive = !!document.getElementById('TopBannerLMS');
    const topLimit = applyFormTitleTextTop;
    const hiddenLimit = TopBannerIsActive ? (oneRem * 25 - topLimit + 75) : (oneRem * 25 - topLimit);
    const titleName = isChangeMode() ? '무료 수업 신청 변경' : juniorFlag ? '주니어 무료수업 신청' : '무료수업 신청';
    if (alertInfo.alertText) {
      return;
    }
    setBodyTop(bodyTopValue > topLimit ? `${bodyTopValue}px` : `${topLimit}px`);
    setBodyHiddenSpace(bodyTopValue > topLimit ? `${top}px` : `${hiddenLimit}px`);
    setFixedTitle(bodyTopValue > topLimit ? '' : (titleName));
  };
  
  useEffect(() => {
    if (mode !== 'mobile') {
      return;
    }
    const timeoutId = setTimeout(() => {
      if (bodyRef.current) {
        bodyRef.current.addEventListener('scroll', scrollHandler);
      }
    }, 500);
  
    return () => {
      if (bodyRef.current) {
        bodyRef.current.removeEventListener('scroll', scrollHandler);
      }
      clearTimeout(timeoutId);
    };
  }, [juniorFlag, window.innerWidth,window.innerHeight]);
  
  const startScroll = () => {
    const scrollTimer = setInterval(() => {
      if (bodyRef.current) {
        bodyRef.current.addEventListener('scroll', scrollHandler);
        clearInterval(scrollTimer);
      }
    }, 100);
  };

  useEffect(() => {
    if (redirectPath) {
      setRedirectPath('');
      history.push(redirectPath);
      return;
    }
    if (replacePath) {
      setReplacePath('');
      history.replace(replacePath);
      return;
    }
  }, [redirectPath, replacePath]);
  
  useEffect(() => {
    return () => {
      clearRequestDateTime();
      resetTrialState();
      resetTrialInputState();
      utilFn.ScrollNone();
    };
  }, []);
  
  return (
    <>
      <ReactMeta title='무료수업으로 실력진단 | 민병철유폰 1:1영어회화' description='1:1수업부터 유폰 콘텐츠, AI학습까지 모두 무료체험으로 경험해보세요.'/>
      <div className='trial-page apply-form'>
        <div className={'apply-form__wrapper'}>
          <TrailHeader />
          <div className='trial-body apply-form-body'
            style={mode === 'mobile' ? { 'top': bodyTop, 'height': `calc(100% - ${bodyTop})` } : {}} 
            ref={bodyRef}
          >
            <div style={{ height: mode === 'mobile' ? bodyHiddenSpace : '0' }} />
            {isLoading ? (
              <div className={'lottieLoading'}>
                <Lottie options={{ loop: true, autoplay: true, animationData: loading }} />
              </div>
            ) : (
              <TrialBody />
            )}
          </div>
        </div>
        {alertInfo.alertText &&
          <LayerPopup
            tit={alertInfo.alertText}
            btnCnt={1}
            btnOkCmt={'확인'}
            unmountScrollNone={'N'}
            btnOkFunction={alertInfo.alertFunction}
          />
        }
        {isShowTrialBook &&
          <TrialBookPopup
            unitSeq={selectedTopic.unitSeq+''}
            closeFunction={() => {
              setIsShowTrialBook(false);
              utilFn.ScrollNone();
            }}
          />
        }
      </div>
    </>
  );
}
