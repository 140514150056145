import React from 'react';
import { completeTrialStore } from 'store/trial/complete/completeTrialStore';

export default function TrialCompleteInfo() {
  const trialInfo = completeTrialStore((state) => state.trialInfo);
  const originalDateTime = trialInfo.lessonDate;
  const formattedDateTime = originalDateTime.replace(/-/g, '.');
  const lessonMode = trialInfo.lessonMode === 'VIDEO' ? trialInfo.juniorFlag ? '수업 시간 직전에 문자로 발송되는 링크로 접속해주세요.' : '화상수업' : '전화수업';
  
  return (
    <div className='trialComplete__info'>
      <p className='info-title'>예약 내역</p>
      <ul className='info-box'>
        <li className='info-wrap'>
          <p className='label'>이름</p>
          <div className='value'>{trialInfo.memberName}</div>
        </li>
        <li className='info-wrap'>
          <p className='label'>수업 일시</p>
          <div className='value'>{formattedDateTime} {trialInfo.lessonTime}</div>
        </li>
        <li className='info-wrap'>
          <p className='label'>방식</p>
          <div className='value'>{lessonMode}</div>
        </li>
        <li className='info-wrap'>
          <p className='label'>시간</p>
          <div className='value'>{trialInfo.lessonMinute}분</div>
        </li>
        <li className='info-wrap'>
          <p className='label'>교재</p>
          <div className='value'>{trialInfo.courseName} - {trialInfo.subject}</div>
        </li>
      </ul>
    </div>
  );
}
