import {httpDefaultClient} from 'configs/api';

export class AccessAnalytics {
  constructor() {
    this.accessDevice = window.innerWidth >= 1025 ? 'PC' : 'MOBILE';
  }
  
  send() {
    httpDefaultClient.post('/web/accessData/save', {
      accessDevice: this.accessDevice,
      accessType: this.accessType,
      entryCode: this.entryCode,
      accessFunction: this.accessFunction,
      accessButton: this.accessButton,
      parameterType: this.parameterType,
      parameterData: this.parameterData,
    });
  }
}

export class AccessData extends AccessAnalytics {
  constructor({ accessType, accessFunction, entryCode, accessButton, parameterData, accessUrl }) {
    super();
    if (accessType) this.accessType = accessType;
    if (entryCode) this.entryCode = entryCode;
    this.accessFunction = accessFunction;
    if (accessButton) this.accessButton = accessButton;
    else if (entryCode && accessFunction) this.accessButton = `${entryCode}_${accessFunction}`;
    if (parameterData) this.parameterData = parameterData;
    if (accessUrl) this.accessUrl = accessUrl;
  }
}

export function saveAccessData(params) {
  new AccessData(params).send();
}

export function saveAccessDataByAccessFunction(accessFunction) {
  new AccessData({ accessFunction }).send();
}
