import React from 'react';
import completeTrialStore from 'store/trial/complete/completeTrialStore';

export default function TrialCompleteLessonBookButton() {
  const setIsShowTrialBook = completeTrialStore((state) => state.setIsShowTrialBook);
  const openTrialBook = () => {
    setIsShowTrialBook(true);
  };
  return (
    <button onClick={openTrialBook} className='trialComplete__book'>수업 교재보기</button>
  );
}
