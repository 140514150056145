import React, { Component } from 'react';
import axios from 'axios';

import Top from '../component/top';
import Footer from '../component/Footer';

import '../css/Landing.scss';
import {httpDefaultClient} from 'configs/api';

export default class Landing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiCalled : false
    };
  };

  componentDidMount(){
    this.checkRefusal();
  }

  componentWillMount() {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    window.addEventListener('resize', () => {
      this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    });
  }

  eMessage = (v,link) => {
    if (!v) return false;
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (response.data.result){
          alert(response.data.message);
          if (link)window.location.href = link;
          return null;
        } else {
          alert(v);
          if (link)window.location.href = link;
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  checkRefusal = e => {
    if (!this.state.apiCalled) {
      this.setState({apiCalled: true});
      httpDefaultClient.get('/web/checkRejectEmail?memberEmail=' + this.props.match.params.mailCode)
        .then(res => {
          const json = res.data;
          if (Boolean(json.result) === false){
            this.eMessage(json.messageCode, '/');
          }
          this.setState({apiCalled: false});
        })
        .catch((error) => {
          this.setState({apiCalled: false});
          console.error('error: ', error);
        });
    } else {
      alert('처리가 진행중 입니다.');
    }
  };

  refusalConfirm = (e) => {
    //수신 거부 호출
    if (!this.state.apiCalled) {
      this.setState({ apiCalled : true });
      httpDefaultClient.post('/web/rejectEmail?memberEmail=' + this.props.match.params.mailCode)
        .then(res => {
          const json = res.data;
          if (Boolean(json.result) === true){
            window.alert(
              '광고 및 홍보성 이메일 수신거부가 완료되었습니다.\n수신정보 변경은 유폰 앱에서 가능합니다.');
            window.location.href = '/';
          } else if (Boolean(json.result) === false){
            this.eMessage(json.messageCode,'/');
          } else {
            window.alert('서버 전송 실패');
          }
          this.setState({ apiCalled : false });
        })
        .catch((error) => {
          this.setState({ apiCalled : false });
          console.error('error: ', error);
        });
    } else {
      alert('처리가 진행중 입니다.');
    }
  };

  refusalCancel = (e) => {
    //수신 거부 취소
    window.alert('이메일 수신거부가 취소되었습니다.');
    window.location.href = '/';
  };

  render() {
    return (
      <div className={'landingWrap'}>
        <div className={'landing'}>
          {this.props.match.params.category === 'email' ?
            (() => {
              if (this.props.match.params.type === 'refusal'){
                return (
                  <>
                    <Top title={''} ww={this.ww} sub={''} history={this.props.history}  chgTitle={() => this.chgTitle()} />
                    <div className={'emailWrap refusal'}>
                      <h2>이메일을 그만 <br className={'mbr'} />
                                            받으시겠습니까?</h2>
                      <p>수신거부는 민병철유폰의 광고 및 홍보성 <br className={'mbr'} />이메일에 대한 거부 입니다. <br/>
                                            서비스 이용에 필요한 결제 및 주요 정책에 대한 <br className={'mbr'} />내용은 수신동의 여부와 관계없이 발송됩니다.</p>
                      <div className='btns'>
                        <button className={'btn subBtn'} onClick={ this.refusalConfirm }>네</button>
                        <button className={'btn'} onClick={ this.refusalCancel }>계속 받을래요</button>
                      </div>
                    </div>
                    <Footer ww={this.ww}/>
                  </>
                );
              } else {
                return (
                  <>
                    그 외 페이지
                  </>
                );
              }
            })()
            :
            <>
              email 랜딩이 아닌 경우
            </>
          }
        </div>
      </div>
    );
  }
}
