import React from 'react';

const NPayGuidePopup = ({ props }) => {
  // props
  const {
    ww,
  } = props;

  // etc
  const {
    closePopup,
  } = props;

  return (
    <>
      <div className={'popupWrap full nPay' + (ww === 'pc' ? '' : ' mo')}>
        <div className='infoPop info1'>
          <div className='title'>네이버페이 결제 안내</div>
          <div className='clsBtn' onClick={() => closePopup('nPayGuide')}/>
          <div className={'content'}>
            <div className='cardInfo'>
              <div className='topLine'>
                <ul className={'naver'}>
                  <li>주문 변경 시 카드사 혜택 및 할부 적용 여부는 해당 카드사 정책에 따라 변경될 수 있습니다.</li>
                  <li>네이버페이는 네이버ID로 별도 앱 설치 없이 신용카드 또는 은행계좌 정보를 등록하여 네이버페이 비밀번호로 결제할 수 있는 간편결제 서비스입니다.</li>
                  <li>결제 가능한 신용카드 : 신한, 삼성, 현대, BC, 국민, 하나, 롯데, NH농협, 씨티, 카카오뱅크</li>
                  <li>결제 가능한 은행 : NH농협, 국민, 신한, 우리, 기업, SC제일, 부산, 경남, 수협, 우체국, 미래에셋대우, 광주, 대구, 전북, 새마을금고, 제주은행, 신협, 하나은행, 케이뱅크, 카카오뱅크, 삼성증권, KDB산업은행,씨티은행, SBI은행, 유안타증권, 유진투자증권</li>
                  <li>네이버페이 카드 간편결제는 네이버페이에서 제공하는 카드사 별 무이자, 청구할인 혜택을 받을 수 있습니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='msk'></div>
    </>
  );
};

export default NPayGuidePopup;
