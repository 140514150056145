import React, {Component} from 'react';
import axios from 'axios';

import * as utilFn from '../common/utilFn';
import LeveltestBook from '../component/LevelTestBook';

import '../css/levelTestBook.scss';
import {httpDefaultClient, httpRefreshClient} from 'configs/api';

export default class LevelTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'p1',
    };
  }

  componentWillMount() {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    window.addEventListener('resize', () => {
      this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    });

    httpRefreshClient.post('/sign/checkLogin').then(res => {
      if (res.data.result) {
        this.tokenOK();
      }
    });
  }

  componentDidMount() {
    let userAgD;
    if (navigator.userAgent.indexOf('bcm_agent::') >= 0){
      userAgD = navigator.userAgent.split('bcm_agent::')[1].split('|');
    } else {
      userAgD = 'web|test||||';
    }
    this.setState({
      userAgD: userAgD
    });

    window.addEventListener('scroll', this.bottomBtn, true);
  }

  componentDidUpdate() {
    let a;

    if (this.state.page !== this.props.match.params.page) {
      this.setState({
        page: this.props.match.params.page,
        pTitle: a
      });
    }
  }

  tokenOK = () => {
    httpDefaultClient.get('/myInfo/list',)
      .then(response => {
        const json = response.data;
        if (json.result) {
          this.setState({
            'name': json.data.memberName,
            'cp':  utilFn['cpInput']('ko', json.data.memberCellphone) ,
            member: true,
          });
        } else {
          this.props.history.push('/');
        }
      })
      .catch((error) => console.error('error: ', error));
  };

  eMessage = (t, v) => {
    axios({
      method:'get',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (response.data.result){
          if (t === 'emailError'){
            this.setState({emailError: response.data.message});
          } else {
            alert(response.data.message);
            return null;
          }
        } else {
          alert(v);
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  render() {
    const ts = this.state;
    return (
      <>
        <LeveltestBook seq={this.props.match.params.seq} bp={this.bookPreview} ww={this.ww} book={this.book} level={ts.ltState} page={this.props.match.params.page} goM={this.onClickGomain} history={this.props.history}/>
        <div className={'viewTime'}>{ts.realTime}</div>
      </>
    );
  }
}
