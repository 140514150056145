import React from 'react';

const PaymentGatewayIntel = ({ props }) => {
  // state
  const {
    purchaseSeq, lg,
  } = props;
    
  return (
    <form method={'post'} id={'LGD_PAYINFO'} action={'payreq_crossplatform.jsp'}>
      <input type={'hidden'} name={'purchaseSeq'} id={'purchaseSeq'} value={purchaseSeq} />

      {lg && Object.keys(lg).map((obj, i) => {
        return (
          <input key={i} type={'hidden'} name={`${obj}`} id={`${obj}`} value={`${lg[obj]}`} />
        );})}
    </form>
  );
};

export default PaymentGatewayIntel;
