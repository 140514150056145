import axios, {AxiosError} from 'axios';

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

export const httpDefaultClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

httpDefaultClient.interceptors.request.use((config) => {
  const token = getCookie('accessToken');
  if (token) {
    config.headers!['X-AUTH-TOKEN'] = token;
  }
  config.headers['Content-Type'] = 'application/json';

  return config;
},(error) => {
  return Promise.reject(error);
});

httpDefaultClient.interceptors.response.use(
  (response: any) => response,
  async (error: any) => {
    const originalConfig = error.config;
    if (error.response.status === 401) {
      try {
        const { data : { result }} = await httpRefreshClient.post('/sign/checkLogin');
        if (!result) {
          window.location.href = '/';
        }
        return axios.request({
          method: originalConfig.method,
          data: originalConfig.data,
          baseURL: originalConfig.baseURL,
          url: originalConfig.url,
          headers: {
            'Content-Type':'application/json',
            'X-AUTH-TOKEN': getCookie('accessToken'),
          }
        });
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

// refresh token
export const httpRefreshClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// TODO:: 로컬스토리지 토큰관리 안쓰기(배포 수개월 이후 삭제 필요)
httpRefreshClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('refreshToken');
  if (token) {
    config.headers!['X-AUTH-TOKEN'] = token;
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
},(error) => {
  return Promise.reject(error);
});

export function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError;
}

function getCookie(cookieName: string) {
  const name = cookieName + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    const cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}
