import React, { useState, useEffect, useRef, useContext } from 'react';
import {isAndroid, isIOS} from 'react-device-detect';
import playerContext from '../context/playerContext';

const Controls = props => {

  // Global State
  const {
    currentSong,
    songs,
    playing,
    togglePlaying,
    handleEnd,

  } = useContext(playerContext);

  const subject = props.subject;
  const audio = useRef('audio_tag');

  // self State
  const [dur, setDur] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);

  const toggleAudio = () => {

    if (audio.current.paused){
      /*현재 재생중인 오디오 끄기*/
      const p = document.getElementsByTagName('audio');
      for (let a of p) {
        a.pause();
        if (a.id !== 'rAudio')  a.currentTime = 0;
      }

      console.warn('======================= play event');
      audio.current.play();
      props.onChangeStatus('LessonAgain', 'START',audio.current.currentTime, audio.current.src);

    } else {
      console.warn('======================= pause event');
      audio.current.pause();
      props.onChangeStatus('LessonAgain', 'END',audio.current.currentTime, audio.current.src);
    }
  };

  const handleProgress = (e) => {
    let compute = (e.target.value * dur) / 100;
    setCurrentTime(compute);
    audio.current.currentTime = compute;

    if (!playing) {
      audio.current.play();
    }
  };

  const audioDownload = () => {
    let txt;
    if (props.subject === 'Free talking'){
      txt = props.lDate+'_'+props.subject+'_'+props.lTime;
    } else {
      txt = props.lDate+'_'+props.subject;
    }
    console.warn(audio.current.src);
    if (isAndroid){
      // alert('다운로드 클릭 체크 검사중 : ' + audio.current.src + ' , ' +  txt+'.mp3')
      window.bcmapp.downloadFile(audio.current.src, txt+'.mp3');
    } else if (isIOS){
      window.webkit.messageHandlers.bcmapp.postMessage('downloadFile,'+ audio.current.src+','+ txt+'.mp3');
    } else {
      alert('검수중 : 기기 체크 안됨');
    }
  };

  if (audio.current.paused === playing) togglePlaying();

  useEffect(() => {
    //audio.current.volume = stateVolume;
    if (playing) { toggleAudio(); }
  }, [currentSong]);

  return (
    <div className='controls'>
      <audio
        id={'rAudio'}
        onTimeUpdate={(e) => setCurrentTime(e.target.currentTime)}
        onCanPlay={(e) => setDur(e.target.duration)}
        onEnded={handleEnd}
        ref={audio}
        type='audio/mpeg'
        preload='true'
        src={songs[currentSong][1].replace('http://222.122.38.188', 'https://dialcom.uphone3.com')} />
      <div className='audioControls'>
		        <span className='icon play' onClick={() => { togglePlaying(); toggleAudio(); }}>
		          <span className={!playing ? '' : 'hidden'}><img src={`${process.env.REACT_APP_IMG_URL}/icon/btn_player_black.png`} alt={'play'} /></span>
		          <span className={playing ? '' : 'hidden'}><img src={`${process.env.REACT_APP_IMG_URL}/icon/btn_pause_black.png`} alt={'play'} /></span>
		        </span>
      </div>
      <div className='progressBox'>
        <input
          className={currentTime !== 0 ? 'on' : ''}
          onChange={(e) => handleProgress(e)}
          value={dur ? (currentTime * 100) / dur : 0}
          // defaultValue={dur ? (currentTime * 100) / dur : 0}
          type='range' name='progressBar' id='prgBar' />
        <div className={'prg'} style={{width: `${dur ? (currentTime * 100) / dur : 0}%`}}/>
      </div>
      <a className={'audioDownload'} href={songs[currentSong][1].replace('http://222.122.38.188', 'https://dialcom.uphone3.com')} title='오디오 다운로드' download={props.lDate+'_'+props.subject + '.mp3'}>
        <span className={'icon dn'}><img src={`${process.env.REACT_APP_IMG_URL}/icon/btn_down_black.png`} alt={'download'} /></span>
      </a>
    </div>
  );
};

export default Controls;
