import React, { useEffect } from 'react';
import * as utilFn from '../../common/utilFn';

import '../../css/purchase/purchasePopup.scss';

export default function PurchasePopup({ props }:any) {
  const { purchasePopupContent, closePopup } = props;
  const { popupHtml, popupBackgroundColor, popupBackgroundImage,
    popupImage, popupType, popupcloseColor, popupSize } = purchasePopupContent;

  const handleButtonClick = () => {
    closePopup('purchasePopup');
    utilFn.ScrollNone(false);
  };
  
  useEffect(() => {
    utilFn.ScrollNone(true);
    return () => { utilFn.ScrollNone(false); };
  }, []);
  
  if (popupType === 'IMAGE') {
    return (
      <div className='purchase-popup-backdrop'>
        <div className={`purchase-popup purchase-popup--image ${popupSize}`}>
          <div className='purchase-popup__scroll-wrap purchase-popup__scroll-wrap--image' style={{ backgroundColor: popupBackgroundColor }} >
            <button className={`purchase-popup__close-button purchase-popup__close-button--${popupcloseColor}`} onClick={handleButtonClick} />
            <img src={popupImage} alt='' />
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className='purchase-popup-backdrop'>
      <div className={`purchase-popup purchase-popup--html ${popupSize}`}>
        <div className='purchase-popup__scroll-wrap' style={{
          backgroundColor: popupBackgroundColor,
          backgroundImage: popupBackgroundImage ? `url(${popupBackgroundImage})` : 'none',
          backgroundSize:'contain',
          backgroundRepeat: 'no-repeat',
        }}
        >
          <button className={`purchase-popup__close-button purchase-popup__close-button--${popupcloseColor}`} onClick={handleButtonClick}/>
          <div className='purchase-popup__container' dangerouslySetInnerHTML={{ __html: popupHtml }} 
          />
        </div>
      </div>
    </div>
  );
}
