import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import Lottie from 'react-lottie-segments';
import moment from 'moment';
import axios from 'axios';

import '../css/refEvent.scss';

import * as utilFn from '../common/utilFn';
import SupportBoard from '../component/common/SupportBoard';
import LayerPopup from '../component/LayerPopup';
import loading from '../json/loading.json';
import invitationAi from '../json/invitation/lottie_ai.json';
import invitationSchedule from '../json/invitation/lottie_schedule.json';
import invitationCall from '../json/invitation/freelesson.json';
import invitationReport from '../json/invitation/lottie_report_new.json';
import invitationAirplane from '../json/invitation/airplane.json';
import DOMPurify from 'dompurify';
import {httpDefaultClient, httpRefreshClient} from 'configs/api';

export default class RefEvent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'p1',
      toastOff : true,
      imgData1 : [{src : 'class1_1@2x.jpg', alt : '슬기로운 직장생활 전화'},
        {src : 'class1_2@2x.jpg', alt : '영어 맛집의 5가지 공부 비법'},
        {src : 'class1_3@2x.jpg', alt : '오늘은 내가 요리사'},
        {src : 'class1_4@2x.jpg', alt : '와썹캘리포니아'},
        {src : 'class1_5@2x.jpg', alt : 'MZ세대'},
        {src : 'class1_1@2x.jpg', alt : '슬기로운 직장생활 전화'},
        {src : 'class1_2@2x.jpg', alt : '영어 맛집의 5가지 공부 비법'},
        {src : 'class1_3@2x.jpg', alt : '오늘은 내가 요리사'},
        {src : 'class1_4@2x.jpg', alt : '와썹캘리포니아'},
        {src : 'class1_5@2x.jpg', alt : 'MZ세대'},
      ],
      imgData2 : [{src : 'class2_1@2x.jpg', alt : '컨버런스 준비 유폰과 함께해요'},
        {src : 'class2_2@2x.jpg', alt : 'K-POP'},
        {src : 'class2_3@2x.jpg', alt : '영어 알레르기 처방전'},
        {src : 'class2_4@2x.jpg', alt : '슬기로운 직장생활 이메일'},
        {src : 'class2_5@2x.jpg', alt : 'TOEIC Speaking'},
        {src : 'class2_6@2x.jpg', alt : '프리토킹 직장생활'},
        {src : 'class2_1@2x.jpg', alt : '컨버런스 준비 유폰과 함께해요'},
        {src : 'class2_2@2x.jpg', alt : 'K-POP'},
        {src : 'class2_3@2x.jpg', alt : '영어 알레르기 처방전'},
        {src : 'class2_4@2x.jpg', alt : '슬기로운 직장생활 이메일'},
        {src : 'class2_5@2x.jpg', alt : 'TOEIC Speaking'},
        {src : 'class2_6@2x.jpg', alt : '프리토킹 직장생활'},
      ],
      imgData3 : [
        {src : 'class3_1@2x.jpg', alt : '건강에 관한 지식 7가지'},
        {src : 'class3_2@2x.jpg', alt : 'MBTI'},
        {src : 'class3_3@2x.jpg', alt : '은행에서 필요한 영어만'},
        {src : 'class3_4@2x.jpg', alt : '셀럽이야기 조언'},
        {src : 'class3_5@2x.jpg', alt : '문화차이 알아보기'},
        {src : 'class3_6@2x.jpg', alt : '메타버스타고 가상세계로'},
        {src : 'class3_7@2x.jpg', alt : '따끈따근한 영자신문'},{src : 'class3_1@2x.jpg', alt : '건강에 관한 지식 7가지'},
        {src : 'class3_1@2x.jpg', alt : '건강에 관한 지식 7가지'},
        {src : 'class3_2@2x.jpg', alt : 'MBTI'},
        {src : 'class3_3@2x.jpg', alt : '은행에서 필요한 영어만'},
        {src : 'class3_4@2x.jpg', alt : '셀럽이야기 조언'},
        {src : 'class3_5@2x.jpg', alt : '문화차이 알아보기'},
        {src : 'class3_6@2x.jpg', alt : '메타버스타고 가상세계로'},
        {src : 'class3_7@2x.jpg', alt : '따끈따근한 영자신문'},
      ],
    };
  }

  componentWillMount() {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.getCommonMsgList();
  }
  
  componentDidMount() {
    window.addEventListener('resize', this.changeWw, true);
    utilFn.ScrollNone();

    httpRefreshClient.post('/sign/checkLogin').then(res => {
      if (res.data.result) {
        this.tokenOK();
      }
    });
    this.getRecommender();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeWw);
  }

  changeWw = () => {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.setState({
      ww : this.ww
    });
  };

  tokenOK = () => {
    httpDefaultClient.get('/myInfo/recommend/findMyCode').then((res) => {
      const json = res.data;
      if (json.result) {
        this.setState({
          myRcmdCode: json.data,
        });
      }
    }).catch((error) => {
      console.error('error: ', error);
    });

    httpDefaultClient.get('/myInfo/list').then((res) => {
      const json = res.data;
      if (json.result) {
        this.setState({
          myCp: json.data.lessonPhone,
        });
      }
    }).catch((error) => {
      console.error('error: ', error);
    });
  };

  getRecommender = () => {
    httpDefaultClient.get('/trial/recommender?recommendCode='+this.props.match.params.id).then(res => {
      const json = res.data;
      if (json.result) {
        const data = json.data;
        utilFn.setMarketingCodeValue('rcmdCode', this.props.match.params.id, 1);
        let friendName = data.memberName;
        let originName = friendName.split('');
        if (friendName.length <= 3) {
          originName.forEach(function(name, i) {
            if (i !== 1) return;
            originName[i] = '*';
          });
        } else {
          originName.forEach(function(name, i) {
            if (i !== 1 && i !== 2) return;
            originName[i] = '*';
          });
        }
        let joinName = originName.join();
        friendName = joinName.replace(/,/g, '');

        this.setState({
          rcmdCode: this.props.match.params.id,
          friendName,
          rcmdCp: data.lessonPhone,
          pageComplete: true,
        });
      } else {
        utilFn.removeMarketingCodeValue('rcmdCode');
        this.alertMessage(json.message, true, 'error');
      }
    }).catch((error) => {
      console.error('error: ', error);
    });
  };

  commonCode = (c) => {
    httpDefaultClient.get('/commonCode/getList/'+c)
      .then( (response) => {
        this.setState({[c]:response.data});
      })
      .catch((error) => console.error('error: ', error));
  };

  commonCodeValue = (t, k, v) => {
    if (t && k && v){
      const a = this.state[t].map(x => x.detailCode);
      const b = a.indexOf(v);
      return this.state[t][b][k];
    }
  };

  getCommonMsgList = () => {
    axios({
      method: 'GET',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getList',
      params: {
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        this.setState({
          commonMsg : response.data,
        });
      })
      .catch((error) => {
        console.error('getCommonMsgList: ', error);
      });
  };

  alertMessage = (v, com, type) => {
    if (com) {
      this.setState({
        eMessageTxt: this.state.commonMsg[v],
      });
    } else {
      this.setState({
        eMessageTxt: v,
      });
    }
    this.setState({
      alertType: type,
    });

    this.openPopup('eMessageFlag');
  };

  openPopup = (s, overlap) => {
    this.setState({
      [s] : true,
      popupOverlap : overlap,
    });
    utilFn.ScrollNone(true);
  };

  closePopup = (s) => {
    this.setState({
      [s] : false,
    });
    utilFn.ScrollNone();
    if (s === 'eMessageFlag') {
      this.go('/');
    }
  };

  eMessage(t, v){
    axios({
      method:'get',
      url:process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (response.data.result){
          if (t === 'emailError'){
            this.setState({emailError: response.data.message});
          } else {
            this.alertMessage(response.data.message);
            this.setState({
              alertType: t,
            });
            return null;
          }
        } else {
          this.alertMessage(v);
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  numberFormat(n){
    const a = parseInt(n);
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  setTrue = (s) => {
    this.setState({
      [s] : true,
    });
  };

  go = (t) => {
    if (t === '/FreeLesson') {
      if (this.state.rcmdCode === this.state.myRcmdCode) {
        utilFn.removeMarketingCodeValue('rcmdCode');
        this.alertMessage('FRIEND0005', true, 'error');
        return false;
      } else if (this.state.rcmdCp === this.state.myCp) {
        utilFn.removeMarketingCodeValue('rcmdCode');
        this.alertMessage('FRIEND0012', true, 'error');
        return false;
      }
    }
    this.props.history.push(t);
  };
  
  parseNumberToKoreanAlphabet = (number) => {
	  const tenUnit = ['', '십', '백', '천'];
	  const tenThousandUnit = ['조', '억', '만', ''];
	  const unit = 10000;
	
	  let answer = '';
	
	  while (number > 0) {
	    const mod = number % unit;
	    const modToArray = mod.toString().split('');
	    const length = modToArray.length - 1;
	
			 const modToKorean = modToArray.reduce((acc, value, index) => {
	      const valueToNumber = +value;
	      if (!valueToNumber) return acc;
	      const numberToKorean = valueToNumber;
	      return `${acc}${numberToKorean}${tenUnit[length - index]}`;
	    }, '');
	
	    answer = `${modToKorean}${tenThousandUnit.pop()}${answer}`;
	    number = Math.floor(number / unit);
	  }
	
	  return answer.replace();
  };

  render() {
    const state = this.state;
    const mileage = this.numberFormat(this.state.commonMsg?.['FRIEND0022'] || 0);
    const classSwiperOption = {
      slidesPerView: 'auto',
      centeredSlides: true,
      observer: true,
      observeParents: true,
      allowTouchMove: true,
      loop: true,
      autoplay:{
        delay: 2000,
        disableOnInteraction: false,
      },
      loopedSlides: 1,
      loopAdditionalSlides: 1,
    };

    const reviewSwiperOption = {
      slidesPerView: 'auto',
      centeredSlides: true,
      observer: true,
      observeParents: true,
      allowTouchMove: true,
      loop: true,
      autoplay:{
        delay: 2000,
        disableOnInteraction: false,
      },
      loopedSlides: 1,
      loopAdditionalSlides: 1,
      pagination: {
        el: '.review-pagination',
        clickable: true,
      },
    };

    return (
      <>
        <div className={'invitation-page '+(this.props.match.params.page ? this.props.match.params.page : 'p1') + (this.ww === 'pc' ? '' : ' mo')}>
          {!state.pageComplete ?
            <div className={'lottieLoading'}>
              <Lottie options={{loop: true, autoplay: true, animationData: loading}}/>
            </div>
            :
            <>
              <article className='invitation'>
                <section className='invitation__head'>
                  <div className='invitation__head-lottie'>
                    <Lottie options={{loop: true, autoplay: true, animationData: invitationAirplane}}/>
                  </div>
                  <h2 className='invitation__head-title'>
                    {state.friendName}님이 유폰행 <br /> 무료티켓을 보냈어요.
                  </h2>
                  <p className='invitation__head-desc'>
                    외국인강사와 수업하고 영어회화 여정에 합류하세요
                  </p>
                  <div className='invitation__head-ticket'>
                    <img src={process.env.REACT_APP_IMG_URL + '/event/friends/vis_ticket@2x.png'} alt='Free Ticket. Arrival-외국인강사와 무료수업, Time-10분, Type- 전화 또는 화상' />
                    <button type='button' onClick={() => this.go('/FreeLesson')} className='btn-move-application'><span>30초만에 신청하기</span></button>
                  </div>
                </section>
                <section className='invitation__gifts'>
                  <div className='container'>
                    <h2 className='invitation__gifts-title'>유폰 웰컴 기프트</h2>
                    <ul className='gift-list'>
                      <li className='gift-item'>
                        <img src={process.env.REACT_APP_IMG_URL + '/event/friends/ico_report.svg'} alt='' className='gift-item__bg' />
                        <p className='gift-item__txt'>실력진단 <br /><strong>결과 리포트</strong></p>
                      </li>
                      <li className='gift-item'>
                        <img src={process.env.REACT_APP_IMG_URL + '/event/friends/ico_reserve.svg'} alt='' className='gift-item__bg' />
                        <p className='gift-item__txt'>친구추천적립금<br /><strong>{this.parseNumberToKoreanAlphabet(parseInt(mileage.replaceAll(',', '')) || 0)}원</strong></p>
                      </li>
                    </ul>
                  </div>
                </section>
                <section className='invitation__reviews'>
                  <div className='container'>
                    <h2 className='invitation__reviews-title'>유폰 여행 후기</h2>
                    <div className='slide-row'>
                      <Swiper {...reviewSwiperOption}>
                        <div className='slide-item' style={{backgroundColor : '#0C7C96'}}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/person1.svg'} alt='' className='slide-item__img' />
                          <strong className='slide-item__title'>취업을 앞둔 <br />
                            영어회화 시험 준비생</strong>
                          <p className='slide-item__desc'>예상 OPIc, TOEIC <br />
                            Speaking 레벨을 <br />
                            알려주니 시험응시료 굳었죠!</p>
                          <span className='slide-item__reviewer'>by. 서*미</span>
                        </div>
                        <div className='slide-item' style={{backgroundColor : '#FF9B38'}}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/person2.svg'} alt='' className='slide-item__img' />
                          <strong className='slide-item__title'>영어말하기가 낯선 <br />
                            영어 왕초보</strong>
                          <p className='slide-item__desc'>걱정했던 것과 달리 <br />
                            외국인강사님이 이끌어주시니 <br />
                            10분이 금방 지나갔어요</p>
                          <span className='slide-item__reviewer'>by. 김*정</span>
                        </div>
                        <div className='slide-item' style={{backgroundColor : '#5C6EFF'}}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/person3.svg'} alt='' className='slide-item__img' />
                          <strong className='slide-item__title'>해외여행에서 <br />
                            외국인친구 사귀고픈 <br />
                            프로여행러 </strong>
                          <p className='slide-item__desc'>앱 하나로 어느나라에서든지 <br />
                            수강이 가능하니깐 간편해요</p>
                          <span className='slide-item__reviewer'>by. 박*수</span>
                        </div>
                        <div className='slide-item' style={{backgroundColor : '#51BE75'}}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/person4.svg'} alt='' className='slide-item__img' />
                          <strong className='slide-item__title'>막연히 영어를 <br />
                            잘하고 싶은 대학생</strong>
                          <p className='slide-item__desc'>요즘 핫한 MBTI를 영어로 배우니 <br />
                            재밌어서 머리에 쏙쏙 <br />
                            들어오더라구요</p>
                          <span className='slide-item__reviewer'>by. 정*경</span>
                        </div>
                        <div className='slide-item' style={{backgroundColor : '#F87A6F'}}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/person5.svg'} alt='' className='slide-item__img' />
                          <strong className='slide-item__title'>갑작스런 해외출장이 <br />
                            잡힌 직장인</strong>
                          <p className='slide-item__desc'>비즈니스 과정이 따로 있어서 <br />
                            나에게 지금 필요한 영어표현을 <br />
                            배울 수 있어요</p>
                          <span className='slide-item__reviewer'>by. 이*미</span>
                        </div>
                      </Swiper>
                    </div>

                  </div>
                </section>
                <section className='invitation__steps'>
                  <div className='container'>
                    <h2 className='invitation__steps-title'>유폰 여행 일정</h2>
                    <ol className='step-list'>
                      <li className='step-item step-item--apply'><button type='button' onClick={() => this.go('/FreeLesson')}><em>원하는 시간, 수업으로</em> 무료수업 신청</button></li>
                      <li className='step-item step-item--study'>레벨 맞춤 <em>온라인 교재로 예습</em></li>
                      <li className='step-item step-item--call'>외국인강사님과 <em>10분 무료수업</em></li>
                    </ol>
                  </div>
                  <button type='button' onClick={() => this.go('/FreeLesson')} className='btn-move-application'>1:1 무료수업 신청하기</button>
                </section>
                <section className='invitation__report'>
                  <div className='container'>
                    <h2 className='invitation__report-title'>무료수업 리포트로 <br /> 실력 진단하고 <br /> <strong>나의 영어 강&middot;약점 파악</strong></h2>
                    <div className='lottie-item'>
                      <div className='lottie-item__animation'>
                        <Lottie options={{loop: true, autoplay: true, animationData: invitationReport}}/>
                      </div>
                    </div>
                    <ul className='example-list'>
                      <li className='example-item'>
                        <strong className='example-item__title'>문장 피드백 / 발음연습 </strong>
                        <img src={process.env.REACT_APP_IMG_URL + '/event/friends/free_feedback@2x.png'} alt='' />
                      </li>
                    </ul>
                    <button type='button' onClick={() => this.go('/FreeLesson')} className='btn-move-application'>1:1 무료수업 신청하기</button>
                  </div>
                </section>
                <section className='invitation__tesol'>
                  <div className='container'>
                    <h2 className='invitation__tesol-title'><span>친구가 유폰을 선택한 이유</span><br />
                      <strong>TESOL 100% 취득</strong> <br />
                      전문 외국인강사와 <br />
                      1:1 무료수업</h2>
                    <img src={process.env.REACT_APP_IMG_URL + '/event/friends/tesol_cont@2x.png'} alt='업계 유일, 25:1 경쟁률, 160 시간의 교육시간, 데모수업 40회' className='desc-img' />
                    <p className='desc-txt'>매 수업 전문강사에게 <br />
                      내 실력에 맞게 교정 받고 칭찬받는 <br />
                      최고의 수업을 경험하세요.</p>
                    <button type='button' onClick={() => this.go('/FreeLesson')} className='btn-move-application'>1:1 무료수업 신청하기</button>
                  </div>
                </section>
                <section className='invitation__class'>
                  <h2 className='invitation__class-title'>지겨울 틈없이 <br />원하는 주제로 배우는 <br /><strong>무제한 콘텐츠</strong></h2>
                  <div className='slide-row slide-row--first'>
                    <Swiper {...classSwiperOption}>
                      {state.imgData1.map((item, index) => {
                        return <div className='swiper-slide' key={index}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/' + item.src} alt={item.alt} />
                        </div>;
                      })}
                    </Swiper>
                  </div>
                  <div className='slide-row slide-row--second'>
                    <Swiper {...classSwiperOption}>
                      {state.imgData2.map((item, index) => {
                        return <div className='swiper-slide' key={index}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/' + item.src} alt={item.alt} />
                        </div>;
                      })}
                    </Swiper>
                  </div>
                  <div className='slide-row slide-row--third'>
                    <Swiper {...classSwiperOption}>
                      {state.imgData3.map((item, index) => {
                        return <div className='swiper-slide' key={index}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/' + item.src} alt={item.alt} />
                        </div>;
                      })}
                    </Swiper>
                  </div>
                  <p className='invitation__class-desc'>일상 회화, 비즈니스, 여행영어는 물론, <br />
                    내가 좋아하는 영화와 드라마까지</p>
                  <button type='button' onClick={() => this.go('/FreeLesson')} className='btn-move-application'>1:1 무료수업 신청하기</button>
                </section>
                <section className='invitation__service'>
                  <article className='lottie-item lottie-item--ai'>
                    <h2 className='lottie-item__title'>
                      음절 단위까지 <br />
                      잡아주는 나만의 <br />
                      <strong>AI 발음 연습 코치</strong>
                    </h2>
                    <div className='lottie-item__animation'>
                      <Lottie options={{loop: true, autoplay: true, animationData: invitationAi}}/>
                      <img src={process.env.REACT_APP_IMG_URL + '/event/friends/badge_ai@2x.png'} alt='실시간 발음교정' className='ico-badge' />
                    </div>
                    <p className='lottie-item__desc'>한국인이 가장 어려워하는 발음  ‘R’과 ‘L’ <br />
                      무제한으로 연습해 보세요.</p>
                  </article>
                  <article className='lottie-item lottie-item--schedule'>
                    <h2 className='lottie-item__title'>
                      <strong>
                        10분 전 취소, <br />
                        100% 보강
                      </strong> <br />
                      터치 한 번에 스케줄 조정
                    </h2>
                    <div className='lottie-item__animation'>
                      <Lottie options={{loop: true, autoplay: true, animationData: invitationSchedule}}/>
                      <img src={process.env.REACT_APP_IMG_URL + '/event/friends/badge_schedule@2x.png'} alt='업계 유일' className='ico-badge' />
                    </div>
                    <p className='lottie-item__desc'>갑자기 잡힌 약속, 야근에 <br />
                      비싼 수업료 아깝게 버리지 마세요.</p>
                  </article>
                  <button type='button' onClick={() => this.go('/FreeLesson')} className='btn-move-application'>1:1 무료수업 신청하기</button>
                </section>
                <section className='invitation__close'>
                  <div className='lottie-item'>
                    <h2 className='lottie-item__title'>
                      처음 가보는 여행지는 <br />
                      늘 긴장되기 마련이니깐 <br />
                      <strong>이제 두려움은 저 멀리, <br />
                        밀어서 영어시작!</strong>
                    </h2>
                    <div className='lottie-item__animation'>
                      <Lottie options={{loop: true, autoplay: true, animationData: invitationCall}}/>
                    </div>
                  </div>
                  <button type='button' onClick={() => this.go('/FreeLesson')} className='btn-move-application'>1:1 무료수업 신청하기</button>
                </section>
                <section className='invitation__notice'>
                  <div className='container'>
                    <SupportBoard supportId={'refEvenNoticeF'} />
                  </div>
                </section>
              </article>
            </>
          }
        </div>
        {state.eMessageFlag &&
				<LayerPopup
				  tit={state.eMessageTxt}
				  btnCnt={1}
				  btnOkCmt={state.commonMsg['0000000094']}
				  btnOkFunction={() => this.closePopup('eMessageFlag')}
				/>
        }
      </>
    );
  }
}
