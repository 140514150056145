import React from 'react';

import completeTrialStore from 'store/trial/complete/completeTrialStore';
import { saveAccessDataByAccessFunction } from 'services/accessData/accessAnalytics';

export default function TrialCompleteAppDownloadButton() {
  const setAppDownloadPopupFlag = completeTrialStore((state) => state.setAppDownloadPopupFlag);
  const trialInfo = completeTrialStore((state) => state.trialInfo);

  const openAppDownloadPopup = () => {
    saveAccessDataByAccessFunction('pop_up_app_down_button');
    if (window.innerWidth >= 1025) {
      setAppDownloadPopupFlag(true);
    } else {
      location.href = trialInfo.appDownloadLink;
    }
  };

  return (
    <>
      {trialInfo.lessonMode === 'VIDEO' &&
        <section className={'trialComplete__notice'}>
          <h1 className={'trialComplete__notice--title'}>꼭 알아두세요!</h1>
          <p className={'trialComplete__notice--content'}>반드시 아래 버튼을 눌러 유폰 앱을 다운로드하고,<br />무료수업에 출석해 주시기 바랍니다.</p>
        </section>
      }
      <button onClick={openAppDownloadPopup} className='trialComplete__book'>앱에서 수업 준비하기</button>
    </>
  );
}
