import React from 'react';
import completeTrialStore, { getTrialDate, isChangeMode } from 'store/trial/complete/completeTrialStore';

export default function TrialCompleteTitle() {
  const trialInfo = completeTrialStore((state) => state.trialInfo);
  const trialDate = getTrialDate();
  const description = isChangeMode()? '수업예약이 변경되었어요!' : '수업이 예약되었어요!';
  
  return (
    <div className='trialComplete__title'>
      <p className='title'>
        {trialInfo.memberName}님, 응원합니다.<br/>
        <span className='lesson-time'>{trialDate}</span>으로<br />
        {description}
      </p>
      <img className='icon' src={process.env.REACT_APP_IMG_URL + '/trial/complete/trial_complete_ico_calendar.png'} />
    </div>
  );
}
