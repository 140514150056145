import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import DOMPurify from 'dompurify';
import { ScrollNone } from 'common/utilFn';
import 'css/purchase/PurchaseShowcaseB2C.scss';
import { useEmergencyMessage } from 'queries/purchase/usePurchaseService';
import purchaseShowcaseB2CStore from 'store/purchase/purchaseShowcaseB2CStore';

const PurchaseShowcaseB2CEmergency: FC = () => {
  const { isFetched, data: emergencyMessage } = useEmergencyMessage();
  const [showPopup, setShowPopup] = useState(false);
  const { setShowEmergencyPopup } = purchaseShowcaseB2CStore.getState();

  const openPopup = useCallback(() => {
    ScrollNone(true);
    setShowPopup(true);
  }, []);

  const closePopup = useCallback(() => {
    ScrollNone(false);
    setShowPopup(false);
  }, []);

  useEffect(() => {
    if (isFetched && emergencyMessage?.supportSeq) {
      const supportSeq = sessionStorage.getItem('orderissue');
      if (supportSeq && Number(supportSeq) === emergencyMessage.supportSeq) return;
      sessionStorage.setItem('orderissue', emergencyMessage.supportSeq.toString());
      setShowPopup(true);
      setShowEmergencyPopup(true);
    }
  }, [isFetched]);


  const content = useMemo(() => {
    return emergencyMessage?.content?.replace(/\r?\n/g, '<br/>');
  }, [emergencyMessage?.content]);

  if (!isFetched || !emergencyMessage?.supportSeq) return null;

  return (
    <>
      <button className='purchase-showcase-emergency-button' onClick={openPopup} />
      {
        showPopup && (
          <>
            <div className='purchase-showcase-emergency-popup'>
              <div className='purchase-showcase-emergency-popup__body'>
                <strong className='popup-title'>{emergencyMessage.title}</strong>
                <div className='popup-content' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content || '') }} />
              </div>
              <button type='button' className='popup-close' onClick={closePopup}>
                닫기
              </button>
            </div>
            <div className='purchase-showcase-emergency-popup__dimmed' />
          </>
        )
      }
    </>
  );
};
export default PurchaseShowcaseB2CEmergency;
