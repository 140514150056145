import React, {useState} from 'react';
import {ExpressionType} from 'component/book/book-type';

interface PropsType {
  content: ExpressionType;
}

export default function TodayExpression({content}: PropsType) {
  const [showMemo, setShowMemo] = useState(false);
  const [firstSpeaker, setFirstSpeaker] = useState('A');
  return (
    <div className={'page TE'}>
      <div className={'component-name'}>Today's Expression</div>
      <div className='component-desc'>오늘의 핵심 표현을 이해하고, 대화에 적용해 보세요.</div>

      <div className={'expression-wrapper'}>
        <div className={'definition'}>{content.definition}</div>
        <hr className={'line'}/>
        <div className='description'>{content.dialogueFlag?'강사님과 역할을 나누어 대화문을 읽어보세요.':'아래 글을 소리내어 읽어 보세요.'}</div>
      </div>

      {/* 본문 스크립트 형태 */}
      {!content.dialogueFlag &&
        content.script.map((item, index) => (
          <div className='content' key={`s${index}`}>
            {item.speaker ? `${item.speaker}: ` : ''}
            {item.sentence}
          </div>
        ))
      }

      {/* 본문 대화 형태 */}
      {content.dialogueFlag &&
        (<>
          {
            <button className={'switch'}
              onClick={() =>
                firstSpeaker === 'A' ? setFirstSpeaker('B') : setFirstSpeaker('A')
              }
            >
              <img src={`${process.env.REACT_APP_IMG_URL}/icon/switch_role.svg`} alt='switch' />
              <span>Switch</span>
            </button>
          }

          <div className='dialogue-wrapper'>
            {content.script &&
                content.script.map((item, index) => (
                  <div
                    key={`s${index}`} className={`speaker ${
                      firstSpeaker === 'A'
                        ? item.speaker === 'A'
                          ? 'A'
                          : 'B'
                        : item.speaker === 'A'
                          ? 'B'
                          : 'A'
                    }`}
                  >
                    {item.speaker === firstSpeaker && (
                      <div
                        className={`part ${firstSpeaker === 'A' ? 'a' : 'as'}`}
                      >
                        Student<span>S</span>
                      </div>
                    )}
                    {item.speaker !== firstSpeaker && (
                      <div
                        className={`part ${firstSpeaker === 'A' ? 'b' : 'bs'}`}
                      >
                        <span>T</span>Teacher
                      </div>
                    )}
                    <div className='bubble'>
                      <div className='sentence'>{item.sentence}</div>
                    </div>
                  </div>
                ))}
            {Array.isArray(content) &&
                content.map((item, index) => (
                  <div
                    key={`s${index}`}
                    className={`speaker ${
                      firstSpeaker === 'A'
                        ? item.speaker === 'A'
                          ? 'A'
                          : 'B'
                        : item.speaker === 'A'
                          ? 'B'
                          : 'A'
                    }`}
                  >
                    {item.speaker === firstSpeaker && (
                      <div
                        className={`part ${firstSpeaker === 'A' ? 'a' : 'as'}`}
                      >
                        Student<span>S</span>
                      </div>
                    )}
                    {item.speaker !== firstSpeaker && (
                      <div
                        className={`part ${firstSpeaker === 'A' ? 'b' : 'bs'}`}
                      >
                        <span>T</span>Teacher
                      </div>
                    )}
                    <div className='bubble'>
                      <div className='sentence'>{item.sentence}</div>
                    </div>
                  </div>
                ))}
          </div>
        </>
        )}

      <div className={'expression-wrapper mt'}>
        <hr className={'line'}/>
        <div className='description'>나만의 문장을 말해보세요.</div>
        {content?.memo && (
          <div className={'memo'}>
            <div
              className={showMemo ? 'memo-button open' : 'memo-button close'}
              onClick={() => setShowMemo((v) => !v)}
            >
              메모
            </div>
            {showMemo && <div className={'memo-content'}>{content.memo}</div>}
          </div>
        )}
      </div>
    </div>
  );
}
