import React from 'react';
import {PictureLTType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: PictureLTType;
}

export default function Picture({componentCode, content}: PropsType) {
  return (
    <div className={`page ${componentCode}`}>
      <div className={'leveltest-component-name'}>Part 4. 유창성&이해력</div>
      <div className='component-desc'>사진을 보고 질문에 답해보세요.</div>
      {content.imageUrl && (
        <div className='pictureBox'>
          <img className='picture' src={content.imageUrl} alt='' />
        </div>
      )}
      {content.examples && content.examples.length > 0 && (
        <div className='tip'>
          <div className='tip__title'>
            <span>TIP</span> 아래 단어를 활용해도 좋아요
          </div>
          <ul>
            {content.examples.map((item, index) => (
              <li key={`k${index}`}>
                {item.sentence}
                {item.description ? <span>{item.description}</span> : ''}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}
