import React from 'react';
import loading from 'json/loading.json';
import 'css/loading.scss';
import Lottie from 'react-lottie';

export const Loading: React.FC = () => {
  return (
    <div className={'loading-container'} >
      <div className={'loading-container__lottie'}>
        <Lottie options={{ loop: true, autoplay: true, animationData: loading }} />
      </div>
    </div>
  );
};
