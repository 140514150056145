import React, {Component} from 'react';
import axios from 'axios';

import '../css/course.scss';
import '../css/ClassRoom.scss';

import Top from '../component/top';
import Footer from '../component/Footer';
import ClassCard from '../component/ClassCard';
import * as utilFn from '../common/utilFn';
import SupportBoard from '../component/common/SupportBoard';
import ChannelService from '../component/ChannelService';
import ReactMeta from '../component/ReactMeta';
import ChannelTalkButton from 'component/channelTalk/ChannelTalkButton';
import {httpDefaultClient, httpRefreshClient} from 'configs/api';
import Lottie from 'react-lottie';
import loading from 'json/loading.json';

export default class ClassRoom extends Component {
  constructor(props) {
    super(props);
    this.preview = React.createRef();
    this.state = {
      loading: true,
      filterView: false,
      ppt01: true,
      popuCourseL: [],
      th: 'white',
      showModal: false,
      mGnb: false,
      tgNotice: !utilFn.getCookie('closeWebClassroomNotice'),
    };
  }

  async componentDidMount() {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    window.addEventListener('resize', () => {
      this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    });
    this.getCommonMsgList();
    try {
      const {data: {result}} = await httpRefreshClient.post('/sign/checkLogin');
      if (result) {
        this.getWebLectureRoom();
      } else {
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Failed to /sign/checkLogin:', error);
    }

    // Boot Channel
    ChannelService.boot({
      'pluginKey': '86cab79d-4bcc-4b38-b517-4808b0bcbe6b', //please fill with your plugin key
      'customLauncherSelector': '.channel-talk',
      'hideChannelButtonOnBoot': true,
    }, this.getChannelTalkBadge);

    ChannelService.setPage(window.location.href);
  }

  componentWillUnmount() {
    // Shutdown Channel
    ChannelService.shutdown();
  }

  getChannelTalkBadge = (count) => {
    this.setState({channelTalkBadge : count});
  };

  getCommonMsgList = () => {
    if (!this.state.commonMsg) {
      axios({
        method: 'GET',
        url: process.env.REACT_APP_API_URL + '/commonMessage/getList',
        params: {
          'languageCode': 'ko'
        }
      })
        .then((response) => {
          this.setState({
            commonMsg: response.data,
          });
        })
        .catch((error) => {
          console.error('getCommonMsgList: ', error);
        });
    }
  };

  // API : Web 강의실 리스트 조회
  getWebLectureRoom = () => {
    httpDefaultClient.get('/lectureRoom/web')
      .then((response) => {
        const {
          result,
          data,
        } = response.data;
        if (!result) {
          alert(this.state.commonMsg['COURSE0016']);
          this.props.history.push('/');
          return false;
        } else {
          let testData = [...data.unitList];
          testData.reverse();
          const find = testData.findIndex(el => !(el.feedbackstatusCode === 'BEFORE' || !el.feedbackstatusCode));
          const arrival = testData.length - find;

          this.setState({
            pLectList: data.unitList,
            courseDetail: data.course,
            languageType: data.course.languageType,
            currLessonCount: find === -1 ? 0 : find === 0 ? data.unitList.length + 1 : arrival,
            nextCourseName: data.nextCourseName,
            videoUrl: data.videoUrl
          });

          if (data.videoUrl) {
            ChannelService.hideBtn();
          }
        }
      })
      .then(() => {
        this.setState({
          loading: false,
        });
      })
      .catch((error) => {
        alert(this.state.commonMsg['COURSE0016']);
        this.props.history.push('/');
      });
  };

  // 화상 강의실 입장
  enterVideoClass = () => {
    window.open(this.state.videoUrl);
  };

  // 공지사항 열기/닫기
  toggleNotice = () => {
    if (this.state.tgNotice) {
      utilFn.setCookie('closeWebClassroomNotice', true, 365);
    } else {
      utilFn.deleteCookie('closeWebClassroomNotice');
    }

    this.setState({
      tgNotice : !this.state.tgNotice
    });
  };

  // 강의실 새로고침
  listReload = () => {
    this.getWebLectureRoom();
    window.scrollTo(0, 0);
  };

  render() {
    const state = this.state;

    return (
      <>
        <ReactMeta title='3000+ 오리지널 콘텐츠를 무제한으로' description='지금 가장 핫한 3000+ 트렌드 과정으로 1:1영어회화'/>
        <div
          className={'coursePage p4'}>
          <Top
            title={''}
            sub={''}
            history={this.props.history}
            noBg = {null}
            notFixed ={false}
            theme={(state.sticky4 && state.th)}
            useTit={true}
            setTopBannerMargin = {this.ww === 'pc' && this.props.setTopBannerMargin}
            onn = {this.ww === 'pc' ? 'on' : ''}
          />
          <div id={'step2'} className={'step2 step4'}>
            {state.courseDetail &&
            <>
              {/* 과정명 */}
              <div className={'sticky off'}>
                <div className={'pageTit'}>
                  {state.courseDetail.courseName}
                </div>
              </div>
              <div className={'stepWrap stepWrap02'}>
                <div className={'stepInner'}>
                  <div className={'desc'}
                    dangerouslySetInnerHTML={{__html: state.courseDetail.courseDescription}}/>
                  <div className={'detailInfo'}>
                    <div className={'tit'}>
                      권장레벨
                    </div>
                    <ul className={'rcmdLv'}>
                      <li>{state.courseDetail.levelDesc1}</li>
                      <li>{state.courseDetail.levelDesc2}</li>
                      <li>{state.courseDetail.levelDesc3}</li>
                    </ul>
                  </div>

                  {/* 공지사항 */}
                  <div className={'noticeWrap'}>
                    <div className={'tit'} onClick={this.toggleNotice}>
                      <img className={'info'} src={process.env.REACT_APP_IMG_URL + '/common/ico_excm.svg'} alt='info'/>
                        웹 강의실 이용 안내
                      <img className={'arrow ' + (state.tgNotice ? 'up' : '')} src={process.env.REACT_APP_IMG_URL + '/common/arrow_down.svg'} alt='arrow'/>
                    </div>
                    <div className={'content ' + (state.tgNotice ? '' : 'hide')}>
                      <SupportBoard supportId={'classRoomNotice'} />
                    </div>
                  </div>

                  {/* 화상수업 입장 버튼 */}
                  { this.state.videoUrl &&
                    <div className={'videoBnr'}>
                      <div className={'txtWrap'}>
                        <div className={'title'}>화상수업에 입장하세요.</div>
                        수업 시작 시간에 입장이 승인됩니다.
                      </div>
                      <button className={'btn'} onClick={this.enterVideoClass}>입장하기</button>
                    </div>
                  }

                  {/* 유닛 카드 */}
                  <div className={'classPage'}>
                    <div className={'classN'}>
                      {state.pLectList.map((v, n) => {
                        return (
                          <ClassCard key={v + n}
                            lectType={'pLectList'}
                            t={v}
                            n={n}
                            disableClass={(!this.state.currLessonCount && n >= 2) || (this.state.currLessonCount > 1 && n > this.state.currLessonCount - 1) || (this.state.currLessonCount === 1 && n >= 2)}
                            lockCard={(n === 1 && this.state.currLessonCount < 2) || (n === (this.state.currLessonCount - 1) && this.state.currLessonCount >= 2)}
                            preview={false}
                            txtBook={true}
                            ww={this.ww}
                          />
                        );
                      })}
                    </div>
                  </div>

                  {/* 다음 과정 안내 */}
                  <div className={'guide'}>
                    다음 과정 '{state.nextCourseName}'은 마지막 Unit 수업이 끝남과 동시에 변경됩니다.
                    <p className={'info'}>
                      <img src={process.env.REACT_APP_IMG_URL + '/common/ico_excm.svg'} alt='info'/>
                      혹시 다음 과정이 보이지 않는다면 '<span onClick={this.listReload}>새로고침</span>'해주세요.
                    </p>
                  </div>
                </div>
              </div>
            </>
            }
          </div>
          {state.loading &&
            <div className={'lottieLoadingBg'}>
              <div className={'lottieLoading'}>
                <Lottie options={{loop: true, autoplay: true, animationData: loading}}/>
              </div>
            </div>
          }
          <Footer ww={this.ww}/>
        </div>
        <ChannelTalkButton badgeCount={this.state.channelTalkBadge}/>
      </>
    );
  }
}
