import {create, } from 'zustand';
import { devtools } from 'zustand/middleware';

import appDownloadPopupSlice, { AppDownloadPopupSliceT } from './appDownloadPopupSlice';

export const popupStore = create<
AppDownloadPopupSliceT
>()(devtools((...all) => ({
  ...appDownloadPopupSlice(...all),
}), { store: 'popup' }));

export default popupStore;
