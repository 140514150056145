import React, { useEffect, useMemo, useState } from 'react';
import {useHistory} from 'react-router-dom';
import { commonCodeConverter, ScrollNone, isHiAliceProduct } from 'common/utilFn';
import Footer from 'component/Footer';
import { httpDefaultClient } from 'configs/api';
import 'css/order.scss';
import 'css/orderImprove.scss';
import { useMultipleCommonCodes } from 'queries/common/useCommonCode';
import { useAllCommonMessage } from 'queries/common/useCommonMessage';
import { saveAccessDataByAccessFunction } from 'services/accessData/accessAnalytics';
import { AfterPurchaseInfo, PurchaseResult } from 'types/purchase/purchase.interface';

interface Props {
  purchaseInfo: PurchaseResult;
}

const OrderCompleted = ({ purchaseInfo }: Props) => {
  const history = useHistory();
  const { data: commonMsg = {}, isFetched: isCommonMsgFetched } = useAllCommonMessage();
  const { data: commonCodes = {} } = useMultipleCommonCodes(['ASSIGNTEM', 'TIMEZONE']);
  const [infoName, setInfoName] = useState('');
  const [footerTitle, setFooterTitle] = useState('');
  const [footerButton, setFooterButton] = useState('');
  const [afterPurchaseInfo, setAfterPurchaseInfo] = useState<AfterPurchaseInfo | null>(null);
  const [mode, setMode] = useState<string>(window.innerWidth >= 1025 ? 'pc' : 'mo');
  const [showOrderAppPopup, setShowOrderAppPopup] = useState(false);

  useEffect(() => {
    saveAccessDataByAccessFunction('pay_fin_referrer');
    const handleResize = () => {
      setMode(window.innerWidth >= 1025 ? 'pc' : 'mo');
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      closeOrderAppPopup();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isCommonMsgFetched) {
      getAfterPurchaseInfo();
    }
  }, [isCommonMsgFetched]);

  // 첫 수업일 요일 조회
  const firstLessonWeekday = useMemo(() => {
    if (!afterPurchaseInfo) { return ''; }
    const firstLessonDate = afterPurchaseInfo.firstLessonDate;
    if (!firstLessonDate) { return ''; }
    const date = new Date(firstLessonDate);
    const options: Intl.DateTimeFormatOptions = { weekday: 'short' as const };
    return date.toLocaleDateString('ko-KR', options);
  }, [afterPurchaseInfo]);

  // 첫 수업일 포맷팅
  const firstLessonData = useMemo(() => {
    if (!afterPurchaseInfo) { return ''; }
    const firstLessonDate = afterPurchaseInfo.firstLessonDate;
    if (!firstLessonDate) { return ''; }
    return firstLessonDate.replace(/-/g, '.');
  }, [afterPurchaseInfo]);

  // 수강권 구매 후 정보 조회
  const getAfterPurchaseInfo = async () => {
    try {
      const { data } = await httpDefaultClient.get(`/purchase/afterPurchaseInfo/${purchaseInfo.purchaseSeq}`);
      setAfterPurchaseInfo(data);
      let infoName; // 결제완료 타이틀
      let footerTitle; // 하단 문구
      let footerButton; // 하단 버튼 문구
      if (data.retakeCode === 'NEW') { // 신규
        infoName = commonMsg['PUR000112'];
        footerTitle = data.languageCategory === 'SELF' ? data.freelessonSeq ? commonMsg['MATCH00046'] : commonMsg['MATCH00047'] : commonMsg['MATCH00053'];
        footerButton = data.languageCategory === 'SELF' ? commonMsg['MATCH00044'] : commonMsg['MATCH00052'];
      } else { // 기간 내 재수강 (RETAKE), 기간 외 재수강 (RETAKE_OUT)
        infoName = commonMsg[data.retakeCode === 'RETAKE' ? 'PUR000113' : 'PUR000114'];
        if (data.lessonTimeChangeFlag) {  // lessonTimeChangeFlag : 수업시간 변경 노출 여부
          footerTitle = commonMsg[data.retakeCode === 'RETAKE' ? 'PUR000121' : 'PUR000132'].replace('$1', data.lessonTime);
          footerButton = commonMsg['PUR000124'];
        } else {
          footerTitle = commonMsg['PUR000122'];
          footerButton = commonMsg['PUR000125'];
        }
      }
      console.log('infoName', infoName);
      setInfoName(infoName.replace('$1', data.memberName));
      setFooterTitle(footerTitle);
      setFooterButton(footerButton);
    } catch (error) {
      console.error('getAfterPurchaseInfo', error);
    }
  };

  // 앱 팝업 열기
  const openOrderAppPopup = () => {
    ScrollNone(true);
    setShowOrderAppPopup(true);
  };

  // 앱 팝업 닫기
  const closeOrderAppPopup = () => {
    ScrollNone(false);
    setShowOrderAppPopup(false);
  };

  return (
    <>
      <div className='orderPage p3'>
        <div className={'step3'}>
          {mode === 'pc' &&
            <header className='order-payment-header'>
              <h1 className='order-payment-header__logo'><a href={process.env.REACT_APP_MAIN_URL + '/'}><span className='blind'>민병철유폰</span></a></h1>
            </header>
          }
          <div className={'orderProduct top'}>
            <div>
              <img className={'checkIcon'}
                src={process.env.REACT_APP_IMG_URL + '/order/ico_check_gra.svg'}
                alt={'check'} />
              <div className={'title'}>{commonMsg['PUR000110']}</div>
              <div className={'subInfo'}>
                {infoName}<br />
                {commonMsg['PUR000111']}
              </div>

              {firstLessonData && (
                <div className='order-first-date'>
                  <img src={process.env.REACT_APP_IMG_URL + '/order/ico_lesson_book.svg'} alt={'lesson_book'} />
                  <p>첫 수업일</p>
                  <span>{firstLessonData} ({firstLessonWeekday})</span>
                </div>
              )}
              <section className='order-summary'>
                <dl className='order-summary__item'>
                  <dt>{commonMsg['PUR000115']}</dt>
                  <dd>{purchaseInfo.productPrice.productName}</dd>
                </dl>
                {purchaseInfo.productPrice.productType!=='C' &&
                <>
                  <dl className='order-summary__item'>
                    <dt>{commonMsg['PUR000116']}</dt>
                    <dd>{purchaseInfo.productPrice.productMinute}분 수업</dd>
                  </dl>
                  <dl className='order-summary__item'>
                    <dt>{commonMsg['0000000083']}</dt>
                    <dd>{commonCodeConverter(commonCodes.ASSIGNTEM, 'note2', purchaseInfo.productPrice.templateCode)}(주{commonCodeConverter(commonCodes.ASSIGNTEM, 'note3', purchaseInfo.productPrice.templateCode)}회)</dd>
                  </dl>
                  <dl className='order-summary__item'>
                    <dt>{commonMsg['0000000579']}</dt>
                    <dd>{commonCodeConverter(commonCodes.TIMEZONE, 'note5', purchaseInfo.productPrice.timezoneCode)?.replace(/ /gi, '').replace(',', ' + ')}</dd>
                  </dl>
                </>
                }
                <dl className='order-summary__item'>
                  <dt>{commonMsg['PUR000117']}</dt>
                  <dd>
                    {!purchaseInfo.productPrice.billFlag &&
                      <div>{purchaseInfo.productPrice.productWeek}주{purchaseInfo.productPrice.productType !== 'C' && `(총 ${Number.parseInt(commonCodeConverter(commonCodes.ASSIGNTEM, 'note3', purchaseInfo.productPrice.templateCode)) * purchaseInfo.productPrice.productWeek}회 수업)`}</div>
                    }
                    <span>{purchaseInfo.startlessonDate.replace(/-/g, '.')}~{purchaseInfo.productPrice.billFlag ? '정기수강' : purchaseInfo.endlessonDate.replace(/-/g, '.')}</span>
                    {purchaseInfo.productPrice.billFlag && <span><br />다음 결제 예정일 : {purchaseInfo.endlessonDate.replace(/-/g, '.')}</span>}
                  </dd>
                </dl>
                {
                  purchaseInfo.book &&
                  <>
                    <dl className='order-summary__item'>
                      <dt>{commonMsg['PUR000164']}</dt>
                      <dd>
                        {purchaseInfo.book.courseName}
                      </dd>
                    </dl>
                    <dl className='order-summary__item'>
                      <dt>{commonMsg['PUR000165']}</dt>
                      {purchaseInfo.book.applyFlag &&
                        <dd>
                          {commonMsg['PUR000166']}
                          <div className='order-summary__item-address'>주소: {purchaseInfo.book.address} {purchaseInfo.book.addressDetail}</div>
                        </dd>
                      }
                      {!purchaseInfo.book.applyFlag && <dd>{commonMsg['PUR000167']}</dd>}
                    </dl>
                  </>
                }
                <ul className='order-summary__notice'>
                  <li>{commonMsg['PUR000118']}</li>
                  {/* 기간내 재수강 일 경우만 노출 */}
                  {afterPurchaseInfo?.retakeCode === 'RETAKE' && <li>{commonMsg['PUR000119']}</li>}
                </ul>
              </section>
              {!isHiAliceProduct(purchaseInfo.productPrice.productName)
                ?
                <section className='order-confirm'>
                  <p className='order-confirm__notice' dangerouslySetInnerHTML={{__html: footerTitle}}/>
                  {afterPurchaseInfo?.retakeCode === 'NEW' && afterPurchaseInfo?.languageCategory === 'SELF' &&
                    <article className='order-matching'>
                      <section className='recommend-item'>
                        <h2 className='recommend-item__title'>{commonMsg['MATCH00014']}</h2>
                        <div className='recommend-item__time'>
                          {afterPurchaseInfo?.productType === 'C' || afterPurchaseInfo?.productType === 'P' || afterPurchaseInfo?.lessonTime === '00:00' ?
                            <strong
                              className='recommend-item__time-message'>{commonMsg[afterPurchaseInfo?.productType === 'C' ? 'MATCH00015' : 'MATCH00045']}</strong>
                            : <strong className='recommend-item__time-value'>{afterPurchaseInfo?.lessonTime}</strong>
                          }
                        </div>
                        {afterPurchaseInfo.lessonTimeChangeFlag &&
                          <button type='button' className='recommend-item__btn-change' onClick={openOrderAppPopup}>
                            <span>{commonMsg['0000000231']}</span></button>
                        }
                      </section>
                      <section className='recommend-item'>
                        <h2 className='recommend-item__title'>{commonMsg['MATCH00016']}</h2>
                        <div className='recommend-item__course'>
                          <div className='recommend-item__course-column recommend-item__course-column--img'>
                            <img src={process.env.REACT_APP_LMS_IMG_URL + afterPurchaseInfo?.imgUrl} alt='과정 이미지'/>
                          </div>
                          <div className='recommend-item__course-column recommend-item__course-column--info'>
                            <strong className='recommend-item__course-subject'>{afterPurchaseInfo?.courseName}</strong>
                            <strong
                              className='recommend-item__course-unit'>{commonMsg['MATCH00017'].replace('$1', `${afterPurchaseInfo?.unitCount}`)}</strong>
                          </div>
                        </div>
                        <button type='button' className='recommend-item__btn-change' onClick={openOrderAppPopup}>
                          <span>{commonMsg['MATCH00019']}</span></button>
                      </section>
                    </article>
                  }
                  <button type='button' className='btn btn--submit' onClick={openOrderAppPopup}>{footerButton}</button>
                  {(afterPurchaseInfo?.retakeCode !== 'NEW' && afterPurchaseInfo?.lessonTimeChangeFlag) &&
                    <button type='button' className='btn-txt'
                      onClick={openOrderAppPopup}>{commonMsg['PUR000126']}</button>
                  }
                </section>
                :
                <button type='button' className='btn btn--submit' onClick={() => history.push('/')}>{commonMsg['0000000094']}</button>
              }

              {/* 앱 이동 유도 팝업 */}
              {showOrderAppPopup &&
                <>
                  <div className='msk'></div>
                  <article className='order-app-popup bottomPop'>
                    <h2 className='order-app-popup__title'>{commonMsg['PUR000127']}</h2>
                    <p className='order-app-popup__desc'>{commonMsg['PUR000128']}</p>
                    {/* mobile */}
                    <a className='btn' href={`https://r${window.location.hostname === 'app.uphone.co.kr' ? '' : 'd'}.uphone3.com/home`}>{commonMsg['PUR000129']}</a>
                    <button className='btn-txt-close' type='button' onClick={closeOrderAppPopup}>{commonMsg['PUR000130']}</button>
                    {/*  pc */}
                    <div className='app-qr'>
                      <div className='app-qr__img'>
                        <img src={process.env.REACT_APP_IMG_URL + '/matching/app_qr.png'} alt='민병철 유폰 앱으로 보기 QR코드' />
                      </div>
                      <strong className='app-qr__notice'>{commonMsg['PUR000131']}</strong>
                    </div>
                    <button className='btn-close' type='button' onClick={closeOrderAppPopup}><span className='blind'>닫기</span></button>
                  </article>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      <Footer ww={mode} />
    </>
  );

};

export default OrderCompleted;
