import React from 'react';
import reserveTrialStore, { isChangeMode } from 'store/trial/reserve/reserveTrialStore';
import TrialInputUserInfo from './TrialInputUserInfo';
import TrialDisabledUserInfo from './TrialDisabledUserInfo';

export default function TrialUserInfo() {
  const memberFlag = reserveTrialStore((state) => state.memberFlag);
  
  if (isChangeMode()) {
    return null;
  }
  return (
    <>
      <div className={'titleBox'}>
        <div className={'titleBox__title'}>수업 정보</div>
      </div>
      {memberFlag ? <TrialDisabledUserInfo /> : <TrialInputUserInfo />}
    </>
  );
}
