import moment from 'moment';
import {httpDefaultClient, httpRefreshClient} from 'configs/api';

export const storage = {
  set: (key, object) => {
    if (!localStorage) return;
    localStorage[key] = (typeof object) === 'string' ? object : JSON.stringify(object);
  },
  get: (key) => {
    if (!localStorage) return null;

    if (!localStorage[key]) {
      return null;
    }

    try {
      const parsed = JSON.parse(localStorage[key]);
      return parsed;
    } catch (e) {
      return localStorage[key];
    }
  },
  remove: (key) => {
    if (!localStorage) return null;

    if (localStorage[key]) {
      localStorage.removeItem(key);
    }
  }
};
//이름, 전화번호, 이메일, 인증번호
export function nameChk(lang, v) {
  let nameRex;

  if (lang==='ko'){
    nameRex = /^[가-힣]+$/;
  }
  const a = nameRex.test(v);
  let b;

  if (v === '') {
    b = '필수 정보입니다.';
  } else if (v.length < 2 || v.length > 6) {
    b = '이름은 한글 2~6자까지 사용 가능합니다.';
  } else {
    a ? b = '' : b = '이름은 한글 2~6자까지 사용 가능합니다.';
  }

  return b;
}
export function nameInput(lang, v, ml){
  let a;
  if (v.length <= ml){
    a = v.replace(/[a-z0-9]|[\[\]{}()<>?|`~!@#$%^&*-_+=,.;:\"\'\\\/]|[\s*]/gi, '');
  } else {
    a = v.slice(0, ml);
  }
  return a;
}
export function hasUnicode(v) {
  return v.match(/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g);
}
export function emailChk(lang, v) {
  console.warn('email = ' + lang, v);
}
export function emailInput(lang, v) {
  const regex=/^[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[@]{1}[-A-Za-z0-9_]+[-A-Za-z0-9_.]*[.]{1}[A-Za-z]{1,5}$/;
  v = v.replace(regex, '');

  return v;
}

export function cellPhoneChk(lang, v) {
  let a;
  v.length < 12 ? a = '휴대전화번호 형식이 올바르지 않습니다. 정확하게 다시 입력해주세요.': a='';

  return a;
}

export function cellPhoneInput(lang, v){
  const regex = /[^0-9]/;
  v = v.replace(regex, '');
  const a = formChk('cellPhone', v);

  return a[1];
}

export function cpInput(lang, v){
  const regex = /[^0-9]/;
  v = v.replace(regex, '');
  const a = formChk('cp', v);

  return a[1];
}

export function authInput(lang, v){

  return v;
}
export function authChk(lang, v){
  //return v
}

const formChk = (t, n) => {
  const phoneRex = /^\d{3}-\d{3,4}-\d{4}$/;
  let v = n;
  v = v.replace( /-/gi , '');
  if (v.length >= 2  &&  v.length <= 5 ){
    const nn =  /(\d{2})(\d{1,3})/;
    v.replace(nn, function(str, p1, p2){
      v = p1 + '-' + p2;
    });
  } else if (v.length >= 6 && v.length <= 9){
    const nn =  /(\d{2})(\d{1,3})(\d{1,4})/;
    v.replace(nn, function(str, p1, p2, p3){
      v = p1 + '-' + p2 + '-' + p3;
    });
  } else if (v.length === 10){
    // 일반전화이면서 서울일때
    if (v.substring(1, 2) === '2') {
      const nn =  /(\d{2})(\d{1,4})(\d{2,4})/;
      v.replace(nn, function(str, p1, p2, p3){
        v = p1 + '-' + p2 + '-' + p3;
      });
    } else {
      const nn =  /(\d{3})(\d{1,3})(\d{2,4})/;
      v.replace(nn, function(str, p1, p2, p3){
        v = p1 + '-' + p2 + '-' + p3;
      });
    }
  } else if (v.length > 10){
    const nn =  /(\d{3})(\d{1,4})(\d{2,4})/;
    v.replace(nn, function(str, p1, p2, p3){
      v = p1 + '-' + p2 + '-' + p3;
    });
  }
  const p = phoneRex.test(v);
  return [p, v];
};
export function whatDay(d){
  const week = ['일', '월', '화', '수', '목', '금', '토'];
  return week[new Date(d).getDay()];
}
export function ScrollNone(a){
  if (a){
    const y = window.scrollY || window.pageYOffset; // 현재 스크롤 위치
    window.currentScroll = y;
    document.querySelectorAll('html')[0].style.marginTop = y*-1 + 'px';
    document.querySelectorAll('html')[0].style.overflow = 'hidden';
    document.querySelectorAll('body')[0].style.position = 'fixed';
    document.querySelectorAll('body')[0].style.left = 0;
    document.querySelectorAll('body')[0].style.right = 0;
  } else {
    document.querySelectorAll('html')[0].style.marginTop = 0;
    document.querySelectorAll('html')[0].style.overflow = 'initial';
    document.querySelectorAll('body')[0].style.setProperty('position', 'initial');
    window.scrollTo(0, window.currentScroll);
  }
}
export class LevelInfo{
  constructor(lv) {

  }
  getInfo(lv){
    switch (lv){
    case 1 :
      return {
        contentLevel : 'low',
        minScore : 50,
        maxScore : 50
      };
    case 2 :
      return {
        contentLevel : 'low',
        minScore : 65,
        maxScore : 95
      };
    case 3 :
      return {
        contentLevel : 'low',
        minScore : 110,
        maxScore : 155
      };
    case 4 :
      return {
        contentLevel : 'middle',
        minScore : 170,
        maxScore : 200
      };
    case 5 :
      return {
        contentLevel : 'middle',
        minScore : 215,
        maxScore : 245
      };
    case 6 :
      return {
        contentLevel : 'middle',
        minScore : 260,
        maxScore : 305
      };
    case 7 :
      return {
        contentLevel : 'middle',
        minScore : 320,
        maxScore : 350
      };
    case 8 :
      return {
        contentLevel : 'high',
        minScore : 365,
        maxScore : 395
      };
    case 9 :
      return {
        contentLevel : 'high',
        minScore : 410,
        maxScore : 455
      };
    case 10 :
      return {
        contentLevel : 'high',
        minScore : 470,
        maxScore : 500
      };
    default:
      console.warn('레벨 값에 해당되는 결과가 없습니다.');
    }
  }
  //getInfo

  infoFromScore(sc){
    //{
    // 				'communication' : 의사소통,
    // 				'pronunciation' : 발음,
    // 				'grammar' : 문법,
    // 				'fluency' : 유창성,
    // 				'comprehension': 이해력
    // 			};

    if (sc >= 50 && sc <= 155){
      //초급
      return {
        'communication' : 24,
        'pronunciation' : 23,
        'grammar' : 21,
        'fluency' : 21,
        'comprehension': 23
      };

    } else if (sc >= 160 && sc <= 350){
      //중급
      return {
        'communication' : 63,
        'pronunciation' : 59,
        'grammar' : 55,
        'fluency' : 57,
        'comprehension': 61
      };

    } else if (sc >= 355 && sc <= 500){
      //고급
      return {
        'communication' : 92,
        'pronunciation' : 88,
        'grammar' : 85,
        'fluency' : 87,
        'comprehension': 89
      };

    }
  }
  //infoFromScore
}

export function setMarketingCodeValue(k, v, e){
  const item = {
    value: v,
    expiry: moment().add(e, 'days').format('YYYYMMDD'),
  };
  localStorage.setItem(k, JSON.stringify(item));
}

export function getMarketingCodeValue(k){
  const itemStr = localStorage.getItem(k);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  if (moment() > moment(item.expiry)) {
    localStorage.removeItem(k);
    return null;
  }
  return item.value;
}

export function removeMarketingCodeValue(k){
  const itemStr = localStorage.getItem(k);
  if (itemStr) {
    localStorage.removeItem(k);
  }
}

export function mobileChk(){
  const mobileKeyWords = new Array('iPhone', 'iPod', 'BlackBerry', 'Android', 'Windows CE', 'Windows CE;', 'LG', 'MOT', 'SAMSUNG', 'SonyEricsson', 'Mobile', 'Symbian', 'Opera Mobi', 'Opera Mini', 'IEmobile');
  let mobileChk;
  for (let word in mobileKeyWords) {
    if (navigator.userAgent.match(mobileKeyWords[word]) != null) {
      mobileChk = 'MOBILE';
      break;
    } else {
      mobileChk = 'WEB';
    }
  }
  return mobileChk;
}

export function sendFacebook(eventName,amount,type){
  var uphone_fb_ms = sessionStorage.getItem('uphone_fb_ms'); // 세션스토리지에 멤버 seq 저장해서 facebook 전환 api 호출할 때 가져가서 호출함
  var uphone_fb_email = sessionStorage.getItem('uphone_fb_email'); // 세션스토리지에 멤버 이메일 저장해서 facebook 전환 api 호출할 때 가져가서 호출함
  var uphone_fb_cellphone = sessionStorage.getItem('uphone_fb_cellphone'); // 세션스토리지에 멤버 전번 저장해서 facebook 전환 api 호출할 때 가져가서 호출함

  const param = {
    eventId : uphone_fb_ms ? '회원' + uphone_fb_ms + '_' + Math.floor(+ new Date() / 1000)+ '_' + window.location.pathname : '비회원_' + Math.floor(+ new Date() / 1000)+ '_' + window.location.pathname,
    eventName : eventName,
    amount :amount ? amount : 0,
    actionSource : 'website',
    email : uphone_fb_email ? uphone_fb_email : '',
    phone : uphone_fb_cellphone ? uphone_fb_cellphone : '',
    eventSourceUrl : window.location.href,
    client_ip_address : localStorage.getItem('uphone3uIp') ? localStorage.getItem('uphone3uIp') : '',
    // client_user_agent : navigator.userAgent,
    device : navigator.userAgent
  };

  //프론트단에서 추적 코드 전송
  if (type && type === 'Lead') window.fbq('track', 'Lead', {}, { eventID : param.eventId, event_name : eventName  });

  //서버단에서 추적 코드 전송
  httpDefaultClient.post('/web/sendFacebook', param).then(res => {
    console.warn(res.data);
  });

  if (type && type === 'Lead'){
    //잠재고객
    const param2 = { ...param, eventName : 'Lead' };
    httpDefaultClient.post('/web/sendFacebook', param2).then(res => {
      console.warn(res.data);
    });
  }
}

// 인증번호 발송 api 호출
export function sendAuthNumber(params, onSuccess, onFail){
  // params: cellPhone, authType(FREELESSON_APPLY, FREELESSON_RESULT, SIGN_UP, RECEIVING_CATEGORY, FIND_EMAIL, RESET_PASSWORD)
  httpDefaultClient.post('/sign/sendAuthNo', params).then(res => {
    const json = res.data;
    if (json.result){
      onSuccess();
    } else {
      onFail(json.message);
    }
  }).catch((error) => {
    console.error('error: ', error);
  });
}

// 인증번호 확인 api 호출
export function checkAuthNumber(params, onSuccess, onFail){
  // params: cellPhone,authNumber, authType(FREELESSON_APPLY, FREELESSON_RESULT, SIGN_UP, RECEIVING_CATEGORY, FIND_EMAIL, RESET_PASSWORD)
  httpDefaultClient.post('/sign/checkAuthNo', params).then(res => {
    const json = res.data;
    if (json.result){
      onSuccess();
    } else {
      onFail(json.message);
    }
  }).catch((error) => {
    console.error('error: ', error);
  });
}
//top.js 에 있던 리사이즈에 대한 정의를 공통함수로 만들었음 (다른 페이지에 비슷하게 쓰이는 곳이 많아서) - 20221103 남인식
export function resizeFn(params,target){
  /*** 사용법 : resizeFn({파라미터},컨텍스트);
	 * 파라미터 ww : 브라우저 창의 넓이값을 체크해서 pc인지 mo인지 컨텐스트의 ww에 저장한다.
	 * */
  if (params?.ww && target){
    target.ww = window.innerWidth>=1025?'pc':'mo';
  }
  return window.addEventListener('resize', () => {
    if (params?.ww && target) {
      target.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    }
  });
}

export async function onLogout(callback) {
  await httpDefaultClient.post('/sign/deleteTokenCookie');
  // TODO:: 로컬스토리지 토큰 존재할경우 삭제(배포 수개월 이후 생기지 않을 것으로 삭제하면 됨)
  storage.remove('accessToken');
  storage.remove('refreshToken');
  sessionStorage.removeItem('uphone_fb_ms'); // 세션스토리지에 멤버 seq 저장해서 facebook 전환 api 호출할 때 가져가서 호출함
  sessionStorage.removeItem('uphone_fb_email'); // 세션스토리지에 멤버 seq 저장해서 facebook 전환 api 호출할 때 가져가서 호출함
  sessionStorage.removeItem('uphone_fb_cellphone'); // 세션스토리지에 멤버 seq 저장해서 facebook 전환 api 호출할 때 가져가서 호출함
  storage.remove('com.naver.nid.oauth.state_token');
  storage.remove('com.naver.nid.access_token');
  document.cookie = 'uphone_ga_email=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'; // 구글 애널리틱스용 이메일을 쿠키값 제거
  if (callback) return callback();
}

export async function getMemberSeq() {
  const { data } = await httpRefreshClient.post('/sign/checkLogin');
  if (data.result) {
    return data.data.memberSeq;
  } else {
    return 0;
  }
}

export async function getCommonMessage(messageCode) {
  const { data } = await httpDefaultClient.get('/commonMessage/getCommonMessage', {
    params: {
      'messageCode': messageCode,
      'languageCode': 'ko'
    }
  });
  if (!data) {
    return null;
  }
  return data.message;
}

// 타임스탬프 기준으로 현재시각과 비교해서 n분 이내인지 확인
export function isTimestampWithinRange(timestamp, minutes) {
  // ex) timestamp = 1715054400 minutes = 10;
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const timeRangeInSeconds = minutes * 60;
  const timeDifference = Math.abs(currentTimestamp - timestamp);
  return timeDifference <= timeRangeInSeconds;
}

export const convertDate = (dateString) => {
  // 날짜 형식 변경
  // ex) '2024년 5월 8일 수요일' -> '2024. 05. 08(수)
  const parts = dateString.split(' ');
  const year = parts[0].replace('년', '');
  const month = parts[1].replace('월', '').padStart(2, '0');
  const day = parts[2].replace('일', '').padStart(2, '0');
  const dayOfWeek = parts[3].slice(0, 1);
  return `${year}. ${month}. ${day}(${dayOfWeek})`;
};

export function formatMemberId(memberId) {
  // 세글자 이상 *** 처리 3글자 이하 닉넴인 경우 추가로 별처리
  if (memberId.length <= 3) {
    return memberId + '***';
  } else {
    return memberId.slice(0, 3) + '*'.repeat(memberId.length - 3);
  }
}

export function setCookie(key, value, days) {
  let cookieString = key + '=' + encodeURIComponent(value) + ';path=/';

  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + date.toUTCString();
    cookieString += ';' + expires;
  }

  document.cookie = cookieString;
}

export function getCookie(cookieName) {
  let name = cookieName + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return '';
}

export function deleteCookie(cookieName) {
  document.cookie = cookieName + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export const commonCodeConverter = (commonCodeList, key, detailCode) => {
  if (!commonCodeList || !key || !detailCode) return null;
  const foundItem = commonCodeList.find((item) => item.detailCode === detailCode);
  if (!foundItem) return null;
  return foundItem[key];
};

export function numberFormat(value) {
  return parseInt(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const isHiAliceProduct = (productName) => {
  const hialiceKeywords = ['hialice', 'ai', '하이', '앨리스'];
  return hialiceKeywords.some(keyword => productName.toLowerCase().includes(keyword));
};
