import React from 'react';
import DOMPurify from 'dompurify';
import { numberFormat, commonCodeConverter } from 'common/utilFn';
import { useMultipleCommonCodes } from 'queries/common/useCommonCode';
import {useAllCommonMessage} from 'queries/common/useCommonMessage';

const TicketItem = (props) => {
  const { isFetched: isFetchedCommonCodes, data: commonCodes = {} } = useMultipleCommonCodes(['ASSIGNTEM', 'TIMEZONE', 'NATIONCODE']);
  const { isFetched: isFetchedCommonMsg, data: commonMsg = {} } = useAllCommonMessage();
  if (!isFetchedCommonCodes || !isFetchedCommonMsg) return null;

  // props
  const {
    i, item,
    isSummary,
    productClick,
    selectProductList,
    showWaitingClassPopupToggle,
    presetWaitingClassPriceSeq,
    waitingFlag,
    isAliceOrder,
    isB2B,
    juniorFlag,
    inClassFlag,
    hideBestTag,
  } = props;
  // item
  const {
    productCode, nationCode, lessonMode, productType, productMinute, templateCode, lessonCount, timezoneCode,
    productWeek, soldoutFlag, inClassProductFlag,
    price, realPrice, priceSeq,
    weekdiscountRate, billdiscountRate, mindiscountRate, productdiscountRate,
    billFlag,
  } = item;

  const product = {
    lessonMode,
    nationCode,
    timezoneCode,
    templateCode,
    productWeek,
  };

  const lessonModeName = isAliceOrder ? commonMsg['HIALICEORD']
    : productType === 'C' ? '콘텐츠'
      : lessonMode === 'AUDIO' ? '전화' : '화상';
  const subInfo = productType === 'C' ? `${productWeek}주`: commonCodeConverter(commonCodes?.NATIONCODE, nationCode === 'U' ? 'detailName' : 'note1', nationCode);
  
  const totalDiscountRate = (weekdiscountRate + billdiscountRate + mindiscountRate + productdiscountRate);

  const getProductTag = () => {
    const isUnlimitedClass = 
      product.nationCode === 'P' &&
      product.timezoneCode === 'A' &&
      product.templateCode === 'MOTUWETHFR';
    const is24Week = product.productWeek === 24;

    if (isB2B) {
      return null;
    }

    if (inClassProductFlag) {
      return `수강중${!hideBestTag && is24Week ? ' BEST' : ''}`;
    }

    if (inClassFlag || juniorFlag) { // 수강중이거나 주니어는 무제한 수업 제공 안함
      return is24Week ? 'BEST' : null;
    }

    if (isUnlimitedClass) {
      if (hideBestTag) {
        return '무제한수업';
      }
      return is24Week ? '<span>BEST</span><span>무제한수업</span>' : '무제한수업';
    }

    return is24Week && !hideBestTag ? 'BEST' : null;
  };

  const handleShowWaitingClass = () => {
    productClick(productCode, i);
    presetWaitingClassPriceSeq(priceSeq);
    showWaitingClassPopupToggle();
  };

  const isShowWaitingClassButton = waitingFlag && soldoutFlag;

  if (isShowWaitingClassButton) {
    return (
      <div className='swiper-slide ticket-item ticket-item--w4 ticket-item--voice' onClick={handleShowWaitingClass}>
        <div className='ticket-item__inner ticket-item__inner--closed'>
          <div className='ticket-item__top-row'>
            <strong className='ticket-item__badge ticket-item__badge--closed'>마감</strong>
            <strong className='ticket-item__prd-name ticket-item__prd-name--closed'>
              {lessonModeName}
              <span className='division'>{subInfo}</span>
            </strong>
            <div className='ticket-item__monthly-info'>
              <div className='ticket-item__price-box'>
                <div className='ticket-item__button ticket-item__button--closed'>
                  대기 신청
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={'swiper-slide ticket-item' +
				(' ticket-item--w' + (productWeek)) +
				(' ticket-item--' + (lessonMode === 'AUDIO' ? 'voice' : 'face'))}
      onClick={soldoutFlag ? null : () => {
        productClick(productCode, i);
      }}
    >
      <div className={'ticket-item__inner ' + (selectProductList[i] && 'ticket-item__inner--active')}>
        <div className='ticket-item__top-row'>
          {getProductTag() && (
            <strong 
              className={`ticket-item__badge ${hideBestTag ? 'ticket-item__badge-black' : ''}`}
              dangerouslySetInnerHTML={{ 
                __html: DOMPurify.sanitize(getProductTag()) 
              }}
            />
          )}
          <strong className='ticket-item__prd-name'>
            {isB2B ?
              <>
                {lessonModeName}
                <span className='division'>{subInfo}</span>
              </>
              :
              <>
                {productWeek}<span>주 {billFlag &&'정기'}</span>
              </>
            }
          </strong>
          <div className='ticket-item__monthly-info'>
            {!isB2B && totalDiscountRate > 0 &&
              <em className='ticket-item__discount-rate'>
                {totalDiscountRate}<span>%</span>
              </em>
            }
            {!isB2B && <span className='ticket-item__month'>4주</span>}
            <div className='ticket-item__price-box'>
              <div className='ticket-item__price-inner'>
                {!isB2B && totalDiscountRate > 0 &&
                  <del className='ticket-item__full-price'>
                    <span className='blind'>할인전가격</span>
                    <span>{numberFormat(isB2B ? price : (price / (productWeek / 4)))}원</span>
                  </del>
                }
                {realPrice > 0 &&
                  <strong className='ticket-item__current-price'>
                    {numberFormat(isB2B ? realPrice : (realPrice / (productWeek / 4)))}<span>원</span>
                  </strong>
                }
              </div>
            </div>
          </div>
        </div>
        {isSummary &&
						<ul className='ticket-item__summary'>
						  <li className='ticket-item__summary-item ticket-item__summary-item--minutes'>
						    {productMinute}분
						  </li>
						  <li className='ticket-item__summary-item ticket-item__summary-item--days'>
						    {productType === 'C' ? productWeek + '주' : commonCodeConverter(commonCodes?.ASSIGNTEM, 'note2', templateCode)}
						  </li>
						  <li className='ticket-item__summary-item ticket-item__summary-item--zone'>
						    {productType === 'C' ?
						      <span className={'time'}>주 {lessonCount}회</span>
						      :
						      <>
						        <span className={'time'}>
						          {commonCodeConverter(commonCodes?.TIMEZONE, 'note3', timezoneCode).replace('<br>', ', ').replace(/시~/gi, '~')}
						        </span>
						      </>
						    }
						  </li>
						</ul>
        }
        {soldoutFlag &&
						<div className={'ticket-item__sold-out'}>
						  <span>SOLD OUT</span>
						</div>
        }
      </div>
    </div>
  );
};
export default TicketItem;
