import React, {useState} from 'react';
import {AnswerType} from 'component/book/book-type';

interface PropsType {
  componentCode: string;
  content: AnswerType[];
}

export default function Answer({componentCode, content}: PropsType) {
  const [showMemo, setShowMemo] = useState(
    new Array(content?.length || 0).fill(false)
  );
  const toggleShowMemo = (index: number) => {
    const arr = [...showMemo];
    arr[index] = !arr[index];
    setShowMemo(arr);
  };

  return (
    <div className={`page ${componentCode}`}>
      <div className='component-name'>Answering with Information</div>
      <div className='component-desc'>정보를 이용해 질문에 답해보세요.</div>
      {content.map((item, index) => (
        <div key={`s${index}`}>
          <div className='content-box'>
            <div className='label long'>Question {index + 1}</div>
            <div className='content AI'>{item.mainQuestion}</div>
            {
              item.guideline &&
              item.guideline.length > 0 &&
              <>
                <div className='subtitle'>You should say:</div>
                <ul className={'subcontent'}>
                  {
                    item.guideline?.map((row, i) => (
                      <li key={`g${index}${i}`}>
                        {row}
                      </li>
                    ))
                  }
                </ul>
              </>
            }
            {item?.memo && (
              <div className={'memo'}>
                <div
                  className={showMemo[index] ? 'memo-button open' : 'memo-button close'}
                  onClick={() => toggleShowMemo(index)}
                >
                  메모
                </div>
                {showMemo[index] && <div className={'memo-content'}>{item.memo}</div>}
              </div>
            )}
          </div>
          {item.followupQuestion && item.followupQuestion.length > 0 && (
            <ul className='step-progress'>
              {item.followupQuestion.map((row, i) => (
                <li className='step-progress__item' key={`f${index}${i}`}>
                  <div className='follow-title'>Follow up Question {i + 1}</div>
                  <div className='follow-content'>{row}</div>
                </li>
              ))}
            </ul>
          )}
        </div>
      ))}
    </div>
  );
}
