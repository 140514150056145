import { StateCreator } from 'zustand';

export interface IUnitedTopBanner {
  type: string,
  bannerColor: string,
  mobileImageHeight: number,
  mobileImageFile: string,
  pcImageHeight: number,
  pcImageFile: string,
  actionType: string,
  moveUrl?: string,
  popupcloseColor?: string,
  popupImageFile?: string,
}

export interface UnitedTopBannerSliceT {
  type: string,
  bannerColor: string,
  mobileImageHeight: number,
  mobileImageFile: string,
  pcImageHeight: number,
  pcImageFile: string,
  actionType: string,
  moveUrl: string,
  popupcloseColor: string,
  popupImageFile: string,
  setUnitedTopBanner: (unitedTopBanner: IUnitedTopBanner) => void;
}

const initialState = {
  type: '',
  bannerColor: '',
  mobileImageHeight: 0,
  mobileImageFile: '',
  pcImageHeight: 0,
  pcImageFile: '',
  actionType: '',
  moveUrl: '',
  popupcloseColor: '',
  popupImageFile: '',
};

const unitedTopBannerSlice: StateCreator<
  UnitedTopBannerSliceT, any[], any[], UnitedTopBannerSliceT
> = (set) => ({
  ...initialState,
  setUnitedTopBanner: (unitedTopBanner) => {
    set(() => ({ ...initialState, ...unitedTopBanner }));
  },
});

export default unitedTopBannerSlice;
