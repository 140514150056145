import React, {useEffect, useState} from 'react';
import '../css/common.scss';
import axios from 'axios';

const ServiceNotice = (props) => {

  const [status, setStatus] = useState();
  const [responMsg, setResponMsg] = useState();


  useEffect( () => {
    if (props.code) {
      //App.js 같이 컴포넌트에서 이동시킬 때
      setStatus(props.code);
      if (props.message) setResponMsg(props.message);
    } else if (props.location.state?.code) {
      //특정 버튼이나 함수에서 history.push에 props 담아 보낼 때
      setStatus(props.location.state.code);
      if (props.location.state.message) setResponMsg(props.location.state.message);
    } else {
      //바로 리다이렉트 받아 온 경우 (ex, 하드코딩 html 자바스크립트)
      axios({
        method:'GET',
        url:process.env.REACT_APP_API_URL + '/503.json',
        headers: {
          // 'Content-Type': 'application/json;charset=UTF-8',
        }
      })
        .then( (response) => {
          if (response.data.status === 503) {
            setStatus(503);
            setResponMsg(response.data.message);
          }
        })
        .catch((error) => console.error('error: ', error));
    }

  },[]);
  return (
    <div className={'servicenotice'}>
      {(status === 503 || status === 404) &&
                <div className={`servicenotice__${status}`}>
                  <img className={'servicenotice__icon'} src={`${process.env.REACT_APP_IMG_URL}/common/ico_caution.svg`}
                    alt={'Icon'}/>
                  <p className={'servicenotice__message'}>
                    {status === 503 &&
                        <>
                            보다 안정된 서비스 제공을 위해<br/>
                            시스템 점검 중입니다. 더 좋은 모습으로<br/>
                            돌아올테니 잠시만 기다려주세요.
                        </>
                    }
                    {status === 404 &&
                        <>
                            죄송합니다.<br/>
                            해당 페이지를 찾을 수 없습니다.
                        </>
                    }
                  </p>
                  {responMsg &&
                    <p className={'servicenotice__responmsg'}>
                        예상 시간 : {responMsg}
                    </p>
                  }
                </div>
      }
    </div>
  );
};

export default ServiceNotice;
