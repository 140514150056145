import React, { useState, useEffect } from 'react';

const Polly = props => {
  const audio = props.t.replace(/(\*\r\n|\n|\r)/gm, '').replace(/\*/gi, '');
  const badge = props.badge;

  const [txt, setTxt] = useState('');
  const [playing, setPlaying] = useState('');
  const [loading, setLoading] = useState(true);
  const [audioURL, setAudioURL] = useState('');
  const [EventList, setEventList] = useState(false);
  let a='';
  let b  = props.t.split('**');
  b.length !== 1 ? b.forEach((v, i) => {
    a += i === 0 ? v : i%2 ?  '<b>'+v : '</b>'+v;
  }) : a = props.t;

  !txt && setTxt(a);

  useEffect(() => {
    if (loading){
      setLoading(false);

      let myHeaders = new Headers();
      myHeaders.append('Content-Type', 'text/plain');
      const myBody = '{"postBody":"text='+audio+'","languageType":"'+(props.languageType??'en')+'"}';
      const apiAddress = process.env.REACT_APP_AWS_POLLY_URL;
      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: myBody,
        redirect: 'follow'
      };

      fetch(apiAddress, requestOptions)
        .then(response => response.json())
        .then(json => {
          if (json.status === 200){
            setAudioURL(json.url);
          }
        })
        .catch(error => console.error('error: ', error));
    }
  });

  const onClickplayTxt = e => {
    const a = document.getElementById(`${props.type + props.n}`);
    const p = document.getElementsByTagName('audio');
    if (!EventList){
      console.warn('----한번만 호출되야 함');
      setEventList(true);
      a.addEventListener('pause', function (_event) {
        setPlaying('');
        props.onChangeStatus(props.type, 'END',a.currentTime, audioURL);
      });
      a.addEventListener('playing', function (_event) {
        console.warn('---- play',a.currentTime);
        setPlaying('_on');
        props.onChangeStatus(props.type, 'START',a.currentTime, audioURL);
      });
    } else {
      console.warn('리스너 이미 호출 했으니까 다음 누를 때는 이 콘솔이 노출 되어야 함');
    }

    for (let a of p) {
      a.pause();
      if (a.id !== 'rAudio')  a.currentTime = 0;
    }

    a.play();
  };

  return (
    <div onClick={onClickplayTxt}>
      {badge && <span className={'badge'}>{badge}</span>}
      <span className={'txt'} dangerouslySetInnerHTML={{__html: txt}}/>
      <img src={`${process.env.REACT_APP_IMG_URL}/icon/speaker_small${playing}.png`} alt={'Icon'}/>
      {audioURL && <audio id={`${props.type + props.n}`} preload='auto' className={'polly'}>
        <source src={audioURL} type='audio/mp3'/>
      </audio>}
    </div>
  );
};

export default Polly;
