import React, { Component } from 'react';
import Swiper from 'react-id-swiper';
import Lottie from 'react-lottie-segments';
import axios from 'axios';
import YouTube from 'react-youtube';

import '../css/refEvent.scss';

import * as utilFn from '../common/utilFn';
import SupportBoard from '../component/common/SupportBoard';
import LayerPopup from '../component/LayerPopup';
import loading from '../json/loading.json';
import invitationReport from '../json/invitation/lottie_report_new.json';
import invitationAirplane from '../json/invitation/airplane.json';
import DOMPurify from 'dompurify';
import {httpDefaultClient, httpRefreshClient} from 'configs/api';

export default class RefEventJunior extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 'p1',
      toastOff: true,
      reasonTextList: [
        { text: '아이 스스로<br /> 영상보고 퀴즈풀며 <br /> <strong>수업 전 자기 주도 학습</strong>' },
        { text: '10분 전 수업 취소,<br /> 보강까지 탭 한번으로 <br /> <strong>바쁜 아이 일정관리</strong>' },
        { text: '진입하자마자<br /> 교재, 수업까지 한 화면에! <br /> <strong>쉽고 간편한 화상 교실</strong>'},
        { text: '수업 직후 녹음 파일과,<br /> 수업 피드백으로 <br /> <strong>우리 아이 수업 모니터링</strong>'},
      ],
    };
  }

  componentWillMount() {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.getCommonMsgList();
  }

  componentDidMount() {
    window.addEventListener('resize', this.changeWw, true);
    utilFn.ScrollNone();

    httpRefreshClient.post('/sign/checkLogin').then(res => {
      if (res.data.result) {
        this.tokenOK();
      }
    });
    this.getRecommender();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.changeWw);
  }

  changeWw = () => {
    this.ww = window.innerWidth >= 1025 ? 'pc' : 'mo';
    this.setState({
      ww: this.ww
    });
  };

  tokenOK = () => {
    httpDefaultClient.get('/myInfo/recommend/findMyCode').then((res) => {
      const json = res.data;
      if (json.result) {
        this.setState({
          myRcmdCode: json.data,
        });
      }
    }).catch((error) => {
      console.error('error: ', error);
    });

    httpDefaultClient.get('/myInfo/list').then((res) => {
      const json = res.data;
      if (json.result) {
        this.setState({
          myCp: json.data.lessonPhone,
        });
      }
    }).catch((error) => {
      console.error('error: ', error);
    });
  };

  getRecommender = () => {
    httpDefaultClient.get('/trial/recommender?recommendCode=' + this.props.match.params.id).then(res => {
      if (res.data.result) {
        const data = res.data.data;
        utilFn.setMarketingCodeValue('rcmdCode', this.props.match.params.id, 1);
        let friendName = data.memberName;
        let originName = friendName.split('');
        if (friendName.length <= 3) {
          originName.forEach(function (name, i) {
            if (i !== 1) return;
            originName[i] = '*';
          });
        } else {
          originName.forEach(function (name, i) {
            if (i !== 1 && i !== 2) return;
            originName[i] = '*';
          });
        }
        let joinName = originName.join();
        friendName = joinName.replace(/,/g, '');

        this.setState({
          rcmdCode: this.props.match.params.id,
          friendName,
          rcmdCp: data.lessonPhone,
          pageComplete: true,
        });
      } else {
        utilFn.removeMarketingCodeValue('rcmdCode');
        this.alertMessage(json.message, true, 'error');
      }
    }, (error) => {
      console.error('error: ', error);
    });
  };

  commonCode = (c) => {
    httpDefaultClient.get('/commonCode/getList/' + c).then(response => {
      this.setState({ [c]: response.data });
    })
      .catch((error) => console.error('error: ', error));
  };

  commonCodeValue = (t, k, v) => {
    if (t && k && v) {
      const a = this.state[t].map(x => x.detailCode);
      const b = a.indexOf(v);
      return this.state[t][b][k];
    }
  };

  getCommonMsgList = () => {
    httpDefaultClient.get('/commonMessage/getList')
      .then((response) => {
        this.setState({
          commonMsg: response.data,
        });
      })
      .catch((error) => {
        console.error('getCommonMsgList: ', error);
      });
  };

  alertMessage = (v, com, type) => {
    if (com) {
      this.setState({
        eMessageTxt: this.state.commonMsg[v],
      });
    } else {
      this.setState({
        eMessageTxt: v,
      });
    }
    this.setState({
      alertType: type,
    });

    this.openPopup('eMessageFlag');
  };

  openPopup = (s, overlap) => {
    this.setState({
      [s]: true,
      popupOverlap: overlap,
    });
    utilFn.ScrollNone(true);
  };

  closePopup = (s) => {
    this.setState({
      [s]: false,
    });
    utilFn.ScrollNone();
    if (s === 'eMessageFlag') {
      this.go('/');
    }
  };

  eMessage(t, v) {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (response.data.result) {
          if (t === 'emailError') {
            this.setState({ emailError: response.data.message });
          } else {
            this.alertMessage(response.data.message);
            this.setState({
              alertType: t,
            });
            return null;
          }
        } else {
          this.alertMessage(v);
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  numberFormat(n) {
    const a = parseInt(n);
    return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  setTrue = (s) => {
    this.setState({
      [s]: true,
    });
  };

  go = (t) => {
    if (t === '/FreeLesson/junior') {
      if (this.state.rcmdCode === this.state.myRcmdCode) {
        utilFn.removeMarketingCodeValue('rcmdCode');
        this.alertMessage('FRIEND0005', true, 'error');
        return false;
      } else if (this.state.rcmdCp === this.state.myCp) {
        utilFn.removeMarketingCodeValue('rcmdCode');
        this.alertMessage('FRIEND0012', true, 'error');
        return false;
      }
    }
    this.props.history.push(t);
  };
  
  parseNumberToKoreanAlphabet = (number) => {
	  const tenUnit = ['', '십', '백', '천'];
	  const tenThousandUnit = ['조', '억', '만', ''];
	  const unit = 10000;
	
	  let answer = '';
	
	  while (number > 0) {
	    const mod = number % unit;
	    const modToArray = mod.toString().split('');
	    const length = modToArray.length - 1;
	
			 const modToKorean = modToArray.reduce((acc, value, index) => {
	      const valueToNumber = +value;
	      if (!valueToNumber) return acc;
	      const numberToKorean = valueToNumber;
	      return `${acc}${numberToKorean}${tenUnit[length - index]}`;
	    }, '');
	
	    answer = `${modToKorean}${tenThousandUnit.pop()}${answer}`;
	    number = Math.floor(number / unit);
	  }
	
	  return answer.replace();
  };

  render() {
    const state = this.state;
    const mileage = this.numberFormat(this.state.commonMsg?.['FRIEND0022'] || 0);
    const sysyemSwiperOption = {
      slidesPerView: 'auto',
      centeredSlides: true,
      observer: true,
      observeParents: true,
      allowTouchMove: true,
      loop: true,
      autoplay: {
        delay: 3500,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.swiper-info',
        clickable: false,
        renderBullet: function (index, className) {
          return '<p class="' + className + '">' + reasonTextList[index].text + '</p>';
        },
      }
    };

    const reviewSwiperOption = {
      slidesPerView: 'auto',
      centeredSlides: true,
      observer: true,
      observeParents: true,
      allowTouchMove: true,
      loop: true,
      autoplay: {
        delay: 2000,
        disableOnInteraction: false,
      },
      loopedSlides: 1,
      loopAdditionalSlides: 1,
      pagination: {
        el: '.review-pagination',
        clickable: true,
      },
    };

    const reasonTextList = [
      {
        text: '아이 스스로<br /> 영상보고 퀴즈풀며 <br /> <strong>수업 전 자기 주도 학습</strong>'
      },
      {
        text: '10분 전 수업 취소,<br /> 보강까지 탭 한번으로 <br /> <strong>바쁜 아이 일정관리</strong>'
      },
      {
        text: '진입하자마자<br /> 교재, 수업까지 한 화면에! <br /> <strong>쉽고 간편한 화상 교실</strong>'
      },
      {
        text: '수업 직후 녹음 파일과,<br /> 수업 피드백으로 <br /> <strong>우리 아이 수업 모니터링</strong>'
      },
    ];

    return (
      <>
        <div className={'invitation-page ' + (this.props.match.params.page ? this.props.match.params.page : 'p1') + (this.ww === 'pc' ? '' : ' mo')}>
          {!state.pageComplete ?
            <div className={'lottieLoading'}>
              <Lottie options={{ loop: true, autoplay: true, animationData: loading }} />
            </div>
            :
            <>
              <article className='invitation'>
                <section className='invitation__head'>
                  <div className='invitation__head-lottie'>
                    <Lottie options={{ loop: true, autoplay: true, animationData: invitationAirplane }} />
                  </div>
                  <h2 className='invitation__head-title'>
                    {state.friendName}님이 유폰행 <br /> 무료티켓을 보냈어요.
                  </h2>
                  <p className='invitation__head-desc'>
                    외국인강사와 수업하고 아이의 영어자신감을 키워요
                  </p>
                  <div className='invitation__head-ticket'>
                    <img src={process.env.REACT_APP_IMG_URL + '/event/friends/vis_ticket_junior@2x.png'} alt='Free Ticket. Arrival-외국인강사와 무료수업, Time-10분, Type- 전화 또는 화상' />
                    <button type='button' onClick={() => this.go('/FreeLesson/junior')} className='btn-move-application btn-move-application--junior'><span>무료 레벨테스트 신청</span></button>
                  </div>
                </section>
                <section className='invitation__gifts'>
                  <div className='container'>
                    <h2 className='invitation__gifts-title'>유폰 웰컴 기프트</h2>
                    <ul className='gift-list'>
                      <li className='gift-item'>
                        <img src={process.env.REACT_APP_IMG_URL + '/event/friends/ico_report.svg'} alt='' className='gift-item__bg' />
                        <p className='gift-item__txt'>실력진단 <br /><strong>결과 리포트</strong></p>
                      </li>
                      <li className='gift-item'>
                        <img src={process.env.REACT_APP_IMG_URL + '/event/friends/ico_reserve.svg'} alt='' className='gift-item__bg' />
                        <p className='gift-item__txt'>친구추천적립금<br /><strong>{this.parseNumberToKoreanAlphabet(parseInt(mileage.replaceAll(',', '')) || 0)}원</strong></p>
                      </li>
                    </ul>
                  </div>
                </section>
                <section className='invitation__reviews invitation__reviews--junior'>
                  <div className='container'>
                    <h2 className='invitation__reviews-title'>아이가 70% 말하는<br />유폰주니어 수업 후기</h2>
                    <div className='slide-row'>
                      <Swiper {...reviewSwiperOption}>
                        <div className='slide-item' style={{ backgroundColor: '#FF9B38' }}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/junior_person2@2x.png'} alt='' className='slide-item__img' />
                          <strong className='slide-item__title'>무엇보다 <br />
                            아이가 재밌어 해요.</strong>
                          <p className='slide-item__desc'>
                            강사님 리액션 최고!<br />
                            걱정과 달리 아이가 수업에<br />
                            집중하며 잘 따라가고,<br />
                            무엇보다 아이가 선생님과<br />
                            수업하는 날을 기다리네요.
                          </p>
                          <span className='slide-item__reviewer'>장*유</span>
                        </div>
                        <div className='slide-item' style={{ backgroundColor: '#51BE75' }}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/junior_person3@2x.png'} alt='' className='slide-item__img' />
                          <strong className='slide-item__title'>VOD 시청, 퀴즈 풀다보면<br />수업준비 끝!</strong>
                          <p className='slide-item__desc'>
                            수업 전에 VOD 영상으로<br />
                            렉사일 필수 영단어를 배우고,<br />
                            퀴즈로 반복해서 말하니까<br />
                            아이가 배운표현을 내걸로<br />
                            만드는 느낌이에요.</p>
                          <span className='slide-item__reviewer'>강*늘</span>
                        </div>
                        <div className='slide-item' style={{ backgroundColor: '#F87A6F' }}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/junior_person4@2x.png'} alt='' className='slide-item__img' />
                          <strong className='slide-item__title'>유폰 앱 하나만 있으면되니<br />간편해서 좋아요</strong>
                          <p className='slide-item__desc'>
                            아이의 태블릿에 유폰 앱만<br />
                            다운로드 받으면  예습부터 수업까지<br />
                            모두 가능해서 편해요.<br />
                            아이 혼자서 영상도 보고 퀴즈도 풀면서<br />
                            영어에 재미를 찾는 듯합니다.  </p>
                          <span className='slide-item__reviewer'>송*윤</span>
                        </div>
                        <div className='slide-item' style={{ backgroundColor: '#5C6EFF' }}>
                          <img src={process.env.REACT_APP_IMG_URL + '/event/friends/junior_person1@2x.png'} alt='' className='slide-item__img' />
                          <strong className='slide-item__title'>드디어 실력이 느는 게<br />
                            눈에 보여요.</strong>
                          <p className='slide-item__desc'>
                            학원 수업은 아이가 소극적이라<br />
                            말할 기회가 적더라구요.<br />
                            1:1 수업에 아이 발화량이<br />
                            훨씬 많으니까 진짜 스피킹이<br />
                            느는 게 눈에 보여요
                          </p>
                          <span className='slide-item__reviewer'>이*안</span>
                        </div>
                      </Swiper>
                    </div>
                  </div>
                </section>
                <section className='invitation__youtube'>
                  <YouTube
                    className={'youtube-video'}
                    videoId={'Yrfd3L1kHWc'}
                    opts={{
                      playerVars: {
                        autoplay: 0,
                        rel: 0,
                        modestbranding: 1,
                      },
                    }}
                    onEnd={(e) => { e.target.stopVideo(0); }}
                  />
                </section>
                <section className='invitation__steps invitation__steps--junior'>
                  <div className='container'>
                    <h2 className='invitation__steps-title'>무료 레벨테스트<br />진행 방법</h2>
                    <ol className='step-list'>
                      <li className='step-item step-item--apply'><button type='button' onClick={() => this.go('/FreeLesson/junior')}><em>원하는 시간, 수업으로</em> 레벨테스트 신청</button></li>
                      <li className='step-item step-item--study'><em>앱 설치 후 레벨 맞춤 교재</em>로 예습</li>
                      <li className='step-item step-item--call'>외국인강사님과 <em>10분 레벨테스트</em></li>
                    </ol>
                  </div>
                  <button type='button' onClick={() => this.go('/FreeLesson/junior')} className='btn-move-application'>무료 레벨테스트 신청</button>
                </section>
                <section className='invitation__report invitation__report--junior'>
                  <div className='container'>
                    <h3 className='invitation__report-sub-title'>엄마들이 유폰을 선택한 이유</h3>
                    <h2 className='invitation__report-title'>무료수업 리포트로 <br /> 실력 진단하고 <br /> <strong>아이의 영어 강&middot;약점 파악</strong></h2>
                    <div className='lottie-item'>
                      <div className='lottie-item__animation'>
                        <Lottie options={{ loop: true, autoplay: true, animationData: invitationReport }} />
                      </div>
                    </div>
                    <ul className='example-list'>
                      <li className='example-item'>
                        <strong className='example-item__title'>문장 피드백 / 발음연습 </strong>
                        <img src={process.env.REACT_APP_IMG_URL + '/event/friends/free_feedback@2x.png'} alt='' />
                      </li>
                    </ul>
                    <button type='button' onClick={() => this.go('/FreeLesson/junior')} className='btn-move-application'>무료 레벨테스트 신청</button>
                  </div>
                </section>
                <section className='invitation__tesol invitation__tesol--junior'>
                  <div className='container'>
                    <h2 className='invitation__tesol-title'>
                      우리아이<br />
                      <strong>스피킹 비중 70% <br />말하는 시간을 확 높인 수업</strong>
                    </h2>
                    <img src={process.env.REACT_APP_IMG_URL + '/event/friends/tesol_cont_junior@2x.png'} alt='업계 유일, 25:1 경쟁률, 160 시간의 교육시간, 데모수업 40회' className='desc-img' />
                    <p className='desc-txt'>전원 TESOL 취득 외국인강사진 <br />
                      자체 개발 스피킹 집중 교재로 <br />
                      말하기 수업에 최적화된 수업을 제공해요.</p>
                    <button type='button' onClick={() => this.go('/FreeLesson/junior')} className='btn-move-application'>무료 레벨테스트 신청</button>
                  </div>
                </section>

                <section className='invitation__junior-curriculum'>
                  <div className='container'>
                    <h2 className='invitation__junior-curriculum-title'>
                      미국 외국어 스피킹 표준
                      <br />
                      <strong>ACTFL에 맞춘 <br />주니어 커리큘럼</strong>

                    </h2>
                    <img src={process.env.REACT_APP_IMG_URL + '/web/junior/mo/main/course_junior_70@2x.png'} alt='주니어 커리큘럼' className='desc-img' />
                    <p className='invitation__junior-curriculum-desc'>레벨 별 초등 필수 영어단어로 <br />반복하고 말하며 익혀요.</p>
                    <button type='button' onClick={() => this.go('/FreeLesson/junior')} className='btn-move-application'>무료 레벨테스트 신청</button>
                  </div>
                </section>

                <section className='invitation__junior-system'>
                  <div className='container'>
                    <div className='title-area'>
                      <h2 className='sub-title'>우리아이를 위한</h2>
                      <strong className='title'>유폰만의 철저한 <br /> 학습 관리 시스템</strong>
                    </div>
                    <Swiper className='reason-swiper' {...sysyemSwiperOption}>
                      <div className='slide-item'>
                        <img className='app-img' src={process.env.REACT_APP_IMG_URL + '/web/junior/mo/main/uphone_reason1@2x.png'} alt='' />
                      </div>
                      <div className='slide-item'>
                        <img className='app-img' src={process.env.REACT_APP_IMG_URL + '/web/junior/mo/main/uphone_reason2@2x.png'} alt='' />
                      </div>
                      <div className='slide-item'>
                        <img className='app-img' src={process.env.REACT_APP_IMG_URL + '/web/junior/mo/main/uphone_reason3@2x.png'} alt='' />
                      </div>
                      <div className='slide-item'>
                        <img className='app-img' src={process.env.REACT_APP_IMG_URL + '/web/junior/mo/main/uphone_reason4@2x.png'} alt='' />
                      </div>
                    </Swiper>
                    <button type='button' onClick={() => this.go('/FreeLesson/junior')} className='btn-move-application'>무료 레벨테스트 신청</button>
                  </div>
                </section>

                <section className='invitation__notice'>
                  <div className='container'>
                    <SupportBoard supportId={'refEvenNoticeF'} />
                  </div>
                </section>
              </article>
            </>
          }
        </div>
        {state.eMessageFlag &&
          <LayerPopup
            tit={state.eMessageTxt}
            btnCnt={1}
            btnOkCmt={state.commonMsg['0000000094']}
            btnOkFunction={() => this.closePopup('eMessageFlag')}
          />
        }
      </>
    );
  }
}
