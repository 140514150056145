import React, {useState} from 'react';
import {BookType, DialogType, ExerciseType, PictureLTType} from 'component/book/book-type';


interface PropsType {
  book: BookType;
  lessonType?: string;
  scrollToComponent: (component: string) => void;
}

export default function Greeting({book, lessonType, scrollToComponent}: PropsType) {
  const teacherName = book.teacherName!=='Teacher'?book.teacherName:'';

  const handleClick = () => {
    if (book?.components && book?.components.length>2) scrollToComponent(book.components[1]);
  };

  if (lessonType==='TICKET') {
    return (
      <div className={'greeting leveltest'}>
        <div className='greeting1'>
          {teacherName} 강사님과<br />
          오늘의 레벨테스트를<br />
          진행하세요.
        </div>
        <div className='greeting2'>오늘의 반가운 인사말</div>
        <div className='greeting3'>"{book.greeting}"</div>
        <img
          className='arrow'
          src='https://img.uphone3.com/icon/arrow_down_w.svg'
          alt='arrow'
          onClick={handleClick}
        />
      </div>
    );
  }
  else if (lessonType==='TRIAL') {
    return (
      <div className={'greeting'}>
        <div className='greeting1'>
          {teacherName} 강사님과<br />
          교재를 보며 무료수업을<br />
          진행해보세요.
        </div>
        <div className='greeting2'>오늘의 반가운 인사말</div>
        <div className='greeting3'>"{book.greeting}"</div>
        <img
          className='arrow'
          src='https://img.uphone3.com/icon/arrow_down_w.svg'
          alt='arrow'
          onClick={handleClick}
        />
      </div>
    );
  }
  return (
    <div className={'greeting'}>
      {book.unitNo &&
        <div className='subject'>
          {`UNIT.${book.unitNo} ${book.subject}`}
        </div>
      }
      <div className='greeting1'>
        {teacherName} 강사님과<br />
        반갑게 인사하며<br />
        시작할까요?
      </div>
      <div className='greeting2'>오늘의 반가운 인사말</div>
      <div className='greeting3'>"{book.greeting}"</div>
      <img
        className='arrow'
        src='https://img.uphone3.com/icon/arrow_down_w.svg'
        alt='arrow'
        onClick={handleClick}
      />
    </div>
  );
}
