import React from 'react';

import useSupportBoard from '../../hooks/common/useSupportBoard';

const SupportBoard = (props) => {
  // parameter
  const { supportId, type } = props;

  const {
    data,
  } = useSupportBoard(props);


  if (type === 'image') {
    return (
      <img className={'img'} src={data} alt={supportId} />
    );
  }

  return (
    <div className={'listWrap ' + supportId} dangerouslySetInnerHTML={{__html : data}} />
  );
};

export default SupportBoard;
