import React, { useState } from 'react';
import { mobileChk, numberFormat } from 'common/utilFn';

const ClassQuotationB2BSection = ({ props }) => {
  const {
    selectProduct,
    movingToB2BClassPayment,
  } = props;

  const handleClickMovingToClassPayment = () => {
    movingToB2BClassPayment();
  };
  
  return (
    <div
      className='order-foot order-foot--active'>
      {selectProduct.realPrice > 0 && (
        <>
          <div className={'order-foot__top-wrap order-foot__top-wrap--price'}>
            <p className='order-foot__price-description'>{selectProduct.productWeek}주 총금액</p>
            <div className='order-foot__price-wrap'>
              <p>
                <strong>
                  {numberFormat(selectProduct.realPrice)}
                </strong>원
              </p>
            </div>
          </div>
          <div className={'order-foot__line order-foot__line--hidden'}></div>
        </>
      )}
      <div className='order-foot__bottom-wrap'>
        <button
          type='button'
          className={`btn order-foot__button full ${selectProduct.realPrice <= 0 && 'no-price'}`}
          onClick={() => handleClickMovingToClassPayment()}
        >
          {selectProduct.realPrice > 0 ? '결제하기' : '수강신청하기'}
        </button>
      </div>
    </div>
  );
};

export default ClassQuotationB2BSection;
