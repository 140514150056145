import React from 'react';

const ToastPopup = ({ props }) => {
  // state
  const {
    toastOff, toastMsg,
  } = props;

  return (
    <div className={'toastPopup ' + (toastOff ? 'off' : '')}>
      {toastMsg}
    </div>
  );
};

export default ToastPopup;
