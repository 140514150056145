import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AppleSignin from 'react-apple-signin-auth';
import axios from 'axios';

import KakaoLoginButton from '../component/kakao/KakaoLoginButton';
import LayerPopup from '../component/LayerPopup';
import * as utilFn from '../common/utilFn';

import '../css/join.scss';
import referrerStore from 'store/referrer/referrerStore';
import {httpDefaultClient} from 'configs/api';

// 간편 로그인 관련 설정 변수
const kakao_client_id = process.env.REACT_APP_KAKAO_CLIENT_ID;
const naver_client_id = process.env.REACT_APP_NAVER_CLIENT_ID;
const apple_client_id = 'com.uphone3.app'; // serviceID, AppID 아님
const naver_redirectURI = encodeURI(window.location.protocol + '//' + window.location.host + '/Login/naver');
const apple_redirectURI = encodeURI('https://' + (window.location.hostname) + '/Login/apple');

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      pw: '',
      idError: '',
      pwError: '',
      pwView: 'password',
      naverData: '',
      socialType: '', // 간편 로그인 시 타입 (NAVER/APPLE)
      privateKey: '', // 간편 로그인 시 회원 고유 값
      socialPopup: false, // 미가입 소셜 계정으로 로그인시 가입페이지로 가도록 팝업
      loading: false,
      layerPopupMessage: '',
      returnUrl: '', // 로그인 후에 돌아갈 페이지 url
    };
  }
  componentDidMount() {
    // 소셜 로그인
    this.naverLoginInit();

    const em = localStorage.getItem('email');
    localStorage.removeItem('email');
    if (em) {
      this.setState({
        id: em
      });
    }
    utilFn.sendFacebook('로그인');
  };

  naverLoginInit = () => {
    const naverLogin = new window.naver.LoginWithNaverId({
      clientId: naver_client_id,
      callbackUrl: naver_redirectURI,
      isPopup: false, /* 팝업을 통한 연동처리 여부 */
      loginButton: {/*color: "white", type: 3, height: 60*/ },
      callbackHandle: true
    });

    /* 설정정보를 초기화하고 연동을 준비 */
    naverLogin.init();
    document.querySelector('#naverIdLogin_loginButton').removeAttribute('href'); // 네아로 자동으로 버튼 생성시 href를 삭제하지 않으면 #으로 이동되는 현상때문에 속성 지움

    /* 로그인후 콜백처리 */
    if (this.props.match.params.type === 'naver') {
      window.addEventListener('load', () => {
        naverLogin.getLoginStatus((status) => {
          if (status) {
            this.setState({
              id: naverLogin.user.email,
              name: naverLogin.user.name,
              privateKey: naverLogin.user.id,
              socialType: 'NAVER'
            });
            this.loginAccess();
          } else {
            console.error('callback 처리에 실패하였습니다.');
          }
        });
      });
    }
  };

  // 카카오 로그인 로직
  kakaoLoginInit = (kakaoLogin) => {
    const { email, name } = kakaoLogin.profile.kakao_account;
    if (email) {
      this.setState({
        id: email,
        name,
        privateKey: kakaoLogin.profile.id,
        socialType: 'KAKAO',
        plusfriendsFlag: kakaoLogin.response.scope.includes('plusfriends')
      }, () => { this.loginAccess(); });
    } else {
      alert('다시 시도해 주세요.');
    }
  };

  onErrorKakaoLogin = (error) => {
    console.error('error: ', error);
  };

  // 애플 로그인 라이브러리 사용하기 위한 옵션 값
  appleAuthOptions = {
    clientId: apple_client_id,
    scope: 'email name',
    redirectURI: apple_redirectURI,
    state: new Date().getTime().toString(), //  CSRF공격을 완화하기 위한 OAuth 파라미터, 추정 할 수 없는 랜덤값
    nonce: 'nonce',
    usePopup: true,
  };

  convertJwt = (jwtcode) => {
    const base64cvt = (jwtcode.split('.')[1]).replace(/-/g, '+').replace(/_/g, '/');
    const json = decodeURIComponent(atob(base64cvt).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return json;
  };

  onSuccessAppleLogin = (response) => {
    const idToken = JSON.parse(this.convertJwt(response.authorization.id_token));

    this.setState({
      id: idToken.email,
      privateKey: idToken.sub,
      socialType: 'APPLE'
    }, () => { this.loginAccess(); });
  };

  onErrorAppleLogin = (error) => {
    console.error('error: ', error);
  };

  inputID = (e) => {
    const v = e.target.value.replace(/[\s*]/gi, '');
    this.setState({ id: v });
    if (v.length !== 0) {
      this.setState({ idError: '' });
    }
  };
  
  onBlurID = (e) => {
    let em = '';
    if (e.target.value.length === 0) {
      this.setState({ idError: '필수 정보입니다.' });
      return null;
    }
    this.setState({ idError: em });
  };

  inputPW = (e) => {
    const pw_regex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/gi;
    const a = pw_regex.test(e.target.value);
    const v = e.target.value;
    this.setState({ pw: v });
    if (v.length !== 0) {
      this.setState({ pwError: '' });
    }
    if (a) {
      this.setState({ pwError: '비밀번호에 한글을 사용할 수 없습니다.' });
    } else if (utilFn.hasUnicode(v)) {
      this.setState({ pwError: '비밀번호에 유니코드 문자를 사용할 수 없습니다.' });
    }
  };

  onBlurPW = () => {
    if (this.state.pw === '') {
      this.setState({ pwError: '필수 정보입니다.' });
      return null;
    }
  };
  
  loginAccess = (e) => {
    let a, b, c;

    if (this.state.socialType) {
      a = '';
      b = '';
      c = { 'privateKey': this.state.privateKey, 'socialType': this.state.socialType, };
    } else {
      this.state.id === '' ? a = '필수 정보입니다.' : (this.state.idError) ? a = this.state.idError : a = '';
      this.state.pw === '' ? b = '필수 정보입니다.' : (this.state.pwError) ? b = this.state.pwError : b = '';
      this.setState({
        idError: a,
        pwError: b
      });
      e.preventDefault();
      c = { 'memberId': this.state.id, 'password': this.state.pw };
    }

    if (a !== '' || b !== '') {
      return;
    }

    httpDefaultClient.post('/sign/logIn', c)
      .then(res => {
        const json = res.data;
        if (json.status === 200) {
          if (json.result) {
            // 이동
            const { prevPage, resetData } = referrerStore.getState();
            if (prevPage) {
              resetData();
              window.location.href = prevPage;
            } else {
              window.location.href = '/';
            }
          } else if (json.message === 'SIGN000010') {
            setTimeout(() => {
              this.props.history.push({
                pathname: `/release/p1/${json.data}`,
                state: { detail: c }
              });
            }, 10);
          } else if (json.message === 'SIGN000023') {
            // 가입 또는 연결되어 있지 않은 소셜 아이디입니다. 회원 가입 페이지로 이동합니다.
            this.setState({ socialPopup: true });
          } else {
            this.eMessage('alert', json.message);
            return null;
          }
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  goJoin = () => {
    this.setState({ socialPopup: false });
    // 가입페이지로 이동시에 가지고 있던 계정값 가지고 가기
    if (this.state.socialType === 'KAKAO') {
      this.props.history.push('/Join');
      return;
    }

    this.props.history.push({
      pathname: '/Join/p2/' + (this.state.socialType).toLowerCase(),
      state: {
        detail: {
          email: this.state.id,
          name: this.state.name,
          privateKey: this.state.privateKey,
          socialType: this.state.socialType,
          plusfriendsFlag: this.state.plusfriendsFlag,
        }
      }
    });
  };

  onChangePwView = (n) => {
    let s;

    if (n === 1) {
      this.state.pwView === 'password' ? s = 'text' : s = 'password';
      this.setState({ pwView: s });
    }
  };

  eMessage = (t, v) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_URL + '/commonMessage/getCommonMessage?',
      params: {
        'messageCode': v,
        'languageCode': 'ko'
      }
    })
      .then((response) => {
        if (response.data.result) {
          if (t === 'emailError') {
            this.setState({ emailError: response.data.message });
          } else if (t === 'return') {
            return response.data.message;
          } else {
            this.setState({ layerPopupMessage: response.data.message });
            return null;
          }
        } else {
          this.setState({ layerPopupMessage: v });
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  };

  handleLoginByNaverClick = () => {
    const naverLoginButton = document.getElementById('naverIdLogin_loginButton');

    if (!naverLoginButton) { return; }
    naverLoginButton.click();
  };

  render() {
    return (
      <div className={'login'}>
        <Link className={'logoLink'} to='/'><img src={process.env.REACT_APP_IMG_URL + '/course/freelesson_bi.svg'} alt={'view off'} /></Link>
        <div className={'loginContent'}>
          <div className={'pageTit'}>로그인</div>
          <div className='loginForm'>
            <form>
              <input type='email' className={this.state.idError ? 'error' : ''} value={this.state.socialType ? '' : this.state.id} autoComplete='uid' autoCapitalize='off' name='uid' id='uid' onChange={this.inputID} placeholder='이메일 또는 기업회원ID' onBlur={this.onBlurID} />
              {this.state.idError && <div className='error errorMsg'>{this.state.idError}</div>}
              <div className={'box'}>
                <input className={'msClear ' + (this.state.pwError ? 'error' : '')} autoComplete='upw' name='upw' id='upw' onChange={this.inputPW} value={this.state.pw} placeholder='비밀번호(8~16자 영문, 숫자, 특수문자)' type={this.state.pwView} onBlur={this.onBlurPW} />
                <div className={'viewIcon'} onClick={() => this.onChangePwView(1)}>
                  {this.state.pwView === 'password' ? <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOff.svg'} alt={'view off'} /> : <img src={process.env.REACT_APP_IMG_URL + '/join/ico_eyeOn.svg'} alt={'view on'} />}
                </div>
              </div>

              {this.state.pwError && <div className='error errorMsg'>{this.state.pwError}</div>}
              <button onClick={this.loginAccess} className='btn'>로그인</button>
            </form>
            <hr className={'socialLine'} />
            <div className='login-corner'>

              <KakaoLoginButton
                token={kakao_client_id}
                onSuccess={(response) => { this.kakaoLoginInit(response); }}
                onFail={(error) => { this.onErrorKakaoLogin(error); }}
                render={(props) =>
                  <div {...props} className='login-corner__button login-corner__button--kakao'>
                    <img src={process.env.REACT_APP_IMG_URL + '/join/icon_kakao.svg'} alt={'kakao'} />
                    <p>카카오로 계속하기</p>
                  </div>
                }
              />
              <div className='login-corner__button' onClick={this.handleLoginByNaverClick}>
                <img src={`${process.env.REACT_APP_IMG_URL}/join/icon_naver_2.svg`} alt='Naver login' />
                <p>네이버로 로그인</p>
                <div className='naverBtn' id='naverIdLogin' />
              </div>
              <AppleSignin
                authOptions={this.appleAuthOptions}
                uiType='dark'
                className='apple-auth-btn'
                onSuccess={(response) => { this.onSuccessAppleLogin(response); }}
                onError={(error) => { this.onErrorAppleLogin(error); }}
                skipScript={false}
                render={(props) =>
                  <div {...props} className='login-corner__button'>
                    <img src={`${process.env.REACT_APP_IMG_URL}/join/icon_apple.svg`} alt='Apple login' />
                    <p>Apple로 로그인</p>
                  </div>
                }
              />
            </div>
          </div>
          <div className='memCont'>
            <Link to='/Findemail'>이메일/ID 찾기</Link>
            <span className={'bar'}><img src={process.env.REACT_APP_IMG_URL + '/join/ico_bar.svg'} alt={'vertical'} /></span>
            <Link to='/ResetPW'>비밀번호 재설정</Link>
            <span className={'bar'}><img src={process.env.REACT_APP_IMG_URL + '/join/ico_bar.svg'} alt={'vertical'} /></span>
            <Link to='/Join'>회원가입</Link>
          </div>
        </div>
        {this.state.layerPopupMessage &&
          <LayerPopup
            desc1={this.state.layerPopupMessage}
            btnCnt={1}
            btnOkCmt={'확인'}
            btnOkFunction={() => { this.setState({ layerPopupMessage: '' }); }}
          />}
        {this.state.socialPopup &&
          <LayerPopup
            tit={'가입 또는 연결되어 있지 않은 소셜 아이디 입니다. 회원가입 페이지로 이동합니다.'}
            btnCnt={1}
            btnOkCmt={'확인'}
            btnOkFunction={() => this.goJoin()}
          />
        }
      </div>
    );
  }
}
